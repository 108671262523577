<div class="page-container" *ngIf="user">

    <h1>Select a Division</h1>
    <div class="content-container">
        <div class="audit-tool-card more-list-screen-card">

            <form autocomplete="off" #storeSelection="ngForm"  class="audit-tool-cards-container"
                (submit)="switchDivision()">

                <!-- For Internal audit assessemnt only -->

                <div *ngIf="user['division'] !== 'DC'" class="create-survey-form">
                    <p>You are able to access iVerify for multiple divisions, please select the division you would like to use.</p>

                
                    <mat-form-field appearance="outline" color="primary">
                        <mat-label class="" for="storeNumber">Division</mat-label>
                        <mat-select #store [(ngModel)]="selectedDivision" class="custom-control-input"
                            name="store" required>
                            <mat-option *ngFor="let division of availableDivisions" [value]="division">
                                {{division | unescape}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Please select a Division</mat-error>
                    </mat-form-field>
                </div>

                <div class="submit-button-container">
                    <div class="survey-link-container">
                        <div *ngIf="!selectedDivision" class="subtle-centered-text">
                            Please select a division.
                        </div>
                        <button #submitButton [disabled]="!selectedDivision"
                            class="csa-button-solid survey-submit" type="submit">
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>