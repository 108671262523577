import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent, DialogData, DialogMode } from '../shared/dialog/dialog.component';

@Component({
  selector: 'app-developer-handbook',
  templateUrl: './developer-handbook.component.html',
  styleUrls: ['./developer-handbook.component.scss']
})
export class DeveloperHandbookComponent {
  // text field value used for the text field example with length counter
  public textFieldCounterExampleValue = '';

  public searchResults: object[] = [];

  constructor(
    private dialog: MatDialog
  ) { }

  searchTermChanged(event) {
    console.log(event);
    if (event.length >= 2) {
      this.searchResults = [{ id: 1, name: 'option 1' }, { id: 2, name: 'option 2' }, { id: 3, name: 'option 3' }];
    } else {
      this.searchResults = [];
    }
  }

  selectedItem(event) {
    console.log(event);
  }

  successDialog() {
    const dialogSuccess = this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title: 'Success Title goes here...',
        message: 'Success message goes here...',
        mode: DialogMode.Success,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
    dialogSuccess.afterClosed().subscribe((result) => {
      console.log('Success dialog closed. Result:', result);
    });
  }

  errorDialog() {
    const dialogSuccess = this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title: 'Error Title goes here...',
        message: 'Error message goes here...',
        mode: DialogMode.Error,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
    dialogSuccess.afterClosed().subscribe((result) => {
      console.log('Error dialog closed. Result:', result);
    });
  }  

  informationDialog() {
    const dialogSuccess = this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title: 'Information Title goes here...',
        message: 'Information message goes here...',
        mode: DialogMode.Information,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
    dialogSuccess.afterClosed().subscribe((result) => {
      console.log('Information dialog closed. Result:', result);
    });
  } 

  confirmationDialog() {
    const dialogSuccess = this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title: 'Confirmation Title goes here...',
        message: 'Confirmation message goes here...',
        mode: DialogMode.Confirmation,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
    dialogSuccess.afterClosed().subscribe((result) => {
      console.log('Confirmation dialog closed. Result:', result);
    });
  }      
}
