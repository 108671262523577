<div mat-dialog-title>
  <div class="close-container">
    <span class="info-icon med-grey" (click)="closeMe()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </span>
  </div>
</div>

<div mat-dialog-content>
  <div *ngIf="loadingSwiper" class="loader-container">
    <div class="loading-wapple"></div>
  </div>



  <swiper *ngIf="!loadingSwiper && data.type === 'mrrFindings'" #sectionSwiper class="total-swiper" [centeredSlides]="true"
    [spaceBetween]="100" [initialSlide]="evidenceIndex" [lazy]="true" [autoHeight]="true" [slidesPerView]="1"
    (swiper)="onsSectionSwiper()" (slideChange)="onSectionSlideChange($event)" [navigation]="section > 1">
    <ng-template swiperSlide *ngFor="let current of evidence">
      <div class="swiper-container" *ngIf="data.type !== 'table'">



        <div *ngIf="data.type === 'mrrFindings'">
          <div class="question-count">
            <span style="color: var(--primary-color)">
              <span class="large-count">Section {{current.sectionHeadingtotalIndex}}</span> of {{data.sectiontotalNumber}}
            </span>
          </div>
          <strong>Section : </strong>{{current.sectionHeading}}
       
          <br>

        </div>
    
      </div>
    </ng-template>
  </swiper>
  <br>


  <swiper *ngIf="!loadingSwiper && data.type !== 'assessments-table' && data.type !== 'actions-table'" #totalSwiper
    class="total-swiper" [centeredSlides]="true" [spaceBetween]="100" [initialSlide]="evidenceIndex" [lazy]="true"
    [autoHeight]="true" [slidesPerView]="1" (swiper)="onTotalSwiper()" (slideChange)="onTotalSlideChange($event)"
    [navigation]="evidence.length > 1">
    <ng-template swiperSlide *ngFor="let current of evidence">
      <div class="swiper-container" *ngIf="data.type !== 'table'">

        <div *ngIf="data.type === 'survey'">
          <div class="question-count">
            <span style="color: var(--primary-color)">
              <span class="large-count">Question {{current.totalIndex}}</span> of {{data.totalNumber}}
            </span>
          </div>

          <div class="question-title center-align" [innerHTML]="current.questionTitle"></div>
          <br>
          <div class="center-align" *ngIf="current.response">
            <strong>Response: </strong>{{current.response}}
          </div>
        </div>

        <div *ngIf="data.type === 'mrrFindings'">

           
          <div class="question-count">
            <span style="color: var(--primary-color)">
              <span class="large-count">Finding {{current.totalIndex}}</span> of {{data.totalNumber}}
            </span>
          </div>
          <h4 class="primary-text center-align">Findings Title: {{current.questionTitle}}</h4>
       
          <br>
          <br>
        </div>

        <div *ngIf="data.type === 'action'">
          <div class="question-count">
            <span *ngIf="!data.showRemove">
              <span class="large-count">Update {{current.totalIndex}}</span> of {{data.totalNumber}}
            </span>
            <span *ngIf="data.showRemove">
              <span class="large-count">Update Draft</span>
            </span>
          </div>

          <div class="question-title center-align" [innerHTML]="current.notes"></div>
          <div class="center-align">
            By: <strong>{{current.addedBy}}</strong> on <strong>  {{ current.addedOn | date :'dd MMM y'  }} </strong>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
  <br>

  <swiper *ngIf="!loadingSwiper" #imageSwiper class="image-swiper" [centeredSlides]="true" [spaceBetween]="100"
    [initialSlide]="photoIndex" [autoHeight]="true" [slidesPerView]="1" (swiper)="onImageSwiper()"
    (slideChange)="onImageSlideChange($event)" [navigation]="evidence[evidenceIndex].photos.length > 1">
    <ng-template swiperSlide *ngFor="let current of evidence[evidenceIndex]['photos']; let i = index;"
      class="swiper-lazy">
      <div class="swiper-container">
        <div class="question-count">
          <span>
            <span class="large-count">Evidence {{i + 1}}</span> of {{evidence[evidenceIndex].photos.length}}
          </span>
        </div>
        <ng-container *ngIf="data.type === 'assessments-table' && data.filter === 'mrr' ">
          <div class="center-align" *ngIf="current.sectionHeading">
            <strong>Section : </strong>{{current.sectionHeading}}
          </div>
          <br>
        </ng-container>
        <ng-container *ngIf="data.type === 'assessments-table' && data.filter === 'mrr' ">
          <div class="center-align" *ngIf="current.questionTitle">
            <strong>Findings Title: </strong>{{current.questionTitle}}
          </div>
          <br>
        </ng-container>

        <ng-container *ngIf="data.type === 'assessments-table' && data.filter === 'mrr' ">

          <div  (click)="swiperUpdating()">
            <mat-expansion-panel [expanded]="allExpandState" #panel hideToggle>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<span class="neg-5-margin hide-on-mobile">
									<svg height="3" width="200">
										<line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
									</svg>
								</span>
								&nbsp;&nbsp;<span>Detailed Findings</span>&nbsp;&nbsp;
                <fa-icon *ngIf="!isDetailedFindingExpanded" [icon]="faAngleDown"></fa-icon>
                <fa-icon *ngIf="isDetailedFindingExpanded"   [icon]="faAngleUp"></fa-icon>
								&nbsp;&nbsp;
								<span class="neg-5-margin hide-on-mobile">
									<svg height="3" width="200">
										<line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
									</svg>
								</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
            </mat-expansion-panel>
          </div>
          <div *ngIf="!isDetailedFindingExpanded" class="detailed-finding center-align expansion_case" >{{current.questionDescription}}</div>
         
          <div *ngIf="isDetailedFindingExpanded" class="detailed-finding  center-align"  >{{current.questionDescription}}</div>
         
          <br>
        </ng-container>

        <ng-container *ngIf="data.type === 'assessments-table' && data.filter !== 'mrr' ">
          <div class="question-title center-align" [innerHTML]="current.questionTitle"></div>
          <br>
          <div class="center-align" *ngIf="current.response">
            <strong>Response: </strong>{{current.response}}
          </div>
          <br>
        </ng-container>

        <ng-container *ngIf="data.type === 'actions-table'">
          <div class="question-title center-align" [innerHTML]="current.actionTitle"></div>
          <br>
          <div class="center-align" *ngIf="current.actionNotes">
            <strong>Notes: </strong>{{current.actionNotes}}
          </div>
          <br>
        </ng-container>

        <h4 class="primary-text center-align">{{current['photoName']}}</h4>

        <div *ngIf="!current['src']" class="loader-container">
          <div class="loading-wapple"></div>
        </div>
        <div *ngIf="current['src']" #photoContainer>
          <img *ngIf="current['src'] && checkName(current['photoName']) == true && current['src'] !== 'Image not found'"
            #photoImg [attr.id]="current['fileName']" [src]="current['src']" [ngStyle]="{
                      'max-width': current['maxWidth'] + 'px', 
                      'max-height': current['maxHeight'] + 'px', 
                      'margin-top': current['marginTop'] + 'px', 
                      'margin-bottom': current['marginBottom'] + 'px',
                      'transform': 'rotate(' + current['degree'] +'deg)',
                      'transition': 'transform 500ms ease-out'
                    }">
          <pdf-viewer *ngIf="checkName(current['photoName']) == false  && current['src'] !== 'Image not found'"
            [src]="current['src']" [autoresize]="true" [original-size]="false" [show-all]="true"
            [external-link-target]="'blank'" [render-text]="true" style="display: block;">
          </pdf-viewer>
          <div *ngIf="current['src'] === 'Image not found'">
            Image not found!
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>

<div mat-dialog-actions class="remove-download" *ngIf="!loadingSwiper">
  <div class="question-options-row">
    <div class="filter-chips">
      <div class="chipDrop" *ngIf="data.showRemove == true">
        <mat-chip-list aria-label="Filters">
          <mat-chip color="warn" selected (click)="deletePhoto()">
            <fa-icon class="chip-left-icon" [icon]="faTrash"></fa-icon>
            <span>Remove</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="chipDrop" *ngIf="checkName(evidence[evidenceIndex].photos[photoIndex]['photoName']) == true">
        <mat-chip-list aria-label="Filters">
          <mat-chip color="primary" selected (click)="rotatePhoto()">
            <fa-icon class="chip-left-icon" [icon]="faRedo"></fa-icon>
            <span>Rotate</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="chipDrop" *ngIf="data.type === 'assessments-table' || data.type === 'actions-table'">
        <mat-chip-list aria-label="Filters">
          <mat-chip color="primary" selected (click)="viewResponseAction()">
            <fa-icon class="chip-left-icon" [icon]="faEye"></fa-icon>
            <span>View {{ (data.type === 'assessments-table' && data.filter === 'mrr') ? 'Assessment' : (data.type === 'assessments-table' && data.filter !== 'mrr') ? 'Response' :'Action'}}</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip color="primary" selected (click)="downloadSelect.open()">
            <fa-icon class="chip-left-icon" *ngIf="!isDownloading" [icon]="faDownload"></fa-icon>
            <span *ngIf="!isDownloading">Download</span>

            <fa-icon class="chip-left-icon" *ngIf="isDownloading" [icon]="faCircleNotch" [spin]="true"></fa-icon>
            <span *ngIf="isDownloading">Downloading</span>

          </mat-chip>
        </mat-chip-list>
        <mat-select #downloadSelect class="optionsDropDown" panelClass="download-panel">
          <mat-option
            *ngIf="evidence[evidenceIndex].photos[photoIndex]['src'] && evidence[evidenceIndex].photos[photoIndex]['src'] !== 'Image not found'"
            (click)="downloadPhoto(evidence[evidenceIndex].photos[photoIndex])">
            Current Evidence
          </mat-option>
          <mat-option *ngIf="data.type === 'survey' && evidence[evidenceIndex].photos.length > 1"
            (click)="downloadPhotosZip()">
            Question Evidence
          </mat-option>

          <mat-option *ngIf="data.type === 'mrrFindings' && evidence[evidenceIndex].photos.length > 1"
            (click)="downloadPhotosZip()">
            Findings Evidence
          </mat-option>


          <mat-option *ngIf="data.type === 'action' && evidence[evidenceIndex].photos.length > 1"
            (click)="downloadPhotosZip()">
            Update Evidence
          </mat-option>
          <mat-option
            (click)="data.type === 'assessments-table' || data.type === 'actions-table' ? downloadPhotosZip() : downloadAllZip()">
            All Evidence
          </mat-option>
        </mat-select>



      </div>
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip class="clear-chip" (click)="updateSwiper()" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faRedo"></fa-icon>
            <span>Reload</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
</div>