<div class="page-container">

  <div class="oldDashboardBanner">
    <h2>Heads up! You're viewing the old iVerify dashboard and it will be retired soon.</h2>
    <p>
      We're keeping the old iVerify dashboard available for a short time in case we've missed something. <a [routerLink]="['/']">You can go to the new iVerify dashboard by clicking here.</a>
    </p>

    <p>
      If there is something that you can only find in the old dashboard or if there's something you find easier to do using the old dashboard, we'd like to hear your feedback. Please complete the form here.
    </p>
  </div>
  <div class="audit-tool-cards-container">
    <div class="question-options-row">
      <div class="filter-chips question-options">
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters">
            <mat-chip [routerLink]="['/admin-landing/reports']" color="primary" selected
              *ngIf="user['role'] !== 'StoreLossPartner'">
              <fa-icon class="chip-left-icon" [icon]="faChartPie"></fa-icon>
              <span>Charts</span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="chipDrop">
          <mat-chip-list>
            <mat-chip color="primary" selected (click)="goToReports()">
              <fa-icon class="chip-left-icon" [icon]="faTable"></fa-icon>
              <span>Reports & Extracts</span>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>

  <div class="list-screen-container audit-tool-cards-container">
    <div class="audit-tool-card list-screen-card"
      *ngIf="user['role'] !== 'StoreLossPartner' && user['role'] !== 'StoreLossManager'">
      <h1>Once Off Assessments</h1>
      <div class="survey-line survey-heading-line no-ripple">
        <div class="survey-stat template-stat">
          <h4>Assessment</h4>
        </div>
        <div class="survey-stat template-stat">
          <h4>Due Date</h4>
        </div>
        <div class="survey-stat template-stat">
          <h4>% Complete</h4>
        </div>
        <div class="survey-stat template-stat hide-on-mobile">
          <h4>Open/Created Actions</h4>
        </div>
      </div>

      <div *ngIf="!isLoadingOnceOff">
        <div *ngFor="let survey of onceOffSurveys">
          <a class="survey-details-link" (click)="surveyDetails(survey['avgCompletionRate'])"
            [routerLink]="['/above-store-landing/once-off-survey-details', survey.id]">
            <div class="survey-line">
              <div class="survey-stat template-stat">
                <h3><span *ngIf="survey['targetStores']" class="infoTag">Targeted</span>
                  &nbsp;{{survey['title'].slice(0, 50) | unescape}}</h3>
              </div>
              <div class="survey-stat template-stat">
                <h4>{{getDate(survey.dueDate)}}</h4>
              </div>
              <div class="survey-stat template-stat">
                <h1>{{survey['avgCompletionRate'] | percent}}</h1>
              </div>
              <div class="survey-stat template-stat hide-on-mobile">
                <h3 *ngIf="!survey['actionsOutstanding'] && !survey['actionsCreated']">0 / 0 </h3>
                <h3 *ngIf="!survey['actionsOutstanding'] && survey['actionsCreated']">0 / {{survey['actionsCreated']}}
                </h3>
                <h3 *ngIf="survey['actionsOutstanding'] && survey['actionsCreated']">{{survey['actionsOutstanding']}} /
                  {{survey['actionsCreated']}} </h3>
              </div>
            </div>
          </a>
        </div>

        <h3 class="none-found-message" *ngIf="onceOffSurveys.length == 0">
          You currently have no Active Once-Off Assessments.
        </h3>
      </div>

      <div *ngIf="isLoadingOnceOff" class="loader-container">
        <div class="loading-wapple"></div>
      </div>

      <a [routerLink]="['/above-store-landing/above-store-more-surveys']">
        <button class="csa-button-solid">
          More
        </button>
      </a>
    </div>


    <div class="audit-tool-card list-screen-card">
      <h1 *ngIf="user['role'] !== 'StoreLossPartner' && user['role'] !== 'StoreLossManager' ">Adhoc Assessments</h1>
      <h1 *ngIf="user['role'] === 'StoreLossPartner' || user['role'] === 'StoreLossManager'">Store Loss Assessments</h1>
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center"
        (selectedTabChange)="adhocTabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of adhocSurveyGroups">

          <div class="survey-line survey-heading-line no-ripple">
            <div class="survey-details-link center-align">
              <h4>Assessment</h4>
            </div>
            <div class="survey-details-link center-align">
              <h4>Active Responses</h4>
            </div>
            <div class="survey-details-link center-align">
              <h4>Create New</h4>
            </div>
          </div>

          <div *ngIf="!isLoadingAdhoc">
            <div *ngFor="let survey of adhocSurveys; let i=index">
              <div class="survey-line">
                <div class="survey-details-link" [routerLink]="['/onthefly-survey-details', survey.id]">
                  <h3 class="grey-text center-vertical">
                    <span *ngIf="survey['filterName']" class="infoTag">{{survey['filterName'] | uppercase}}</span>
                    &nbsp;{{survey['title'].slice(0, 50) | unescape}}
                    <span *ngIf="survey['title']?.length > 50">...</span>
                  </h3>
                </div>
                <div class="survey-details-link hide-on-mobile center-vertical"
                  [routerLink]="['/onthefly-survey-details', survey.id]">
                  <h1>{{survey['activeResponseCount']}}</h1>
                </div>
                <div class="survey-details-link survey-button"
                  *ngIf="survey['targetRoles']?.indexOf(user['role']) > -1 && survey['status'] === 'published'">
                  <button class="csa-button-solid" (click)="createOnTheFlySurvey(survey.id)">
                    <span>
                      <fa-icon [icon]="faPlus"></fa-icon> Create
                    </span>
                  </button>
                </div>

                <div class="survey-details-link survey-button"
                  *ngIf="survey['status'] === 'closed' && survey['targetRoles']?.indexOf(user['role']) > -1">
                  <div class="light-grey medium-text" *ngIf="survey['daysToEdit'] == 1">
                    Last day to Edit Active Responses
                  </div>
                  <div class="light-grey medium-text" *ngIf="survey['daysToEdit'] > 1">
                    {{survey['daysToEdit']}} Days to Edit Active Responses
                  </div>
                </div>

                <div class="survey-details-link survey-button"
                  *ngIf="survey['targetRoles']?.indexOf(user['role']) == -1 || survey['status'] === 'active'"
                  [routerLink]="['/onthefly-survey-details', survey.id]">
                  Available to <span *ngFor="let role of survey['targetRoles']; let j=index">{{splitByCaps(role)}}<span
                      *ngIf="survey['targetRoles']?.length - 1 > j">,&nbsp;</span></span>
                </div>
              </div>
            </div>

            <h3 class="none-found-message"
              *ngIf="adhocSurveys.length == 0 && (user['role'] !== 'StoreLossPartner'&& user['role'] !== 'StoreLossManager')">
              You currently have no Available Adhoc Assessments.
            </h3>
            <h3 class="none-found-message"
              *ngIf="adhocSurveys.length == 0 && (user['role'] === 'StoreLossPartner' || user['role'] === 'StoreLossManager')">
              You currently have no Available Store Loss Assessments.
            </h3>
          </div>

          <div *ngIf="isLoadingAdhoc" class="loader-container">
            <div class="loading-wapple"></div>
          </div>

          <a [routerLink]="['/more-onthefly-surveys', {status: 'active',type: 'adhoc'}]">
            <button class="csa-button-solid">
              More
            </button>
          </a>

        </mat-tab>
      </mat-tab-group>
    </div>


    <div class="audit-tool-card list-screen-card"
      *ngIf="user['role'] !== 'StoreLossPartner'&& user['role'] !== 'StoreLossManager'">
      <h1>Recurring Assessments</h1>

      <mat-tab-group #recurringTabGroup dynamicHeight="false" mat-align-tabs="center"
        (selectedTabChange)="recurringTabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of recurringSurveyGroups">

          <div class="survey-line survey-heading-line no-ripple">
            <div class="survey-details-link center-align">
              <h4>Assessment</h4>
            </div>
            <div class="survey-details-link center-align hide-on-mobile">
              <h4>Active Assessment Completion</h4>
            </div>
            <div class="survey-details-link center-align hide-on-mobile">
              <h4>Next Publish Date</h4>
            </div>
          </div>

          <div *ngIf="!isLoadingRecurring">
            <div *ngFor="let survey of recurringSurveys; let i=index">
              <a class="survey-details-link" [routerLink]="['/recurring-survey-details', survey.id]">
                <div class="survey-line">
                  <div class="survey-stat template-stat">
                    <h3>
                      {{survey['title'].slice(0, 50) | unescape}}
                      <span *ngIf="survey['title']?.length > 50">...</span>
                    </h3>
                  </div>
                  <div class="survey-stat template-stat hide-on-mobile" *ngIf="surveyGroup === 'Available'">
                    <h1 *ngIf="survey['avgCompletionRate'] || survey['avgCompletionRate'] == 0">
                      {{survey['avgCompletionRate'] | percent}}
                    </h1>
                    <p *ngIf="!survey['avgCompletionRate'] && survey['avgCompletionRate'] != 0">
                      No Active Assessment
                    </p>
                  </div>
                  <div class="survey-stat template-stat hide-on-mobile">
                    {{ getDate(survey['nextPublishDate'])}}
                  </div>
                </div>
              </a>
            </div>

            <h3 class="none-found-message" *ngIf="recurringSurveys.length == 0">
              You currently have no Available Recurring Assessments.
            </h3>
          </div>

          <div *ngIf="isLoadingRecurring" class="loader-container">
            <div class="loading-wapple"></div>
          </div>

          <a [routerLink]="['/more-recurring-surveys']">
            <button class="csa-button-solid">
              More
            </button>
          </a>
        </mat-tab>
      </mat-tab-group>
    </div>


    <div class="audit-tool-card list-screen-card">
      <h1>Open Actions</h1>

      <div class="survey-line survey-heading-line no-ripple">
        <div class="survey-stat template-stat">
          <h4>Action Title</h4>
        </div>
        <div class="survey-stat template-stat">
          <h4>Store</h4>
        </div>
        <div class="survey-stat template-stat">
          <h4>Assessment</h4>
        </div>
        <div class="survey-stat template-stat hide-on-mobile">
          <h4>Description</h4>
        </div>
      </div>

      <div *ngIf="!isLoadingActions">
        <div *ngFor="let action of actions">
          <a class="survey-details-link" [routerLink]="['/action-details', action.id]">
            <div class="survey-line">
              <div class="survey-stat template-stat center-align">
                <h4> {{action['title'].slice(0, 35) | unescape}}
                  <span *ngIf="(action.title.length > 35)">...</span>
                </h4>
                <p [ngClass]="{
                              'orange-text' : isDueInWeek(getDate(action.dueDate)) == true && action['status'] === 'pending',
                              'red-text' : isOverdue(getDate(action.dueDate)) == true && action['status'] === 'pending'
                              }">
                  Due: {{getDate(action.dueDate).slice(0, -4)}}
                </p>
              </div>
              <div class="survey-stat template-stat">
                <h4>{{action['storeID']}}</h4>
              </div>
              <div class="survey-stat template-stat">
                <div>{{action['surveyTitle'] | unescape}}</div>
              </div>
              <div class="survey-stat template-stat hide-on-mobile">
                <p>
                  {{action['description'].slice(0, 100) | unescape}}
                  <span *ngIf="(action.title.length > 100)">...</span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div *ngIf="actions.length == 0">
          <h3 class="none-found-message">
            There are currently no Open Actions
          </h3>
        </div>
      </div>

      <div *ngIf="isLoadingActions" class="loader-container">
        <div class="loading-wapple"></div>
      </div>

      <a [routerLink]="['/actions-drill-down']">
        <button class="csa-button-solid">
          More
        </button>
      </a>
    </div>



    <!-- MRR Survey Assessment -->
  <div *ngIf="(user['division'] === 'Supermarkets')">
    <div class="audit-tool-card list-screen-card" *ngIf="user['role'] !== 'StoreLossPartner' && user['role'] !== 'StoreLossManager'">
      <h1>Material Risk Review Assessments</h1>
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center"
        (selectedTabChange)="mrrTabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of mrrSurveyGroups">
          <div class="survey-line survey-heading-line no-ripple">
            <div class="survey-stat template-stat">
              <h4>Assessment</h4>
            </div>
            <div class="survey-stat template-stat">
              <h4>Sections</h4>
            </div>
            <div *ngIf="surveyGroup !== 'Upcoming'" class="survey-stat template-stat hide-on-mobile">
              <h4>Responses</h4>
            </div>
            <!-- <div *ngIf="surveyGroup == 'Available'" class="survey-stat template-stat">
              <h4>Create Draft</h4>
            </div> -->
            <div *ngIf="surveyGroup === 'Upcoming'" class="survey-stat template-stat">
              <h4>Publish Date</h4>
            </div>
            <div *ngIf="surveyGroup === 'Closed'" class="survey-stat template-stat">
              <h4>End Date</h4>
            </div>
          </div>

          <div *ngIf="!isLoadingMRR">
            <div *ngFor="let survey of mrrSurveys">
              <div class="survey-line">
                <div class="survey-details-link" [routerLink]="['/mrr-survey-details', survey.id]">
                  <h3 class="grey-text center-vertical">
                    <!-- <span *ngIf="survey['filterName']" class="infoTag">{{survey['filterName'] | uppercase}}</span>
                    &nbsp;{{survey['title'].slice(0, 50) | unescape}} -->
                    {{survey['title'].slice(0, 50) | unescape}}
                    <span *ngIf="survey['title']?.length > 50">...</span>
                  </h3>
                </div>

                <div class="survey-details-link center-vertical" [routerLink]="['/mrr-survey-details', survey.id]">
                  <h1>{{survey['sectionHeading']?.length || '0' }}</h1>
                </div>

                <div *ngIf="surveyGroup !== 'Upcoming'" class="survey-details-link hide-on-mobile center-vertical"
                  [routerLink]="['/mrr-survey-details', survey.id]">
                  <h1>{{survey['activeResponseCount']}}</h1>
                </div>

                <div *ngIf="surveyGroup === 'Upcoming'" class="survey-details-link center-vertical"
                  [routerLink]="['/mrr-survey-details', survey.id]">
                  <h3>{{getDate(survey.startDate)}}</h3>
                </div>

                <div *ngIf="surveyGroup === 'Closed'" class="survey-details-link center-vertical"
                  [routerLink]="['/mrr-survey-details', survey.id]">
                  <h3>{{getDate(survey.endDate)}}</h3>
                </div>
              </div>
            </div>

            <h3 class="none-found-message" *ngIf="mrrSurveys.length == 0">
              You currently have no {{surveyGroup}} Material Risk Review Assessments.
            </h3>
          </div>

          <div *ngIf="isLoadingMRR" class="loader-container">
            <div class="loading-wapple"></div>
          </div>

          <a [routerLink]="['/more-mrr-surveys', {status: surveyGroup === 'Available' ? 'published' : 'active', type: 'adhoc'}]">
            <button class="csa-button-solid">More</button>
          </a>
        </mat-tab>
      </mat-tab-group>
    </div>
    </div>

    <!-- MRR Actions -->

    <div *ngIf="(user['division'] === 'Supermarkets')">
      <div class="audit-tool-card list-screen-card" *ngIf="user['role'] !== 'StoreLossPartner' && user['role'] !== 'StoreLossManager'">
        <h1>Material Risk Review Actions</h1>
        <div class="survey-line survey-heading-line no-ripple">
          <div class="survey-stat template-stat">
            <h4>Action Title</h4>
          </div>
          <div class="survey-stat template-stat">
            <h4>Store</h4>
          </div>
          <div class="survey-stat template-stat">
            <h4>Assessment</h4>
          </div>
          <div class="survey-stat template-stat hide-on-mobile">
            <h4>Description</h4>
          </div>
        </div>


        <div *ngIf="!isLoadingActions">
          <div *ngFor="let action of mrrActions">
            <a class="survey-details-link" [routerLink]="['/mrr-action-details', action.id]">
              <div class="survey-line">
                <div class="survey-stat template-stat center-align">
                  <h4> {{action['title'].slice(0, 35) | unescape}}
                    <span *ngIf="(action.title.length > 35)">...</span>
                  </h4>
                  <p [ngClass]="{
                                'orange-text' : isDueInWeek(getDate(action.dueDate)) == true && action['status'] === 'pending',
                                'red-text' : isOverdue(getDate(action.dueDate)) == true && action['status'] === 'pending'
                                }">
                    Due: {{getDate(action.dueDate).slice(0, -4)}}
                  </p>
                </div>
                <div class="survey-stat template-stat">
                  <h4>{{action['storeID']}}</h4>
                </div>
                <div class="survey-stat template-stat">
                  <div>{{action['surveyTitle'] | unescape}}</div>
                </div>
                <div class="survey-stat template-stat hide-on-mobile">
                  <p>
                    {{action['description'].slice(0, 100) | unescape}}
                    <span *ngIf="(action.title.length > 100)">...</span>
                  </p>
                </div>
              </div>
            </a>
          </div>

          <h3 class="none-found-message" *ngIf="actions.length == 0">
            There are currently no Open Actions.
          </h3>
        </div>

        <div *ngIf="isLoadingActions" class="loader-container">
          <div class="loading-wapple"></div>
        </div>

        <a [routerLink]="['/mrr-actions-drill-down']">
          <button class="csa-button-solid">More</button>
        </a>
    </div>
  </div>

  </div>
     <!-- MRR Actions -->
