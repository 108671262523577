import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import {
  faRedoAlt,
  faSortDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { MessagesComponent } from '../messages/messages.component';
import { StateService } from '../../common-services/state.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { LoaderService } from '../../common-services/loader.service';
import { PhotoCarouselComponent } from '../photo-carousel/photo-carousel.component';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-more-evidence',
  templateUrl: './more-evidence.component.html',
  styleUrls: ['./more-evidence.component.scss'],
})
export class MoreEvidenceComponent implements OnDestroy, AfterViewInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  compState = (val1, val2) => val1 == val2;

  faSortDown = faSortDown;

  faTimes = faTimes;

  faRedoAlt = faRedoAlt;

  division: string = '';

  evidenceGroups: string[] = ['assessments', 'actions'];

  evidenceToShow: object = {
    assessments: [],
    actions: [],
  };

  evidenceReturned: object = {
    assessments: false,
    actions: false,
  };

  filtered: boolean = false;

  index: number = 0;

  noEntries: string = '';

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  searching: boolean = false;

  surveysSearching: boolean = false;

  sectionHeadingsSearching: boolean = false;

  controlNamesSearching: boolean = false;

  surveySearchTimer: NodeJS.Timeout;

  sectionHeadingSearchTimer: NodeJS.Timeout;

  controlNameSearchTimer: NodeJS.Timeout;

  storeSearchTimer: NodeJS.Timeout;

  selectedAssessment: string = '';

  selectedSectionHeading: string = '';

  selectedControlName: string = '';

  selectedTab: number = 0;

  selectedStore: object = { Store_No: '', Location_Name: 'All Stores' };

  storeName: string = 'All Stores';

  storeNumber: string = '';

  storesReturned: any[] = [];

  surveyFilter: string = '';

  sectionFilter: string = '';

  controlFilter: string = '';

  surveysReturned: any[] = [];

  sectionHeadingsReturned: any[] = [];

  controlNamesReturned: any[] = [];

  // tableColumns: string[] = ["action", "description", "updates"];
  totalCount: number = 0;

  typeFilter: string = '';

  user: User = {};

  isLoading = false;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  noEvidence: object = {
    assessments: '',
    actions: '',
  };

  evidenceType: string = 'assessments';

  tableKeys: object = {};

  tableColumns: object = {};

  evidenceTypeRouter;

  tableColumnObject: string[];

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private actRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private router: Router,
    private state: StateService,
    private loader: LoaderService,
    private surveyService : SurveysService
  ) {
    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getEvidenceType();
        this.user = this.csaAuth.user;
        this.division = this.user['division'];
        this.setTableColumnsBasedOnEvidenceType(this.evidenceTypeRouter);
  
        if (
          this.state.state
        && this.state.origin == 'more-evidence'
        && this.user['division'] == this.state.state.division // If Super user, division will have changed, in which case we can ignore the state
        ) {
          console.log('rebuilding from state');
          for (const key in this.state.state) {
            this[key] = this.state.state[key];
          }
        }
      }

      if (event instanceof NavigationEnd) {
        this.resetFilters();
        this.getEvidence();
      }
    });
  }

  /**
   * This function throw error when evidenceType is not there.
   * This component is common for standard evidence and MRR evidence.
   * For standard evidence  'evidenceType' param will be 'standard'.
   * For MRR evidence  'evidenceType' param will be 'mrr'.
   */
  getEvidenceType() {
    try {
      this.evidenceTypeRouter = this.actRoute.snapshot.paramMap.get('evidenceType');
      // Try and get the type based on its path
      // This is due to using the new SubfeatureRouteGuard and needing to guard for each evidence path specifically. 
      if (!this.evidenceTypeRouter) {
        if (this.actRoute.snapshot.url.length > 1) {
          this.evidenceTypeRouter = this.actRoute.snapshot.url[1].path;
        }
      }
    } catch (err) {
      throw new Error('Required route param evidenceType not found');
    }
  }

  /**
   * This function set the table column names and key names as per evidenceType.
   *
   * @param evidenceType - Will be 'standard' or 'mrr' as per routing from standard evidence or MRR evidence respectively
   *  for MRR evidence controlName not included.
   */
  setTableColumnsBasedOnEvidenceType(evidenceType:'standard'|'mrr') {
    switch (evidenceType) {
      case 'standard':
        this.tableColumns = {
          storeID: 'Store ID',
          photoName: 'Photo Name',
          surveyTitle: 'Assessment',
          sectionHeading: 'Section Heading',
          controlName: 'Control Name',
          date: 'Date',
        };
        this.typeFilter = '';
        break;
      case 'mrr':
        this.tableColumns = {
          storeID: 'Store ID',
          photoName: 'Photo Name',
          surveyTitle: 'Assessment',
          sectionHeading: 'Section Heading',
          date: 'Date',
        };
        this.typeFilter = `&filterName=${this.evidenceTypeRouter}`;
        break;
      default:
        break;
    }
    this.tableKeys = this.tableColumns;
    this.tableColumnObject = Object.keys(this.tableColumns);
  }

  ngAfterViewInit(): void {
    this.getPagination();
    console.log(this);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.setState();
  }

  setState() {
    if (this.saveState) {
      console.log('saving state');
      this.state.origin = 'more-evidence';
      this.state.state = {
        controlFilter: this.controlFilter,
        controlNamesReturned: this.controlNamesReturned,
        division: this.division,
        evidenceReturned: this.evidenceReturned,
        evidenceToShow: this.evidenceToShow,
        evidenceType: this.evidenceType,
        filtered: this.filtered,
        index: this.index,
        noEntries: this.noEntries,
        noEvidence: this.noEvidence,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sectionFilter: this.sectionFilter,
        sectionHeadingsReturned: this.sectionHeadingsReturned,
        selectedAssessment: this.selectedAssessment,
        selectedControlName: this.selectedControlName,
        selectedSectionHeading: this.selectedSectionHeading,
        selectedStore: this.selectedStore,
        selectedTab: this.selectedTab,
        storeName: this.storeName,
        storeNumber: this.storeNumber,
        storesReturned: this.storesReturned,
        surveyFilter: this.surveyFilter,
        surveysReturned: this.surveysReturned,
        tableKeys: this.tableKeys,
        totalCount: this.totalCount,
        typeFilter: this.typeFilter,
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  // Check if any filters have been selected
  checkFilters() {
    if (
      !this.selectedAssessment
      && !this.selectedSectionHeading
      && !this.selectedControlName
      && !this.storeNumber
    ) {
      this.filtered = false;
    } else {
      this.filtered = true;
    }
  }

  // If any filters selected, show "Clear" button
  showReset(): boolean {
    this.checkFilters();
    return this.filtered;
  }

  // Reset all filters
  resetFilters(): void {
    this.selectedAssessment = '';
    this.surveysReturned = [];
    this.selectedSectionHeading = '';
    this.sectionHeadingsReturned = [];
    this.selectedControlName = '';
    this.controlNamesReturned = [];
    this.setStore();
  }

  // Change table tab
  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedTab = tabChangeEvent.index;
    this.evidenceType = this.evidenceGroups[this.selectedTab];
    this.getEvidence();
  }

  // Empty evidence in table
  resetEvidence() {
    this.evidenceToShow = {
      assessments: [],
      actions: [],
    };
    this.evidenceReturned = {
      assessments: false,
      actions: false,
    };
  }

  // Get next page in table
  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getPagination();
  }

  // Return table to first page and original size
  resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
  }

  // Fetch evidence using current tab and filters
  getEvidence() {
    this.checkFilters();

    // Do not call evidence if !filtered
    if (this.filtered) {
      if (!this.evidenceReturned[this.evidenceType]) {
        setTimeout(() => {
          this.isLoading = true;
          this.evidenceToShow[this.evidenceType] = [];
        }, 0);

        this.surveyService
          .getEvidenceList(
            this.selectedAssessment,
            this.selectedSectionHeading,
            this.selectedControlName,
            this.storeNumber,
            this.evidenceType,
            this.createInitialFilter()
          )
          .subscribe({
            next: (message) => {
              console.log(message);
              this.evidenceToShow[this.evidenceType] = message['evidence'];
              this.evidenceReturned[this.evidenceType] = true;
              this.noEvidence[this.evidenceType] = 'No results to show.';
              this.isLoading = false;
              this.getPagination();
            },
            error: (e) => {
              console.log(e);
              this.evidenceReturned[this.evidenceType] = true;
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'An Error Has Occured',
                  message:
                    `An Error was returned when trying to list evidence. This may be due to the amount of evidence for your current filters, please try narrowing down your search. Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
              this.noEvidence[this.evidenceType] = 'There are too many results to show, please narrow down your search.';
              this.isLoading = false;
              this.getPagination();
            },
          });
      } else {
        // this.noEvidence = "No results to show.";
        this.getPagination();
      }
    } else {
      this.loader.hide('');
      this.noEvidence[this.evidenceType] = 'Please select at least one filter in order to show data.';
      this.evidenceToShow[this.evidenceType] = [];
      this.getPagination();
    }
  }

  createInitialFilter(): string {
    const filter = `?division=${this.user['division']}${this.typeFilter}`;
    return filter;
  }

  // Reset filters beneath assessement and fetch section heading and control names to match. Update evidence for filters.
  assessmentFilter(surveyTitle) {
    this.selectedAssessment = surveyTitle;
    this.selectedSectionHeading = '';
    this.selectedControlName = '';
    if (surveyTitle) {
      this.findSectionHeadings();
      this.findControlNames();
    } else {
      this.sectionHeadingsReturned = [];
      this.controlNamesReturned = [];
    }
    this.resetEvidence();
    this.getEvidence();
  }

  // Reset filters beneath section heading and fetch control names to match. Update evidence for filters.
  sectionHeadingFilter(sectionHeading) {
    this.selectedSectionHeading = sectionHeading;
    this.selectedControlName = '';

    if (sectionHeading || this.selectedAssessment) {
      this.findControlNames();
    } else {
      this.controlNamesReturned = [];
    }
    this.resetEvidence();
    this.getEvidence();
  }

  // Update evidence for filters.
  controlNameFilter(controlName) {
    this.selectedControlName = controlName;
    this.resetEvidence();
    this.getEvidence();
  }

  // Set store to empty or to current selection.
  setStore(store = null) {
    if (!store) {
      store = { Store_No: '', Location_Name: 'All Stores' };
    }

    this.selectedStore = store;
    this.storeNumber = store['Store_No'];
    this.storeName = store['Location_Name'];
    this.resetEvidence();
    this.getEvidence();
  }

  // Update datasource depending on current pagination values and update table display.
  getPagination() {
    this.dataSource = new MatTableDataSource(
      this.evidenceToShow[this.evidenceType]
    );
    this.dataSource.paginator = this.paginator;
    // this.dataSource = this.evidenceToShow[this.evidenceType].slice(this.index, this.index + this.pageSize);

    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    this.totalCount = this.evidenceToShow[this.evidenceType].length;
    if (label) {
      if (this.evidenceToShow[this.evidenceType].length == 0) {
        label.innerHTML = 'No results to show';
      } else {
        let upToCount = this.index + this.pageSize;
        if (upToCount > this.totalCount) {
          upToCount = this.totalCount;
        }

        label.innerHTML = `${String(this.index + 1)
        } to ${
          upToCount
        } of ${
          this.totalCount}`;
      }
    }
  }

  // Search for stores based on current search value.
  searchForStores(storeId) {
    this.noEntries = 'Searching...';
    this.searching = true;

    clearTimeout(this.storeSearchTimer);
    this.storeSearchTimer = setTimeout(() => {
      if (this.user['division'] == 'DC') {
        this.noEntries = 'No matching DCs found..';
      } else {
        this.noEntries = 'No matching Stores found..';
      }
      if (storeId.trim()) {
        this.surveyService.getStoreList(storeId).subscribe(
          (storeData) => {
            this.storesReturned = storeData;
            setTimeout(() => {
              this.searching = false;
            }, 1000);
          },
          (error) => {
            this.searching = false;
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'An Error Has Occured',
                message:
                  `An Error was returned when trying to find stores. Please email ${ 
                    this.user['supportEmail']
                  } for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      } else {
        this.storesReturned = [];
        this.searching = false;
      }
    }, 1000);
  }

  // Search for assessments based on current search value.
  findSurveys(surveyTitle) {
    this.noEntries = 'Searching...';
    this.surveysSearching = true;

    clearTimeout(this.surveySearchTimer);
    this.surveySearchTimer = setTimeout(() => {
      let filter = '';
      if (this.evidenceTypeRouter == 'mrr') {
        filter = '?filterName=mrr';
      }
      if (surveyTitle.trim()) {
        this.surveyService.searchSurveys(surveyTitle + filter).subscribe(
          (data) => {
            this.surveysReturned = data['surveys'];
            this.surveysReturned.sort();
            this.noEntries = 'No results found';
            setTimeout(() => {
              this.surveysSearching = false;
            }, 1000);
          },
          (error) => {
            this.surveysSearching = false;
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'An Error Has Occured',
                message:
                  `An Error was returned when trying to find surveys. Please email ${ 
                    this.user['supportEmail']
                  } for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      } else {
        this.surveysReturned = [];
        this.surveysSearching = false;
      }
    }, 1000);
  }

  // Search for section headings based on current search value.
  findSectionHeadings(sectionHeading = '') {
    if (!sectionHeading && !this.selectedAssessment) {
      this.sectionHeadingsReturned = [];
      this.noEntries = 'No results found';
    } else {
      this.noEntries = 'Searching...';
      this.sectionHeadingsSearching = true;

      clearTimeout(this.sectionHeadingSearchTimer);
      this.sectionHeadingSearchTimer = setTimeout(() => {
        let filter = `?division=${this.user['division']}`;
        if (this.evidenceTypeRouter == 'mrr') {
          filter = '?filterName=mrr';
        }
        this.surveyService
          .searchSectionHeadings(
            this.selectedAssessment,
            sectionHeading,
            filter
          )
          .subscribe(
            (data) => {
              this.sectionHeadingsReturned = data['sectionHeadings'];
              this.sectionHeadingsReturned.sort();
              this.noEntries = 'No results found';
              setTimeout(() => {
                this.sectionHeadingsSearching = false;
              }, 1000);
            },
            (error) => {
              this.sectionHeadingsSearching = false;
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'An Error Has Occured',
                  message:
                    `An Error was returned when trying to find section headings. Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
      }, 1000);
    }
  }

  // Search for control names based on current search value.
  findControlNames(controlName = '') {
    if (this.evidenceTypeRouter != 'mrr') {
      if (
        !controlName
        && !this.selectedSectionHeading
        && !this.selectedAssessment
      ) {
        this.controlNamesReturned = [];
        this.noEntries = 'No results found';
      } else {
        this.noEntries = 'Searching...';
        this.controlNamesSearching = true;

        clearTimeout(this.controlNameSearchTimer);
        this.controlNameSearchTimer = setTimeout(() => {
          let filter = `?division=${this.user['division']}`;
          if (this.evidenceTypeRouter == 'mrr') {
            filter = '?filterName=mrr';
          }
          this.surveyService
            .searchControlNames(
              this.selectedAssessment,
              this.selectedSectionHeading,
              controlName,
              filter
            )
            .subscribe(
              (data) => {
                this.controlNamesReturned = data['controlNames'];
                this.controlNamesReturned.sort();
                this.noEntries = 'No results found';
                setTimeout(() => {
                  this.controlNamesSearching = false;
                }, 1000);
              },
              (error) => {
                this.controlNamesSearching = false;
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'An Error Has Occured',
                    message:
                      `An Error was returned when trying to find control names. Please email ${ 
                        this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              }
            );
        }, 1000);
      }
    } else {
      console.log('mrr serch case.......');
    }
  }

  // Build data object for photo-carousel.
  getAllPhotos(photoIndex) {
    const photos = this.evidenceToShow[this.evidenceType].slice(
      this.index,
      this.index + this.pageSize
    );

    for (let i = 0; i < photos.length; i++) {
      if (!photos[i]['fileName']) {
        photos[i]['fileName'] = `${photos[i].surveyID
        }-${
          photos[i].questionID
        }-${
          photos[i].storeID
        }-${
          photos[i].photoName}`;
      }
    }
    const evidence = [
      {
        photos,
      },
    ];
    const type = `${this.evidenceType}-table`;

    const photoDialog = this.dialog.open(PhotoCarouselComponent, {
      data: {
        evidence,
        evidenceIndex: 0,
        photoIndex,
        showRemove: false,
        type,
        filter: this.evidenceTypeRouter,
      },
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto',
    });

    photoDialog.afterClosed().subscribe((result) => {
      if (result.status == 'view') {
        this.saveState = true;
        if (
          result.type == 'assessments-table'
          && this.evidenceTypeRouter == 'standard'
        ) {
          this.router.navigate(['/store-landing/survey-details/', result.id]);
        } else if (
          result.type == 'assessments-table'
          && this.evidenceTypeRouter == 'mrr'
        ) {
          this.router.navigate([
            '/mrr-response',
            result.id,
          ]);
        } else if (
          result.type == 'actions-table'
          && this.evidenceTypeRouter == 'standard'
        ) {
          this.router.navigate(['/action-details', result.id]);
        } else if (
          result.type == 'actions-table'
          && this.evidenceTypeRouter == 'mrr'
        ) {
          this.router.navigate(['/mrr-action-details', result.id]);
        }
      }
    });
  }
}
