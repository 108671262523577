import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  _state;

  _origin;

  get state() {
    return this._state;
  }

  set state(data) {
    this._state = data;
  }

  get origin() {
    return this._origin;
  }

  set origin(data) {
    this._origin = data;
  }
}
