import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { faCheckSquare, faPlus, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { reassignArrayPropertyValues } from 'src/app/utils/data';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { getUserLocationsQueryString } from 'src/app/utils/user/get-location-query-string';
import { createTagList } from 'src/app/utils/create-tag-list/create-tag-list';
import { TagType } from 'src/app/types/tag';
import { addCustomTagToAddToTagList } from 'src/app/utils/add-custom-property-to-add-totarget-list/add-custom-property-to-add-totarget-list';
import { splitRoleByCaps } from 'src/app/utils/user/split-role-by-caps';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { StateService } from '../../common-services/state.service';
import { StoreSelectionDialogComponent } from '../../surveys/store-selection-dialog/store-selection-dialog.component';
import { User } from '../../models/user';
import { AdhocSurveyService } from '../adhoc-survey.service';
import { CsaAuthService } from '../../auth/csa-auth.service';

type surveyGroupTypes = 'Upcoming' | 'Available' | 'My' | 'Other' | 'Closed';

@Component({
  selector: 'app-more-onthefly-surveys',
  templateUrl: './more-onthefly-surveys.component.html',
  styleUrls: ['./more-onthefly-surveys.component.scss'],
})
export class MoreOntheflySurveysComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faCheckSquare = faCheckSquare;

  faPlus = faPlus;

  faSortDown = faSortDown;

  filter: string = '';

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  selectedGroup: number = 0;

  surveyGroups: string[];

  surveyGroupTypes: surveyGroupTypes[];

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  user: User;

  isLoading: boolean = false;

  public viewAllAvailable = false;

  public viewAllUpcoming = false;

  public viewAllClosed = false;

  public viewAssigned = false;

  public viewAssignedGPSOnly = false;

  public viewOthersAssessments = false;

  public viewOthersAssessmentsGPSOnly = false;

  public viewAssignedClosedAssessmentsGPSOnly = false;

  public viewTags = false;

  public emptyAssessmentListMessage = '';

  private onDestroy$ = new Subject<void>();

  constructor(
    private csaAuth: CsaAuthService,
    private featureAccessService: FeatureAccessService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private router: Router,
    private state: StateService,
    private adhocService : AdhocSurveyService,
    private surveyService : SurveysService
  ) {}

  ngOnInit(): void {
    this.getPermissions();
    this.user = this.csaAuth.user;
    this.csaAuth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.user = user;
    });

    if (this.state.state && this.state.origin === 'more-onthefly-surveys') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }

    this.surveyGroups = [];
    this.surveyGroupTypes = [];

    if (this.viewAssigned) {
      this.surveyGroups.push('My Assessments');
      this.surveyGroupTypes.push('My');
    }
    if (this.viewOthersAssessments) {
      this.surveyGroups.push("Other's Assessments");
      this.surveyGroupTypes.push('Other');
    }
    if (this.viewAllAvailable) {
      this.surveyGroups.push('Available');
      this.surveyGroupTypes.push('Available');
    }
    if (this.viewAllUpcoming) {
      this.surveyGroups.push('Upcoming');
      this.surveyGroupTypes.push('Upcoming');
    }
    if (this.viewAllClosed) {
      this.surveyGroups.push('Closed');
      this.surveyGroupTypes.push('Closed');
    }  
    this.getInitialFilters();
  }

  getPermissions() {
    this.viewAllAvailable = this.featureAccessService.hasAccess('adhocAssessments.viewAllAvailable');
    this.viewAllUpcoming = this.featureAccessService.hasAccess('adhocAssessments.viewAllUpcoming');    
    this.viewAssigned = this.featureAccessService.hasAccess('adhocAssessments.viewAssigned');
    this.viewAssignedGPSOnly = this.featureAccessService.hasAccess('adhocAssessments.viewAssignedGPSOnly');
    this.viewOthersAssessments = this.featureAccessService.hasAccess('adhocAssessments.viewOthersAssessments');
    this.viewOthersAssessmentsGPSOnly = this.featureAccessService.hasAccess('adhocAssessments.viewOthersAssessmentsGPSOnly');
    this.viewAssignedClosedAssessmentsGPSOnly = this.featureAccessService.hasAccess('adhocAssessments.viewAssignedClosedAssessmentsGPSOnly');
    this.viewAllClosed = this.featureAccessService.hasAccess('adhocAssessments.viewAllClosed');
    this.viewTags = this.featureAccessService.hasAccess('tags.view');
  }
  
  getInitialFilters() {
    this.filter = getUserLocationsQueryString(this.csaAuth.user);
    this.getSurveysToShow();
  }

  ngAfterViewInit() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    label.innerHTML = `${String(this.index + 1)} to ${this.pageSize} of Many`;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.setState();
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'more-onthefly-surveys';
      this.state.state = {
        filter: this.filter,
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedGroup: this.selectedGroup,
        surveyGroups: this.surveyGroups,
        surveysToShow: this.surveysToShow,
        tableColumns: this.tableColumns,
        totalCount: this.totalCount,
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;

    this.getSurveysToShow();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.getSurveysToShow();
  }

  getSurveysToShow() {
    setTimeout(() => {
      this.isLoading = true;
      this.emptyAssessmentListMessage = '';
      this.surveysToShow = [];
    }, 0);

    let status = '';
    let filters = '';
    
    if (this.user['role'] === 'Support' || this.user['role'] === 'Super' || this.user['role'] === 'Admin') {
      if (this.surveyGroupTypes[this.selectedGroup] === 'Closed') {
        this.tableColumns = ['tags', 'survey', 'endDate'];
        status = 'closed';
        filters = 'adhoc';
      } else if (this.surveyGroupTypes[this.selectedGroup] === 'Available') {
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        status = 'published';
        filters = 'adhoc';
      } else if (this.surveyGroupTypes[this.selectedGroup] === 'Upcoming') {
        this.tableColumns = ['tags', 'survey', 'openDate', 'status'];
        status = 'active';
        filters = 'adhoc';
      } else if (this.surveyGroupTypes[this.selectedGroup] == 'My') {
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        status = 'published';
        filters = `adhoc&targetRoles=self${this.filter}`;
      }
    } else if (this.user['role'] == 'StoreLossPartner') {
      if (this.surveyGroupTypes[this.selectedGroup] == 'Closed') {
        this.tableColumns = ['tags', 'survey', 'endDate'];
        status = 'closed';
        filters = 'adhoc';
        if (this.viewAssignedClosedAssessmentsGPSOnly) {
          filters += '&filterName=eitb';
        }
      } else if (this.surveyGroupTypes[this.selectedGroup] == 'Other') {
        this.tableColumns = ['tags', 'survey', 'status'];
        status = 'published';
        filters = 'adhoc&targetRoles=other';
        if (this.viewOthersAssessmentsGPSOnly) {
          filters += '&filterName=eitb';
        }
      } else if (this.surveyGroupTypes[this.selectedGroup] == 'My') {
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        status = 'published';
        filters = 'adhoc&targetRoles=self';
        if (this.viewAssignedGPSOnly) {
          filters += '&filterName=eitb';
        }
      }
    } else if (this.user['role'] == 'StoreLossManager') {
      if (this.surveyGroupTypes[this.selectedGroup] == 'Closed') {
        this.tableColumns = ['tags', 'survey', 'endDate'];
        status = 'closed';
        filters = 'adhoc';
        if (this.viewAssignedClosedAssessmentsGPSOnly) {
          filters += '&filterName=eitb';
        }
      } else if (this.surveyGroupTypes[this.selectedGroup] == 'Other') {
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        status = 'published';
        filters = 'adhoc&targetRoles=other';
        if (this.viewOthersAssessmentsGPSOnly) {
          filters += '&filterName=eitb';
        }
      } else if (this.surveyGroupTypes[this.selectedGroup] == 'My') {
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        status = 'published';
        filters = 'adhoc&targetRoles=self';
        if (this.viewAssignedGPSOnly) {
          filters += '&filterName=eitb';
        }
      }
    } else if (this.user['role'] == 'Store' || this.user['role'] == 'MultiStore') {
      if (this.surveyGroupTypes[this.selectedGroup] == 'Closed') {
        this.tableColumns = ['tags', 'survey', 'endDate'];
        status = 'closed';
        filters = 'adhoc';
      } else if (this.surveyGroupTypes[this.selectedGroup] == 'Other') {
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        status = 'published';
        filters = `adhoc&targetRoles=other&storeID=${this.user['storeID']}`;
      } else if (this.surveyGroupTypes[this.selectedGroup] == 'My') {
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        status = 'published';
        filters = `adhoc&targetRoles=self&storeID=${this.user['storeID']}`;
      }
    } else if (this.surveyGroupTypes[this.selectedGroup] == 'Closed') {
      this.tableColumns = ['tags', 'survey', 'endDate'];
      status = 'closed';
      filters = 'adhoc';
    } else if (this.surveyGroupTypes[this.selectedGroup] == 'Other') {
      this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
      status = 'published';
      filters = `adhoc&targetRoles=other${this.filter}`;
    } else if (this.surveyGroupTypes[this.selectedGroup] == 'My') {
      this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
      status = 'published';
      filters = `adhoc&targetRoles=self${this.filter}`;
    } else if (this.surveyGroupTypes[this.selectedGroup] == 'Available') {
      this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
      status = 'published';
      filters = 'adhoc';
    }

    this.adhocService
      .getOnTheFlySurveytemplates(
        '',
        this.user['division'],
        status,
        `/${this.index}`,
        `/${this.pageSize}`,
        filters
      )
      .subscribe((surveysData) => {
        // Reassign all filterName property values from 'eitb' to 'GPS'
        this.surveysToShow = reassignArrayPropertyValues(surveysData['surveys'], 'filterName', 'eitb', 'GPS');
        this.surveysToShow = addCustomTagToAddToTagList(this.surveysToShow, { propertyName: 'filterName', propertyValueToMatch: 'GPS', tagType: TagType.Gps });
        this.surveysToShow = createTagList(this.surveysToShow, this.viewTags, ['addToTagList']);
        this.hasNext = surveysData['has_next'];
        this.emptyAssessmentListMessage = this.getEmptyAssessmentListMessage(this.surveyGroupTypes[this.selectedGroup]);
        this.getAdhocUsers();
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }

  getEmptyAssessmentListMessage(surveyGroupType: string): string {
    return `${surveyGroupType} Adhoc assessments are not available as of now.`;
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  getAdhocUsers() {
    this.surveysToShow.forEach((adhocSurvey) => {
      // convert to UTC and then a specific format
      if (
        adhocSurvey['status'] == 'closed'
        && moment().isAfter(
          moment(adhocSurvey['endDate'], [
            'DD/MM/YYYY',
            'DD MMM YYYY',
            moment.ISO_8601,
          ]).format('YYYY-MM-DD')
        )
      ) {
        const maxEndDate = moment(adhocSurvey['maxEndDate'], [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).format('YYYY-MM-DD');
        adhocSurvey['daysToEdit'] = moment(maxEndDate).diff(moment(), 'days') + 1;
      }
      adhocSurvey['final'] = moment()
        > moment(adhocSurvey['endDate'], [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).add(adhocSurvey['surveyValidity'], 'days');
    });
  }

  // Exposes global function splitRoleByCaps to template
  splitRoleByCaps(value): string {
    return splitRoleByCaps(value, this.user['division']);
  }

  createOnTheFlySurvey(surveyID, event) {
    event.stopPropagation();
    if (this.user['role'] == 'Store' || this.user['role'] == 'MultiStore') {
      const adhocAssessment = {};
      adhocAssessment['storeID'] = this.user['storeID'];
      adhocAssessment['survey_id'] = surveyID;

      for (let i = 0; i < this.surveysToShow.length; i++) {
        if (this.surveysToShow[i]['id'] == surveyID) {
          if (this.surveysToShow[i]['activeResponseCount'] > 0) {
            const confirmMultipleDialog = this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Active Responses',
                message:
                  `You currently have ${ 
                    this.surveysToShow[i]['activeResponseCount']
                  } Active Responses for this Assessment. Are you sure you would like to create a new one?`,
                closeText: 'Create',
                cancelText: 'See Active Responses',
              },
              backdropClass: 'dialog-backdrop',
            });
            confirmMultipleDialog.afterClosed().subscribe((result) => {
              if (result == 'logout') {
                this.adhocService
                  .createOnTheFlySurvey(adhocAssessment)
                  .subscribe((reply) => {
                    if (reply['status'] == 200) {
                      const dialogSuccess = this.dialog.open(MessagesComponent, {
                        data: {
                          heading: 'Adhoc Assessment Created',
                          closeText: 'OK',
                          message:
                            'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                      dialogSuccess.afterClosed().subscribe((result) => {
                        // window.location.reload();
                        this.router.navigate([
                          '/assessment-response-edit',
                          reply['response_id'],
                        ]);
                      });
                    } else {
                      this.dialog.open(MessagesComponent, {
                        data: {
                          heading: "Couldn't Schedule Adhoc Assessment",
                          message:
                            `Please email ${ 
                              this.user['supportEmail']
                            } for support`,
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                    }
                  });
              } else {
                this.router.navigate(['/onthefly-survey-details', surveyID]);
              }
            });
          } else {
            this.adhocService
              .createOnTheFlySurvey(adhocAssessment)
              .subscribe((reply) => {
                if (reply['status'] == 200) {
                  const dialogSuccess = this.dialog.open(MessagesComponent, {
                    data: {
                      heading: 'Adhoc Assessment Created',
                      closeText: 'OK',
                      message:
                        'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                    },
                    disableClose: true,
                    backdropClass: 'dialog-backdrop',
                  });
                  dialogSuccess.afterClosed().subscribe((result) => {
                    this.router.navigate([
                      '/assessment-response-edit',
                      reply['response_id'],
                    ]);
                  });
                } else {
                  this.dialog.open(MessagesComponent, {
                    data: {
                      heading: "Couldn't Schedule Adhoc Assessment",
                      message:
                        `Please email ${ 
                          this.user['supportEmail']
                        } for support`,
                    },
                    disableClose: true,
                    backdropClass: 'dialog-backdrop',
                  });
                }
              });
          }
        }
      }
    } else {
      const storeSelectDialog = this.dialog.open(StoreSelectionDialogComponent, {
        backdropClass: 'dialog-backdrop',
        minWidth: '70%',
        maxWidth: '90vw',
        height: 'auto',
        data: {
          allowClose: true,
          message:
            'Please select the store you are completing this Adhoc Assessment for.',
        },
      });
      storeSelectDialog.afterClosed().subscribe((result) => {
        if (result['storeNo']) {
          const adhocAssessment = {};
          adhocAssessment['storeID'] = result['storeNo'];
          adhocAssessment['survey_id'] = surveyID;
          this.surveyService
            .getAboveStoreSurveyID(
              surveyID,
              `?storeID=${adhocAssessment['storeID']}`
            )
            .subscribe((surveyDetails) => {
              if (surveyDetails['activeResponseCount'] > 0) {
                const confirmMultipleDialog = this.dialog.open(
                  MessagesComponent,
                  {
                    data: {
                      heading: 'Active Responses',
                      message:
                        `You currently have ${ 
                          surveyDetails['activeResponseCount']
                        } Active Responses for this Store & Assessment. Are you sure you would like to create a new one?`,
                      closeText: 'Create',
                      cancelText: 'See Active Responses',
                    },
                    backdropClass: 'dialog-backdrop',
                  }
                );
                confirmMultipleDialog.afterClosed().subscribe((result) => {
                  console.log(result);
                  if (result == 'logout') {
                    this.adhocService
                      .createOnTheFlySurvey(adhocAssessment)
                      .subscribe((reply) => {
                        if (reply['status'] == 200) {
                          const dialogSuccess = this.dialog.open(
                            MessagesComponent,
                            {
                              data: {
                                heading: 'Adhoc Assessment Created',
                                closeText: 'OK',
                                message:
                                  'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                              },
                              disableClose: true,
                              backdropClass: 'dialog-backdrop',
                            }
                          );
                          dialogSuccess.afterClosed().subscribe((result) => {
                            // window.location.reload();
                            this.router.navigate([
                              '/assessment-response-edit',
                              reply['response_id'],
                            ]);
                          });
                        } else {
                          this.dialog.open(MessagesComponent, {
                            data: {
                              heading: "Couldn't Schedule Adhoc Assessment",
                              message:
                                `Please email ${ 
                                  this.user['supportEmail']
                                } for support`,
                            },
                            disableClose: true,
                            backdropClass: 'dialog-backdrop',
                          });
                        }
                      });
                  } else {
                    this.router.navigate([
                      '/onthefly-survey-details',
                      surveyID,
                    ]);
                  }
                });
              } else {
                this.adhocService
                  .createOnTheFlySurvey(adhocAssessment)
                  .subscribe((reply) => {
                    if (reply['status'] == 200) {
                      const dialogSuccess = this.dialog.open(MessagesComponent, {
                        data: {
                          heading: 'Adhoc Assessment Created',
                          closeText: 'OK',
                          message:
                            'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                      dialogSuccess.afterClosed().subscribe((result) => {
                        // window.location.reload();
                        this.router.navigate([
                          '/assessment-response-edit',
                          reply['response_id'],
                        ]);
                      });
                    } else {
                      this.dialog.open(MessagesComponent, {
                        data: {
                          heading: "Couldn't Schedule Adhoc Assessment",
                          message:
                            `Please email ${ 
                              this.user['supportEmail']
                            } for support`,
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                    }
                  });
              }
            });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Adhoc Assessment Cancelled',
              message:
                'You need to select a store for your Adhoc Assessment before starting.',
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        }
      });
    }
  }
}
