import {
  Component, HostBinding, Input, OnInit 
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() color: string = 'currentcolor';

  @Input() iconName: string = 'action';

  @Input() label: string = '';

  @HostBinding('class') @Input() size: 'small' | 'medium' | 'large' = 'medium';

  public iconHtml: SafeHtml = '';

  private icons = {
    action: '<svg height="100%" width="100%" viewBox="0 0 26 26"> xmlns="http://www.w3.org/2000/svg"><path d="M20.5833 20.5833H5.41667V8.66667H20.5833M20.5833 3.25H19.5V1.08333H17.3333V3.25H8.66667V1.08333H6.5V3.25H5.41667C4.21417 3.25 3.25 4.225 3.25 5.41667V20.5833C3.25 21.158 3.47827 21.7091 3.8846 22.1154C4.29093 22.5217 4.84203 22.75 5.41667 22.75H20.5833C21.158 22.75 21.7091 22.5217 22.1154 22.1154C22.5217 21.7091 22.75 21.158 22.75 20.5833V5.41667C22.75 4.84203 22.5217 4.29093 22.1154 3.8846C21.7091 3.47827 21.158 3.25 20.5833 3.25ZM17.9075 11.9817L16.7592 10.8333L11.4725 16.12L9.17583 13.8233L8.0275 14.9717L11.4725 18.4167L17.9075 11.9817Z" fill="{{color}}"/></svg>',
    add: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 14H14V20H12V14H6V12H12V6H14V12H20V14Z" fill="{{color}}"/></svg>',
    assessment: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21.6667 2.16666H4.33334C3.14167 2.16666 2.17751 3.14166 2.17751 4.33332L2.16667 23.8333L6.5 19.5H21.6667C22.8583 19.5 23.8333 18.525 23.8333 17.3333V4.33332C23.8333 3.14166 22.8583 2.16666 21.6667 2.16666ZM21.6667 17.3333H5.60084L4.96167 17.9725L4.33334 18.6008V4.33332H21.6667V17.3333ZM11.375 15.1667H19.5V13H13.5417L11.375 15.1667ZM15.5567 8.80749C15.7733 8.59082 15.7733 8.25499 15.5567 8.03832L13.6392 6.12082C13.4225 5.90416 13.0867 5.90416 12.87 6.12082L6.5 12.4908V15.1667H9.17584L15.5567 8.80749Z" fill="{{color}}"/> </svg>',
    calendar: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.2 12.45H10.3V14.55H8.2V12.45ZM22.9 7.2V21.9C22.9 23.055 21.955 24 20.8 24H6.1C4.9345 24 4 23.055 4 21.9L4.0105 7.2C4.0105 6.045 4.9345 5.1 6.1 5.1H7.15V3H9.25V5.1H17.65V3H19.75V5.1H20.8C21.955 5.1 22.9 6.045 22.9 7.2ZM6.1 9.3H20.8V7.2H6.1V9.3ZM20.8 21.9V11.4H6.1V21.9H20.8ZM16.6 14.55H18.7V12.45H16.6V14.55ZM12.4 14.55H14.5V12.45H12.4V14.55Z" fill="{{color}}"/> </svg>',
    charts: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.5 18.1667H7.33333V10.5833H9.5V18.1667ZM13.8333 18.1667H11.6667V7.33333H13.8333V18.1667ZM18.1667 18.1667H16V13.8333H18.1667V18.1667ZM20.3333 20.3333H5.16667V5.16667H20.3333V20.3333ZM20.3333 3H5.16667C3.975 3 3 3.975 3 5.16667V20.3333C3 21.525 3.975 22.5 5.16667 22.5H20.3333C21.525 22.5 22.5 21.525 22.5 20.3333V5.16667C22.5 3.975 21.525 3 20.3333 3Z" fill="{{color}}"/> </svg> ',
    close: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M22 5.81286L20.1871 4L13 11.1871L5.81286 4L4 5.81286L11.1871 13L4 20.1871L5.81286 22L13 14.8129L20.1871 22L22 20.1871L14.8129 13L22 5.81286Z" fill="{{color}}"/> </svg> ',
    collapse: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18.5481 17.5558L12.9928 11.6636L7.43758 17.5558C6.8792 18.1481 5.97718 18.1481 5.41879 17.5558C4.8604 16.9636 4.8604 16.0068 5.41879 15.4146L11.9906 8.44419C12.549 7.85194 13.451 7.85194 14.0094 8.44419L20.5812 15.4146C21.1396 16.0068 21.1396 16.9636 20.5812 17.5558C20.0228 18.1329 19.1065 18.1481 18.5481 17.5558Z" fill="{{color}}"/> </svg> ',
    dashboard: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.5833 5.41667V7.58333H16.25V5.41667H20.5833ZM9.75 5.41667V11.9167H5.41667V5.41667H9.75ZM20.5833 14.0833V20.5833H16.25V14.0833H20.5833ZM9.75 18.4167V20.5833H5.41667V18.4167H9.75ZM22.75 3.25H14.0833V9.75H22.75V3.25ZM11.9167 3.25H3.25V14.0833H11.9167V3.25ZM22.75 11.9167H14.0833V22.75H22.75V11.9167ZM11.9167 16.25H3.25V22.75H11.9167V16.25Z" fill="{{color}}"/> </svg> ',
    download: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20.9625 10.8767C20.2258 7.13918 16.9433 4.33334 13 4.33334C9.86917 4.33334 7.15 6.11001 5.79583 8.71001C2.535 9.05668 0 11.8192 0 15.1667C0 18.7525 2.91417 21.6667 6.5 21.6667H20.5833C23.5733 21.6667 26 19.24 26 16.25C26 13.39 23.7792 11.0717 20.9625 10.8767ZM20.5833 19.5H6.5C4.10583 19.5 2.16667 17.5608 2.16667 15.1667C2.16667 12.9458 3.82417 11.0933 6.02333 10.8658L7.1825 10.7467L7.72417 9.71751C8.75333 7.73501 10.7683 6.50001 13 6.50001C15.8383 6.50001 18.2867 8.51501 18.8392 11.2992L19.1642 12.9242L20.8217 13.0433C22.5117 13.1517 23.8333 14.5708 23.8333 16.25C23.8333 18.0375 22.3708 19.5 20.5833 19.5ZM14.5708 10.8333H11.4292V14.0833H8.66667L13 18.4167L17.3333 14.0833H14.5708V10.8333Z" fill="{{color}}"/> </svg> ',
    edit: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.5178 9.35778L16.6422 10.4822L5.56889 21.5556H4.44444V20.4311L15.5178 9.35778ZM19.9178 2C19.6122 2 19.2944 2.12222 19.0622 2.35444L16.8256 4.59111L21.4089 9.17444L23.6456 6.93778C24.1222 6.46111 24.1222 5.69111 23.6456 5.21444L20.7856 2.35444C20.5411 2.11 20.2356 2 19.9178 2ZM15.5178 5.89889L2 19.4167V24H6.58333L20.1011 10.4822L15.5178 5.89889Z" fill="{{color}}"/> </svg> ',
    evidence: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21.6667 4.33334H18.2325L16.25 2.16667H9.75L7.7675 4.33334H4.33334C3.7587 4.33334 3.2076 4.56161 2.80127 4.96794C2.39494 5.37427 2.16667 5.92537 2.16667 6.50001V19.5C2.16667 20.0746 2.39494 20.6257 2.80127 21.0321C3.2076 21.4384 3.7587 21.6667 4.33334 21.6667H21.6667C22.2413 21.6667 22.7924 21.4384 23.1987 21.0321C23.6051 20.6257 23.8333 20.0746 23.8333 19.5V6.50001C23.8333 5.92537 23.6051 5.37427 23.1987 4.96794C22.7924 4.56161 22.2413 4.33334 21.6667 4.33334ZM21.6667 19.5H4.33334V6.50001H8.72084L10.7033 4.33334H15.2967L17.2792 6.50001H21.6667V19.5ZM13 7.58334C11.5634 7.58334 10.1857 8.15402 9.16984 9.16984C8.15402 10.1857 7.58334 11.5634 7.58334 13C7.58334 14.4366 8.15402 15.8143 9.16984 16.8302C10.1857 17.846 11.5634 18.4167 13 18.4167C14.4366 18.4167 15.8143 17.846 16.8302 16.8302C17.846 15.8143 18.4167 14.4366 18.4167 13C18.4167 11.5634 17.846 10.1857 16.8302 9.16984C15.8143 8.15402 14.4366 7.58334 13 7.58334ZM13 16.25C12.1381 16.25 11.3114 15.9076 10.7019 15.2981C10.0924 14.6886 9.75 13.862 9.75 13C9.75 12.1381 10.0924 11.3114 10.7019 10.7019C11.3114 10.0924 12.1381 9.75001 13 9.75001C13.862 9.75001 14.6886 10.0924 15.2981 10.7019C15.9076 11.3114 16.25 12.1381 16.25 13C16.25 13.862 15.9076 14.6886 15.2981 15.2981C14.6886 15.9076 13.862 16.25 13 16.25Z" fill="{{color}}"/> </svg> ',
    expand: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.4519 8.44419L13.0072 14.3364L18.5624 8.44419C19.1208 7.85194 20.0228 7.85194 20.5812 8.44419C21.1396 9.03645 21.1396 9.99317 20.5812 10.5854L14.0094 17.5558C13.451 18.1481 12.549 18.1481 11.9906 17.5558L5.41879 10.5854C4.8604 9.99317 4.8604 9.03645 5.41879 8.44419C5.97718 7.86712 6.89351 7.85194 7.4519 8.44419Z" fill="{{color}}"/> </svg> ',
    feedback: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21.5 2H4.16667C2.975 2 2.01083 2.975 2.01083 4.16667L2 23.6667L6.33333 19.3333H21.5C22.6917 19.3333 23.6667 18.3583 23.6667 17.1667V4.16667C23.6667 2.975 22.6917 2 21.5 2ZM21.5 17.1667H5.43417L4.795 17.8058L4.16667 18.4342V4.16667H21.5V17.1667ZM11.75 12.8333H13.9167V15H11.75V12.8333ZM11.75 6.33333H13.9167V10.6667H11.75V6.33333Z" fill="{{color}}"/> </svg> ',
    filter: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.1699 21.6906C16.2166 22.0406 16.0999 22.4138 15.8316 22.6588C15.3766 23.1137 14.6416 23.1137 14.1866 22.6588L9.50816 17.9811C9.23982 17.7128 9.12316 17.3512 9.16982 17.0129V11.0404L3.58142 3.88974C3.18474 3.38814 3.27808 2.65324 3.77975 2.25663C4.00142 2.09332 4.24642 2 4.50309 2H20.8366C21.0933 2 21.3383 2.09332 21.56 2.25663C22.0617 2.65324 22.155 3.38814 21.7583 3.88974L16.1699 11.0404V21.6906ZM6.88313 4.33301L11.5032 10.2355V16.6746L13.8366 19.0076V10.2239L18.4566 4.33301H6.88313Z" fill="{{color}}"/> </svg> ',
    logOut: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.09 17.59L12.5 19L17.5 14L12.5 9L11.09 10.41L13.67 13H4V15H13.67L11.09 17.59ZM20 5H6C4.89 5 4 5.9 4 7V11H6V7H20V21H6V17H4V21C4 22.1 4.89 23 6 23H20C21.1 23 22 22.1 22 21V7C22 5.9 21.1 5 20 5Z" fill="{{color}}"/> </svg> ',
    note: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.41667 20.5833V5.41667H13V13H20.5833V14.0833C21.3417 14.0833 22.0675 14.2242 22.75 14.4625V9.75L16.25 3.25H5.41667C4.21417 3.25 3.25 4.21417 3.25 5.41667V20.5833C3.25 21.775 4.21417 22.75 5.41667 22.75H14.4625C14.2242 22.0675 14.0833 21.3417 14.0833 20.5833H5.41667ZM15.1667 4.875L21.125 10.8333H15.1667V4.875ZM24.9167 19.5V21.6667H21.6667V24.9167H19.5V21.6667H16.25V19.5H19.5V16.25H21.6667V19.5H24.9167Z" fill="{{color}}"/> </svg> ',
    quickLinks: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M23.8333 3.25H7.58333C6.83583 3.25 6.25083 3.62917 5.86083 4.20333L0 13L5.86083 21.7858C6.25083 22.36 6.91167 22.75 7.65917 22.75H23.8333C25.025 22.75 26 21.775 26 20.5833V5.41667C26 4.225 25.025 3.25 23.8333 3.25ZM23.8333 20.5833H7.65917L2.6 13L7.64833 5.41667H23.8333V20.5833Z" fill="{{color}}"/> <path d="M9.75 14.625C10.6475 14.625 11.375 13.8975 11.375 13C11.375 12.1025 10.6475 11.375 9.75 11.375C8.85254 11.375 8.125 12.1025 8.125 13C8.125 13.8975 8.85254 14.625 9.75 14.625Z" fill="{{color}}"/> <path d="M15.1667 14.625C16.0641 14.625 16.7917 13.8975 16.7917 13C16.7917 12.1025 16.0641 11.375 15.1667 11.375C14.2692 11.375 13.5417 12.1025 13.5417 13C13.5417 13.8975 14.2692 14.625 15.1667 14.625Z" fill="{{color}}"/> <path d="M20.5833 14.625C21.4808 14.625 22.2083 13.8975 22.2083 13C22.2083 12.1025 21.4808 11.375 20.5833 11.375C19.6859 11.375 18.9583 12.1025 18.9583 13C18.9583 13.8975 19.6859 14.625 20.5833 14.625Z" fill="{{color}}"/> </svg> ',
    search: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.2917 3.25C12.1592 3.25 13.9503 3.99189 15.2709 5.31246C16.5914 6.63303 17.3333 8.4241 17.3333 10.2917C17.3333 12.0358 16.6942 13.6392 15.6433 14.8742L15.9358 15.1667H16.7917L22.2083 20.5833L20.5833 22.2083L15.1667 16.7917V15.9358L14.8742 15.6433C13.6392 16.6942 12.0358 17.3333 10.2917 17.3333C8.4241 17.3333 6.63303 16.5914 5.31246 15.2709C3.99189 13.9503 3.25 12.1592 3.25 10.2917C3.25 8.4241 3.99189 6.63303 5.31246 5.31246C6.63303 3.99189 8.4241 3.25 10.2917 3.25ZM10.2917 5.41667C7.58333 5.41667 5.41667 7.58333 5.41667 10.2917C5.41667 13 7.58333 15.1667 10.2917 15.1667C13 15.1667 15.1667 13 15.1667 10.2917C15.1667 7.58333 13 5.41667 10.2917 5.41667Z" fill="{{color}}"/> </svg> ',
    switchUser: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.58333 14.4792C7.04833 14.4792 2 15.7467 2 18.2708V20.1667H17.1667V18.2708C17.1667 15.7467 12.1183 14.4792 9.58333 14.4792ZM4.535 18C5.445 17.3717 7.64417 16.6458 9.58333 16.6458C11.5225 16.6458 13.7217 17.3717 14.6317 18H4.535ZM9.58333 12.5833C11.6742 12.5833 13.375 10.8825 13.375 8.79167C13.375 6.70083 11.6742 5 9.58333 5C7.4925 5 5.79167 6.70083 5.79167 8.79167C5.79167 10.8825 7.4925 12.5833 9.58333 12.5833ZM9.58333 7.16667C10.4825 7.16667 11.2083 7.8925 11.2083 8.79167C11.2083 9.69083 10.4825 10.4167 9.58333 10.4167C8.68417 10.4167 7.95833 9.69083 7.95833 8.79167C7.95833 7.8925 8.68417 7.16667 9.58333 7.16667ZM17.21 14.5442C18.4667 15.4542 19.3333 16.6675 19.3333 18.2708V20.1667H23.6667V18.2708C23.6667 16.0825 19.875 14.8367 17.21 14.5442ZM16.0833 12.5833C18.1742 12.5833 19.875 10.8825 19.875 8.79167C19.875 6.70083 18.1742 5 16.0833 5C15.4983 5 14.9567 5.14083 14.4583 5.37917C15.1408 6.34333 15.5417 7.52417 15.5417 8.79167C15.5417 10.0592 15.1408 11.24 14.4583 12.2042C14.9567 12.4425 15.4983 12.5833 16.0833 12.5833Z" fill="{{color}}"/> </svg> ',
    template: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.5 2.16666H6.50001C5.30834 2.16666 4.33334 3.14166 4.33334 4.33332V21.6667C4.33334 22.8583 5.30834 23.8333 6.50001 23.8333H19.5C20.6917 23.8333 21.6667 22.8583 21.6667 21.6667V4.33332C21.6667 3.14166 20.6917 2.16666 19.5 2.16666ZM9.75001 4.33332H11.9167V9.74999L10.8333 8.93749L9.75001 9.74999V4.33332ZM19.5 21.6667H6.50001V4.33332H7.58334V14.0833L10.8333 11.6458L14.0833 14.0833V4.33332H19.5V21.6667Z" fill="{{color}}"/> </svg> ',
    toDoList: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.84615 4H10.2308V11.3846H2.84615V4ZM5.30769 6.46154V8.92308H7.76923V6.46154H5.30769ZM12.6923 6.46154H25V8.92308H12.6923V6.46154ZM12.6923 16.3077H25V18.7692H12.6923V16.3077ZM5.30769 22.4615L1 18.1538L2.73538 16.4185L5.30769 18.9785L10.9569 13.3415L12.6923 15.0769L5.30769 22.4615Z" fill="{{color}}"/> </svg> ',
    roundCheck: '<svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13 1C6.3868 1 1 6.3868 1 13C1 19.6132 6.3868 25 13 25C19.6132 25 25 19.6132 25 13C25 6.3868 19.6132 1 13 1ZM13 3.4C18.3161 3.4 22.6 7.68385 22.6 13C22.6 18.3161 18.3161 22.6 13 22.6C7.68385 22.6 3.4 18.3161 3.4 13C3.4 7.68385 7.68385 3.4 13 3.4ZM18.1516 8.55156L10.6 16.1031L7.84844 13.3516L6.15156 15.0484L10.6 19.4969L19.8484 10.2484L18.1516 8.55156Z" fill="{{color}}"/> </svg> ',
    threeDots: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 28 28" fill="none"><path d="M14 9.33332C15.2833 9.33332 16.3333 8.28332 16.3333 6.99999C16.3333 5.71666 15.2833 4.66666 14 4.66666C12.7167 4.66666 11.6667 5.71666 11.6667 6.99999C11.6667 8.28332 12.7167 9.33332 14 9.33332ZM14 11.6667C12.7167 11.6667 11.6667 12.7167 11.6667 14C11.6667 15.2833 12.7167 16.3333 14 16.3333C15.2833 16.3333 16.3333 15.2833 16.3333 14C16.3333 12.7167 15.2833 11.6667 14 11.6667ZM14 18.6667C12.7167 18.6667 11.6667 19.7167 11.6667 21C11.6667 22.2833 12.7167 23.3333 14 23.3333C15.2833 23.3333 16.3333 22.2833 16.3333 21C16.3333 19.7167 15.2833 18.6667 14 18.6667Z" fill="{{color}}"/></svg>',
    accessRole: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 26 26" fill="none"><path d="M13 2L4 6V12C4 17.55 7.84 22.74 13 24C18.16 22.74 22 17.55 22 12V6L13 2ZM20 12C20 16.52 17.02 20.69 13 21.93C8.98 20.69 6 16.52 6 12V7.3L13 4.19L20 7.3V12ZM8.41 12.59L7 14L11 18L19 10L17.59 8.58L11 15.17L8.41 12.59Z" fill="{{color}}"/></svg>',
    menu: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none"><path d="M4 8H28V10.6667H4V8ZM4 14.6667H28V17.3333H4V14.6667ZM4 21.3333H28V24H4V21.3333Z" fill="{{color}}"/></svg>',
    store: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" fill="none"><path d="M25.8946 12.89L24.8446 8.52C24.6246 7.62 23.8446 7 22.9346 7H9.04464C8.14464 7 7.35464 7.63 7.14464 8.52L6.09464 12.89C5.85464 13.91 6.07464 14.95 6.71464 15.77C6.79464 15.88 6.90464 15.96 6.99464 16.06V23C6.99464 24.1 7.89464 25 8.99464 25H22.9946C24.0946 25 24.9946 24.1 24.9946 23V16.06C25.0846 15.97 25.1946 15.88 25.2746 15.78C25.9146 14.96 26.1446 13.91 25.8946 12.89ZM22.9046 8.99L23.9546 13.36C24.0546 13.78 23.9646 14.2 23.7046 14.53C23.5646 14.71 23.2646 15 22.7646 15C22.1546 15 21.6246 14.51 21.5546 13.86L20.9746 9L22.9046 8.99ZM16.9946 9H18.9546L19.4946 13.52C19.5446 13.91 19.4246 14.3 19.1646 14.59C18.9446 14.85 18.6246 15 18.2146 15C17.5446 15 16.9946 14.41 16.9946 13.69V9ZM12.4846 13.52L13.0346 9H14.9946V13.69C14.9946 14.41 14.4446 15 13.7046 15C13.3646 15 13.0546 14.85 12.8146 14.59C12.5646 14.3 12.4446 13.91 12.4846 13.52ZM8.03464 13.36L9.04464 9H11.0146L10.4346 13.86C10.3546 14.51 9.83464 15 9.22464 15C8.73464 15 8.42464 14.71 8.29464 14.53C8.02464 14.21 7.93464 13.78 8.03464 13.36ZM8.99464 23V16.97C9.07464 16.98 9.14464 17 9.22464 17C10.0946 17 10.8846 16.64 11.4646 16.05C12.0646 16.65 12.8646 17 13.7746 17C14.6446 17 15.4246 16.64 16.0046 16.07C16.5946 16.64 17.3946 17 18.2946 17C19.1346 17 19.9346 16.65 20.5346 16.05C21.1146 16.64 21.9046 17 22.7746 17C22.8546 17 22.9246 16.98 23.0046 16.97V23H8.99464Z" fill="{{color}}"/></svg>',
    errorFill: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none"><path d="M11.9902 22.0372C10.61 22.0372 9.3112 21.7736 8.09375 21.2462C6.8763 20.7254 5.80208 19.9995 4.87109 19.0685C3.94661 18.1375 3.2207 17.0633 2.69336 15.8458C2.16602 14.6284 1.90234 13.3295 1.90234 11.9493C1.90234 10.5691 2.16602 9.2703 2.69336 8.05286C3.2207 6.83541 3.94661 5.76445 4.87109 4.83997C5.80208 3.90898 6.87305 3.17981 8.08398 2.65247C9.30143 2.12512 10.6003 1.86145 11.9805 1.86145C13.3672 1.86145 14.6693 2.12512 15.8867 2.65247C17.1042 3.17981 18.1784 3.90898 19.1094 4.83997C20.0404 5.76445 20.7695 6.83541 21.2969 8.05286C21.8242 9.2703 22.0879 10.5691 22.0879 11.9493C22.0879 13.3295 21.8242 14.6284 21.2969 15.8458C20.7695 17.0633 20.0404 18.1375 19.1094 19.0685C18.1784 19.9995 17.1042 20.7254 15.8867 21.2462C14.6693 21.7736 13.3704 22.0372 11.9902 22.0372ZM12 13.6388C12.5794 13.6388 12.8757 13.3361 12.8887 12.7306L13.0449 7.82825C13.0579 7.52877 12.9635 7.28463 12.7617 7.09583C12.5599 6.90051 12.3027 6.80286 11.9902 6.80286C11.6712 6.80286 11.4141 6.90051 11.2188 7.09583C11.0234 7.28463 10.9323 7.52551 10.9453 7.81848L11.082 12.7404C11.1016 13.3393 11.4076 13.6388 12 13.6388ZM12 16.9982C12.3255 16.9982 12.6055 16.894 12.8398 16.6857C13.0742 16.4773 13.1914 16.2104 13.1914 15.8849C13.1914 15.5659 13.0742 15.299 12.8398 15.0841C12.6055 14.8693 12.3255 14.7618 12 14.7618C11.668 14.7618 11.3848 14.8693 11.1504 15.0841C10.916 15.299 10.7988 15.5659 10.7988 15.8849C10.7988 16.2104 10.916 16.4773 11.1504 16.6857C11.3913 16.894 11.6745 16.9982 12 16.9982Z" fill="{{color}}"/></svg>',
    sixDotMatrix: '<svg height="100%" width="100%" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.23076 14.6149C9.23076 15.4611 8.53845 16.1534 7.6923 16.1534C6.84615 16.1534 6.15384 15.4611 6.15384 14.6149C6.15384 13.7688 6.84615 13.0765 7.6923 13.0765C8.53845 13.0765 9.23076 13.7688 9.23076 14.6149ZM7.6923 8.46109C6.84615 8.46109 6.15384 9.1534 6.15384 9.99955C6.15384 10.8457 6.84615 11.538 7.6923 11.538C8.53845 11.538 9.23076 10.8457 9.23076 9.99955C9.23076 9.1534 8.53845 8.46109 7.6923 8.46109ZM7.6923 3.8457C6.84615 3.8457 6.15384 4.53801 6.15384 5.38416C6.15384 6.23032 6.84615 6.92263 7.6923 6.92263C8.53845 6.92263 9.23076 6.23032 9.23076 5.38416C9.23076 4.53801 8.53845 3.8457 7.6923 3.8457ZM12.3077 6.92263C13.1538 6.92263 13.8461 6.23032 13.8461 5.38416C13.8461 4.53801 13.1538 3.8457 12.3077 3.8457C11.4615 3.8457 10.7692 4.53801 10.7692 5.38416C10.7692 6.23032 11.4615 6.92263 12.3077 6.92263ZM12.3077 8.46109C11.4615 8.46109 10.7692 9.1534 10.7692 9.99955C10.7692 10.8457 11.4615 11.538 12.3077 11.538C13.1538 11.538 13.8461 10.8457 13.8461 9.99955C13.8461 9.1534 13.1538 8.46109 12.3077 8.46109ZM12.3077 13.0765C11.4615 13.0765 10.7692 13.7688 10.7692 14.6149C10.7692 15.4611 11.4615 16.1534 12.3077 16.1534C13.1538 16.1534 13.8461 15.4611 13.8461 14.6149C13.8461 13.7688 13.1538 13.0765 12.3077 13.0765Z" fill="{{color}}"/></svg>',
    warning: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"><path d="M2.72479 21C2.54146 21 2.37479 20.9542 2.22479 20.8625C2.07479 20.7708 1.95812 20.65 1.87479 20.5C1.79146 20.35 1.74562 20.1875 1.73729 20.0125C1.72896 19.8375 1.77479 19.6667 1.87479 19.5L11.1248 3.5C11.2248 3.33333 11.354 3.20833 11.5123 3.125C11.6706 3.04167 11.8331 3 11.9998 3C12.1665 3 12.329 3.04167 12.4873 3.125C12.6456 3.20833 12.7748 3.33333 12.8748 3.5L22.1248 19.5C22.2248 19.6667 22.2706 19.8375 22.2623 20.0125C22.254 20.1875 22.2081 20.35 22.1248 20.5C22.0415 20.65 21.9248 20.7708 21.7748 20.8625C21.6248 20.9542 21.4581 21 21.2748 21H2.72479ZM4.44979 19H19.5498L11.9998 6L4.44979 19ZM11.9998 18C12.2831 18 12.5206 17.9042 12.7123 17.7125C12.904 17.5208 12.9998 17.2833 12.9998 17C12.9998 16.7167 12.904 16.4792 12.7123 16.2875C12.5206 16.0958 12.2831 16 11.9998 16C11.7165 16 11.479 16.0958 11.2873 16.2875C11.0956 16.4792 10.9998 16.7167 10.9998 17C10.9998 17.2833 11.0956 17.5208 11.2873 17.7125C11.479 17.9042 11.7165 18 11.9998 18ZM11.9998 15C12.2831 15 12.5206 14.9042 12.7123 14.7125C12.904 14.5208 12.9998 14.2833 12.9998 14V11C12.9998 10.7167 12.904 10.4792 12.7123 10.2875C12.5206 10.0958 12.2831 10 11.9998 10C11.7165 10 11.479 10.0958 11.2873 10.2875C11.0956 10.4792 10.9998 10.7167 10.9998 11V14C10.9998 14.2833 11.0956 14.5208 11.2873 14.7125C11.479 14.9042 11.7165 15 11.9998 15Z" fill="{{color}}"/></svg>',
    information: '<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"><path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V12C13 11.7167 12.9042 11.4792 12.7125 11.2875C12.5208 11.0958 12.2833 11 12 11C11.7167 11 11.4792 11.0958 11.2875 11.2875C11.0958 11.4792 11 11.7167 11 12V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="{{color}}"/></svg>',
    roundXMark: '<svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="icon" d="M9.99996 11.166L12.4166 13.5827C12.5694 13.7355 12.7638 13.8118 13 13.8118C13.2361 13.8118 13.4305 13.7355 13.5833 13.5827C13.7361 13.4299 13.8125 13.2355 13.8125 12.9993C13.8125 12.7632 13.7361 12.5688 13.5833 12.416L11.1666 9.99935L13.5833 7.58268C13.7361 7.4299 13.8125 7.23546 13.8125 6.99935C13.8125 6.76324 13.7361 6.56879 13.5833 6.41602C13.4305 6.26324 13.2361 6.18685 13 6.18685C12.7638 6.18685 12.5694 6.26324 12.4166 6.41602L9.99996 8.83268L7.58329 6.41602C7.43052 6.26324 7.23607 6.18685 6.99996 6.18685C6.76385 6.18685 6.5694 6.26324 6.41663 6.41602C6.26385 6.56879 6.18746 6.76324 6.18746 6.99935C6.18746 7.23546 6.26385 7.4299 6.41663 7.58268L8.83329 9.99935L6.41663 12.416C6.26385 12.5688 6.18746 12.7632 6.18746 12.9993C6.18746 13.2355 6.26385 13.4299 6.41663 13.5827C6.5694 13.7355 6.76385 13.8118 6.99996 13.8118C7.23607 13.8118 7.43052 13.7355 7.58329 13.5827L9.99996 11.166ZM9.99996 18.3327C8.84718 18.3327 7.76385 18.1139 6.74996 17.6764C5.73607 17.2389 4.85413 16.6452 4.10413 15.8952C3.35413 15.1452 2.76038 14.2632 2.32288 13.2493C1.88538 12.2355 1.66663 11.1521 1.66663 9.99935C1.66663 8.84657 1.88538 7.76324 2.32288 6.74935C2.76038 5.73546 3.35413 4.85352 4.10413 4.10352C4.85413 3.35352 5.73607 2.75977 6.74996 2.32227C7.76385 1.88477 8.84718 1.66602 9.99996 1.66602C11.1527 1.66602 12.2361 1.88477 13.25 2.32227C14.2638 2.75977 15.1458 3.35352 15.8958 4.10352C16.6458 4.85352 17.2395 5.73546 17.677 6.74935C18.1145 7.76324 18.3333 8.84657 18.3333 9.99935C18.3333 11.1521 18.1145 12.2355 17.677 13.2493C17.2395 14.2632 16.6458 15.1452 15.8958 15.8952C15.1458 16.6452 14.2638 17.2389 13.25 17.6764C12.2361 18.1139 11.1527 18.3327 9.99996 18.3327Z" fill="{{color}}"/></svg>',
  };

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.displayIcon();
  }

  ngOnChanges() {
    this.displayIcon();
  }

  displayIcon() {
    let iconSvgCode = this.icons[this.iconName];

    if (!iconSvgCode) {
      throw new Error(`Icon not found: ${this.iconName}`);
    } else {
      // make substitutions in the icon to make it accessible and use the provided color
      iconSvgCode = iconSvgCode.replaceAll('{{color}}', this.color);

      // attach aria-hidden OR the label if there is one present (for screen readers)
      if (this.label) {
        iconSvgCode = iconSvgCode.replace('<svg', `<svg role="graphics-symbol" aria-label="${this.label}"`);
      } else {
        iconSvgCode = iconSvgCode.replace('<svg', '<svg role="graphics-symbol" aria-hidden="true"');
      }
    }

    this.iconHtml = this.sanitizer.bypassSecurityTrustHtml(iconSvgCode);
  }
}
