import { IRole } from 'src/app/access-management/models/role';

export const mockRoleResponse: object = {
  role: {
    ROLE_ID: 1,
    NAME: 'Admin',
    DESCRIPTION: 'Special admin role cannot be removed',
    IS_PROTECTED: true,
    LEVEL_ID: 1,
    LEVEL_NAME: 'GLOBAL',
    DIVISION: 'Supermarket',
  },
  features: [
    {
      FEATURE_ID: 1,
      NAME: 'MRR Assessment 1',
      DESCRIPTION: 'This is placeholder content for description of ‘Once Off Assessments’ feature. If this description is very lengthy then it can get wrapped to two lines and will look like this.',
      SUBFEATURES: [
        {
          SUBFEATURE_ID: 1,
          NAME: 'View',
          DESCRIPTION: 'User would be able to only ‘View’ the Once Off Assessments. If ‘Manage’ is enabled then ‘View’ need to get auto enabled.',
          IS_ENABLED: true
        },
        {
          SUBFEATURE_ID: 2,
          NAME: 'Manage',
          DESCRIPTION: 'User would be able to Create / Extend / Publishing / Schedule / Cancel + View',
          IS_ENABLED: false
        }
      ]
    },
    {
      FEATURE_ID: '2',
      NAME: 'MRR Assessment 2',
      DESCRIPTION: 'This is placeholder content for description of ‘Once Off Assessments’ feature. If this description is very lengthy then it can get wrapped to two lines and will look like this.',
      SUBFEATURES: [
        {
          SUBFEATURE_ID: 3,
          NAME: 'View',
          DESCRIPTION: 'User would be able to only ‘View’ the Once Off Assessments. If ‘Manage’ is enabled then ‘View’ need to get auto enabled.',
          IS_ENABLED: true
        },
        {
          SUBFEATURE_ID: 4,
          NAME: 'Manage',
          DESCRIPTION: 'User would be able to Create / Extend / Publishing / Schedule / Cancel + View',
          IS_ENABLED: false
        }
      ]
    } 
  ]
};

export const mockRole: IRole = {
  id: 1,
  name: 'Admin',
  description: 'Special admin role cannot be removed',
  isProtected: true,
  levelId: 1,
  levelName: 'GLOBAL',
  features: [
    // {
    //   id: 1,
    //   name: 'MRR Assessment 1',
    //   description: 'User would be able to only ‘View’ the Once Off Assessments. If ‘Manage’ is enabled then ‘View’ need to get auto enabled.',
    //   children: [
    //     {
    //       id: 1,
    //       name: 'View',
    //       description: 'User would be able to only ‘View’ the Once Off Assessments. If ‘Manage’ is enabled then ‘View’ need to get auto enabled.',
    //       isEnabled: true
    //     },
    //     {
    //       id: 1,
    //       name: 'Manage',
    //       description: 'User would be able to only Manage the Once Off Assessments. If ‘Manage’ is enabled then ‘View’ need to get auto enabled.',
    //       isEnabled: false
    //     }
    //   ]
    // },
    // {
    //   id: 2,
    //   name: 'MRR Assessment 2',
    //   description: 'User would be able to Create / Extend / Publishing / Schedule / Cancel + View',
    //   children: [
    //     {
    //       id: 3,
    //       name: 'View',
    //       description: 'User would be able to only ‘View’ the Once Off Assessments. If ‘Manage’ is enabled then ‘View’ need to get auto enabled.',
    //       isEnabled: true
    //     },
    //     {
    //       id: 4,
    //       name: 'Manage',
    //       description: 'User would be able to only Manage the Once Off Assessments. If ‘Manage’ is enabled then ‘View’ need to get auto enabled.',
    //       isEnabled: false
    //     }
    //   ]
    // }
  ]
};

export const mockRoleListResponse: object = {
  roleList: [
    {
      ROLE_ID: 1,
      NAME: 'Admin',
      DESCRIPTION: 'Special admin role cannot be removed',
      IS_PROTECTED: true,
      LEVEL_ID: 1,
      LEVEL_NAME: 'GLOBAL'
    },
    {
      ROLE_ID: 3,
      NAME: 'Super',
      DESCRIPTION: 'Super role cannot be removed',
      IS_PROTECTED: true,
      LEVEL_ID: 1,
      LEVEL_NAME: 'GLOBAL'
    },
    {
      ROLE_ID: 4,
      NAME: 'Support',
      DESCRIPTION: 'Support cannot be removed',
      IS_PROTECTED: true,
      LEVEL_ID: 1,
      LEVEL_NAME: 'GLOBAL'
    },
    {
      ROLE_ID: 5,
      NAME: 'NationalView',
      DESCRIPTION: 'NationalView role can be removed',
      IS_PROTECTED: false,
      LEVEL_ID: 1,
      LEVEL_NAME: 'GLOBAL'
    },
    {
      ROLE_ID: 2,
      NAME: 'StateManager',
      DESCRIPTION: 'StateManager role can be removed',
      IS_PROTECTED: false,
      LEVEL_ID: 2,
      LEVEL_NAME: 'state'
    },
    {
      ROLE_ID: 6,
      NAME: 'StoreLossManager',
      DESCRIPTION: 'StoreLossManager role can be removed',
      IS_PROTECTED: false,
      LEVEL_ID: 2,
      LEVEL_NAME: 'state'
    },
    {
      ROLE_ID: 9,
      NAME: 'StoreLossPartner',
      DESCRIPTION: 'StoreLossPartner role can be removed',
      IS_PROTECTED: false,
      LEVEL_ID: 2,
      LEVEL_NAME: 'state'
    },
    {
      ROLE_ID: 6,
      NAME: 'OperationManager',
      DESCRIPTION: 'OperationManager role can be removed',
      IS_PROTECTED: false,
      LEVEL_ID: 4,
      LEVEL_NAME: 'zone'
    },
    {
      ROLE_ID: 7,
      NAME: 'GroupManager',
      DESCRIPTION: 'GroupManager role can be removed',
      IS_PROTECTED: false,
      LEVEL_ID: 3,
      LEVEL_NAME: 'group'
    },
    {
      ROLE_ID: 10,
      NAME: 'Store',
      DESCRIPTION: 'Store role can be removed',
      IS_PROTECTED: false,
      LEVEL_ID: 5,
      LEVEL_NAME: 'store'
    },
  ]
};

export const mockRoleList: object = {
  roleList: [
    {
      id: 1,
      name: 'Admin 1',
      description: 'Special admin role cannot be removed',
      isProtected: true,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 2,
      name: 'Admin 2',
      description: 'Special admin role cannot be removed',
      isProtected: true,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 3,
      name: 'Admin 3',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 4,
      name: 'Admin 4',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 5,
      name: 'Admin 5',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 6,
      name: 'Admin 6',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 7,
      name: 'Admin 7',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 8,
      name: 'Admin 8',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 9,
      name: 'Admin 9',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 10,
      name: 'Admin 10',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 11,
      name: 'Admin 11',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    },
    {
      id: 12,
      name: 'Admin 12',
      description: 'Special admin role cannot be removed',
      isProtected: false,
      levelId: 1,
      levelName: 'GLOBAL'
    }
  ],
  status: 200
};

export const mockRoleListV2 = [
  {
    name: 'Admin',
    id: 1,
    isProtected: true,
    levelName: 'Global',
    levelId: 1
  },
  {
    name: 'Super',
    id: 3,
    isProtected: true,
    levelName: 'Global',
    levelId: 1
  },
  {
    name: 'Support',
    id: 4,
    isProtected: true,
    levelName: 'Global',
    levelId: 1
  },
  {
    name: 'NationalView',
    id: 5,
    isProtected: false,
    levelName: 'Global',
    levelId: 1
  },
  {
    name: 'StateManager',
    id: 2,
    isProtected: false,
    levelName: 'State',
    levelId: 2
  },
  {
    name: 'StoreLossManager',
    id: 8,
    isProtected: false,
    levelName: 'State',
    levelId: 2
  },
  {
    name: 'StoreLossPartner',
    id: 9,
    isProtected: false,
    levelName: 'State',
    levelId: 2
  },
  {
    name: 'OperationManager',
    id: 6,
    isProtected: false,
    levelName: 'Zone',
    levelId: 4
  },
  {
    name: 'GroupManager',
    id: 7,
    isProtected: false,
    levelName: 'Group',
    levelId: 3
  },
  {
    name: 'Store',
    id: 10,
    isProtected: false,
    levelName: 'Store',
    levelId: 5
  }
];
