import { Dictionary } from 'src/app/types/dictionary';

/**
 * Checks if a dictionary is either undefined or empty.
 *
 * @param {Dictionary<string, string> | undefined} value - The dictionary to check.
 * @returns {boolean} True if the dictionary is undefined or empty, otherwise false.
 * @example
 * // Example 1: Undefined dictionary
 * const dict1: Dictionary<string, string> | undefined = undefined;
 * console.log(isDictionaryEmpty(dict1)); // true
 * @example
 * // Example 2: Empty dictionary
 * const dict2: Dictionary<string, string> = {};
 * console.log(isDictionaryEmpty(dict2)); // true
 * @example
 * // Example 3: Non-empty dictionary
 * const dict3: Dictionary<string, string> = { key1: 'value1' };
 * console.log(isDictionaryEmpty(dict3)); // false
 */
export function isDictionaryEmpty(value: Dictionary<string, string> | undefined): boolean {
  return value === undefined || Object.keys(value).length === 0;
}
