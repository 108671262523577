import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  thankYouType: any;

  constructor(private actRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.thankYouType = this.actRoute.snapshot.paramMap.get('id');
  }
}
