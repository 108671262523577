/**
 * Determines whether an object is empty (i.e., has no own properties) or is `undefined`.
 *
 * @param {object | undefined} obj - The object to check, which can be an object or `undefined`.
 * @returns {boolean} `true` if the object is `undefined` or has no own properties, `false` otherwise.
 * @example
 * const emptyObj = {};
 * const nonEmptyObj = { key: 'value' };
 * const undefinedObj = undefined;
 *
 * isObjectEmpty(emptyObj); // true
 * isObjectEmpty(nonEmptyObj); // false
 * isObjectEmpty(undefinedObj); // true
 */
export function isObjectEmpty(obj: object | undefined): boolean {
  return obj === undefined || Object.keys(obj).length === 0;
}
