import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '../../common-services/loader.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  constructor(private cd: ChangeDetectorRef, private loaderService: LoaderService) { }

  isLoading = true;

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.cd.detectChanges();
    });
  }
}
