/**
 * Returns a user-friendly organization name based on the given value.
 * If the value is not a predefined identifier, it is capitalized.
 *
 * @param {string} value - The organization identifier.
 * @returns {string} The friendly name corresponding to the given identifier.
 */
export function getFriendlyOrgName(value: string): string {
  if (value.toLowerCase() == 'groupid') {
    return 'Group';
  }
  if (value.toLowerCase() == 'groupno') {
    return 'Group';
  }
  if (value.toLowerCase() == 'storeid') {
    return 'Store';
  }
  // Capitalize the first letter and keep the rest as is
  return value.length ? value.charAt(0).toUpperCase() + value.slice(1) : value;
}
