import { Injectable } from '@angular/core';
import { UserV2 } from '../access-management/models/user';

@Injectable({
  providedIn: 'root',
})
export class UsersMapper {
  public mapUserResponseToUser(
    userResponse: object
  ): UserV2 {
    if (!userResponse) {
      return <UserV2>{};
    }
    const role = <UserV2>{
      email: userResponse['EMAIL_ADDRESS'],
      firstName: userResponse['FIRST_NAME'],
      lastName: userResponse['LAST_NAME'],
      name: `${userResponse['FIRST_NAME'] ?? ''} ${userResponse['LAST_NAME'] ?? ''}`.trim(),
      description: userResponse['DESCRIPTION'],
      division: userResponse['DIVISION'],
      storeNumber: userResponse['STORE_NO'],
      roleId: userResponse['ROLE_ID'],
      roleName: userResponse['ROLE'],
      locationName: userResponse['LOCATION_NAME'],
      positionTitle: userResponse['POSITION_TITLE'],
      state: userResponse['STATE'],
      altState: userResponse['ALT_STATE'],
      zone: userResponse['ZONE'],
      groupNumber: userResponse['GROUP_NO'],
      region: userResponse['REGION'],
      area: userResponse['AREA'],
      locations: userResponse['LOCATIONS'],
    };
    return role;
  }

  public mapUsersResponseToUsers(
    usersResponse: object[]
  ): UserV2[] {
    if (!usersResponse || usersResponse.length === 0) {
      return [];
    }
    return usersResponse.map((userResponse: object) => this.mapUserResponseToUser(userResponse));
  }
}
