import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-loading-state',
  templateUrl: './table-loading-state.component.html',
  styleUrls: ['./table-loading-state.component.scss']
})
export class TableLoadingStateComponent {
  constructor() { }
}
