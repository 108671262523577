import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core'; import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

import { MessagesComponent } from '../../surveys/messages/messages.component';
import { StateService } from '../../common-services/state.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-mrr-more-actions',
  templateUrl: './mrr-more-actions.component.html',
  styleUrls: ['./mrr-more-actions.component.scss']
})
export class MrrMoreActionsComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  actionGroups: string[] = ['Open', 'Complete'];

  actionStatus: string[] = ['pending', 'complete'];

  actionsToShow: object[] = [];

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  selectedGroup: number = 0;

  tableColumns: string[] = ['action', 'assessment', 'description', 'updates'];

  totalCount: number = 0;

  user: User;

  isLoading: boolean = false;

  constructor(
    private actRoute: ActivatedRoute,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private router: Router,
    private state: StateService,
    private surveyService : SurveysService
  ) {}

  ngOnInit() {
    this.user = this.csaAuth.user;

    if (this.state.state && this.state.origin == 'mrr-more-actions') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }

    this.getActionsToShow();
  }

  ngOnDestroy() {
    this.setState();
  }

  ngAfterViewInit() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    label.innerHTML = `${String(this.index + 1)} to ${this.pageSize} of Many`;
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'mrr-more-actions';
      this.state.state = {
        actionGroups: this.actionGroups,
        actionsToShow: this.actionsToShow,
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedGroup: this.selectedGroup,
        store: this.user,
        totalCount: this.totalCount,
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  getActionUpdates(actionID) {
    const actionUpdates = [];
    for (let i = 0; i < this.actionsToShow.length; i++) {
      if (
        this.actionsToShow[i]['workNotes']
        && actionID == this.actionsToShow[i]['id']
      ) {
        for (let j = 0; j < this.actionsToShow[i]['workNotes'].length; j++) {
          if (
            !(
              this.actionsToShow[i]['workNotes'][j]['notes']
                == 'Due date updated.'
              || this.actionsToShow[i]['workNotes'][j]['notes']
                == 'Status updated.'
            )
          ) {
            actionUpdates.push(this.actionsToShow[i]['workNotes'][j]['notes']);
          }
        }
      }
    }
    return actionUpdates.length;
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }

  calculateCheckEnabled(action) {
    if (
      moment().diff(moment(action['modifiedOn']), 'hours') > 24
      && action['status'] == 'complete'
    ) {
      action['checkEnabled'] = false;
    } else if (
      moment().diff(moment(action['modifiedOn']), 'hours') < 24
      && action['status'] == 'complete'
    ) {
      action['checkEnabled'] = true;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getActionsToShow();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getActionsToShow();
  }

  getActionsToShow() {
    setTimeout(() => {
      this.isLoading = true;
      this.actionsToShow = [];
    }, 0);

    this.surveyService
      .getActionList(
        `/${this.index}`,
        `/${this.pageSize}`,
        `?status=${ 
          this.actionStatus[this.selectedGroup]
        }&storeID=${this.user['storeID']}&filterName=mrr`
      )
      .subscribe((message) => {
        this.actionsToShow = message['actions'];
        this.hasNext = message['has_next'];
        this.getActionDueDates();
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.actionsToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.actionsToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  getActionDueDates() {
    for (let i = 0; i < this.actionsToShow.length; i++) {
      this.calculateCheckEnabled(this.actionsToShow[i]);
      const actionDueDate = this.actionsToShow[i]['dueDate'];
      const m = moment(actionDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]);
      const currentDate = moment();
      const isDueInWeek = currentDate.diff(m, 'days') > -7 && currentDate.diff(m, 'days') <= 0;
      this.actionsToShow[i]['overDue'] = m < currentDate;
      this.actionsToShow[i]['isDueInWeek'] = isDueInWeek;
    }
  }
}
