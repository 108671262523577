import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-empty-state',
  templateUrl: './table-empty-state.component.html',
  styleUrls: ['./table-empty-state.component.scss']
})
export class TableEmptyStateComponent {
  @Input() emptyStateText = 'no results found';

  constructor() { }
}
