<div class="page-container">
  <h1>Hi {{user['firstName']}}</h1>
</div>

<div class="audit-tool-cards-container">
  <div class="question-options-row">

    <div class="filter-chips question-options">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=selectAssessment.open() color="primary" selected>
            <span *ngIf="selectedAssessment.indexOf('All CSA\'s') > -1">All CSAs</span>
            <span *ngIf="selectedAssessment.indexOf('All Assessments') > -1">All Assessments</span>
            <span
              *ngIf="selectedAssessment.indexOf('All Assessments') == -1 && selectedAssessment.indexOf('All CSA\'s') == -1">
              {{selectedAssessment.length}} Assessment<span *ngIf="selectedAssessment.length > 1">s</span>
            </span>
            <fa-icon [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #selectAssessment class="optionsDropDown">
          <mat-option (click)="assessmentFilter('All Assessments', {})"
            [ngClass]="{'active' : selectedAssessment.indexOf('All Assessments') > -1 }">All Assessments</mat-option>
          <mat-option
            *ngIf="user['division'] !== 'DC' && user['division'] !== 'Woolworths NZ eStores' && user['division'] !== 'Woolworths NZ Primary Connect' && user['role'] !== 'StoreLossManager' "
            (click)="assessmentFilter('All CSA\'s', {})"
            [ngClass]="{'active' : selectedAssessment.indexOf('All CSA\'s') > -1 }">All CSA's</mat-option>
          <mat-optgroup label="Once Off Assessments"
            *ngIf="activeSurveys.length > 0 && user['role'] !== 'StoreLossManager'">
            <mat-option [ngClass]="{'active' : selectedAssessment.indexOf(survey['title']) > -1 }"
              *ngFor="let survey of activeSurveys" (click)="assessmentFilter(survey['title'], survey)">
              <span *ngIf="selectedAssessment.indexOf(survey['title']) > -1 ">
                <fa-icon [icon]="faCheck"></fa-icon>
              </span>
              {{survey['title']}}, {{survey['avgCompletionRate'] | percent}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Adhoc Assessments" *ngIf="adhocSurveys.length > 0 && user['role'] !== 'StoreLossManager'">
            <mat-option [ngClass]="{'active' : selectedAssessment.indexOf(survey['title']) > -1 }"
              *ngFor="let survey of adhocSurveys" (click)="assessmentFilter(survey['title'], survey)">
              <span *ngIf="selectedAssessment.indexOf(survey['title']) > -1 ">
                <fa-icon [icon]="faCheck"></fa-icon>
              </span>
              {{survey['title']}}, {{survey['totalResponseCount']}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="GPS Assessments"
            *ngIf="adhocSurveys_eitb.length > 0 && user['role'] === 'StoreLossManager'">
            <mat-option [ngClass]="{'active' : selectedAssessment.indexOf(survey['title']) > -1 }"
              *ngFor="let survey of adhocSurveys_eitb" (click)="assessmentFilter(survey['title'], survey)">
              <span *ngIf="selectedAssessment.indexOf(survey['title']) > -1 ">
                <fa-icon [icon]="faCheck"></fa-icon>
              </span>
              {{survey['title']}}, {{survey['totalResponseCount']}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="GPS Closed Assessments"
            *ngIf="closedAdhocSurveys_eitb.length > 0 && user['role'] === 'StoreLossManager'">
            <mat-option [ngClass]="{'active' : selectedAssessment.indexOf(survey['title']) > -1 }"
              *ngFor="let survey of closedAdhocSurveys_eitb" (click)="assessmentFilter(survey['title'], survey)">
              <span *ngIf="selectedAssessment.indexOf(survey['title']) > -1 ">
                <fa-icon [icon]="faCheck"></fa-icon>
              </span>
              {{survey['title']}}, {{survey['totalResponseCount']}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Recurring Assessments"
            *ngIf="recurringSurveys.length > 0 && user['role'] !== 'StoreLossManager'">
            <mat-option [ngClass]="{'active' : selectedAssessment.indexOf(survey['title']) > -1 }"
              *ngFor="let survey of recurringSurveys" (click)="assessmentFilter(survey['title'], survey)">
              <span *ngIf="selectedAssessment.indexOf(survey['title']) > -1  ">
                <fa-icon [icon]="faCheck"></fa-icon>
              </span>
              {{survey['title']}}, {{survey['avgCompletionRate'] | percent}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Closed Assessments"
            *ngIf="closedSurveys.length > 0 && user['role'] !== 'StoreLossManager'">
            <mat-option [ngClass]="{'active' : selectedAssessment.indexOf(survey['title']) > -1 }"
              *ngFor="let survey of closedSurveys" (click)="assessmentFilter(survey['title'], survey)">
              <span *ngIf="selectedAssessment.indexOf(survey['title']) > -1  ">
                <fa-icon [icon]="faCheck"></fa-icon>
              </span>
              {{survey['title']}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Closed Adhoc Assessments"
            *ngIf="closedAdhocSurveys.length > 0 && user['role'] !== 'StoreLossManager'">
            <mat-option [ngClass]="{'active' : selectedAssessment.indexOf(survey['title']) > -1 }"
              *ngFor="let survey of closedAdhocSurveys" (click)="assessmentFilter(survey['title'], survey)">
              <span *ngIf="selectedAssessment.indexOf(survey['title']) > -1 ">
                <fa-icon [icon]="faCheck"></fa-icon>
              </span>
              {{survey['title']}}, {{survey['totalResponseCount']}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Closed Recurring Assessments"
            *ngIf="closedRecurringSurveys.length > 0 && user['role'] !== 'StoreLossManager'">
            <mat-option [ngClass]="{'active' : selectedAssessment.indexOf(survey['title']) > -1 }"
              *ngFor="let survey of closedRecurringSurveys" (click)="assessmentFilter(survey['title'], survey)">
              <span *ngIf="selectedAssessment.indexOf(survey['title']) > -1 && user['role'] !== 'StoreLossManager' ">
                <fa-icon [icon]="faCheck"></fa-icon>
              </span>
              {{survey['title']}}, {{survey['avgCompletionRate'] | percent}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </div>
    </div>

    <div class="filter-chips question-options" *ngIf="showState">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=stateSelect.open() color="primary" selected>
            {{selectedState}} <fa-icon [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #stateSelect class="optionsDropDown">
          <mat-option (click)="getDataToShow({}, 'All States', 'division')">All States</mat-option>
          <mat-option *ngFor="let stateID of availableStates"
            (click)="getDataToShow({'state':stateID}, stateID, 'state')">{{stateID}}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="filter-chips" *ngIf="showZone == true">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=zoneSelect.open() color="primary" selected>
            <span class="pr1" *ngIf="selectedZone !== 'All Zones'">Zone</span> {{selectedZone}} <fa-icon
              [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #zoneSelect class="optionsDropDown">
          <mat-option *ngIf="orgStructure['division'] === 'state'"
            (click)="getDataToShow({'state':selectedState}, selectedState, 'state')">All Zones</mat-option>
          <mat-option *ngIf="orgStructure['division'] === 'zone'" (click)="getDataToShow({}, 'All Zones', 'division')">
            All Zones</mat-option>
          <span *ngIf="orgStructure['division'] === 'state'">
            <mat-option *ngFor="let zoneID of availableZones"
              (click)="getDataToShow({'state':selectedState, 'zone': zoneID}, zoneID, 'zone')">{{zoneID}}</mat-option>
          </span>
          <span *ngIf="orgStructure['division'] === 'zone'">
            <mat-option *ngFor="let zoneID of availableZones" (click)="getDataToShow({'zone': zoneID}, zoneID, 'zone')">
              {{zoneID}}</mat-option>
          </span>
        </mat-select>
      </div>
    </div>

    <div class="filter-chips" *ngIf="showGroup == true">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=groupSelect.open() color="primary" selected>
            <span class="pr1" *ngIf="selectedGroupID !== 'All Groups'">Group</span>
            {{selectedGroupID}} <fa-icon [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #groupSelect class="optionsDropDown">
          <mat-option *ngIf="orgStructure['state'] === 'zone'"
            (click)="getDataToShow({'state':selectedState, 'zone': selectedZone}, selectedZone, 'zone')">All Groups
          </mat-option>
          <mat-option *ngIf="orgStructure['state'] === 'groupID'"
            (click)="getDataToShow({'state':selectedState}, selectedState, 'state')">All Groups</mat-option>
          <mat-option *ngIf="orgStructure['division'] === 'zone'"
            (click)="getDataToShow({'zone': selectedZone}, selectedZone, 'zone')">All Groups</mat-option>
          <span *ngIf="orgStructure['state'] === 'zone'">
            <mat-option *ngFor="let groupID of availableGroups"
              (click)="getDataToShow({'state':selectedState, 'zone': selectedZone, 'groupID': groupID}, groupID, 'group')">
              {{groupID}}</mat-option>
          </span>
          <span *ngIf="orgStructure['state'] === 'groupID'">
            <mat-option *ngFor="let groupID of availableGroups"
              (click)="getDataToShow({'state':selectedState, 'groupID': groupID}, groupID, 'group')">{{groupID}}
            </mat-option>
          </span>
          <span *ngIf="orgStructure['division'] === 'zone'">
            <mat-option *ngFor="let groupID of availableGroups"
              (click)="getDataToShow({'zone': selectedZone, 'groupID': groupID}, groupID, 'group')">{{groupID}}
            </mat-option>
          </span>
        </mat-select>
      </div>
    </div>

    <div class="filter-chips question-options" *ngIf="showRegion">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=regionSelect.open() color="primary" selected>
            {{selectedRegion}} <fa-icon [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #regionSelect class="optionsDropDown">
          <mat-option (click)="getDataToShow({}, 'All States', 'division')">All Regions</mat-option>
          <mat-option *ngFor="let regionID of availableRegions"
            (click)="getDataToShow({'region':regionID}, regionID, 'region')">{{regionID}}</mat-option>
        </mat-select>
      </div>
    </div>

    <div class="filter-chips question-options" *ngIf="showArea">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=areaSelect.open() color="primary" selected>
            <span class="pr1" *ngIf="selectedArea !== 'All Areas'">Area </span>{{selectedArea}} <fa-icon
              [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #areaSelect class="optionsDropDown">
          <mat-option (click)="getDataToShow({'region':selectedRegion}, selectedRegion, 'region')">All Area</mat-option>
          <mat-option *ngFor="let areaID of availableAreas"
            (click)="getDataToShow({'region':selectedRegion, 'area': areaID}, areaID, 'area')">{{areaID}}</mat-option>
        </mat-select>
      </div>
    </div>

    <div class="filter-chips question-options">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=storeSelect.open() color="primary" selected>
            <span *ngIf="selectedStore['Location_Name'] === 'All Stores' && user['division'] !== 'DC'">
              {{selectedStore['Location_Name']}}
              <fa-icon [icon]="faSortDown"></fa-icon>
            </span>
            <span *ngIf="selectedStore['Location_Name'] === 'All Stores' && user['division'] === 'DC'">All DCs</span>
            <span *ngIf="selectedStore['Location_Name'] !== 'All Stores'">
              {{selectedStore['Store_No']}} - {{selectedStore['Location_Name']}}
              <fa-icon [icon]="faSortDown"></fa-icon>
            </span>
          </mat-chip>
        </mat-chip-list>
        <mat-select #storeSelect class="optionsDropDown">
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching"
              placeholderLabel="Search by Store Name or Number.." [noEntriesFoundLabel]="noEntries" name="searchValue"
              #searchValue ngModel (ngModelChange)="searchForStores($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngIf="user['division'] === 'DC'" (click)="setStore({Store_No: '', Location_Name: 'All Stores'})">
            All DCs</mat-option>
          <mat-option *ngIf="user['division'] !== 'DC'" (click)="setStore({Store_No: '', Location_Name: 'All Stores'})">
            All Stores</mat-option>
          <mat-option [disabled]="searching" *ngFor="let returnedStore of storesReturned"
            (click)="setStore(returnedStore)">
            {{returnedStore['Store_No']}} - {{returnedStore['Location_Name']}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <!-- <div class="filter-chips question-options">
      <div class="chipDrop" *ngIf="showReset()">
        <mat-chip-list aria-label="Filters">
          <mat-chip class="clear-chip" (click)="resetFilters()" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faRedoAlt"></fa-icon>
            <span>Clear</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div> -->

  </div>
</div>

<div class="audit-tool-cards-container" *ngIf="selectedAssessment.length == 0">
  <div class="audit-tool-card-interum">
    <div class="audit-tool-card-outer more-list-screen-card">
      <h3>Please select a closed Assessment from the drop down above to see charts and data.</h3>
    </div>
  </div>
</div>


<div class="audit-tool-cards-container" *ngIf="reportsList.length > 0">
  <div class="audit-tool-card-interum" *ngFor="let report of reportsList">
    <div class="reports-heading">
      <h2 class="no-bottom-margin">{{report['description']}}</h2>
      For
      <span *ngFor="let assessment of selectedAssessment">
        {{assessment}}
        <span
          *ngIf="selectedAssessment.indexOf(assessment) != selectedAssessment.length-2 && selectedAssessment.indexOf(assessment) != selectedAssessment.length-1">
          ,
        </span>
        <span
          *ngIf="selectedAssessment.indexOf(assessment) == selectedAssessment.length-2 && selectedAssessment.indexOf(assessment) != selectedAssessment.length-1">
          &
        </span>
        <span *ngIf="selectedAssessment.indexOf(assessment) == selectedAssessment.length-1">
          &nbsp;-
        </span>
      </span>
      &nbsp;for&nbsp;<span *ngIf="selectedStore['Store_No']">{{selectedStore['Store_No']}} -
      </span>{{selectedStore['Location_Name']}}
      <span *ngIf="selectedState === 'All States' && showState">&&nbsp;</span>
      <span *ngIf="selectedState !== 'All States' && showState">&nbsp;in&nbsp;</span>
      <span *ngIf="showState">{{selectedState}}</span>
      <span *ngIf="showRegion">{{selectedRegion}}</span>
      <span *ngIf="showArea">,&nbsp;{{selectedArea}}</span>
      <span *ngIf="showZone">,&nbsp;Zone {{selectedZone}}</span>
      <span *ngIf="showGroup">,&nbsp;Group {{selectedGroupID}}</span>
    </div>

    <div class="audit-tool-card-outer more-list-screen-card">
      <div class="survey-data">
        <div #chartContainer class="chart-container" [id]="report['chartID']"></div>
      </div>
    </div>
  </div>
</div>
<div class="audit-tool-cards-container" *ngIf="reportsList.length < 0">
  <h3>Sorry, no reports found for {{user['division']}}</h3>
</div>






<!--############Legacy Code##################-->
<!--<div class="filter-chips">-->
<!--<div class="chipDrop" *ngIf="user['role'] != 'GroupManager'">-->
<!--<mat-chip-list aria-label="Filters">-->
<!--<mat-chip (click)=levelSelect.open() color="primary" selected>-->
<!--<span *ngIf="level == 'GroupManager'">Group Reports </span>-->
<!--<span *ngIf="level == 'OperationManager' && user['division'] != 'Metro'">Zone Reports </span>-->
<!--<span *ngIf="level == 'OperationManager' && user['division'] == 'Metro'">State Reports </span>-->
<!--<span *ngIf="level == 'StateManager'">State Reports </span>-->
<!--<span *ngIf="level == 'Admin'">National Reports </span>-->
<!--<fa-icon [icon]="faSortDown"></fa-icon>-->
<!--</mat-chip>-->
<!--</mat-chip-list>-->
<!--<mat-select-->
<!--#levelSelect-->
<!--class="optionsDropDown">-->
<!--<mat-option *ngFor="let levelID of availableLevels" (click)="chartLevelHandler(levelID['id'])">{{levelID['title']}}</mat-option>-->
<!--</mat-select>-->
<!--</div>-->
<!--<div class="chipDrop">-->
<!--<mat-chip-list aria-label="Filters">-->
<!--<mat-chip *ngIf="level != 'Admin' && user['role'] == 'Admin'" (click)=stateSelect.open() color="primary" selected>-->
<!--{{selectedState}} <fa-icon [icon]="faSortDown"></fa-icon>-->
<!--</mat-chip>-->
<!--</mat-chip-list>-->
<!--<mat-select-->
<!--#stateSelect-->
<!--class="optionsDropDown">-->
<!--<mat-option (click)="chartStateHandler('All States')">All States</mat-option>-->
<!--<mat-option *ngFor="let stateID of availableStates" (click)="chartStateHandler(stateID)">{{stateID}}</mat-option>-->
<!--</mat-select>-->
<!--</div>-->
<!--</div>-->