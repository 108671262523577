import { excludeValues } from '../exclude-values/exclude-values';
import { getIncludedValues } from '../get-included-values/get-included-values';
import { getUniqueValues } from '../get-unique-values/get-unique-values';

export interface IValidatedBulkValues<T> {
  malformedValues: T[],
  excludedValues: T[],
  validValues: T[],

}
/**
 * Validates an array of values against a regular expression and categorizes them based on format, inclusion, and exclusion.
 * Returns an object containing the categorized validation results:
 * - `malformedValues`: Unique values that do not match the regular expression.
 * - `excludedValues`: Values from `formattedValues` that are not present in the `allowedValues`.
 * - `validValues`: Values from `formattedValues` that are present in the `allowedValues`.
 *
 * @template T - The type of elements in the `values` array.
 * @param {T[]} values - The array of values to validate.
 * @param {RegExp} validRegexInput - The regular expression used to validate the format of each value.
 * @param {T[]} allowedValues - The array of values that should be included in or excluded from the validation.
 * @returns {IValidatedBulkValues<T>} An object containing the categorized validation results:
 * - `malformedValues`: Values that do not match the regular expression.
 * - `excludedValues`: Values that are not part of the `allowedValues` after filtering.
 * - `validValues`: Values that are part of the `allowedValues` after filtering.
 * @example
 * const values = [1080, 123, 123, 1082, 1080, 1082, 1083, 1500];
 * const validRegexInput = /^[0-9]{4}$/; // Only 4 digit numbers
 * const allowedValues = [1080, 1082, 1999];
 * 
 * const result = validateBulkValues(values, validRegexInput, allowedValues);
 * console.log(result);
 * // Output:
 * // {
 * //   malformedValues: [123],
 * //   excludedValues: [1083, 1500],
 * //   validValues: [1080, 1082]
 * // }
 */
export function validateBulkValues<T>(values: T[], validRegexInput: RegExp, allowedValues: T[]): IValidatedBulkValues<T> {
  const result: IValidatedBulkValues<T> = {
    malformedValues: [],
    excludedValues: [],
    validValues: []
  };

  // Filter values based on the regular expression
  const formattedValues = getUniqueValues<T>(values.filter((value) => validRegexInput.test(value.toString())));

  // Identify malformed values (those that don't match the regex)
  result.malformedValues = getUniqueValues<T>(values.filter((value) => !validRegexInput.test(value.toString())));

  // Get excluded correctly formatted values (values that aren't part of allowedValues)
  result.excludedValues = excludeValues<T>(formattedValues, allowedValues);

  // Get valid correctly formatted values (values that are part of allowedValues)
  result.validValues = getIncludedValues<T>(formattedValues, allowedValues);

  return result;
}
