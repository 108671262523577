import {
  Component, EventEmitter, Input, OnInit, Output 
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Dictionary } from 'src/app/types/dictionary';
import { deepCopy } from 'src/app/utils/deep-copy/deep-copy';
import { isObjectEmpty } from 'src/app/utils/is-object-empty/is-object-empty';

export interface ILocationSelection {
  id: string;
  roleLevel: string;
  hasInitialState: boolean,
  locationValues: Dictionary<string, string>;
  availableLocations?: object;
}

@Component({
  selector: 'app-multi-location-selection',
  templateUrl: './multi-location-selection.component.html',
  styleUrls: ['./multi-location-selection.component.scss']
})
export class MultiLocationSelectionComponent implements OnInit {
  @Input() form: FormGroup;

  @Input() maxLocations;

  @Input() set roleLevel(value: string) {
    if (this.hasInitialState && !this.isStateInitialised) {
      return;
    }
    if (this._isRoleLevelInitialized) {
      if ((value ?? '') !== (this._roleLevel ?? '')) {
        this._roleLevel = value;
        this.initialiseLocationSelectionList();
      }
    } else {
      // Initialise role level
      this._roleLevel = value;
      this._originalRoleLevel = this.roleLevel;
      this._isRoleLevelInitialized = true;
    }
  }

  get roleLevel(): string {
    return this._roleLevel;
  }

  @Input() userOrgStructure: object = {};

  @Input() division: string;

  // Ensure this properties (hasInitialState, initialState) are set before property roleLevel;
  // otherwise the roleLevel setter triggers before hasInitialState is assigned.
  // This should be true for all non setter inputs.
  @Input() hasInitialState = false;  

  // The initial state value is a list of objects with property values roleLevel and selectedLocations.
  private isStateInitialised = false;  

  @Input() set initialState(value: ILocationSelection[] | undefined) {
    if (this.isStateInitialised || isObjectEmpty(value)) {
      // This checks for the scenario where there is a state to be assigned.
      // Due to async operations this setter may get triggered before the initial state value is defined.
      // Therefore, it is import to define the unitialised state value as undefined where empty object value {} is considered a valid state.
      if (value !== undefined) {
        this.isStateInitialised = true;
      }
      return;
    }

    // Initialise list of initial states
    this.locationSelectionList = deepCopy(value);
    this._originalLocationSelectionsList = deepCopy(value);
    this.isStateInitialised = true;
  }

  locationSelectionList: ILocationSelection[] = [];

  isLocationSelectionLoadingList: boolean[] = [];

  isLoading = false;

  validLocationList: boolean[] = [];

  @Input() showLabels = true;

  @Output() locationSelectionListChanged = new EventEmitter<ILocationSelection[]>();

  @Output() validLocationsChanged = new EventEmitter<boolean>();

  @Output() isLoadingChanged = new EventEmitter<boolean>();

  private _roleLevel: string;

  private _isRoleLevelInitialized = false;

  private _originalRoleLevel: string;

  private _originalLocationSelectionsList: ILocationSelection[] = [];

  readonly locationSelectionErrorTitle = 'Location selection error';

  constructor() { }

  ngOnInit(): void {
    if (!this.form) {
      this.form = new FormGroup({});
    }
    this._originalRoleLevel = this.roleLevel;
    this._originalLocationSelectionsList = deepCopy(this.locationSelectionList);
  }

  initialiseLocationSelectionList() {
    this.locationSelectionList = this._roleLevel !== 'Global' ? [{
      id: '1', roleLevel: this._roleLevel, hasInitialState: false, locationValues: {} 
    }] : [];
    this.validLocationList = [];
    this.isLocationSelectionLoadingList = [];
    this.clearAllLocationFormControls();
  }

  clearAllLocationFormControls() {
    const locationFormNames = this.getOrderedOrgLocations(this.userOrgStructure);
    Object.keys(this.form.controls).forEach((controlName: string) => {
      if (this.containsMatchingNamesWithNumber(controlName, locationFormNames)) {
        if (this.form && this.form.get(controlName)) {
          this.form.removeControl(controlName);
        }
      }
    });
  }

  containsMatchingNamesWithNumber(value: string, matchingNames: string[]): boolean {
    // value = value.toLowerCase(); // Make it case-insensitive
    for (let i = 0; i < matchingNames.length; i++) {
      const matchingName = matchingNames[i] === 'groupID' ? 'group' : matchingNames[i];
      const index = value.indexOf(matchingName);

      if (index !== -1) {
        const remainingText = value.slice(index + matchingName.length); // Get text after matching name
        
        // Ensure there's at least one character after matching name
        if (remainingText.length > 0) {
          const num = parseInt(remainingText, 10);
          return !isNaN(num) && num >= 1; // Check if it's a valid number starting from 1
        }
      }
    }
    return false;
  }

  // TODO: Move to common util folder
  getOrderedOrgLocations(orgStruct: object): string[] {
    const locationsOrdered: string[] = [];
    let key = orgStruct['division'];
  
    // Iterate through the organization structure
    while (orgStruct[key]) {
      // If the location is not already in the ordered list, add it
      if (locationsOrdered.indexOf(key) === -1) {
        locationsOrdered.push(key);
      }
      // Move to the next organization node
      key = orgStruct[key]; // get next org node
    }
    return locationsOrdered;     
  }

  /**
   * Removes a location selection from the locationSelections list at the specified index.
   *
   * @param {number} index - The index of the location selections to remove.
   * @returns {void}
   */
  removeLocationSelections(index: number) {
    this.locationSelectionList.splice(index, 1);
    this.form.markAsDirty(); // Manually set form as dirty because form does not detect change.
    this.locationSelectionListChanged.emit(this.locationSelectionList);
  }

  /**
   * Adds a new location selections to the locationSelections list with the provided location values with unique id.
   *
   * @param {object} newLocationValues - The location values for the new selection.
   * @returns {void}
   */
  addLocationSelections(newLocationValues: object) {
    const newId = parseInt(this.locationSelectionList[this.locationSelectionList.length - 1].id) + 1;
    this.locationSelectionList.push(<ILocationSelection>{
      id: newId.toString(), hasInitialState: false, roleLevel: this.roleLevel, locationValues: newLocationValues 
    });
    this.locationSelectionListChanged.emit(this.locationSelectionList);
  }

  /**
   * Updates the location values of a location selections list item at the specified index with the provided selected locations.
   *
   * @param {Dictionary<string, string>} selectedLocations - The selected locations to update.
   * @param {number} index - The index of the location selections to update.
   * @returns {void}
   */
  locationSelectionsChanged(selectedLocations: Dictionary<string, string>, index: number) {
    this.locationSelectionList[index].locationValues = selectedLocations;
    this.locationSelectionListChanged.emit(this.locationSelectionList); 
  }

  isLocationSelectionLoadingChanged(isLocationSelectionLoading: boolean, index: number) {
    this.isLocationSelectionLoadingList[index] = isLocationSelectionLoading;
    const isLoading = !this.isLocationSelectionLoadingList.every((value: boolean) => value === false);
    // console.log(`isLocationSelectionLoadingChanged - isLocationSelectionLoading: ${isLocationSelectionLoading}, index: ${index}, isLoading: ${isLoading}`);
    if (this.isLoading !== isLoading) {
      this.isLoading = isLoading;
      this.isLoadingChanged.emit(this.isLoading); 
    }
  }

  hasValidLocationsChanged(validLocations: boolean, index: number) {
    const previousHasValidLocations = this.validLocationList.every((value: boolean) => value === true);
    this.validLocationList[index] = validLocations;
    const hasValidLocations = this.validLocationList.every((value: boolean) => value === true);
    if (hasValidLocations !== previousHasValidLocations) {
      this.validLocationsChanged.emit(hasValidLocations); 
    }
  }

  isDirty() {
    return this.form.dirty || JSON.stringify(this._originalLocationSelectionsList) !== JSON.stringify(this.locationSelectionList);
  }

  /**
   * Resets the component's state to its original values.
   * Restores the original role level and location selection list.
   */
  reset() {
    this._roleLevel = this._originalRoleLevel;
    this.locationSelectionList = deepCopy(this._originalLocationSelectionsList);
  }  
}
