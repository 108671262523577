<div class="close-container">
    <span class="info-icon med-grey" (click)="closeMe()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </span>
</div>

<mat-dialog-content>

    <form #finalReportForm="ngForm" (submit)="saveNotes(finalReportForm.value)"
    (keydown.enter)="$event.preventDefault()">
        <div class="create-action-form">

            <h2>Final Report findings</h2>


            <h1 class="create-action-headings left-align padding-top">Final Report received Date:</h1>
            <div class="left-align size-msg">

            </div>
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Final Report Date</mat-label>
                <input name="finalReportDate" (keydown)="false" [max]="today"
                    ngModel matInput [matDatepicker]="finalReportDatepicker" (click)="finalReportDatepicker.open()"
                    required  (ngModelChange)="saveNotes($event)"  [(ngModel)]="survey['finalReportDate']">
                <mat-datepicker-toggle matSuffix [for]="finalReportDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #finalReportDatepicker></mat-datepicker>
                <mat-error>
                    Please select Final Report received date
                </mat-error>
            </mat-form-field>

            <h1 class="create-action-headings left-align">Rating : </h1>
            <div class="left-align size-msg">
            </div>
			<mat-form-field appearance="outline" color="primary">
				<mat-label class="" for="rating">Rating</mat-label>
				<mat-select #rating matInput [(ngModel)]="survey['rating']" class="custom-control-input"
				  name="rating" required    (ngModelChange)="saveNotes($event)">
				  <mat-option *ngFor="let rating of ratings" [value]="rating">
					{{rating}}
				  </mat-option>
				</mat-select>
				<mat-error>Please select a Rating</mat-error>
			  </mat-form-field> 


            <h1 class="create-action-headings left-align">Summary :</h1>
            <mat-form-field appearance="outline" color="primary">
                <mat-label class="" for="summaryFindings">Summary of Key Findings</mat-label>
                <!-- <input spellcheck="true" ngModel #summery maxLength="1000" pattern="[a-zA-Z0-9 ]+" matInput
                            class="custom-control-input" name="summery" required (ngModelChange)="saveNotes($event)"> -->
                <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" spellcheck="true"
                    ngModel #summaryFindings maxLength="3000"  matInput
                    class="custom-control-input" name="summaryFindings" required   (ngModelChange)="saveNotes($event)"
                    [(ngModel)]="survey['summaryFindings']"></textarea>

                <mat-hint align="end">{{summaryFindings['value'].length || 0}}/3000</mat-hint>
                <mat-error>Please enter Summary of Key Findings</mat-error>
            </mat-form-field>


        <!-- extra filed for file upload	 -->

        <h1 class="create-action-headings left-align padding-top">
            Final Report:
        </h1>
        <div class="action-line" *ngFor="let FinalReport of FinalReports; let i = index">
            <div class="file-name">{{FinalReport['photoName']?.slice(0, 25)}}<span
                    *ngIf="FinalReport['photoName']?.length > 25">...</span></div>
            <div class="right-align hide-on-mobile">{{FinalReport['fileSize'] | filesize}}</div>
            <div class="right-align">
                <a (click)="
           viewfinalDoc(
                  FinalReport['photoName'], 
                 'final', 
                  FinalReport['fileName'], 
                  true
                )
          ">
                    View
                </a>
            </div>
        </div>


        <div class="action-line" *ngIf="uploading == true">
            <div class="light-blue">Uploading...</div>
            <div class="light-blue right-align"><span class="padded-icon">
                    <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
                </span></div>
        </div>




        <button class="csa-button-solid survey-submit" (click)="uploader.click()" [ngClass]="{
        'semi-disabled' : uploading == true,
        'disabled' : FinalReports?.length  >= 10
      }">
            <span class="action-icon">
                <fa-icon *ngIf="uploading == true" [icon]="faCircleNotch" [spin]="true"></fa-icon>
                <fa-icon *ngIf="uploading == false" [icon]="faPaperclip"></fa-icon>
            </span>
            Upload Final Report
            <input hidden type="file" #uploader (click)="uploader.value = null"
                (change)="uploadDoc($event,'final', survey['id'])" />
        </button>



    </div>
        <!-- extra filed for file upload	 -->

        <div class="submit-button-container">
            <div class="survey-link-container">
                <div *ngIf="!finalReportForm.valid || !finalReportForm.dirty && !uploadDocument" class="subtle-centered-text">
                    <span *ngIf="!finalReportForm.dirty">
                        The form has not been changed.
                    </span>
                    <span *ngIf="!finalReportForm.valid">
                        Please complete all fields to save.
                    </span>
                </div>
                <button [disabled]="!finalReportForm.valid || !finalReportForm.dirty && !uploadDocument"
                    class="csa-button-solid survey-submit" type="submit"
                     (click)="sendSurvey(finalReportForm, finalReportForm.value)">
                    <span>
                        <fa-icon [icon]="faPaperPlane"></fa-icon>
                        Save Changes
                    </span>
                </button>
            </div>
        </div>
    </form>

</mat-dialog-content>
