import {
  Component,
  Inject,
  Injectable,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faCircleNotch,
  faDownload,
  faTimes,
  faTrash,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

import { MessagesComponent } from '../../surveys/messages/messages.component';
import { LoaderService } from '../../common-services/loader.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-photo-preview',
  templateUrl: './photo-preview.component.html',
  styleUrls: ['./photo-preview.component.scss'],
})
export class PhotoPreviewComponent implements OnInit {
  faCircleNotch = faCircleNotch;

  faDownload = faDownload;

  faRedo = faRedo;

  faTimes = faTimes;

  faTrash = faTrash;

  isDownloading: boolean = false;

  photo: object = {};

  photoName = '';

  photoURL = '';

  user: User = {};

  maxHeight: '';

  maxWidth: '';

  marginLeft = '';

  marginTop = '';

  rotationDegree: number = 90;

  transformOffset = '';

  divHeight = '';

  imageDegree: number = 0;

  documentType = 'Evidence';
  // divHeight: string = "";
  // height: number = -1;
  // width: number = -1;
  // maxHeight: number = 0;
  // maxWidth: number = 0;
  // marginLeft: number = 0;
  // marginTop: number = 0;
  // degree: number = 0;
  // imageRotation: object = {};

  @ViewChild('photoContainer', { read: ElementRef, static: false }) photoContainer: any;

  @ViewChild('photoImg', { read: ElementRef, static: false }) photoImg: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cd: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private csaAuthService: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PhotoPreviewComponent>,
    private loader: LoaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.photoName = this.data.photoName;
    this.photoURL = this.data.photoURL;
    this.documentType = this.data.documentType;
    this.loader.hide('');
  }

  public closeMe() {
    this.dialogRef.close();
  }

  deletePhoto() {
    const message = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          `Are you sure you want to delete this  ${this.documentType} from file? This cannot be un-done.`,
        closeText: 'Delete',
        cancelText: 'Cancel',
        maxWidth: '90vw',
      },
      backdropClass: 'dialog-backdrop',
    });

    message.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        this.dialogRef.close('delete');
      }
    });
  }

  checkName(name) {
    const check = name.toLowerCase().match(/\.(pdf)/g);
    if (check == null) {
      return true;
    } 
    return false;
  }

  downloadPhoto() {
    this.isDownloading = true;

    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        saveAs(xhr.response, this.photoName);
        resolve('downloaded');
      };
      xhr.onerror = (error) => {
        reject(new Error('could not download'));
      };
      xhr.open('GET', this.photoURL);
      xhr.send();
    })
      .then(() => {
        this.isDownloading = false;
      })
      .catch(() => {
        this.isDownloading = false;
        this.dialog.open(MessagesComponent, {
          data: {
            heading: `Cannot Download ${this.documentType}`,
            message: `Please email ${this.user['supportEmail']} for support.`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
      });
  }

  // rotatePhoto() {
  //   if (this.width == -1 || this.height == -1) {
  //     this.width = this.photoImg.nativeElement.offsetWidth;
  //     this.height = this.photoImg.nativeElement.offsetHeight;
  //   }

  //   let maxHeight = this.height;
  //   let maxWidth = this.width;
  //   let marginTop = 0;
  //   // let marginBottom = 0;
  //   if (this.degree) {
  //     this.degree += 90;
  //   } else {
  //     this.degree = 90;
  //   }

  //   if ((this.degree / 90) % 2 != 0) {
  //     if (this.width > this.height) {
  //       marginTop = (this.photoImg.nativeElement.offsetWidth -
  //         this.photoImg.nativeElement.offsetHeight) /
  //         2        
  //                   // marginBottom = marginTop;
  //     } else if (this.width < this.height) {
  //       maxHeight = this.photoContainer.nativeElement.offsetWidth;

  //       if (maxHeight > this.height) {
  //         maxHeight = this.height;
  //         maxWidth = this.width;
  //       } else {
  //         maxWidth =
  //           this.width * (maxHeight / this.height);
  //       }

  //       marginTop =
  //         (-1 * (maxHeight - maxWidth)) / 2;
  //       // marginBottom = marginTop;
  //     }
  //   }

  //   this.imageRotation = {
  //     'max-width': maxWidth + 'px', 
  //     'max-height': maxHeight + 'px', 
  //     'margin-top': marginTop + 'px', 
  //     // 'margin-bottom': marginBottom + 'px',
  //     'transform': 'rotate(' + this.degree +'deg)',
  //     'transition': 'transform 500ms ease-out'
  //   }
  // }

  rotatePhoto() {
    this.imageDegree += this.rotationDegree;

    if ((this.imageDegree / 90) % 2 == 0) {
      this.marginTop = '0';
      this.transformOffset = '';
      this.divHeight = 'auto';
      this.marginLeft = '0';
    } else if (
      this.photoImg.nativeElement.offsetWidth
        > this.photoImg.nativeElement.offsetHeight
    ) {
      this.marginTop = `${(this.photoImg.nativeElement.offsetWidth
            - this.photoImg.nativeElement.offsetHeight)
            / 2
      }px`;
    } else if (
      this.photoImg.nativeElement.offsetWidth
        < this.photoImg.nativeElement.offsetHeight
    ) {
      this.marginTop = `-${
        (this.photoImg.nativeElement.offsetHeight
            - this.photoImg.nativeElement.offsetWidth)
            / 2
      }px`;
      // this.marginLeft =
      //   (this.photoImg.nativeElement.offsetHeight -
      //     this.photoImg.nativeElement.offsetWidth) /
      //     2 +
      //   "px";
    }
  }
}
