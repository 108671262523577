<div class="page-container">

  <h1>Set Up Your Session</h1>
  <div class="content-container">
    <div class="audit-tool-card more-list-screen-card">
      <form #setupUserForm="ngForm" class="audit-tool-cards-container">
        <div class="access-management-form">
          <div *ngIf="user['domain'] !== 'Bigw'">
            <h1 class="access-management-headings left-align">Division:</h1>
            <mat-form-field appearance="outline" color="primary">
              <mat-label class="" for="division">Division</mat-label>
              <mat-select (ngModelChange)="resetUser(); userOverride['division']=$event; getRoles();" [ngModel]="userOverride['division']" color="primary"
                name="division" class="custom-control-input" required>
                <mat-option *ngFor="let division of availableDivisions" [value]="division">
                  {{division}}
                </mat-option>
              </mat-select>
              <mat-error>Please select a division</mat-error>
            </mat-form-field>
          </div>
          
          <h1 class="access-management-headings left-align" *ngIf="userOverride['division']">Role:</h1>
          <mat-form-field appearance="outline" color="primary" *ngIf="userOverride['division']">
            <mat-label class="" for="role">Role</mat-label>
            <mat-select (ngModelChange)="userOverride['role']=$event; initRole()" [ngModel]="userOverride['role'] | unescape" color="primary"
              name="role" class="custom-control-input" required>
              <mat-option *ngFor="let role of availableRoles" [value]="role">
                {{(role === 'Store' && userOverride['division'] === 'DC' ? 'DC' : splitByCaps(role)) | unescape}}
              </mat-option>
            </mat-select>
            <mat-error>Please select a role</mat-error>
          </mat-form-field>
          
          <ng-container *ngIf="showLocation">
            <h1 class="access-management-headings left-align">Location:</h1>
            <div class="centered-fields">
              <ng-container *ngFor="let key of locationsOrdered">
                <div class="multiple-field" [style.--numShown]="numShown()" *ngIf="showLocations[key]">
                  <mat-form-field appearance="outline" color="primary">
                    <mat-label class="" for="key" *ngIf="!isLoading[key]">
                      {{(key === 'groupID' ? 'group' : key === 'storeID' ? 'store' : key) | titlecase}}
                    </mat-label>
                    <mat-label *ngIf="isLoading[key]">
                      <div class="select-placeholder-container">
                        Finding {{(key === 'groupID' ? 'group' : key === 'storeID' ? 'store' : key) | titlecase}}s
                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                      </div>
                    </mat-label>
                    <mat-select [disabled]="!availableLocations[key] || availableLocations[key]?.length == 0"
                      [(value)]="userOverride[(key == 'groupID' ? 'groupNo' : key)]"
                      name="key" class="custom-control-input"
                      [required]="showLocations[key]">
                      <mat-option 
                      *ngFor="let location of availableLocations[key]" 
                      (click)="getFiltersForLevel(key); setupUserForm.form.markAsDirty(); isValid();"
                      [value]="location">
                        {{location}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select the user's {{(key === 'groupID' ? 'group' : key === 'storeID' ? 'store' : key)}}</mat-error>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        
        <button [disabled]="!formIsValid || !setupUserForm.valid || !setupUserForm.dirty" class="csa-button-solid survey-submit"
          (click)="saveForm()">
            <fa-icon [icon]="faPlus"></fa-icon>
            <span> Save Access Settings</span>
        </button>
        
      </form>
    </div>
  </div>
</div>