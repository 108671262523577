import { Injectable, Component, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CsaSantiserService {
  constructor(private sanitizer: DomSanitizer) {}

  sanitizedHtmlProperty(text) : SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, text);
  }

  escapedHtmlProperty(text) : string {
    return this.escapeHtml(text);
  }

  escapedAndSanitizedHtmlProperty(text) : string {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.escapeHtml(text));
  }

  escapeHtml(unsafe) {
    return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }
}
