import { Dictionary } from 'src/app/types/dictionary';
import { clearSelectedLocation } from './clear-selected-location';
import { getOrgPropertyName } from './get-org-property-name';

/**
 * Resets the selected locations level values. This is achieved by traversing the organizational structure,
 * starting from the next level (@param {string} nextLevel) continuing until reaching the last level.
 * All the traversed selected location level values are reset as empty values.
 * 
 * The show location level state for the next level @param {string} nextLevel) is set to true when within 
 * the traversed organizational structure and not found to be the next role level (which is out of scope). 
 * Its purpose is to make the next valid location level visible.
 *
 * @param {object} orgStruct - The organizational structure defining the location levels.
 * @param {string} nextLevel - The location level to reset from.
 * @param {string} roleLevel - The role location level.
 * @param {Dictionary<string, string>} selectedLocations - Selected location values.
 * @param {Dictionary<string, boolean>} showLocations - Visibility of the location levels.
 * @returns {void} - This function does not return a value.
 */
export function resetLocationsFromLevel(
  orgStruct: object, 
  nextLevel: string, 
  roleLevel: string, 
  selectedLocations: Dictionary<string, string>, 
  showLocations: Dictionary<string, boolean>
) {
  // Format next level name
  nextLevel = getOrgPropertyName(nextLevel);

  // Get the next role organizational level (boundary out of scope level) from roleLevel
  const levelName = getOrgPropertyName(roleLevel || 'division');
  const nextOutOfScopeRoleLevel = orgStruct[levelName] ? orgStruct[levelName] : orgStruct['division'];

  // Iterate through organizational structure levels starting from the next level.
  let key = nextLevel;
  while (orgStruct[key]) {
    // Clear the selected location at the current level.
    clearSelectedLocation(key, selectedLocations);

    // Show location for next level when not out of scope
    showLocations[key] = (key == nextLevel && key != nextOutOfScopeRoleLevel);

    // Move to the next level in the organizational structure.
    key = orgStruct[key];
  }
}
