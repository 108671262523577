<div mat-dialog-title>
	<div class="close-container">
		<span class="info-icon med-grey" (click)="closeMe()">
			<fa-icon [icon]="faTimes"></fa-icon>
		</span>
		<h2 *ngIf="data['type'] === 'update'">Update Store</h2>
		<h2 *ngIf="data['type'] === 'create'">Create New store</h2>
	</div>
</div>

<div mat-dialog-content>
	<form #storeManagementForm="ngForm">
		<div class="store-dashboard-form">
			<h1 class="store-dashboard-headings left-align">Store Name:</h1>
			<div class="centered-fields">
				<mat-form-field appearance="outline" color="primary">
					<mat-label>Store Name</mat-label>
					<input (ngModelChange)="storeData['Location_Name']=$event" [ngModel]="storeData['Location_Name'] | unescape" ngModel
						maxLength="100" #storeName matInput class="custom-control-input" name="storeName" required>
					<mat-hint *ngIf="storeData['Location_Name']" align="end">{{storeData['Location_Name'].length || 0}}/100</mat-hint>
					<mat-error>Please enter the store's name</mat-error>
				</mat-form-field>
			</div>

			<h1 class="store-dashboard-headings left-align">Email:</h1>
			<mat-form-field appearance="outline" color="primary">
				<mat-label>Email</mat-label>
				<input (ngModelChange)="storeData['EMAIL']=$event"
					[ngModel]="storeData['EMAIL'] | unescape" ngModel maxLength="100" #email matInput
					class="custom-control-input" required type="email" name="email" [email]="true">
				<mat-hint *ngIf="storeData['EMAIL']" align="end">{{storeData['EMAIL'].length || 0}}/100</mat-hint>
				<mat-error>Please enter the store's email address</mat-error>
			</mat-form-field>
      
			<h1 class="store-dashboard-headings left-align">Location:</h1>
			<div class="centered-fields">
				<div class="multiple-field" [style.--numShown]="numShown()" *ngFor="let key of locationsOrdered">
					<mat-form-field appearance="outline" color="primary">
						<mat-label class="" for="key" *ngIf="!isLoading[key]">
							{{removeID(key) | titlecase}} {{key === 'storeID' ? 'ID' : ''}}
						</mat-label>
						<mat-label *ngIf="isLoading[key]">
							<div class="select-placeholder-container">
								Finding {{removeID(key) | titlecase}}s
								<mat-spinner class="spinner" diameter="20"></mat-spinner>
							</div>
						</mat-label>
						<mat-select *ngIf="key !== 'storeID'; else selectStore" [disabled]="!availableLocations[key] || availableLocations[key]?.length == 0"
							[(value)]="storeData[upperCaseKey(key)]"
							name="key" class="custom-control-input"
							required>
							<mat-option 
							*ngFor="let location of availableLocations[key]" 
							(click)="getFiltersForLevel(key); storeManagementForm.form.markAsDirty(); isValid();"
							[value]="location">
								{{location}}
							</mat-option>
						</mat-select>
						<ng-template #selectStore>
							<input (ngModelChange)="storeData['Store_No']=$event; storeManagementForm.form.markAsDirty(); isValid();" [ngModel]="storeData['Store_No'] | unescape" ngModel
							maxLength="10" #storeNo matInput class="custom-control-input" name="storeNo" required [disabled]="!storeData[upperCaseKey(getPreviousLevel(key))] || data['type'] === 'update'">	
						</ng-template>
						<mat-hint *ngIf="key === 'storeID'" align="start">This field can not be changed once the store is created.</mat-hint>

						<mat-error>Please select the store's {{(key === 'storeID' ? 'ID' : removeID(key))}}</mat-error>
					</mat-form-field>
				</div>
			</div>

			<h1 class="store-dashboard-headings left-align">Store Trading Status:</h1>
			<div class="switch-container">
				<p>
					Inactive&nbsp;
					<mat-slide-toggle
          (change)="storeData['PILOT'] === 'Y' ? storeData['PILOT'] = 'N' : storeData['PILOT'] = 'Y'; storeManagementForm.form.markAsDirty(); isValid();"
					color="primary"
          [checked]="storeData['PILOT'] === 'Y'">
      		</mat-slide-toggle>
					&nbsp;Active
				</p>
			</div>
		</div>
	</form>
</div>

<div mat-dialog-actions>
	<div *ngIf="!storeManagementForm.valid || !formIsValid" class="subtle-centered-text">Please complete all fields correctly to {{data['type'] === 'update' ? 'update' : 'create new'}} store</div>

	<div *ngIf="!storeManagementForm.dirty && data['type'] === 'update'" class="subtle-centered-text">Change store information to update the store</div>

	<div layout="row" layout-align="space-between center" flex>
		<button [disabled]="!storeManagementForm.valid || !storeManagementForm.dirty || !formIsValid" class="csa-button-solid survey-submit"
			(click)="saveForm()">
			<span *ngIf="data['type'] === 'update'">
				<fa-icon [icon]="faCheck"></fa-icon>
				<span> Save Store</span>
			</span>
			<span *ngIf="data['type'] === 'create'">
				<fa-icon [icon]="faPlus"></fa-icon>
				<span> Create Store</span>
			</span>
		</button>
	</div>
</div>