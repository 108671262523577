
<mat-drawer-container class="example-container">
  <mat-drawer #drawer class="example-sidenav" mode="push" hasBackdrop="true" position="end">
    <a [routerLink]="['/']" (click)="drawer.close();"><div class="survey-line no-effect"><fa-icon [icon]="faHome"></fa-icon> &nbsp; &nbsp;Home</div></a>

    <div *ngIf="user.role" class="survey-line no-effect" (click)="csaAuthService.logout()"><fa-icon [icon]="faUser"></fa-icon> &nbsp; &nbsp; Logout</div>

    <div *ngIf="user.role && (userRole !== 'Store')" class="survey-line no-effect"><mat-slide-toggle color="primary" (change)="onChange($event)">Disable Auto Log Out?</mat-slide-toggle></div>

    <div *ngIf="user['support']" class="survey-line no-effect" (click)="switchUser()"><fa-icon [icon]="faUserFriends"></fa-icon> &nbsp; &nbsp; Switch User Access</div>

    <!-- <div *ngIf="user['super']" class="survey-line no-effect" (click)="divisionSelect.open()">
        <fa-icon [icon]="faUserFriends"></fa-icon> &nbsp; &nbsp; Switch Division
      <div>
        <mat-select #divisionSelect class="division-select" [(value)]="user['division']">
          <mat-option *ngFor="let division of availableDivisions" [ngClass]="" (click)="drawer.close(); switchDivision(division)" [value]="division">
            {{division | unescape}}
          </mat-option>
        </mat-select>
      </div>
    </div> -->

    <mat-accordion *ngIf="user['super'] && availableDivisions.length > 1">
      <mat-expansion-panel class="division-select" #divisionSelect>
        <mat-expansion-panel-header class="survey-line no-effect">
          <fa-icon [icon]="faUserFriends"></fa-icon> &nbsp; &nbsp; Switch Division
        </mat-expansion-panel-header>
        <div  *ngFor="let division of availableDivisions">
          <mat-option *ngIf="!(user['role'] === 'NationalView' && division === 'DC')" (click)="switchDivision(drawer, division)" [ngClass]="{'selected' : division == user['division']}" [disabled]="division == user['division']">
            {{division | unescape}}
          </mat-option>
        </div>
      
        <!-- <mat-option *ngFor="let division of availableDivisions" (click)="drawer.close(); switchDivision(division)">
          {{division | unescape}}
        </mat-option> -->
   
        
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="user['supportEmail']" class="survey-line center-align flex-column no-ripple no-effect">
      <div *ngIf="user['division'] === 'Supermarkets'; else feedback">
        To send feedback, please complete <h4>Weekly Store Feedback Survey</h4>
      </div>
      <ng-template #feedback>
        To send feedback, please {{supportEmail.indexOf('@') > -1 ? 'email' : 'visit'}}: <h4>{{supportEmail}}</h4>
      </ng-template>
    </div>
  </mat-drawer>

  <div class="example-sidenav-content">

    <mat-toolbar class="header-bar">
      <a class="header-home-link" [routerLink]="['/']">&nbsp;</a>
      <span class="horizontal-spacing"></span>
      <span class="hide-on-mobile smaller-text" *ngIf="user.role">Welcome {{userName}}</span>
      <button *ngIf="user.role" type="button" mat-button (click)="drawer.toggle()">
        <fa-icon [icon]="faBars"></fa-icon>
      </button>

    </mat-toolbar>

    <router-outlet></router-outlet>


  </div>

</mat-drawer-container>

