import {
  Component,
  Inject,
  OnInit,
  KeyValueChanges,
  KeyValueDiffer,
  KeyValueDiffers,
  Input,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel, FormControl } from '@angular/forms';
import {
  faCheck,
  faPaperPlane,
  faInfoCircle,
  faTimes,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { last } from 'rxjs';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { MessagesComponent } from '../messages/messages.component';
import { SortPipe } from '../../custom-pipes/sort.pipe';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-access-management-dialog',
  templateUrl: './access-management-dialog.component.html',
  styleUrls: ['./access-management-dialog.component.scss'],
  providers: [
    SortPipe
  ]
})
export class AccessManagementDialogComponent implements OnInit {
  faCheck = faCheck;

  faInfoCircle = faInfoCircle;

  faPaperPlane = faPaperPlane;

  faPlus = faPlus;

  faTimes = faTimes;

  faTrash = faTrash;

  availableLocations: object = {};

  availableRoles: string[] = [];

  locationsOrdered: string[] = [];

  objDiffer: object = {};

  showLocation: boolean = true;

  showLocations: object = {};

  user: User = {};

  userData: object = {};

  isLoading: object = {};

  currentLevel: string = '';

  nextLevel: string = '';

  lastLevel: string = '';

  formIsValid = false;

  private differ: KeyValueDiffer<string, any>;

  constructor(
    private differs: KeyValueDiffers,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private dialogRef: MatDialogRef<AccessManagementDialogComponent>,
    private sortPipe: SortPipe,
    private surveyService: SurveysService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.user = this.csaAuth.user;

    for (const [key, value] of Object.entries(this.user['divisionRoles'])) {
      if (
        (value > this.user['divisionRoles']['Store']
          && value <= this.user['divisionRoles']['Admin'])
        || (key == 'Super' && this.user['super'])
      ) {
        this.availableRoles.push(key);
      }
    }

    this.availableRoles.sort((a, b) => this.user['divisionRoles'][b] - this.user['divisionRoles'][a]);

    let key = 'division';
    while (this.user['orgStruct'][key]) {
      const value = this.user['orgStruct'][key];
      if (this.locationsOrdered.indexOf(value) === -1) {
        this.locationsOrdered.push(value);
      }
      key = value;
    }

    if (this.data['type'] == 'update') {
      this.formIsValid = true;
      Object.assign(this.userData, this.data['userData']);
      // for (let [key, value] of Object.entries(this.data['userData'])) {
      //   this.userData[key.toLowerCase()] = value;
      // }
    }

    this.initRole(true);
    this.userData['DIVISION'] = this.user['division'];
  }

  closeMe(result?) {
    this.dialogRef.close(result);
  }

  createLevelQuery(queryNextLevel): string {
    let key = 'division';
    let query = '';
    while (key != queryNextLevel) {
      if (key == 'division') {
        query = `?division=${this.user['division']}`;
      } else if (key == 'groupID') {
        query += `&${key}=${this.userData['GROUP_NO']}`;
      } else {
        query += `&${key}=${this.userData[key.toUpperCase()]}`;
      }
      key = this.user['orgStruct'][key];
    }
    return query;
  }

  getFiltersForLevel(level: string, reset: boolean = true): void {
    this.currentLevel = level;
    this.nextLevel = this.user['orgStruct'][level];

    if (reset) {
      this.resetFiltersBelowLevel();
    }

    if (
      this.user['orgStruct'][this.nextLevel]
      && this.showLocations[this.nextLevel]
      && this.userData[this.currentLevel == 'groupID' ? 'GROUP_NO' : this.currentLevel.toUpperCase()]
    ) {
      this.isLoading[this.nextLevel] = true;
      this.surveyService
        .getNextLevel(this.createLevelQuery(this.nextLevel))
        .subscribe(
          (data) => {
            this.isLoading[this.nextLevel] = false;
            this.availableLocations[this.nextLevel] = this.sortPipe.transform(data[this.nextLevel]);
            if (this.nextLevel != this.lastLevel) {
              this.getFiltersForLevel(this.nextLevel, reset);
            }
          },
          (error) => {
            this.isLoading[this.nextLevel] = false;
            this.dialog.open(MessagesComponent, {
              data: {
                heading: `Error Finding ${level}s`,
                message: `Please email ${this.user['supportEmail']} for support.`
              },
              disableClose: true,
              backdropClass: 'dialog-backdrop',
            });
            console.log(error);
          }
        );
    }
  }

  numShown(): number {
    return Object.values(this.showLocations).filter((value) => value === true).length;
  }

  getPreviousLevel(level): string {
    return Object.keys(this.user['orgStruct']).find((key) => this.user['orgStruct'][key] == level);
  }

  isValid(): void {
    switch (this.userData['ROLE']) {
      case 'StateManager':
        this.formIsValid = !!this.userData['STATE'];
        break;
      case 'OperationManager':
        this.formIsValid = !!this.userData['ZONE'];
        break;
      case 'GroupManager':
        this.formIsValid = !!this.userData['GROUP_NO'];
        break;
      case 'RegionManager':
        this.formIsValid = !!this.userData['REGION'];
        break;
      case 'AreaManager':
        this.formIsValid = !!this.userData['AREA'];
        break;
      case 'StoreLossManager':
        this.formIsValid = !!this.userData['STATE'];
        break;
      default:
        this.formIsValid = true;
        break;
    }
  }

  initRole(initial: boolean = false): void {
    this.showLocation = true;
    this.showLocations = {};
    switch (this.userData['ROLE']) {
      case 'StateManager':
        this.lastLevel = 'state';
        break;
      case 'OperationManager':
        this.lastLevel = 'zone';
        break;
      case 'GroupManager':
        this.lastLevel = 'groupID';
        break;
      case 'RegionManager':
        this.lastLevel = 'region';
        break;
      case 'AreaManager':
        this.lastLevel = 'area';
        break;
      case 'StoreLossManager':
        this.lastLevel = 'state';
        break;
      default:
        this.lastLevel = 'division';
        this.showLocation = false;
        break;
    }

    this.resetShownLocations();

    if (!initial) {
      this.nextLevel = this.user['orgStruct'][this.lastLevel];
      this.resetFiltersBelowLevel();
    }

    this.currentLevel = this.getCurrentLevel();

    if (this.showLocation) {
      this.getFiltersForLevel(this.currentLevel, false);
    }

    this.isValid();
  }

  getCurrentLevel(): string {
    let key = 'division';
    while (this.user['orgStruct'][key]) {
      const value = this.user['orgStruct'][key];
      if (!this.availableLocations[value] || this.availableLocations[value]?.length == 0) {
        return key;
      }
      key = value;
    }
    return 'division';
  }

  resetFiltersBelowLevel(): void {
    let key = this.nextLevel;
    while (this.user['orgStruct'][key]) {
      this.availableLocations[key] = [];
      if (key == 'groupID') this.userData['GROUP_NO'] = '';
      else this.userData[key.toUpperCase()] = '';
      key = this.user['orgStruct'][key];
    }
  }

  resetShownLocations(): void {
    let key = this.user['orgStruct']['division'];
    let show = true;
    while (this.user['orgStruct'][key]) {
      if (this.lastLevel == 'division') {
        this.showLocations[key] = false;
      } else {
        this.showLocations[key] = show;
      }
      if (key == this.lastLevel) {
        show = false;
      }
      key = this.user['orgStruct'][key];
    }
  }

  saveForm() {
    let successHeading; let errorHeading; let
      successMessage;
    if (this.data['type'] == 'update') {
      successHeading = 'Access Changes Saved';
      errorHeading = 'Cannot Save Access Changes';
      successMessage = 'Your changes have been saved. These changes will be reflected on the Access Management page.';
    } else if (this.data['type'] == 'create') {
      successHeading = 'New User Created';
      errorHeading = 'Cannot Create User';
      successMessage = 'New user has been successfully assigned access. These changes will be reflected on the Access Management page.';
    }

    this.surveyService.updateUser(this.data['type'], this.userData).subscribe(
      (response) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: response['status'] == 200 ? successHeading : errorHeading,
            message: response['status'] == 200 ? successMessage : response['message'],
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        this.closeMe('success');
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: errorHeading,
            message: `Please email ${this.user['supportEmail']} for support.`
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(error);
      }
    );
  }

  removeUser() {
    const message = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Are you sure you want to remove this user? This cannot be un-done.',
        closeText: 'Remove',
        cancelText: 'Cancel',
        maxWidth: '90vw',
      },
      backdropClass: 'dialog-backdrop',
    });

    message.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        this.surveyService.updateUser('delete', this.userData).subscribe(
          (response) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'User Removed',
                message: 'User has been successfully removed from iVerify. These changes will be reflected on the Access Management page.',
              },
              disableClose: true,
              backdropClass: 'dialog-backdrop',
            });
            this.closeMe('delete');
          },
          (error) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Could Not Remove User',
                message: `Please email ${this.user['supportEmail']} for support.`
              },
              disableClose: true,
              backdropClass: 'dialog-backdrop',
            });
            console.log(error);
          }
        );
      }
    });
  }

  splitByCaps(value): string {
    if (value == 'OperationManager') {
      value = 'ZoneOperationManager';
    }

    if (
      this.user['division'] == 'DC'
      && (value == 'Store' || value == 'MultiStore')
    ) {
      if (value == 'Store') {
        return 'DC';
      }
      if (value == 'MultiStore') {
        return 'Multi DC User';
      }
    }

    return value.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
  }
}
