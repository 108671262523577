import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

import { faCheckSquare, faLaptopHouse, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../common-services/state.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-more-surveys',
  templateUrl: './more-surveys.component.html',
  styleUrls: ['./more-surveys.component.scss'],
})
export class MoreSurveysComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faCheckSquare = faCheckSquare;

  faPlayCircle = faPlayCircle;

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  selectedGroup: number = 0;

  surveyGroups: string[] = ['Un-Opened', 'Started', 'Submitted', 'Closed', 'All'];

  surveysToShow: any[] = [];

  tableColumns: string[] = ['survey', 'percentComplete', 'status'];

  totalCount: number = 0;

  user: User;

  isLoading: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private elementRef: ElementRef,
    private router: Router,
    private state: StateService,
    private surveyService:SurveysService
  ) {}

  ngOnInit() {
    this.user = this.csaAuth.user;

    if (this.state.state && this.state.origin == 'more-surveys') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }

    this.getSurveysToShow();
  }

  ngAfterViewInit() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    label.innerHTML = `${String(this.index + 1)} to ${this.pageSize} of Many`;
  }

  ngOnDestroy() {
    this.setState();
  }
  
  setState() {
    if (this.saveState) {
      this.state.origin = 'more-surveys';
      this.state.state = {
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedGroup: this.selectedGroup,
        surveysToShow: this.surveysToShow,
        totalCount: this.totalCount
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  startSurvey(surveyID, event) {
    event.stopPropagation();
    this.router.navigate(['/store-landing/store-survey', surveyID]);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getSurveysToShow();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getSurveysToShow();
  }

  getSurveysToShow() {
    setTimeout(() => {
      this.isLoading = true;
      this.surveysToShow = [];
    }, 0);

    const currentDate = moment();
    let status = '';
    if (this.selectedGroup == 3) {
      status = 'closed';
    } else if (this.selectedGroup == 2) {
      status = 'submitted';
    } else if (this.selectedGroup == 1) {
      status = 'started';
    } else if (this.selectedGroup == 0) {
      status = 'active';
    }

    let query;
    if (status) {
      query = `?status=${ 
        status 
      }&division=${ 
        this.user['division']
      }&storeID=${
        this.user['storeID']}`;
    } else {
      query = `?division=${ 
        this.user['division']
      }&storeID=${
        this.user['storeID']}`;
    }

    this.surveyService
      .getStoreSurveys(
        query,
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.hasNext = surveysData['has_next'];
        this.getDueDates(this.surveysToShow);
        this.getSurveyStates(this.surveysToShow);
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  getSurveyStates(surveys) {
    for (let i = 0; i < surveys.length; i++) {
      const status = surveys[i].status;
      if (status == 'active') {
        surveys[i].surveyState = 'Start';
      } else if (status == 'started') {
        surveys[i].surveyState = 'Continue';
      } else if (status == 'submitted') {
        surveys[i].surveyState = 'Submitted';
      } else if (status == 'closed') {
        surveys[i].surveyState = 'Closed';
      }
    }
  }

  getDueDates(surveys) {
    for (let i = 0; i < surveys.length; i++) {
      const surveyDueDate = surveys[i].dueDate;
      const m = moment(surveyDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]);
      const currentDate = moment();
      const isDueInWeek = currentDate.diff(m, 'days') >= -7 && currentDate.diff(m, 'days') <= 0;

      surveys[i].isDueInWeek = isDueInWeek;
    }
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }
}
