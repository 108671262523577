<div class="page-container">
  <h1>{{surveyDetails['title'] | unescape}}</h1>

  <div class="question-options-row" *ngIf="('onceOffAssessment.cancelAssessment' | accessCheck) || ('templates.viewDetails' | accessCheck)">
    <div class="filter-chips question-options">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip *ngIf="surveyDetails['status'] === 'active' && ('onceOffAssessment.cancelAssessment' | accessCheck)" (click)="cancelAssessment()"  color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faTimes"></fa-icon>
            <span>Cancel Assessment</span>
          </mat-chip>
          <mat-chip *ngIf="('templates.viewDetails' | accessCheck)" [routerLink]="['/template-details', surveyDetails['template_id']]" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faFileAlt"></fa-icon>
            <span>See Template</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <h2 class="left-align">Assessment Details:</h2>
  <div class="audit-tool-cards-container">
    <div class="p2 audit-tool-card list-screen-card">
      <div class="question-options-row no-bottom-margin">
        <div class="survey-detail-block">
          <h4>Status:</h4>
          <h4 class="light-green medium-text" *ngIf="surveyDetails['status'] === 'active'">
            Yet To Be Published
          </h4>
          <h4 class="orange-text medium-text" *ngIf="surveyDetails['status'] === 'cancelled'">
            Cancelled
          </h4>
          <h4 class="light-green medium-text" *ngIf="surveyDetails['status'] !== 'active' && surveyDetails['status'] !== 'cancelled'">
            {{ (surveyDetails["avgCompletionRate"] ? surveyDetails["avgCompletionRate"] : 0) | percent }}
          </h4>
        </div>
        <div class="survey-detail-block">
          <h4>No. of Questions:</h4>
          <p>{{ surveyDetails["questions"]?.length }}</p>
        </div>
      </div>

      <div class="question-options-row no-top-margin">
        <div class="survey-detail-block" *ngIf="surveyDetails['status'] === 'cancelled'">
          <h4>Cancelled On:</h4>
          <p>  {{ surveyDetails["lastModified"] | date :'dd MMM y'  }} </p>
        </div>
        <div class="survey-detail-block">
          <h4>Publish Date:</h4>
          <p *ngIf="!editPublishDate">
            {{ surveyDetails["publishDate"]| date :'dd MMM y'  }}  
            <a *ngIf="('onceOffAssessment.editSurveyDetailsBeforePublishDate' | accessCheck) && dateToday < minDueDate && surveyDetails['status'] === 'active'" (click)="editPublishDate = true">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </a>
          </p>
          <form
            *ngIf="editPublishDate"
            class="wide-form"
            #publishDateForm="ngForm"
            (submit)="submitPublishDateForm(publishDateForm, publishDateForm.value)"
          >
            <mat-form-field class="right-margin date-field">
              <mat-label>Publish Date</mat-label>
              <input
                (keydown)="(false)"
                (click)="publishPicker.open()"
                name="publishDate"
                [max]="maxPublishDate"
                [min]="minPublishDate"
                ngModel
                matInput
                [matDatepicker]="publishPicker"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="publishPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #publishPicker></mat-datepicker>
              <mat-error>
                Please select a date which is after today and before the
                selected Due Date
              </mat-error>
            </mat-form-field>
            <button
              [disabled]="!publishDateForm.valid"
              class="csa-button-solid survey-submit"
              type="submit"
            >
              Save
            </button>
            <button
              class="csa-button-outline"
              (click)="editPublishDate = false"
            >
              Cancel
            </button>
          </form>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['firstReminderDate']">
          <h4>First Reminder:</h4>
          <p>  {{ surveyDetails["firstReminderDate"] | date :'dd MMM y'  }} </p>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['finalReminderDate']">
          <h4>Final Reminder:</h4>
          <p>  {{ surveyDetails["finalReminderDate"] | date :'dd MMM y'  }} </p>
        </div>
        <div class="survey-detail-block">
          <h4>Due Date:</h4>
          <p *ngIf="!editDueDate">
            {{ surveyDetails["dueDate"] | date :'dd MMM y'  }}  
            <a *ngIf="('onceOffAssessment.editSurveyDetailsBeforePublishDate' | accessCheck) && dateToday < minDueDate && surveyDetails['status'] === 'active'" (click)="editDueDate = true"
              ><fa-icon [icon]="faPencilAlt"></fa-icon
            ></a>
          </p>
          <form
            *ngIf="editDueDate"
            class="wide-form"
            #dueDateForm="ngForm"
            (submit)="submitDueDateForm(dueDateForm, dueDateForm.value)"
          >
            <mat-form-field class="right-margin date-field">
              <mat-label>Due Date</mat-label>
              <input
                (keydown)="(false)"
                (click)="picker.open()"
                name="dueDate"
                [min]="minDueDate"
                ngModel
                matInput
                [matDatepicker]="picker"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>Please select a date after the Publish Date</mat-error>
            </mat-form-field>
            <button
              [disabled]="!dueDateForm.valid"
              class="csa-button-solid survey-submit"
              type="submit"
            >
              Save
            </button>
            <button class="csa-button-outline" (click)="editDueDate = false">
              Cancel
            </button>
          </form>
        </div>
      </div>

      <div class="question-options-row no-top-margin">
        <div class="section-spacing" *ngIf="(user['role'] === 'Admin' || user['role'] === 'Super') && surveyDetails['excludedStores']">
          <h4>Excluded Stores:</h4>
          <span *ngFor="let storeID of surveyDetails['excludedStores']; let i = index">
            {{storeID | removeWhiteSpace}}<span *ngIf="i < (surveyDetails['excludedStores'] | numKeys).length - 1">,&nbsp;</span><span *ngIf="i == (surveyDetails['excludedStores'] | numKeys).length - 1">.</span>
          </span>
        </div>
      </div>

      <div class="question-options-row no-top-margin">
        <div class="" *ngIf="surveyDetails['targetStores']">
          <h4>Targeted to: </h4>
          <p *ngFor="let targetGroup of surveyDetails['targetStores']">
            <span *ngFor="let orgLevel of targetGroup | numKeys:user['orgStruct']; let i = index">
              <span *ngIf="targetGroup[orgLevel]">
                <span *ngIf="orgLevel === 'storeID'" class="medium-text">
                  Store<span *ngIf="targetGroup[orgLevel].constructor.name === 'Array'">s</span>:&nbsp;
                </span>
                <span *ngIf="orgLevel === 'groupID'" class="medium-text">
                  Group<span *ngIf="targetGroup[orgLevel].constructor.name === 'Array'">s</span>:&nbsp;
                </span>
                <span *ngIf="orgLevel !== 'groupID' && orgLevel !== 'storeID'" class="medium-text">
                  {{orgLevel | titlecase}}<span *ngIf="targetGroup[orgLevel].constructor.name === 'Array'">s</span>:&nbsp;
                </span>
                <span *ngIf="targetGroup[orgLevel].constructor.name === 'Array'">
                  {{targetGroup[orgLevel].join(', ')}}<span *ngIf="i == (targetGroup | numKeys).length - 1">.</span>
                </span>
                <span *ngIf="targetGroup[orgLevel].constructor.name === 'String'">
                  {{targetGroup[orgLevel] | removeWhiteSpace}}<span *ngIf="i < (targetGroup | numKeys).length - 1">,&nbsp;</span><span *ngIf="i == (targetGroup | numKeys).length - 1">.</span>
                </span>
              </span>
            </span>
            <br>
          </p>
        </div>
      </div>
      <div class="question-options-row no-top-margin" *ngIf="surveyDetails['status'] === 'closed'">
        <span class="medium-text orange-text">This Assessment is Closed.</span>
      </div>
    </div>
  </div>

  <app-results-table [surveyType]="'onceoff'"  *ngIf="surveyDetails['status'] !== 'active' && surveyDetails['status'] !== 'cancelled' && ('onceOffAssessment.viewAllResponsesForLocation' | accessCheck)"></app-results-table>
</div>
