
<div class="page-container">

    <h1>Please sign in to continue</h1>
    <div class="content-container">
      <div class="audit-tool-card more-list-screen-card">
       <button class="csa-button-solid" (click)="login()">Click here to sign in</button>

       <a (click)="showHelpDialog()" role="button">Having problems signing in?</a>
      </div>
    </div>
  </div>

<!-- template for help dialog -->
<ng-template #loginProblemsDialogRef>
    <h2>Having problems signing in?</h2>
    <p>If you're experiencing unexpected problems during sign in, confirm that "Third Party Cookies" are enabled in your web browser and sign in using the button below.</p>
    <button class="csa-button-solid" (click)="loginWithRedirect()">Sign in</button>
</ng-template>