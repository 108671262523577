import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams 
} from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { MrrVisitNote } from '../models/mrr-visit-note';

@Injectable({
  providedIn: 'root'
})
export class MrrSurveysService {
  private surveysUrl = `${environment.backEndUrl}/survey/list`;

  private mrrActivateEmailTriggerUrl = `${environment.backEndUrl}/emailTrigger`;

  private uploadPhotosUrl = `${environment.backEndUrl}/storage/upload/files`;

  private removePhotoUrl = `${environment.backEndUrl}/storage/remove/file/`;

  private storeMRRDraft = `${environment.backEndUrl}/mrrDraft`;

  private updateMRRDraft = `${environment.backEndUrl}/mrrDraft/update`;

  private deleteMRRDraft = `${environment.backEndUrl}/mrrDraft/delete`;

  private updateMRRFinalReport = `${environment.backEndUrl}/updateMRRFinalReport`;

  private signOff = `${environment.backEndUrl}/signOff`;

  private addVisitNotes = `${environment.backEndUrl}/addVisitNote`;

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  getmrrSurveys(
    userGrade: string,
    group: string,
    status: string,
    index: string,
    count: string,
    type: string,
    filterName: string
  ): Observable<object[]> {
    const fullUrl = `${this.surveysUrl
      + userGrade
      + index
      + count
    }?status=${
      status
    }&division=${
      group
    }&type=${
      type}&filterName=${filterName}`;
    return this.httpClient.get<object[]>(fullUrl);
  }

  mrrActivateEmailTrigger(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.mrrActivateEmailTriggerUrl;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  uploadDocs(
    data: Blob,
    surveyID: string,
    fileTag: string,
    storeID: string,
    fileSize: string
  ): Observable<object> {
    const fullUrl = this.uploadPhotosUrl;
    const formData: FormData = new FormData();
    formData.append('file1', data, this.removeSpecialCharactersFromFileName(data['name']));
    formData.append('surveyID', surveyID);
    formData.append('fileTag', fileTag);
    formData.append('storeID', storeID);
    formData.append('fileSize', fileSize);
    return this.httpClient.post<object>(fullUrl, formData);
  }

  removeDoc(
    file: string,
    data: object,
    responseID: string,
    fileTag: string
  ): Observable<object> {
    const fullUrl = `${this.removePhotoUrl + file}/${responseID}/${fileTag}`;
    return this.httpClient.delete<object>(fullUrl, data);
  }

  createFinding(data): Observable<object> {
    const fullUrl = this.storeMRRDraft;
    return this.httpClient.post<object>(fullUrl, data);
  }

  updateFinding(data): Observable<object> {
    const fullUrl = this.updateMRRDraft;
    return this.httpClient.post<object>(fullUrl, data);
  }

  deleteFinding(data): Observable<object> {
    const fullUrl = this.deleteMRRDraft;
    return this.httpClient.post<object>(fullUrl, data);
  }

  createMRRFinalReport(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.updateMRRFinalReport;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  SignOff(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.signOff;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  addVisitNote(mrrVisitNotes: MrrVisitNote): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.addVisitNotes;
    return this.httpClient.post<object>(fullUrl, mrrVisitNotes, options);
  }

  /**
   * Our backend security policies reject file names that have special characters, probably because of a false flag.
   * This function removes special characters (except for hyphen, period and space) to allow them to upload successfully.
   * 
   * @param fileName - The file name we want to remove special characters from.
   * @returns The passed in file name, with all special characters removed.
   */
  private removeSpecialCharactersFromFileName(fileName: string): string {
    return fileName.replace(/[^\w .-]/g, '');
  }
}
