
  <div class="close-container">
    <span class="info-icon med-grey" (click)="closeMe()"><fa-icon [icon]="faTimes"></fa-icon></span>
  </div>

<mat-dialog-content>
  <form #createActionForm="ngForm" (submit)="saveAction(createActionForm.value)">
    <div class="create-action-form">
      <h2>New Action Plan</h2>
      
      <div class="pre-filled-data">
        <h1 class="create-action-headings left-align">Assessment: </h1>
        <div>{{data['survey'] | unescape}}</div>
      </div>

      <div class="pre-filled-data" *ngIf="data.questionShortTitle">
        <h1 class="create-action-headings left-align">Control Name: </h1>
        <div [innerHTML]="data.questionShortTitle"></div>
      </div>

      <div class="pre-filled-data">
        <h1 class="create-action-headings left-align">Question: </h1>
        <!-- <div [innerHTML]="data['question']"></div> -->
        <p>{{data['question'].slice(0, 600) | unescape}}<span *ngIf="data['question'].length > 600">...</span></p>
      </div>

      <h1 class="create-action-headings left-align no-bottom-margin">Action Short Title:</h1>
      <h6 class="hint">This will be used throughout iVerify to identify this action plan</h6>

      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="actionTitle">Action Short Title</mat-label>
        <input spellcheck="true" (ngModelChange)="actionData['title']=$event" [ngModel]="actionData['title'] | unescape" (keyup)="saveNotes(createActionForm.value)"  ngModel  maxLength="50" #actionTitle matInput class="custom-control-input" name="actionTitle" required>
        <mat-hint align="end">{{actionTitle['value'].length || 0}}/50</mat-hint>
        <mat-error>Please enter an Action Short Title</mat-error>
      </mat-form-field>

      <h1 class="create-action-headings left-align no-bottom-margin">Action Description: 
        <span class="info-icon" (click)="toggleHelp()">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
        </span>
      </h1>

      <mat-expansion-panel [expanded]="showHelp" #panel hideToggle>
        <div #ToolTipContent class="help-content">
          <h3 class="primary-text my1">Complete the following action details:</h3>
          <div>
            <span>
              <span class="medium-text">Specific - </span>
              What specific steps will you take to address the root cause?
            </span><br>
            <span>
              <span class="medium-text">Measurable - </span>
              How will you measure your progress?
            </span><br>
            <span>
              <span class="medium-text">Achievable - </span>
              How are you going to do it and who is responsable?
            </span><br>
            <span>
              <span class="medium-text">Relevant - </span>
              Ensure your SMART action addresses the root cause and mitigates the risk.
            </span><br>
            <span>
              <span class="medium-text">Time-Bound - </span>
              When will this be completed by to mitigate the risk?
            </span>
          </div>
        </div>
      </mat-expansion-panel>
      <div class="smaller-text">Please add a <span class="medium-text">SMART</span> action plan</div>      
    </div>

    <mat-form-field appearance="outline" color="primary">
      <mat-label class="">What is the root cause of the issue?</mat-label>
      <textarea (ngModelChange)="actionData['description']=$event" [ngModel]="actionData['description'] | unescape" (keyup)="saveNotes(createActionForm.value)" spellcheck="true" required #description name="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput maxlength="1000" ngModel class="custom-control-input"></textarea>
      <mat-hint align="end">{{description.value.length || 0}}/1000</mat-hint>
      <mat-error>Please enter a description of the root cause of the issue.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" color="primary">
      <mat-label class="">What specific steps will you take to resolve the root cause?</mat-label>
      <textarea (ngModelChange)="actionData['stepsToResolve']=$event" [ngModel]="actionData['stepsToResolve'] | unescape" (keyup)="saveNotes(createActionForm.value)" spellcheck="true" required #stepsToResolve name="stepsToResolve" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput maxlength="1000" ngModel class="custom-control-input"></textarea>
      <mat-hint align="end">{{stepsToResolve.value.length || 0}}/1000</mat-hint>
      <mat-error>Please enter a description of the steps that will be taken to resolve the root cause.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" color="primary">
      <mat-label class="">How will you measure your progress and how will you know it is embedded?</mat-label>
      <textarea (ngModelChange)="actionData['issueTracking']=$event" [ngModel]="actionData['issueTracking'] | unescape" (keyup)="saveNotes(createActionForm.value)" spellcheck="true" required #issueTracking name="issueTracking" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput maxlength="1000" ngModel class="custom-control-input"></textarea>
      <mat-hint align="end">{{issueTracking.value.length || 0}}/1000</mat-hint>
      <mat-error>Please enter a description of how you will measure your progress.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" color="primary">
      <mat-label class="">Who is responsible to assist with resolving the root cause?</mat-label>
      <textarea (ngModelChange)="actionData['personResponsible']=$event" [ngModel]="actionData['personResponsible'] | unescape" (keyup)="saveNotes(createActionForm.value)" spellcheck="true" required #personResponsible name="personResponsible" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput maxlength="1000" ngModel class="custom-control-input"></textarea>
      <mat-hint align="end">{{personResponsible.value.length || 0}}/1000</mat-hint>
      <mat-error>Please enter the name of the person to be responsible for resolving the root cause.</mat-error>
    </mat-form-field>

    <h1 class="create-action-headings left-align">Due Date:</h1>
    <div class="smaller-text">Please enter the Due Date</div>
    <mat-form-field class="right-margin date-field">
      <mat-label>When entering the Due Date ensure it is realistic and achievable.</mat-label>
      <input 
        [ngModel]="actionData['dueDate']" 
        (ngModelChange)="saveNotes(createActionForm.value)" 
        name="actionDueDate" 
        readonly="true" 
        [min]="minDate" 
        [max]="maxDate" 
        (keydown)="false" 
        ngModel 
        matInput 
        [matDatepicker]="picker" 
        required 
        (click)="picker.open()"
      >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker touchUi="true"></mat-datepicker>
      <mat-error>Please select a date from today onwards.</mat-error>
    </mat-form-field>

    <div class="submit-button-container">
      <div class="survey-link-container">
        <div *ngIf="!createActionForm.valid" class="subtle-centered-text">Please complete all fields to save.</div>
          <button [disabled]="!createActionForm.valid" class="csa-button-solid survey-submit" type="submit"><span>
            <fa-icon [icon]="faPaperPlane"></fa-icon>
          </span> Save Action</button>
          <div>
            <div *ngIf="actionData" class="medium-text red-text cancel-link" (click)="deleteAction()">Delete Action</div>
          </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

