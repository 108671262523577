<div class="accordion" [ngClass]="{'small': accordionSize === 'small', 'medium': accordionSize === 'medium'}" [class.noMargin]="noMargin">
    <!-- accordion header -->
    <button class="accordionHeader" (click)="toggleState()" [class.expanded]="expanded">

      <!-- icon -->
      <img *ngIf="showHeadingIcon" class="headerIcon" src="assets/svg/dollar.svg" aria-hidden="true" />

      <!-- heading -->
      <div class="accordionTitle" role="heading" [attr.aria-level]="headingLevel">
        <ng-content select="[accordion-header]"></ng-content>
      </div>

      <!-- button -->
      <div class="accordionToggleBtn" aria-hidden="true">
        <app-icon  *ngIf="!expanded" iconName="expand" color="grey"></app-icon>
        <app-icon  *ngIf="expanded" iconName="collapse" color="grey"></app-icon>
      </div>
    </button>

    <!-- accordion body -->
    <div class="accordionContent" *ngIf="expanded">
        <ng-content select="[accordion-body]"></ng-content>
    </div>
</div>