import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unescape'
})
export class UnescapePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const doc = new DOMParser().parseFromString(value, 'text/html');
      const doc2 = new DOMParser().parseFromString(doc.documentElement.textContent, 'text/html');
      return doc2.documentElement.textContent;
    }
  }
}
