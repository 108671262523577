<div class="dialog-container">
  <div mat-dialog-title>
    <app-icon *ngIf="data.mode === DialogMode.Confirmation" class="icon" iconName="warning" color="#7A640C"></app-icon>
    <app-icon *ngIf="data.mode === DialogMode.Success" class="icon" iconName="roundCheck" color="#0D5127"></app-icon>
    <app-icon *ngIf="data.mode === DialogMode.Error" class="icon" iconName="information" color="#BD161C"></app-icon>
    <app-icon *ngIf="data.mode === DialogMode.Information" class="icon" iconName="information" color="#0074BC"></app-icon>
    <div class="title" 
      [ngClass]="{
        'warning': data.mode === DialogMode.Confirmation,
        'success': data.mode === DialogMode.Success,
        'error': data.mode === DialogMode.Error,
        'information': data.mode === DialogMode.Information
      }">
      {{ data.title }}
    </div>
  </div>
  <mat-dialog-content>
    <p class="message">{{ data.message }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button id="cancelButton" class="button secondary" 
      *ngIf="data.mode === DialogMode.Confirmation" mat-button mat-dialog-close (click)="cancel()">
      {{ data.cancelText ?? 'No' }}
    </button>
    <button id="continueButton" class="button primary" 
      *ngIf="data.mode === DialogMode.Confirmation" mat-button mat-dialog-close (click)="continue()">
      {{ data.continueText ?? 'Yes' }}
    </button>
    <button id="okButton" class="button primary"
      *ngIf="data.mode === DialogMode.Information || data.mode === DialogMode.Success || data.mode === DialogMode.Error" 
      mat-button mat-dialog-close (click)="ok()">
      {{ data.okText ?? 'Ok' }}
    </button>
  </mat-dialog-actions>
</div>
