<div class="close-container">
  <span class="info-icon med-grey" (click)="closeMe()">
      <fa-icon [icon]="faTimes"></fa-icon>
  </span>
</div>

<div class="dialog-contents">


              <h1 >  Draft Report  </h1>

              <div class="question-options-row no-bottom-margin">
                  <div class="survey-detail-block">
                      <h4> Store Manager :</h4>
                      <p> {{surveyDetails['storeManager']}}</p>
                    </div>

                    <div class="survey-detail-block">
                      <h4> Lead Auditor :</h4>
                      <p>  {{surveyDetails['LeadAuditor']}}</p>
                    </div>

              </div>

          
              <div class="question-options-row no-bottom-margin">
                  <div class="survey-detail-block">
                      <h4>Dates of Audit :</h4>
                      <p>  {{ surveyDetails['fromDate'] | date :'dd MMM y' }}
                         &nbsp; <b>TO</b>&nbsp; {{ surveyDetails['toDate'] | date :'dd MMM y' }}</p>
                    </div>

                    <!-- <div class="survey-detail-block" *ngIf="surveyDetails['Rating']">
                      <h4> Rating :</h4>
                      <p>  {{surveyDetails['Rating']}}</p>
                    </div> -->

                    <div class="survey-detail-block">
                      <h4>  Due Date :</h4>
                      <p>   {{ surveyDetails['dueDate'] | date :'dd MMM y' }} </p>
                    </div>

              </div>

              <div class="question-options-row report-padding">
              <!-- extra filed for file upload	 -->

              <h4 class="survey-detail-block" *ngIf="Draftphotos?.length > 0 || uploading">
                  Draft Report :
              </h4>
              <div class="action-line" *ngFor="let photo of Draftphotos; let i = index">
                  <div class="file-name">{{photo['photoName']?.slice(0, 25)}}<span
                          *ngIf="photo['photoName']?.length > 25">...</span></div>
                  <div class="right-align hide-on-mobile">{{photo['fileSize'] | filesize}}</div>
                  <div class="right-align">
                      <a (click)="
               viewfinalDoc(
                      photo['photoName'], 
                     'final', 
                      photo['fileName'], 
                      false
                    )
              ">
                          View
                      </a>
                  </div>
              </div>
    
          </div>

          </div>