<div class="pageWrapper">

    <!-- the page dimmer when the menu is open -->
    <div class="slideMenuPageDimmer" aria-hidden="true" (click)="isResponsiveMenuOpen = false" [class.isOpen]="isResponsiveMenuOpen">

    </div>

    <!-- navigation -->
    <nav *ngIf="user && !hideNav" [class.isOpen]="isResponsiveMenuOpen">
        <!-- navigation header -->
        <div class="userStatusContainer">
            <div class="profilePhoto">

            </div>
            <div class="userStatus">
                <div class="userName core-body-sm-medium">
                    {{user.firstName}} {{user.lastName}}
                </div>
                <div class="role core-body-sm">
                    {{user.role}}
                </div>
                <div class="role core-body-sm" *ngIf="user.storeID || user.storeName">
                    <ng-container *ngIf="!user.storeName">
                        Store {{user.storeID}}
                    </ng-container>

                    <ng-container *ngIf="user.storeName">
                        {{user.storeName}} ({{user.storeID}})
                    </ng-container>
                </div>

                <div class="role core-body-sm" *ngIf="user.super">
                    <ng-container *ngIf="user.division">
                        {{user.division}}
                    </ng-container>
                </div>
            </div>
            <div class="actionsButton">
                <button (click)="isUserMenuOpen = !isUserMenuOpen" type="button" cdkOverlayOrigin
                    #userMenuTrigger="cdkOverlayOrigin">
                    <app-icon iconName="threeDots" color="#fff"></app-icon>
                </button>

                <!-- template for user menu -->
                <ng-template cdkConnectedOverlay [cdkConnectedOverlayHasBackdrop]="true"
                    [cdkConnectedOverlayOrigin]="userMenuTrigger" [cdkConnectedOverlayOpen]="isUserMenuOpen"
                    (detach)="isUserMenuOpen = false" (backdropClick)="isUserMenuOpen = false">
                    <app-user-menu></app-user-menu>
                </ng-template>

            </div>
        </div>

        <!-- nav items -->
        <div class="navBody">
            <ul class="topLevelMenuList">
                <li class="topLevelMenuItem" *ngFor="let item of menuItems">
                    <div *ngIf="!item.lineBreak" class="topLevelMenuItemWrapper"
                        (click)="item.children ? toggleMenuSection(item) : navigateToLink(item)"
                        [ngClass]="{'selectedNavItem': selectedMenuItemLink === item.link}">
                        <div class="menuIcon">
                            <app-icon [iconName]="item.icon"></app-icon>
                        </div>
                        <div class="menuItemName core-caption-sm">
                            {{item.itemString}}
                        </div>
                        <div *ngIf="item.children" class="menuToggleBtn">
                            <app-icon iconName="collapse" [class.isExpanded]="item.isExpanded"></app-icon>
                        </div>
                    </div>

                    <ul *ngIf="item.children && item.isExpanded" class="childList core-body-sm">
                        <li *ngFor="let child of item.children" role="link" (click)="navigateToLink(child)"
                            [ngClass]="{
                                'selectedNavItem': selectedMenuItemLink === child.link
                            }">
                            {{child.itemString}}
                        </li>
                    </ul>
                    <div *ngIf="item.lineBreak" aria-hidden="true" class="lineBreak">

                    </div>
                </li>
            </ul>
        </div>
    </nav>

    <div class="headerAndContentWrapper">
        <header *ngIf="user && !hideNav">
            <div class="logoAndHeaderBtnsWrapper">
                <div class="logoContainer">
                    <img *ngIf="user.domain !== 'Bigw'" src="assets/images/woolworths-logo.png" alt="">
                    <img *ngIf="user.domain === 'Bigw'" src="assets/images/bigw-logo.png" alt="">
                    <h1 aria-label="iVerify" [routerLink]="'/'">
                        <svg aria-hidden="true" width="340" height="124" viewBox="0 0 340 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6865 22.4336C19.6865 25.0214 18.7455 27.2562 16.8635 29.1383C15.0599 31.0203 12.8642 31.9613 10.2765 31.9613C7.6887 31.9613 5.45381 31.0203 3.5718 29.1383C1.68979 27.2562 0.748779 25.0214 0.748779 22.4336C0.748779 19.8458 1.68979 17.6109 3.5718 15.7289C5.45381 13.8469 7.6887 12.9059 10.2765 12.9059C12.8642 12.9059 15.0599 13.8469 16.8635 15.7289C18.7455 17.6109 19.6865 19.8458 19.6865 22.4336ZM15.3344 96.0673H5.10093C4.39518 96.0673 3.80705 95.8321 3.33655 95.3616C2.86604 94.8911 2.63079 94.303 2.63079 93.5972V41.8418C2.63079 41.1361 2.86604 40.548 3.33655 40.0775C3.80705 39.607 4.39518 39.3717 5.10093 39.3717H15.3344C16.0401 39.3717 16.6283 39.607 17.0988 40.0775C17.5693 40.548 17.8045 41.1361 17.8045 41.8418V93.5972C17.8045 94.303 17.5693 94.8911 17.0988 95.3616C16.6283 95.8321 16.0401 96.0673 15.3344 96.0673Z" fill="currentColor"/>
                            <path d="M111.431 67.9548C111.431 59.4073 114.254 52.2321 119.9 46.4293C125.624 40.6264 132.76 37.7249 141.308 37.7249C149.62 37.7249 156.521 40.4695 162.01 45.9587C167.499 51.3695 170.244 58.0742 170.244 66.0728V68.8958C170.244 69.7584 170.009 70.4249 169.538 70.8954C169.146 71.2875 168.479 71.4836 167.538 71.4836H127.075C127.467 75.4044 129.036 78.6195 131.78 81.1289C134.603 83.5598 137.975 84.7753 141.896 84.7753C143.308 84.7753 144.641 84.6576 145.895 84.4224C147.15 84.1087 148.365 83.6382 149.542 83.0109C150.483 82.5404 151.345 81.9915 152.129 81.3641C152.992 80.6584 153.855 79.7958 154.717 78.7764C155.188 78.2274 155.658 77.9138 156.129 77.8353C156.678 77.7569 157.227 77.8745 157.776 78.1882L166.127 83.3638C166.754 83.7558 167.107 84.2263 167.186 84.7753C167.264 85.3242 167.107 85.8731 166.715 86.422C165.46 88.0688 163.931 89.5979 162.128 91.0094C160.402 92.4209 158.481 93.6364 156.364 94.6558C154.09 95.6753 151.737 96.4202 149.306 96.8907C146.876 97.4396 144.327 97.7141 141.661 97.7141C132.956 97.7141 125.742 94.8911 120.018 89.245C114.293 83.599 111.431 76.5022 111.431 67.9548ZM127.428 61.8382H155.305C154.678 58.231 153.07 55.3688 150.483 53.2515C147.973 51.0559 144.954 49.958 141.426 49.958C137.975 49.958 134.956 51.0559 132.368 53.2515C129.859 55.4472 128.212 58.3095 127.428 61.8382Z" fill="currentColor"/>
                            <path d="M206.124 38.6659H207.771C208.634 38.6659 209.3 38.9404 209.771 39.4893C210.241 39.9598 210.476 40.6656 210.476 41.6066V51.7224C210.476 52.6634 210.202 53.4084 209.653 53.9573C209.104 54.4278 208.438 54.6631 207.653 54.6631H204.83C200.831 54.6631 197.851 55.8001 195.891 58.0742C193.93 60.2699 192.95 63.681 192.95 68.3076V93.8325C192.95 94.4598 192.676 95.0087 192.127 95.4792C191.656 95.8713 191.107 96.0673 190.48 96.0673H180.129C179.502 96.0673 178.953 95.8321 178.482 95.3616C178.012 94.8911 177.776 94.3422 177.776 93.7148V41.8418C177.776 41.1361 178.012 40.548 178.482 40.0775C178.953 39.607 179.502 39.3717 180.129 39.3717H189.892C190.519 39.3717 191.068 39.607 191.539 40.0775C192.009 40.548 192.244 41.0577 192.244 41.6066V47.0174C193.813 44.3512 195.773 42.3123 198.126 40.9008C200.557 39.4109 203.223 38.6659 206.124 38.6659Z" fill="currentColor"/>
                            <path d="M234.462 22.4336C234.462 25.0214 233.521 27.2562 231.639 29.1383C229.835 31.0203 227.64 31.9613 225.052 31.9613C222.464 31.9613 220.229 31.0203 218.347 29.1383C216.465 27.2562 215.524 25.0214 215.524 22.4336C215.524 19.8458 216.465 17.6109 218.347 15.7289C220.229 13.8469 222.464 12.9059 225.052 12.9059C227.64 12.9059 229.835 13.8469 231.639 15.7289C233.521 17.6109 234.462 19.8458 234.462 22.4336ZM230.11 96.0673H219.876C219.171 96.0673 218.583 95.8321 218.112 95.3616C217.642 94.8911 217.406 94.303 217.406 93.5972V41.8418C217.406 41.1361 217.642 40.548 218.112 40.0775C218.583 39.607 219.171 39.3717 219.876 39.3717H230.11C230.816 39.3717 231.404 39.607 231.874 40.0775C232.345 40.548 232.58 41.1361 232.58 41.8418V93.5972C232.58 94.303 232.345 94.8911 231.874 95.3616C231.404 95.8321 230.816 96.0673 230.11 96.0673Z" fill="currentColor"/>
                            <path d="M242.062 39.3717H249.707V30.4321C249.707 24.3156 251.55 19.4929 255.236 15.9642C259 12.357 263.862 10.5534 269.821 10.5534C272.644 10.5534 275.507 11.0631 278.408 12.0825C281.31 13.0235 283.662 14.3174 285.466 15.9642C286.015 16.4347 286.289 17.062 286.289 17.8462C286.367 18.5519 286.171 19.2577 285.701 19.9634L281.231 26.08C280.761 26.8642 280.133 27.2955 279.349 27.3739C278.565 27.3739 277.702 27.021 276.761 26.3152C275.82 25.6879 274.879 25.2174 273.938 24.9037C272.997 24.5901 272.096 24.4332 271.233 24.4332C269.351 24.4332 267.822 25.0998 266.646 26.4329C265.469 27.766 264.881 29.5303 264.881 31.726V39.3717H276.056C276.761 39.3717 277.35 39.607 277.82 40.0775C278.291 40.548 278.526 41.1361 278.526 41.8418V49.6051C278.526 50.2325 278.251 50.8206 277.702 51.3695C277.232 51.84 276.683 52.0753 276.056 52.0753H264.881V93.5972C264.881 94.2245 264.646 94.8127 264.175 95.3616C263.705 95.8321 263.156 96.0673 262.529 96.0673H252.178C251.55 96.0673 250.962 95.8321 250.413 95.3616C249.943 94.8127 249.707 94.2245 249.707 93.5972V52.0753H242.062C241.356 52.0753 240.768 51.84 240.297 51.3695C239.827 50.899 239.592 50.3109 239.592 49.6051V41.8418C239.592 41.1361 239.827 40.548 240.297 40.0775C240.768 39.607 241.356 39.3717 242.062 39.3717Z" fill="currentColor"/>
                            <path d="M296.829 123.121C293.536 123.121 290.438 122.612 287.537 121.592C284.636 120.573 282.205 119.122 280.244 117.24C279.695 116.691 279.382 116.025 279.303 115.24C279.303 114.456 279.578 113.711 280.127 113.005L283.773 107.948C284.4 107.085 285.145 106.614 286.008 106.536C286.87 106.458 287.733 106.732 288.596 107.359C289.772 108.3 290.948 108.967 292.124 109.359C293.301 109.83 294.516 110.065 295.771 110.065C297.496 110.065 299.025 109.516 300.358 108.418C301.691 107.399 302.711 105.948 303.416 104.066L306.239 96.0673H300.946C300.241 96.0673 299.613 95.8713 299.064 95.4792C298.594 95.0871 298.241 94.499 298.006 93.7148L280.832 43.0181C280.519 41.8418 280.558 40.94 280.95 40.3127C281.42 39.6854 282.205 39.3717 283.302 39.3717H295.418C296.045 39.3717 296.555 39.607 296.947 40.0775C297.418 40.4695 297.77 41.0577 298.006 41.8418L310.356 82.3051L322.001 41.8418C322.237 41.0577 322.589 40.4695 323.06 40.0775C323.609 39.607 324.275 39.3717 325.06 39.3717H336.705C337.724 39.3717 338.43 39.7246 338.822 40.4303C339.214 41.0577 339.214 41.9203 338.822 43.0181L318.355 106.418C316.551 111.829 313.807 115.946 310.121 118.769C306.514 121.671 302.083 123.121 296.829 123.121Z" fill="currentColor"/>
                            <path d="M61.6356 66.859C42.5771 42.5655 28.981 32.9862 22.9239 30.4963C22.1528 30.1794 21.5193 30.9449 21.8863 31.6934C29.8941 48.0286 51.7898 98.5119 59.1175 117.419C59.4248 118.212 60.5074 118.301 60.9269 117.561C81.2 81.8037 119.894 26.254 143.852 2.26522C144.505 1.61174 144.016 0.548718 143.105 0.70447C119.825 4.68751 81.0142 45.4231 63.1953 66.9084C62.7862 67.4017 62.0313 67.3633 61.6356 66.859Z" fill="currentColor"/>
                            </svg>
                    </h1>
                </div>
                <div class="headerBtnsContainer">
                    <button class="button small secondary" (click)="goToFeedback()">
                        <app-icon iconName="feedback"></app-icon>
                        Share Feedback
                    </button>
                    <button *ngIf="canAccessAsOtherRoles" class="button small secondary" (click)="switchUserAccess()">
                        <app-icon iconName="switchUser"></app-icon>
                        Switch User Access
                    </button>
                    <button *ngIf="canAccessAsOtherDivisions" class="button small secondary" (click)="switchDivision()">
                        <app-icon iconName="store"></app-icon>
                        Switch Division
                    </button>
                    <button *ngIf="canAccessAsOtherStores" class="button small secondary" (click)="switchStoreAccess()">
                        <app-icon iconName="store"></app-icon>
                        Switch Store
                    </button>
                    <button class="button small secondary slideToggleBtn" (click)="isResponsiveMenuOpen = !isResponsiveMenuOpen">
                        <app-icon iconName="menu"></app-icon>
                    </button>
                    <button class="button small secondary slideToggleBtn" (click)="openMobileMenuOpen()">
                        <app-icon iconName="threeDots" color="#fff"></app-icon>
                    </button>
                </div>
            </div>
            <div class="breadCrumbs core-body-sm">
                <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index;">
                    <span *ngIf="i < breadcrumbs.length - 1">
                        <span>{{breadcrumb.display}}</span> &nbsp;/&nbsp;
                    </span>

                    <span *ngIf="i === breadcrumbs.length - 1">
                        {{breadcrumb.display}}
                    </span>
                </ng-container>
                <span >
                </span>
            </div>
        </header>

        <main>
            <!-- main content is displayed here via content projection -->
            <ng-content>

            </ng-content>
        </main>
    </div>
</div>