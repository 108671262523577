import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { CsaAuthService } from '../auth/csa-auth.service';
import { ILocation, User } from '../models/user';

interface ILocationItem {
  name: string, 
  value: number, 
  state: string; 
  zone: string; 
  group: string;
  region: string;
  area: string;
}

@Component({
  selector: 'app-switch-location',
  templateUrl: './switch-location.component.html',
  styleUrls: ['./switch-location.component.scss']
})
export class SwitchLocationComponent implements OnInit {
  user: User = {};

  selectedLocation = <ILocation>{};

  displayedColumns: string[] = [];

  dataSource: MatTableDataSource<ILocationItem>;

  locationList: ILocationItem[] = [];
  
  originalLocationList: object[] = [];
  
  isLoading = false;

  selectedValue = 1;

  constructor(
    private auth: CsaAuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.user = this.auth.user;
    this.displayedColumns = this.generateDisplayColumns(this.user.locations);
    this.locationList = this.generateLocationList(this.user.locations);
    this.selectedValue = this.getUserLocationId(this.user);
    this.setUserOverrideForLocation(this.selectedValue);
    this.initialiseGrid();
    this.isLoading = false;    
  }

  getUserLocationId(user: User): number {
    const userLocation = this.auth.getUserLocation(user);
    return userLocation?.locationId;
  }

  getUserLocationById(user: User, locationId: number): ILocation | undefined {
    return user.locations?.find((location: ILocation) => location.locationId === locationId);
  }

  generateDisplayColumns(locations: object[]): string[] {
    if (!locations || locations.length === 0) {
      return [];
    }
    const location = locations[0];
    const columns = ['option'];
    if (location['state']) {
      columns.push('state');
    }
    if (location['zone']) {
      columns.push('zone');
    }
    if (location['groupNo']) {
      columns.push('group');
    }
    if (location['region']) {
      columns.push('region');
    }
    if (location['area']) {
      columns.push('area');
    }
    return columns;
  }

  generateLocationList(locations: object[]): ILocationItem[] {
    return locations?.map((location: object) => <ILocationItem>{ 
      name: `opt${location['locationId']}`, 
      value: location['locationId'], 
      state: location['state'],
      zone: location['zone'],
      group: location['groupNo'],
      region: location['region'],
      area: location['area'],
    });
  }

  initialiseGrid() {
    this.dataSource = new MatTableDataSource<ILocationItem>(this.locationList);
  }

  onChange(locationId: number) {
    this.selectedLocation = this.getUserLocationById(this.user, locationId) ?? <ILocation>{};
  }

  setUserOverrideForLocation(locationId: number) {
    this.selectedLocation = this.getUserLocationById(this.user, locationId) ?? <ILocation>{};
    this.setUserOverride(this.selectedLocation);
  }

  setUserOverride(selectedLocation: ILocation) {
    this.auth.setUserOverride('area', selectedLocation['area'] ?? '');
    this.auth.setUserOverride('groupNo', selectedLocation['groupNo'] ?? '');
    this.auth.setUserOverride('region', selectedLocation['region'] ?? '');
    this.auth.setUserOverride('state', selectedLocation['state'] ?? '');
    this.auth.setUserOverride('zone', selectedLocation['zone'] ?? '');
  }

  save() {
    this.setUserOverride(this.selectedLocation);
    this.router.navigate(['/']);
  }

  cancel() {
    this.router.navigate(['/']);
  }  
}
