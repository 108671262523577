import {
  Component, OnInit 
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import {
  faPencilAlt, faFileAlt, faTimes 
} from '@fortawesome/free-solid-svg-icons';
import { MessagesComponent } from '../messages/messages.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-once-off-assessment-overview',
  templateUrl: './once-off-assessment-overview.component.html',
  styleUrls: ['./once-off-assessment-overview.component.scss'],
})
export class OnceOffAssessmentOverviewComponent implements OnInit {
  faPencilAlt = faPencilAlt;

  faTimes = faTimes;

  faFileAlt = faFileAlt;
  
  dateToday = new Date();

  editDueDate = false;

  editPublishDate = false;

  maxPublishDate;

  minPublishDate = new Date();

  minDueDate;

  orgArray = [];

  orgStructure;

  surveyDetails = {};

  surveyID = '';

  user: User;

  constructor(
    private csaAuth: CsaAuthService,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private surveyService:SurveysService,
  ) {}

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.getAssessmentDetails();
  }

  getAssessmentDetails() {
    this.surveyService
      .getAdminSurveyById(this.surveyID)
      .subscribe((surveysData) => {
        this.surveyDetails = surveysData;
        this.minPublishDate.setDate(this.minPublishDate.getDate() + 1);
        this.maxPublishDate = new Date(this.surveyDetails['dueDate']);
        this.minDueDate = new Date(this.surveyDetails['publishDate']);
      });
  }

  getDate(date) {
    const newDate = moment(date, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format('DD MMM YYYY');
    return newDate;
  }

  cancelAssessment() {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      status: 'cancelled',
    };

    const confirmationCancel = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'If you continue, this Assessment will not be published to users on the publish date.',
        closeText: 'Cancel Assessment',
        cancelText: 'Cancel',
      },
      backdropClass: 'dialog-backdrop',
    });

    confirmationCancel.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        this.surveyService.updateSurvey(updateData).subscribe(
          (message) => {
            if (message['status'] == 200) {
              const confirmationTwo = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Assessment Cancelled',
                  message:
                    'This Assessment has been cancelled. It will now appear only in the All tab of the More Assessments Page',
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });

              confirmationTwo.afterClosed().subscribe((result) => {
                window.location.reload();
              });
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Cancel Assessment",
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          },
          (error) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: "Couldn't Update Due Date",
                message:
                  `Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    });
  }

  submitDueDateForm(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      dueDate: moment(value.dueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
      publishDate: moment(this.surveyDetails['publishDate'], [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        const confirmationTwo = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Due Date Updated',
            message:
              'The due date has been updated. The page will now reload to update the reminder dates',
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });

        confirmationTwo.afterClosed().subscribe((result) => {
          window.location.reload();
        });
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Due Date",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.surveyDetails['dueDate'] = this.getDate(value.dueDate);

    this.editDueDate = false;
    this.maxPublishDate = moment(value.dueDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    survey.reset();
  }

  submitPublishDateForm(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      publishDate: moment(value.publishDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
      dueDate: moment(this.surveyDetails['dueDate'], [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        const confirmation = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Publish Date Updated',
            message:
              'The publish date has been updated. The page will now reload to update the reminder dates',
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });

        confirmation.afterClosed().subscribe((result) => {
          window.location.reload();
        });
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Publish Date",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.minDueDate = moment(value.publishDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    this.editPublishDate = false;
    this.surveyDetails['publishDate'] = this.getDate(value.publishDate);

    survey.reset();
  }
}
