<div class="close-container">
  <span class="info-icon med-grey" (click)="closeMe()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </span>
</div>

<h1 mat-dialog-title>{{photoName}}</h1>
<div mat-dialog-content>
  <div #photoContainer class="photo-container" [ngStyle]="{'height': divHeight}">
    <img *ngIf="checkName(photoName) == true" #photoImg
      [ngStyle]="{
        'margin-top': marginTop, 
        'margin-left': marginLeft, 
        'transform': transformOffset + 'rotate(' + imageDegree +'deg)',
        'transition':'transform 500ms ease-out'
      }"
      [src]="photoURL">
    <!-- <img *ngIf="checkName(photoName) == true" #photoImg
      [ngStyle]="imageRotation"
      [src]="photoURL"> -->
    <pdf-viewer *ngIf="checkName(photoName) == false" [src]="photoURL" [autoresize]="true" [original-size]="false"
      [external-link-target]="'blank'" [render-text]="true" style="display: block;"></pdf-viewer>
  </div>
</div>
<div mat-dialog-actions class="remove-download">
  <div class="question-options-row">
    <div class="filter-chips">
      <div class="chipDrop" *ngIf="data.showRemove == true">
        <mat-chip-list aria-label="Filters">
          <mat-chip color="warn" selected (click)="deletePhoto()">
            <fa-icon class="chip-left-icon" [icon]="faTrash"></fa-icon>
            <span>Remove</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="chipDrop" *ngIf="checkName(photoName) == true">
        <mat-chip-list aria-label="Filters">
          <mat-chip color="primary" selected (click)="rotatePhoto()">
            <fa-icon class="chip-left-icon" [icon]="faRedo"></fa-icon>
            <span>Rotate</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip color="primary" selected (click)="downloadPhoto()">
            <fa-icon class="chip-left-icon" *ngIf="!isDownloading" [icon]="faDownload"></fa-icon>
            <span *ngIf="!isDownloading">Download</span>
            <fa-icon class="chip-left-icon" *ngIf="isDownloading" [icon]="faCircleNotch" [spin]="true"></fa-icon>
            <span *ngIf="isDownloading">Downloading</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
</div>
