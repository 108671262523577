<div class="page-container">
    <div class="audit-tool-cards-container">
  
      <div class="audit-tool-card">
        <div class="success-check">
          <div class="checkmark-circle">
          <div class="background"></div>
          <div class="checkmark draw"></div>
        </div>
        <h2>Thank you</h2>
        <p>Thank you for completing the assessment. Your response has been recorded.</p>
        <p>Click the below button to go back to the home screen</p>
        <div class="survey-button padded-icon">
            <button class="csa-button-solid" [routerLink]="['/']">Return Home</button>
        </div>
      </div>
  
    </div> 
</div>
  