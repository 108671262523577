import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { LoaderService } from '../../common-services/loader.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  errorType = '';

  constructor(private actRoute: ActivatedRoute, private router: Router, private loaderService: LoaderService) { }

  ngOnInit() {
    this.errorType = this.actRoute.snapshot.paramMap.get('id');
    console.log(this.errorType);

    this.loaderService.hide('Error Page Component');
  }
}
