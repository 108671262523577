 <div class="close-container">
      <span class="info-icon med-grey" (click)="closeMe()"><fa-icon [icon]="faTimes"></fa-icon></span>
</div>

<mat-dialog-content>
  <h1>Load Templates from Sheets</h1>

  <p>Below is the list of templates currently in the drive folder. Please review and make any changes prior to loading.</p>

  <div class="template-list">
    <div class="survey-line no-ripple no-effect" *ngFor="let template of (templates | unique :'title')">
        <div class="question-options-row" *ngIf="template['rename'] == false">
          <div class="option-lg">
            {{ template['newTitle'] }}
          </div>
          <div class="option-small">
            <div>
              <a (click)="renameTemplate(template['title'], true)" id="renameBtn"><fa-icon [icon]="faPencilAlt"></fa-icon>Rename</a>
            </div>
            <div *ngIf="user['division'] === 'Supermarkets'">
              <mat-checkbox (change)="toggleEITB($event, template['title'] )" [(ngModel)]="this.flagCheckList[user['division']][template['title']]" color="primary">
              GPS Template
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="question-options-row" *ngIf="template['rename'] == true">
          <div class="option-lg">
            <form #renameForm="ngForm" (submit)="setTemplateName(renameForm.value, template['title'])">
              <mat-form-field class="full-width">
                <mat-label>Template Name</mat-label>
                <input spellcheck="true" [ngModel]="template['newTitle']" #title maxLength="50" pattern="[a-zA-Z0-9-_ ]+" matInput class="custom-control-input" name="title" required>
                <mat-hint align="end">{{title['value'].length || 0}}/50</mat-hint>
                <mat-error>Please enter an Template Name Without Special Characters</mat-error>
              </mat-form-field>
              <div>
                <button [disabled]="!renameForm.valid" class="csa-button-solid survey-submit small-button" type="submit">Save</button>
                <button class="csa-button-outline small-button" (click)="renameTemplate(template['title'], false)">Cancel</button>
              </div>
            </form>
          </div>
        </div>

        <div class="question-options-row" *ngIf="template['listStatus'] === 'active' && template['status'] !== 'New Name'">
          <div class="option-lg">
             <span class="todo-text center-align">A Template with this name already exists. <br> Do you want to archive the old Template?</span>
          </div>
          <div class="option-small">
            <div class="switch-container">
                <p>No &nbsp; <mat-slide-toggle color="primary" #targeted (change)="markAsArchive(template['title'], !template['archive'])" [ngModel]="template['archive']" name="targeted"></mat-slide-toggle> &nbsp; Yes</p>
            </div>
          </div>

        </div>
    </div>
  </div>



  <div class="submit-button-container center-align pt2">
    <button class="csa-button-solid survey-submit" (click)="uploadTemplates()">Load Templates</button>
  </div>

</mat-dialog-content>
