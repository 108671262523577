/**
 * Returns a new array containing only the values from `array` that are also present in `included`.
 *
 * @template T - The type of elements in the arrays.
 * @param {T[]} array - The input array to filter.
 * @param {T[]} included - The array of values to keep in the output.
 * @returns {T[]} A new array containing only the values that exist in both `array` and `included`.
 * @example
 * getIncludedValues([1, 2, 3, 4], [2, 4]); // Returns: [2, 4]
 * getIncludedValues(["apple", "banana", "cherry"], ["banana", "cherry"]); // Returns: ["banana", "cherry"]
 * getIncludedValues([true, false, true], [true]); // Returns: [true, true]
 */
export function getIncludedValues<T>(array: T[], included: T[]): T[] {
  if (!array || !array.length) return [];
  return array.filter((value) => included.includes(value));
}
