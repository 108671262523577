import { Component, OnDestroy } from '@angular/core';
import { Subject, take, takeUntil } from 'rxjs';
import { CsaAuthService } from '../auth/csa-auth.service';
import { ToDoListService, toDoListData } from './to-do-list.service';
import { FeatureAccessService } from '../common-services/feature-access.service';

@Component({
  selector: 'app-to-do-list',
  templateUrl: './to-do-list.component.html',
  styleUrls: ['./to-do-list.component.scss']
})
export class ToDoListComponent implements OnDestroy {
  public toDoListAvailable: toDoListData;

  public toDoListThisWeek: toDoListData;

  public toDoListNextWeek: toDoListData;

  public isToDoListAvailableEmpty = false;

  public isToDoListThisWeekEmpty = false;

  public isToDoListNextWeekEmpty = false;

  public isToDoListAvailableLoading = true;

  public isToDoListThisWeekLoading = true;

  public isToDoListNextWeekLoading = true;

  public noUserAccess = false;
  public viewOnceOffAssessments = false;
  public viewAdhocAssessments = false;
  public viewRecurringAssessments = false;
  public viewMaterialRiskAssessments = false;
  public viewStandardActions = false;
  public viewMaterialRiskActions = false;

  private storeId;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
      private auth: CsaAuthService, 
      private toDoListService: ToDoListService, 
      private featureAccessService: FeatureAccessService, 
  ) {
    this.auth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.storeId = this.auth.user.storeID;
      this.getViewAccess();
      this.getToDoListAvailable();
      this.getToDoListThisWeek();
      this.getToDoListNextWeek();
    });
  }

  getViewAccess() {
    this.viewOnceOffAssessments = this.featureAccessService.hasAccess('storeToDoList.viewOnceOffAssessments');
    this.viewAdhocAssessments = this.featureAccessService.hasAccess('storeToDoList.viewAdhocAssessments');
    this.viewRecurringAssessments = this.featureAccessService.hasAccess('storeToDoList.viewRecurringAssessments');
    this.viewMaterialRiskAssessments = this.featureAccessService.hasAccess('storeToDoList.viewMaterialRiskAssessments');
    this.viewStandardActions = this.featureAccessService.hasAccess('storeToDoList.viewStandardActions');
    this.viewMaterialRiskActions = this.featureAccessService.hasAccess('storeToDoList.viewMaterialRiskActions');
    this.noUserAccess = !this.viewOnceOffAssessments && !this.viewAdhocAssessments && !this.viewRecurringAssessments && 
      !this.viewMaterialRiskAssessments && !this.viewStandardActions && !this.viewMaterialRiskActions
  }

  /**
   * Determines whether the to-do list is empty based on the user's access rights and visibility flags.
   * 
   * This function checks if different categories within the to-do list data (actions and surveys) are empty.
   * Only categories for which the user has visibility (controlled by flags such as `this.viewStandardActions`, 
   * `this.viewMaterialRiskActions`, etc.) are evaluated. If a category is not visible, it is ignored in the check.
   *
   * @param {toDoListData} toDoListData - The data structure representing the to-do list.
   * @param {Array} toDoListData.actions.standard - List of standard actions.
   * @param {Array} toDoListData.actions.mrr_actions - List of material risk actions.
   * @param {Array} toDoListData.surveys.adhoc - List of ad-hoc surveys.
   * @param {Array} toDoListData.surveys.mrr - List of material risk assessments.
   * @param {Array} toDoListData.surveys.recurring - List of recurring assessments.
   * @param {Array} toDoListData.surveys.standard - List of once-off assessments.
   * @returns {boolean} `true` if all visible lists are empty, `false` if any visible list contains items.
   */
  isToDoListEmpty(toDoListData: toDoListData): boolean {
    return !((this.viewStandardActions && toDoListData.actions.standard.length !== 0) || 
        (this.viewMaterialRiskActions && toDoListData.actions.mrr_actions.length !== 0) ||
        (this.viewAdhocAssessments && toDoListData.surveys.adhoc.length !== 0) ||
        (this.viewMaterialRiskAssessments && toDoListData.surveys.mrr.length !== 0) ||
        (this.viewRecurringAssessments && toDoListData.surveys.recurring.length !== 0) ||
        (this.viewOnceOffAssessments && toDoListData.surveys.standard.length !== 0));
  }

  getToDoListAvailable() {
    this.toDoListAvailable = null;
    this.isToDoListAvailableEmpty = false;

    this.isToDoListAvailableLoading = true;

    this.toDoListService
      .getTodoList(
        'available',
        this.storeId
      )
      .pipe(take(1)).subscribe(
        (toDoListData) => {
          this.toDoListAvailable = toDoListData;
          this.isToDoListAvailableLoading = false;
          this.isToDoListAvailableEmpty = this.isToDoListEmpty(toDoListData);
        },
        (error) => {
          this.isToDoListAvailableLoading = false;
          throw new Error('error loading to do list');
        }
      );
  }

  getToDoListThisWeek() {
    this.toDoListThisWeek = null;
    this.isToDoListThisWeekEmpty = false;

    this.isToDoListThisWeekLoading = true;

    this.toDoListService
      .getTodoList(
        'this_week',
        this.storeId
      )
      .pipe(take(1)).subscribe(
        (toDoListData) => {
          this.toDoListThisWeek = toDoListData;
          this.isToDoListThisWeekLoading = false;
          this.isToDoListThisWeekEmpty = this.isToDoListEmpty(toDoListData);
        },
        (error) => {
          this.isToDoListThisWeekLoading = false;
          throw new Error('error loading to do list');
        }
      );
  }

  getToDoListNextWeek() {
    this.toDoListNextWeek = null;
    this.isToDoListNextWeekEmpty = false;

    this.isToDoListNextWeekLoading = true;

    this.toDoListService
      .getTodoList(
        'next_week',
        this.storeId
      )
      .pipe(take(1)).subscribe(
        (toDoListData) => {
          this.toDoListNextWeek = toDoListData;
          this.isToDoListNextWeekLoading = false;
          this.isToDoListNextWeekEmpty = this.isToDoListEmpty(toDoListData);
        },
        (error) => {
          this.isToDoListNextWeekLoading = false;
          throw new Error('error loading to do list');
        }
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
