import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams 
} from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { CookieService } from 'ngx-cookie-service';
import { ContentObserver } from '@angular/cdk/observers';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { MrrVisitNote } from '../models/mrr-visit-note';
import { handleError } from '../utils/handle-error/handle-error';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {
  private updateTemplateUrl = `${environment.backEndUrl}/template/update`;

  private uploadTemplatesUrl = `${environment.backEndUrl}/uploadTemplate/`;

  private getTemplatesDriveUrl = `${environment.backEndUrl}/templates/gdrive/url`;

  private nextLevelUrl = `${environment.backEndUrl}/orgStructure/belowLevel`;

  private storeListbelowLevelUrl = `${environment.backEndUrl}/store/list/belowLevel`;

  private storeListUrl = `${environment.backEndUrl}/store/list`;

  private updateStoreUrl = `${environment.backEndUrl}/store/`;

  private drillDownUrl = `${environment.backEndUrl}/survey/drillDown/`;

  private getUserAccessListUrl = `${environment.backEndUrl}/users/list`;

  private updateUserUrl = `${environment.backEndUrl}/users/`;

  private searchSectionHeadingsUrl = `${environment.backEndUrl}/section-heading/search`;

  private searchControlNamesUrl = `${environment.backEndUrl}/control-name/search`;

  private listEvidenceUrl = `${environment.backEndUrl}/evidence/list`;

  private searchSurveysUrl = `${environment.backEndUrl}/survey/search/`;

  private surveysUrl = `${environment.backEndUrl}/survey/list`;

  private surveyResponseUrl = `${environment.backEndUrl}/survey-response/list`;

  private surveyByIDUrl = `${environment.backEndUrl}/response/`;

  private adminSurveyByIDUrl = `${environment.backEndUrl}/survey/`;

  private templatesUrl = `${environment.backEndUrl}/templates`;

  private createSurveyUrl = `${environment.backEndUrl}/createSurvey`;

  private updateSurveyUrl = `${environment.backEndUrl}/survey/update`;

  private searchTagsUrl = `${environment.backEndUrl}/tags/search`;

  private createActionUrl = `${environment.backEndUrl}/actions/create`;

  private updateActionUrl = `${environment.backEndUrl}/actions/update`;

  private saveResponseUrl = `${environment.backEndUrl}/response/update/all`;

  private autoSaveNewActionUrl = `${environment.backEndUrl}/response/autoSaveAction/push`;

  private autoSaveUpdateActionUrl = `${environment.backEndUrl}/response/autoSaveAction/update`;

  private autoSaveDeleteActionUrl = `${environment.backEndUrl}/response/autoSaveAction/delete`;
  
  private saveQuestionUrl = `${environment.backEndUrl}/response/update/question`;

  private actionListUrl = `${environment.backEndUrl}/actions/list`;

  private getActionUrl = `${environment.backEndUrl}/actions/get/`;

  private getReportsUrl = `${environment.backEndUrl}/reports/list`;

  private uploadPhotosUrl = `${environment.backEndUrl}/storage/upload/files`;

  private removePhotoUrl = `${environment.backEndUrl}/storage/remove/file/`;

  private getPhotosUrl = `${environment.backEndUrl}/storage/generateUrl`;

  private orgStructureUrl = `${environment.backEndUrl}/orgStructure/`;

  private eitbEmailTriggerUrl = `${environment.backEndUrl}/eitbEmailTrigger`;

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }
  
  updateTemplate(data): Observable<object> {
    const fullUrl = this.updateTemplateUrl;
    return this.httpClient.post<object>(fullUrl, data);
  }

  uploadTemplates(check, data?: { templates: { fileName: string, filterName: string }[] }): Observable<object> {
    const fullUrl = this.uploadTemplatesUrl + check;
    if (check == 'upload') {
      return this.httpClient.post<object>(fullUrl, data);
    }
    return this.httpClient.post<object>(fullUrl, {});
  }

  getTemplateDrive(): Observable<object> {
    const fullUrl = this.getTemplatesDriveUrl;
    return this.httpClient.get<object>(fullUrl);
  }

  getNextLevel(filterString: string): Observable<object[]> {
    const httpHeaders = new HttpHeaders({
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.nextLevelUrl + filterString;
    return this.httpClient.get<object[]>(fullUrl, options);
  }

  getNextLevelAll(filterString: string): Observable<object[]> {
    const httpHeaders = new HttpHeaders({
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = `${this.nextLevelUrl}/pilot${filterString}`;
    return this.httpClient.get<object[]>(fullUrl, options);
  }

  getSurveys(
    userGrade: string,
    group: string,
    status: string,
    index: string,
    count: string
  ): Observable<object[]> {
    let fullUrl = '';
    if (status == '') {
      fullUrl = `${this.surveysUrl + userGrade + index + count}?division=${group}`;
    } else {
      fullUrl = `${this.surveysUrl
        + userGrade
        + index
        + count
      }?status=${
        status
      }&division=${
        group}`;
    }
    return this.httpClient.get<object[]>(fullUrl);
  }

  getStoreLevel(filterString: string): Observable<object[]> {
    const fullUrl = this.storeListbelowLevelUrl + filterString;
    return this.httpClient.get<object[]>(fullUrl);
  }

  getStoreAccessList(
    index: string,
    count: string,
    query: string
  ): Observable<object[]> {
    const fullUrl = this.storeListUrl + index + count + query;
    console.log(fullUrl);
    return this.httpClient.get<object[]>(fullUrl);  
  }

  updateStore(type: string, data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.updateStoreUrl + type;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  getDrillDownInfo(searchString): Observable<object[]> {
    const httpHeaders = new HttpHeaders({
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.drillDownUrl + searchString;
    return this.httpClient.get<object[]>(fullUrl, options);
  }

  getUserAccessList(
    index: string,
    count: string,
    query: string
    // status: string
  ): Observable<object[]> {
    const httpHeaders = new HttpHeaders({
      skip: 'true'
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.getUserAccessListUrl
      + index
      + count
      + query;
    return this.httpClient.get<object[]>(fullUrl, options);
  }

  updateUser(type: string, data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.updateUserUrl + type;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  searchSectionHeadings(surveyTitle, sectionHeading, filter): Observable<object> {
    if (surveyTitle) {
      filter += `&surveyTitle=${surveyTitle}`;
    }
    if (sectionHeading) {
      filter += `&sectionHeading=${sectionHeading}`;
    }
    const fullUrl = this.searchSectionHeadingsUrl + filter;
    return this.httpClient.get<object>(fullUrl);
  }

  searchControlNames(surveyTitle, sectionHeading, controlName, filter): Observable<object> {
    if (surveyTitle) {
      filter += `&surveyTitle=${surveyTitle}`;
    }
    if (sectionHeading) {
      filter += `&sectionHeading=${sectionHeading}`;
    }
    if (controlName) {
      filter += `&controlName=${controlName}`;
    }
    console.log(filter);
    const fullUrl = this.searchControlNamesUrl + filter;
    return this.httpClient.get<object>(fullUrl);
  }

  getEvidenceList(surveyTitle, sectionHeading, controlName, storeID, type, filter): Observable<object> {
    if (surveyTitle) {
      filter += `&surveyTitle=${surveyTitle}`;
    }
    if (sectionHeading) {
      filter += `&sectionHeading=${sectionHeading}`;
    }
    if (controlName) {
      filter += `&controlName=${controlName}`;
    }
    if (storeID) {
      filter += `&storeID=${storeID}`;
    }
    if (type) {
      filter += `&type=${type}`;
    }
    console.log(filter);
    const fullUrl = this.listEvidenceUrl + filter;
    return this.httpClient.get<object>(fullUrl);
  }

  searchSurveys(title): Observable<object> {
    const fullUrl = this.searchSurveysUrl + title;
    return this.httpClient.get<object>(fullUrl);
  }

  getStoreList(
    store: string
  ): Observable<object[]> {
    const httpHeaders = new HttpHeaders({
      skip: 'true'
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = `${this.storeListUrl}/${store}`;
    return this.httpClient.get<object[]>(fullUrl, options);
  }

  getAllStoreList(): Observable<object[]> {
    const httpHeaders = new HttpHeaders({
      skip: 'true'
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = `${this.storeListUrl}/all`;
    return this.httpClient.get<object[]>(fullUrl, options);
  }

  getStoreSurveys(
    status: string,
    index: string,
    count: string,
    type: string = undefined,
  ): Observable<object[]> {
    let fullUrl = this.surveyResponseUrl
      + index
      + count
      + status;
    if (type) {
      fullUrl += `&type=${type}`;
    }      
    const httpHeaders = new HttpHeaders({
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };

    return this.httpClient.get<object[]>(fullUrl, options);
  }

  getSurveyById(id: string): Observable<object[]> {
    const fullUrl = this.surveyByIDUrl + id;
    return this.httpClient.get<object[]>(fullUrl);
  }

  getAboveStoreSurveyID(id: string, filter: string): Observable<object[]> {
    const fullUrl = this.adminSurveyByIDUrl + id + filter;
    const httpHeaders = new HttpHeaders({
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };
    return this.httpClient.get<object[]>(fullUrl, options);
  }

  getAdminSurveyById(id: string): Observable<object[]> {
    const fullUrl = this.adminSurveyByIDUrl + id;
    return this.httpClient.get<object[]>(fullUrl);
  }

  getTemplates(UrlParams: string): Observable<object[]> {
    const fullUrl = this.templatesUrl + UrlParams;
    return this.httpClient.get<object[]>(fullUrl);
  }

  createSurvey(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.createSurveyUrl;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  updateSurvey(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.updateSurveyUrl;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  searchTags(searchTerm: string): Observable<object> {
    const payload = {
      search: searchTerm
    };
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });     
    return this.httpClient.post<object>(`${this.searchTagsUrl}`, payload, { headers: httpHeaders }).pipe(
      map((data: object) => ({
        searchResults: data['tagsSearchList'],
        status: data['status']
      })),
      catchError(handleError)
    );
  }

  createAction(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.createActionUrl;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  updateAction(updateParams: string, data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.updateActionUrl + updateParams;
    return this.httpClient.put<object>(fullUrl, data, options);
  }

  saveSurvey(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.saveResponseUrl;
    return this.httpClient.put<object>(fullUrl, data, options);
  }

  autoSaveAction(data: object, status: string): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };
    let fullUrl = '';
    if (status == 'new') {
      fullUrl = this.autoSaveNewActionUrl;
    } else if (status == 'update') {
      fullUrl = this.autoSaveUpdateActionUrl;
    } else if (status == 'delete') {
      fullUrl = this.autoSaveDeleteActionUrl;
    }
    return this.httpClient.put<object>(fullUrl, data, options);
  }

  saveQuestion(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.saveQuestionUrl;
    return this.httpClient.put<object>(fullUrl, data, options);
  }

  getReportsList(
    index: string,
    count: string,
    role: string
  ): Observable<object[]> {
    const fullUrl = this.getReportsUrl + index + count + role;
    return this.httpClient.get<object[]>(fullUrl);
  }

  getActionList(
    index: string,
    count: string,
    status: string
  ): Observable<object[]> {
    let fullUrl = '';
    fullUrl = this.actionListUrl + index + count + status;
    return this.httpClient.get<object[]>(fullUrl);
  }

  getAction(id: string): Observable<object[]> {
    const fullUrl = this.getActionUrl + id;
    return this.httpClient.get<object[]>(fullUrl);
  }

  getPhotos(photos: string): Observable<object[]> {
    const fullUrl = this.getPhotosUrl + photos;
    return this.httpClient.get<object[]>(fullUrl);
  }

  uploadPhoto(
    data: Blob,
    surveyID: string,
    questionID: string,
    storeID: string,
    fileSize: string
  ): Observable<object> {
    const fullUrl = this.uploadPhotosUrl;
    const formData: FormData = new FormData();
    formData.append('file1', data, this.removeSpecialCharactersFromFileName(data['name']));
    formData.append('surveyID', surveyID);
    formData.append('questionID', questionID);
    formData.append('storeID', storeID);
    formData.append('fileSize', fileSize);
    return this.httpClient.post<object>(fullUrl, formData);
  }

  removePhoto(
    file: string,
    data: object,
    responseID: string,
    question_id: string
  ): Observable<object> {
    const fullUrl = `${this.removePhotoUrl + file}/${responseID}/${question_id}`;
    return this.httpClient.delete<object>(fullUrl, data);
  }

  getOrgStructure(division: string): Observable<object[]> {
    const fullUrl = this.orgStructureUrl + division;
    return this.httpClient.get<object[]>(fullUrl);
  }

  eitbEmailTrigger(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.eitbEmailTriggerUrl;
    return this.httpClient.post<object>(fullUrl, data, options);
  }

  /**
   * Our backend security policies reject file names that have special characters, probably because of a false flag.
   * This function removes special characters (except for hyphen, period and space) to allow them to upload successfully.
   * 
   * @param fileName - The file name we want to remove special characters from.
   * @returns The passed in file name, with all special characters removed.
   */
  private removeSpecialCharactersFromFileName(fileName: string): string {
    return fileName.replace(/[^\w .-]/g, '');
  }
}
