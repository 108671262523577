import {
  Component, Inject, OnInit, ChangeDetectorRef 
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

import { CsaAuthService } from '../../auth/csa-auth.service';

import { MessagesComponent } from '../messages/messages.component';

import { UniquePipe } from '../../custom-pipes/unique.pipe';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-load-template-dialog',
  templateUrl: './load-template-dialog.component.html',
  styleUrls: ['./load-template-dialog.component.scss'],
  providers: [UniquePipe],
})
export class LoadTemplateDialogComponent implements OnInit {
  faTimes = faTimes;

  faPencilAlt = faPencilAlt;

  templates = [];

  user: User;

  flagCheckList = {
    Supermarkets: {},
    Metro: {},
    DC: {},
    Bigw: {},
    CFC: {},
    'Woolworths NZ Stores': {},
    'Woolworths NZ eStores': {},
    'Woolworths NZ Primary Connect': {},
  };

  renameList = {
    Supermarkets: {},
    Metro: {},
    DC: {},
    Bigw: {},
    CFC: {},
    'Woolworths NZ Stores': {},
    'Woolworths NZ eStores': {},
    'Woolworths NZ Primary Connect': {},
  };

  constructor(
    private ref: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private surveyService : SurveysService,
    private dialogRef: MatDialogRef<LoadTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log('v3');
    this.user = this.csaAuth.user;
    this.templates = this.data;
    this.templates.forEach((template) => {
      template['rename'] = false;
      template['newTitle'] = template['title'];
      template['archive'] = true;
      this.flagCheckList[this.user['division']][template['title']] = '';
      this.renameList[this.user['division']][template['title']] = '';
      // this.flagCheckList[user['division']][template["title"]]="";

      if (template['status'] == 'active') {
        this.templates.forEach((templateSecondLevel) => {
          if (templateSecondLevel['title'] === template['title']) {
            templateSecondLevel['listStatus'] = 'active';
            templateSecondLevel['archive'] = true;
          }
        });
      }
    });
  }

  toggleEITB(e, templateTitle) {
    if (e.checked) {
      this.flagCheckList[this.user['division']][templateTitle] = 'eitb';
    } else {
      this.flagCheckList[this.user['division']][templateTitle] = '';
    }
  }

  setTemplateName(formValue, oldTitle) {
    this.templates.forEach((template) => {
      if (template['title'] == oldTitle) {
        this.renameList[this.user['division']][template['title']] = formValue['title'];
        template['newTitle'] = this.renameList[this.user['division']][template['title']];
        template['rename'] = false;
        template['status'] = 'New Name';
        template['archive'] = false;
      }
    });
  }

  renameTemplate(templateName, boolVal) {
    this.templates.forEach((template) => {
      if (template['title'] == templateName) {
        template['rename'] = boolVal;
      }
    });
  }

  markAsArchive(templateName, boolVal) {
    this.templates.forEach((template) => {
      if (template['title'] == templateName) {
        template['archive'] = boolVal;
      }
    });
  }

  archiveTemplate(templateID) {
    const request = {};
    request['id'] = templateID;
    request['status'] = 'archived';

    this.surveyService.updateTemplate(request).subscribe(
      (message) => {
        if (message['status'] != 200) {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Archive Template",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        console.log(error);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Archive Template",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  uploadTemplates() {
    // When importing templates, we make a request to the '/validate' endpoint to make sure the templates in the google drive are valid. The endpoint returns a valid list of templates ready for import PLUS a list of templates that have a duplicate name as our templates pending import. These lists are combined, so we need to filter out the duplicates.

    const templatesFilterToUnique = [
      ...new Map(this.templates.map((item) => [item['title'], item])).values(),
    ];
    const templateMetadata = { templates: [] };
    // if template is for eitb, we need to add additional metadata for backend
    templatesFilterToUnique.forEach((template) => {
      const isTemplateForEitb = this.flagCheckList[this.user['division']][template['title']] == 'eitb';
      if (isTemplateForEitb) {
        templateMetadata.templates.push({
          fileName: template['newTitle'],
          filterName:
            this.flagCheckList[this.user['division']][template['title']],
        });
      }
    });
    this.surveyService.uploadTemplates('upload', templateMetadata).subscribe(
      (result) => {
        if (result['response']) {
          result['response'].forEach((loadedTemplate) => {
            this.templates.forEach((existingTemplate) => {
              if (loadedTemplate['status'] == 'active') {
                if (
                  existingTemplate['status'] == 'active'
                  && loadedTemplate['title'] == existingTemplate['title']
                ) {
                  if (existingTemplate['archive']) {
                    this.archiveTemplate(existingTemplate['_id']);
                  }
                }
              }
            });
            if (loadedTemplate['status'] == 'active') {
              const request = {};
              request['id'] = loadedTemplate['_id'];
              if (
                this.renameList[this.user['division']][loadedTemplate['title']]
              ) {
                request['title'] = this.renameList[this.user['division']][
                  loadedTemplate['title']
                ];
              }
              if (
                this.flagCheckList[this.user['division']][
                  loadedTemplate['title']
                ]
              ) {
                request['filterName'] = this.flagCheckList[this.user['division']][
                  loadedTemplate['title']
                ];
              }
              if (request['title'] || request['filterName']) {
                this.surveyService.updateTemplate(request).subscribe(
                  (message) => {
                    if (message['status'] != 200) {
                      this.dialog.open(MessagesComponent, {
                        data: {
                          heading: "Couldn't Update Template",
                          message:
                            `Please email ${ 
                              this.user['supportEmail']
                            } for support`,
                          closeText: 'Ok',
                        },
                        backdropClass: 'dialog-backdrop',
                      });
                    }
                  },
                  (error) => {
                    console.log(error);
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Update Template",
                        message:
                          `Please email ${ 
                            this.user['supportEmail']
                          } for support`,
                        closeText: 'Ok',
                      },
                      backdropClass: 'dialog-backdrop',
                    });
                  }
                );
              }
            }
          });

          const failedTemplatesArray = [];
          const succeededTemplatesArray = [];
          result['response'].forEach((triedTemplate) => {
            if (triedTemplate['status'] == 'fail') {
              failedTemplatesArray.push(triedTemplate);
            } else {
              succeededTemplatesArray.push(triedTemplate);
            }
          });

          let heading = '';
          let message = '';

          if (failedTemplatesArray.length > 0) {
            heading = `${succeededTemplatesArray.length} Templates Loaded, ${failedTemplatesArray.length} Templates Failed`;
            message = '<span>';
            failedTemplatesArray.forEach((failedTemplate) => {
              message += `The Template: <span class="medium-text med-green">${failedTemplate['title']}</span> has the following issues:</span>`;
              message += '<div class="red-text">';

              const errors = new Set();
              failedTemplate['errors'].forEach((error) => {
                errors.add(error);
              });

              errors.forEach((error) => {
                message += `<div>${error}</div>`;
              });

              message += '</div><br>';
            });
          } else {
            heading = `${succeededTemplatesArray.length} Templates Loaded`;
            message = `${succeededTemplatesArray.length
            } Templates have been loaded to iVerify from your drive folder`;
          }

          const alertDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading,
              message,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          alertDialog.afterClosed().subscribe((result) => {
            this.closeMe();
          });
        } else if (result['status'] == 400) {
          console.log(result);
          this.closeMe();
          const reviewDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: result['status_message'],
              message:
                'Please review the Google Sheets and try again once issues are resolved.',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
          reviewDialog.afterClosed().subscribe((result) => {
            this.closeMe();
          });
        } else {
          console.log(result);
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Load Templates",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        console.log(error);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Validate Templates",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  reLoadTemplates() {
    this.surveyService.uploadTemplates('validate').subscribe(
      (result) => {
        if (result['result']) {
          setTimeout(() => {
            this.templates = result['result'];
          }, 10);
        } else {
          console.log(result);
          this.closeMe();
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'No Templates Found',
              message:
                'There are no files in the drive folder, please add a template file to be uploaded.',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        console.log(error);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Validate Templates",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  public closeMe() {
    this.dialogRef.close();
  }
}
