import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  KeyValueDiffer,
  KeyValueDiffers,
  NgZone, ViewChild, ElementRef 
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { take } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  faAngleDown,
  faAngleUp,
  faCalendarCheck,
  faCamera,
  faCheck,
  faCircleNotch,
  faExclamation,
  faPaperPlane,
  faPencilAlt,
  faFileInvoice,
  faSave,
  faTrashAlt,
  faTimes,
  faPlus,
  faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';
import Compressor from 'compressorjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { isAssessmentSignOffRequired } from 'src/app/utils/is-assessment-sign-off-required/is-assessment-sign-off-required';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { PhotoPreviewComponent } from '../../surveys/photo-preview/photo-preview.component';
import { CreateMrrActionDialogComponent } from '../../mrr-surveys/create-mrr-action-dialog/create-mrr-action-dialog.component';
import { LoaderService } from '../../common-services/loader.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { UnescapePipe } from '../../custom-pipes/unescape.pipe';
import { PhotoCarouselComponent } from '../../surveys/photo-carousel/photo-carousel.component';
import { MrrStoreFinalViewDialogComponent } from '../mrr-store-final-view-dialog/mrr-store-final-view-dialog.component';
import { MrrStoreDraftViewDialogComponent } from '../mrr-store-draft-view-dialog/mrr-store-draft-view-dialog.component';
import { SignOffDialogComponent } from '../sign-off-dialog/sign-off-dialog.component';
import { MrrStoreSignoffCommentsDialogComponent } from '../mrr-store-signoff-comments-dialog/mrr-store-signoff-comments-dialog.component';
import { SharedFunctionService } from '../../shared/shared-service/shared-function.service';
import { User } from '../../models/user';
import { MrrSurveysService } from '../mrr-surveys.service';
import { VisitNotesDialogComponent } from '../visit-notes-dialog/visit-notes-dialog.component';
import { MrrVisitNote } from '../../models/mrr-visit-note';
import { CreateMRRFindingDialogComponent } from '../create-mrr-finding-dialog/create-mrr-finding-dialog.component';
import { MrrHeaderDialogComponent } from '../mrr-header-dialog/mrr-header-dialog.component';
import { FinalReportHeaderComponent } from '../final-report-header/final-report-header.component';

@Component({
  selector: 'app-mrr-response',
  templateUrl: './mrr-response.component.html',
  styleUrls: ['./mrr-response.component.scss'],
})
export class MrrResponseComponent implements OnInit {
  private differ: KeyValueDiffer<string, any>;

  @ViewChild('surveyForm') form;

  @ViewChild('surveyForm') surveyForm: NgForm;

  @ViewChild('printSurvey') printSurvey: ElementRef;

  signoffbyRole: boolean = false;

  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  faPlayCircle = faPlayCircle;

  faCalendarCheck = faCalendarCheck;

  faCamera = faCamera;

  faCheck = faCheck;

  faCircleNotch = faCircleNotch;

  faExclamation = faExclamation;

  faPaperPlane = faPaperPlane;

  faPencilAlt = faPencilAlt;

  faFileInvoice = faFileInvoice;

  faSave = faSave;

  faPlus = faPlus;

  faTrash = faTrashAlt;

  faTimes = faTimes;

  canEdit = false;

  actions = [];

  actionsValid;

  allExpandState = true;

  incompleteActions = 0;

  individualObjDiffer = {};

  invalid = [];

  minToDate;

  newAction = {};

  notesTimer;

  objDiffer = {};

  openItems = [];

  outstandingActions = 0;

  outstandingQuestions = 0;

  printVersion: string = '';

  saved = false;

  saving = false;

  showNote = {};

  surveyDetails: any = {};

  surveyID = '';

  surveyResponses = {};

  surveys = [];

  surveyStates = [];

  user: User;

  signoffDone: boolean = false;

  signedOffDetails = false;

  signedOffByAnyRole = false;

  viewNotesAvailable: boolean = false;

  finalreport = {};

  constructor(
    private elementRef: ElementRef,
    private differs: KeyValueDiffers,
    private zone: NgZone,
    private unescapePipe: UnescapePipe,
    private cd: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private loader: LoaderService,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private _ngZone: NgZone,
    private sharedFunction: SharedFunctionService,
    private mrrService: MrrSurveysService,
    private surveyService: SurveysService,
    public featureAccessService: FeatureAccessService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.user = this.csaAuth.user;
    this.surveyService.getSurveyById(this.surveyID).subscribe((surveyData) => {
      // if the response is a draft response (status == open), make sure the user has permission to view the draft
      if (
        surveyData['status'] == 'open' && !this.featureAccessService.hasAccess('mrrAssessments.viewDraftResponse')
      ) {
        console.log('Permission denied - user is trying to access a draft response, without having draft permission');
        this.router.navigate(['/error/unauthorised']);
      }
      this.canEdit = surveyData['status'] == 'started' || surveyData['status'] == 'open' || surveyData['status'] == 'active';

      this.differ = this.differs.find(this.surveyDetails).create();
      this.surveyDetails = surveyData;
      this.signOffAbilityCheck();
      this.surveyDetails['responses'].forEach((response) => {
        response = {};
      });
      this.getNotes(surveyData);
      this.checkSignOffDetailVisibility();
      this.checkVisitNotesVisibility();
      this.getFormValues();
      const changes = this.differ.diff(this.surveyDetails);
      if (changes) {
        changes.forEachChangedItem((r) => { });
        changes.forEachAddedItem((r) => { });
        changes.forEachRemovedItem((r) => { });
      }
    });
    this.cd.detectChanges();
  }

  @ViewChild('saveButton', { static: false }) saveButton: ElementRef;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  setOpened(itemIndex) {
    this.openItems.push(itemIndex);
  }

  setClosed(itemIndex) {
    for (let i = 0; i < this.openItems.length; i++) {
      if (this.openItems[i] === itemIndex) {
        this.openItems.splice(i, 1);
      }
    }
  }

  viewPhoto(photoName, question, fileName) {
    let showRemove = true;
    if (this.surveyDetails['status'] == 'closed') {
      showRemove = false;
    }

    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.surveyDetails['id']
      }-${question
      }-${this.surveyDetails['storeID']
      }-${photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewPhoto(photoName, question, fileName);
            }
          });
        } else if (Object.keys(photoData).length > 0) {
          this.zone.run(() => {
            const photoDialog = this.dialog.open(PhotoPreviewComponent, {
              data: {
                photoURL: photoData[photo],
                photoName,
                showRemove: false,
                documentType: 'Evidence',
              },
              backdropClass: 'dialog-backdrop',
              minWidth: '70%',
              maxWidth: '90vw',
              height: 'auto',
            });
            photoDialog.afterClosed().subscribe((result) => {
              if (result == 'delete') {
                this.removePhoto(photoData, question, photo);
              }
            });
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewPhoto(photoName, question, fileName);
          }
        });
      }
    );
  }

  // eslint-disable-next-line default-param-last
  getAllPhotos(questionID: string, photoIndex = 0, fileName?: string) {
    const evidence = [];
    let questionIndex = 0;
    let sectionHeadingtotalIndex;
    for (let i = 0; i < this.surveyDetails['responses'].length; i++) {
      const response = this.surveyDetails['responses'][i];
      for (let j = 0; j < this.surveyDetails['sectionHeading'].length; j++) {
        if (
          this.surveyDetails['questions'][i]['sectionHeading']
          == this.surveyDetails['sectionHeading'][j]
        ) {
          sectionHeadingtotalIndex = j + 1;
        }
      }
      if (response['photos'] && response['photos'].length > 0) {
        const responsePhotos = {
          photos: [],
          questionTitle: this.surveyDetails['questions'][i]['title'],
          questionID,
          sectionHeading: this.surveyDetails['questions'][i]['sectionHeading'],
          totalIndex: i + 1,
          sectionHeadingtotalIndex,
          response,
        };

        if (questionID == response['question_id']) {
          questionIndex = evidence.length;
        }

        for (let j = 0; j < response['photos'].length; j++) {
          if (!response['photos'][j]['fileName']) {
            response['photos'][j]['fileName'] = `${this.surveyDetails['id']
            }-${response['question_id']
            }-${this.user['storeID']
            }-${response['photos'][j]['photoName']}`;
          }

          if (
            fileName
            == response['photos'][j]['fileName']
              .replace(/\s/g, '-')
              .replace(/\.[^.]*$/, '')
          ) {
            photoIndex = j;
          }

          responsePhotos.photos.push({
            photoName: response['photos'][j]['photoName'],
            fileName: response['photos'][j]['fileName'],
          });
        }

        evidence.push(responsePhotos);
      }
      this.surveyDetails['responses'][i] = response;
    }

    const photoDialog = this.dialog.open(PhotoCarouselComponent, {
      data: {
        evidence,
        evidenceIndex: questionIndex,
        photoIndex,
        totalNumber: this.surveyDetails['questions'].length,
        sectiontotalNumber: this.surveyDetails['sectionHeading'].length,
        showRemove: true,
        type: 'mrrFindings',
      },
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto',
    });
    photoDialog.afterClosed().subscribe((result) => {
      if (result.status == 'delete') {
        this.removePhoto(result.src, result.questionID, result.fileName);
      }
    });
  }

  removePhoto(src, question, fileName) {
    this.surveyService
      .removePhoto(fileName, src, this.surveyDetails['id'], question)
      .subscribe(
        (response) => {
          this.removePhotoFromQ(question, fileName);
          this.cd.detectChanges();
        },
        (error) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Remove File",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
  }

  removePhotoFromQ(question, fileName) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (this.surveyDetails['questions'][i]['question_id'] == question) {
        for (
          let j = 0;
          j < this.surveyDetails['questions'][i]['photos'].length;
          j++
        ) {
          if (
            this.surveyDetails['questions'][i]['photos'][j]['photoName']
            == fileName
          ) {
            this.surveyDetails['questions'][i]['photos'].splice(j, 1);
            setTimeout(() => {
              this.surveyDetails['questions'][i]['uploading'] = false;
            }, 10);
          } else if (
            this.surveyDetails['questions'][i]['photos'][j]['fileName']
            == fileName
          ) {
            this.surveyDetails['questions'][i]['photos'].splice(j, 1);
            setTimeout(() => {
              this.surveyDetails['questions'][i]['uploading'] = false;
            }, 10);
          }
        }
      }
    }
  }

  compressPhoto(file, question) {
    const emitter = new EventEmitter<Blob>();

    const compression = new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: 1000000,
      success(result) {
        emitter.emit(result);
      },
      error(err) {
        emitter.emit();
      },
    });
    return emitter.asObservable();
  }

  checkName(name) {
    const check = name.toLowerCase().match(/\.(pdf)/g);
    if (check == null) {
      return true;
    }
    return false;
  }

  uploadPhoto($event, questionID, survey) {
    const questionIndex = this.surveyDetails['questions'].findIndex(
      (item) => item['question_id'] === questionID
    );
    this.surveyDetails['questions'][questionIndex]['uploading'] = true;
    const photoName = $event.target.files[0].name;
    const goodPhotoType = $event.target.files[0].name
      .toLowerCase()
      .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

    const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
    if (goodPhotoType == null) {
      this.displayUploadError(
        'This file is not an image or PDF',
        'Please select an image or PDF file to attach.',
        questionIndex
      );
    } else if (PDFTest == null) {
      this.compressPhoto($event.target.files[0], questionID).subscribe(
        (compressedPhoto) => {
          if (typeof compressedPhoto === 'undefined') {
            this.displayUploadError(
              'Error Compressing File',
              `Please try a different file or email ${this.user['supportEmail']
              } for support`,
              questionIndex
            );
          } else if (compressedPhoto['size'] > 10000000) {
            this.displayUploadError(
              'This File Is Too Big',
              'Please upload a smaller file',
              questionIndex
            );
          } else {
            const action = this.surveyService
              .uploadPhoto(
                compressedPhoto,
                survey,
                questionID,
                this.surveyDetails['storeID'],
                `${compressedPhoto['size']}`
              )
              .subscribe(
                (response) => {
                  if (!response[0] || response[0]['status'] != 200) {
                    let message;
                    if (response[0]['status'] != 200) {
                      message = `There was an error when sending your file. Please email ${this.user['supportEmail']
                      } for support`;
                    } else {
                      message = `No response from server. Please email ${this.user['supportEmail']
                      } for support`;
                    }
                    this.displayUploadError(
                      "Couldn't Add File",
                      message,
                      questionIndex
                    );
                  } else if (response[0]['message'] == 'OK') {
                    this.getPhotosThisQuestion(questionIndex, {
                      photoName: response[0]['name'],
                      fileName: response[0]['fileID'],
                      fileSize: compressedPhoto['size'],
                    });
                  }
                },
                (error) => {
                  this.displayUploadError(
                    "Couldn't Add File",
                    `Please email ${this.user['supportEmail']
                    } for support`,
                    questionIndex
                  );
                }
              );
          }
        }
      );
    } else if ($event.target.files[0]['size'] > 10000000) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'This File Is Too Big',
          message: 'Please upload a smaller file',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
      setTimeout(() => {
        this.surveyDetails['questions'][questionIndex]['uploading'] = false;
      }, 10);
    } else {
      const action = this.surveyService
        .uploadPhoto(
          $event.target.files[0],
          survey,
          questionID,
          this.surveyDetails['storeID'],
          $event.target.files[0]['size']
        )
        .subscribe(
          (response) => {
            if (!response[0] || response[0]['status'] != 200) {
              let message;
              if (response[0]['status'] != 200) {
                message = `There was an error when sending your file. Please email ${this.user['supportEmail']
                } for support`;
              } else {
                message = `No response from server. Please email ${this.user['supportEmail']
                } for support`;
              }
              this.displayUploadError(
                "Couldn't Add File",
                message,
                questionIndex
              );
            } else {
              this.getPhotosThisQuestion(questionIndex, {
                photoName: response[0]['name'],
                fileName: response[0]['fileID'],
                fileSize: $event.target.files[0]['size'],
              });
              this.cd.detectChanges();
            }
          },
          (error) => {
            this.displayUploadError(
              "Couldn't Add File",
              `Please email ${this.user['supportEmail']} for support`,
              questionIndex
            );
          }
        );
    }
  }

  displayUploadError(heading, message, questionIndex) {
    this.dialog.open(MessagesComponent, {
      data: {
        heading,
        message,
        closeText: 'Ok',
      },
      backdropClass: 'dialog-backdrop',
    });
    setTimeout(() => {
      this.surveyDetails['questions'][questionIndex]['uploading'] = false;
    }, 10);
  }

  getPhotosThisQuestion(questionIndex, photo) {
    // this.saveForm(this.surveyDetails['questions'][questionIndex]['id']);
    setTimeout(() => {
      if (!this.surveyDetails['questions'][questionIndex]['photos']) {
        this.surveyDetails['questions'][questionIndex]['photos'] = [];
        // this.surveyDetails["responses"][questionIndex]["photos"] = [];
      }
      this.surveyDetails['questions'][questionIndex]['photos'].push(photo);
      // this.surveyDetails["responses"][questionIndex]["photos"].push(photo);
      this.surveyDetails['questions'][questionIndex]['uploading'] = false;
    }, 10);
    this.cd.detectChanges();
  }

  getSurveyResponses(question) {
    for (let i = 0; i < this.surveyDetails['responses'].length; i++) {
      if (this.surveyDetails['responses'][i]['question_id'] == question) {
        return this.surveyDetails['responses'][i]['response'];
      }
    }
  }

  unescapeHtml(text) {
    return text
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&#34;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&amp;/g, '&');
  }

  getNotes(surveyData) {
    if (!this.surveyDetails['sectionHeading']) {
      this.surveyDetails['sectionHeading'] = ['All Questions'];
      this.surveyDetails['questions'].forEach((question) => {
        question['sectionHeading'] = 'All Questions';
      });
    }

    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      for (let j = 0; j < this.surveyDetails['responses'].length; j++) {
        this.objDiffer[this.surveyDetails['responses'][j]['question_id']] = this.differs.find(this.surveyDetails['responses'][j]).create();
        this.individualObjDiffer[
          this.surveyDetails['responses'][j]['question_id']
        ] = this.differs.find(this.surveyDetails['responses'][j]).create();
        this.surveyDetails['responses'][j] = surveyData['responses'][j];
        if (
          this.surveyDetails['responses'][j]['question_id']
          == this.surveyDetails['questions'][i]['question_id']
        ) {
          if (this.surveyDetails['responses'][j]['notes']) {
            this.surveyDetails['questions'][i]['showNotes'] = true;
            this.surveyDetails['responses'][j]['notes'] = this.unescapeHtml(
              this.surveyDetails['responses'][j]['notes']
            );
            this.surveyDetails['questions'][i]['notes'] = this.surveyDetails['responses'][j]['notes'];
          }
          this.surveyDetails['questions'][i]['actions'] = this.surveyDetails['responses'][j]['actions'];
          this.surveyDetails['questions'][i]['photos'] = this.surveyDetails['responses'][j]['photos'];
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          this.surveyDetails['questions'][i]['reviewedFrom'] = this.surveyDetails['responses'][i]['reviewedFrom'];
          this.surveyDetails['questions'][i]['reviewedTo'] = this.surveyDetails['responses'][i]['reviewedTo'];
          this.minToDate = moment(
            this.surveyDetails['questions'][i]['reviewedFrom'],
            ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
          );

          if (!this.surveyDetails['responses'][i]['autoSavedAction']) {
            this.surveyDetails['responses'][i]['autoSavedAction'] = [];
            this.surveyDetails['questions'][i]['autoSavedAction'] = [];
          } else {
            this.surveyDetails['questions'][i]['autoSavedAction'] = this.surveyDetails['responses'][i]['autoSavedAction'];
          }

          if (!this.surveyDetails['questions'][i]['sectionHeading']) {
            this.surveyDetails['responses'][j]['sectionHeading'] = 'All Questions';
            this.surveyDetails['questions'][i]['sectionHeading'] = 'All Questions';
          } else {
            this.surveyDetails['responses'][j]['sectionHeading'] = this.surveyDetails['questions'][i]['sectionHeading'];
          }
        }
        const responseDiffer = this.objDiffer[this.surveyDetails['responses'][j]['question_id']];
        const objChanges = responseDiffer.diff(
          this.surveyDetails['responses'][j]
        );
        if (objChanges) {
          objChanges.forEachChangedItem((r) => { });
          objChanges.forEachAddedItem((r) => { });
          objChanges.forEachRemovedItem((r) => { });
        }

        const indResponseDiffer = this.objDiffer[this.surveyDetails['responses'][j]['question_id']];
        const IndObjChanges = indResponseDiffer.diff(
          this.surveyDetails['responses'][j]
        );
        if (IndObjChanges) {
          IndObjChanges.forEachChangedItem((r) => { });
          IndObjChanges.forEachAddedItem((r) => { });
          IndObjChanges.forEachRemovedItem((r) => { });
        }
      }
      setTimeout(() => {
        this.surveyDetails['questions'][i]['uploading'] = false;
      }, 10);
    }
  }

  checkActionsValid(surveyValues) {
    this.outstandingQuestions = 0;
    this.outstandingActions = 0;
    this.incompleteActions = 0;
    this.actionsValid = true;
    if (this.surveyDetails['questions']) {
      for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
        const responseID = `${this.surveyDetails['questions'][i].id}response`;

        if (!surveyValues[responseID]) {
          this.outstandingQuestions++;
          this.cd.detectChanges();
        }

        if (
          !this.surveyDetails['responses'][i]['actions']
          && !this.surveyDetails['questions'][i]['actions']
        ) {
          this.outstandingActions++;
          this.actionsValid = false;
          this.cd.detectChanges();
        }
        if (this.surveyDetails['questions'][i]['autoSavedAction']?.length > 0) {
          setTimeout(
            () => (this.incompleteActions
              += this.surveyDetails['questions'][i]['autoSavedAction'].length)
          );
          setTimeout(() => (this.actionsValid = false));
        }
      }
    }

    setTimeout(() => (this.saving = false));

    this.findInvalidControls();
  }

  makeNoteShow(question) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (this.surveyDetails['questions'][i]['question_id'] == question) {
        if (this.surveyDetails['questions'][i]['showNotes'] == true) {
          this.surveyDetails['questions'][i]['showNotes'] = false;
        } else {
          this.surveyDetails['questions'][i]['showNotes'] = true;
        }
      }
    }
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    // document.querySelector('.mat-drawer-content').scrollTop = 0;
    setTimeout(() => {
      this.form.control.valueChanges.subscribe((values) => this.autoSave(values));
    });

    const invalidControls = [];
    Object.keys(this.surveyForm['controls']).forEach((control) => { });
  }

  getFormValues() {
    const answers = {};
    for (let i = 0; i < this.surveyDetails['responses'].length; i++) {
      answers[`${this.surveyDetails['responses'][i]['question_id']}response`] = this.surveyDetails['responses'][i]['response'];
    }
    setTimeout(() => {
      this.surveyForm.form.patchValue(answers);
      this.checkActionsValid(this.surveyForm.form.value);
    });
  }

  autoSave(values) {
    if (this.form.control.pristine == false) {
      this.saved = false;
    }
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }

  createAction(
    survey,
    surveyId,
    question,
    questionId,
    questionShortTitle,
    autoSavedActionID
  ) {
    const dialogRef = this.dialog.open(CreateMrrActionDialogComponent, {
      data: {
        survey,
        question,
        surveyId,
        questionId,
        questionShortTitle,
        autoSavedActionID,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result['type'] == 'final') {
        this.newAction = result['action'];
        this.getActionsThisQuestion(questionId, this.newAction);
      } else if (result['type'] == 'auto') {
        this.updateAutoSavedAction(questionId, result['action']);
      } else if (result['type'] == 'delete') {
        setTimeout(() => {
          this.deleteAutoSavedAction(questionId, result['autoSavedActionID']);
        });
      }

      setTimeout(() => {
        this.checkActionsValid(this.surveyForm.form.value);
      });
    });
  }

  deleteAutoSavedAction(question, actionID) {
    if (actionID) {
      setTimeout(() => {
        for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
          if (this.surveyDetails['questions'][i]['question_id'] == question) {
            const questionData = this.surveyDetails['questions'][i][
              'autoSavedAction'
            ].filter((e) => e['autoSavedActionID'] != actionID);

            const responseData = this.surveyDetails['responses'][i][
              'autoSavedAction'
            ].filter((e) => e['autoSavedActionID'] != actionID);

            this.surveyDetails['questions'][i]['autoSavedAction'] = questionData;

            this.incompleteActions = this.surveyDetails['questions'][i]['autoSavedAction'].length;
            //
            // this.saveForm(this.surveyDetails['questions'][i]['id']);
          }
        }
        this.checkActionsValid(this.surveyForm.form.value);
      });
    }
  }

  deleteAction(question, actionID) {
    const message = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Are you sure you want to delete this Action? This cannot be un-done.',
        closeText: 'Delete',
        cancelText: 'Cancel',
        maxWidth: '90vw',
      },
      backdropClass: 'dialog-backdrop',
    });

    message.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        const data = {};
        data['id'] = this.surveyDetails['id'];
        data['question_id'] = question;
        if (actionID) {
          data['autoSavedActionID'] = actionID;

          const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['autoSavedAction']);

          this.surveyService.autoSaveAction(requestJSONPayload, 'delete').subscribe(
            (response) => {
              if (response['status'] == 400 || response['status'] == 500) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't Delete Action",
                    message:
                      `Please email ${this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              } else {
                setTimeout(() => {
                  this.deleteAutoSavedAction(question, actionID);
                });
              }
            },
            (error) => {
              console.log(error);

              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Delete Action",
                  message:
                    `Please email ${this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
        }
      }
    });
  }

  updateAutoSavedAction(questionID, action) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (this.surveyDetails['questions'][i]['question_id'] == questionID) {
        if (action['autoSavedActionID']) {
          let actionExists = false;
          for (
            let j = 0;
            j < this.surveyDetails['questions'][i]['autoSavedAction'].length;
            j++
          ) {
            if (
              action['autoSavedActionID']
              == this.surveyDetails['questions'][i]['autoSavedAction'][j][
                'autoSavedActionID'
              ]
            ) {
              this.surveyDetails['questions'][i]['autoSavedAction'][j] = action;
              actionExists = true;
              break;
            }
          }
          if (actionExists == false) {
            this.surveyDetails['questions'][i]['autoSavedAction'].push(action);
          }
        }
      }
    }
    // this.saveForm('All');
  }

  getActionsThisQuestion(questionID, action) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (this.surveyDetails['questions'][i]['question_id'] == questionID) {
        if (!this.surveyDetails['questions'][i]['actions']) {
          this.surveyDetails['questions'][i]['actions'] = [];
        }
        action['title'] = this.unescapePipe.transform(action['title']);
        this.surveyDetails['questions'][i]['actions'].push(action);

        const questionData = this.surveyDetails['questions'][i][
          'autoSavedAction'
        ].filter((e) => e['autoSavedActionID'] != action['autoSavedActionID']);

        const responseData = this.surveyDetails['responses'][i][
          'autoSavedAction'
        ].filter((e) => e['autoSavedActionID'] != action['autoSavedActionID']);

        this.surveyDetails['questions'][i]['autoSavedAction'] = questionData;
        this.surveyDetails['responses'][i]['autoSavedAction'] = responseData;

        // this.saveForm(this.surveyDetails['questions'][i]['id']);
      }
    }
  }

  cancelResponse() {
    const dialogRef = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'If you cancel this response, all answers will be lost. Do you wish to proceed?',
        closeText: 'Yes',
        cancelText: 'No',
      },
      backdropClass: 'dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        const data = {};
        data['id'] = this.surveyDetails['id'];
        data['status'] = 'cancelled';
        data['modifiedBy'] = this.user['email'];

        const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['responses']);

        this.surveyService.saveSurvey(requestJSONPayload).subscribe(
          (response) => {
            if (response['status'] == 200) {
              this.router.navigate([
                'mrr-survey-details',
                this.surveyDetails['survey_id'],
              ]);
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Submit Assessment",
                  message:
                    `Please email ${this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          },
          (error) => {
            console.log(error);
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Network Error',
                message:
                  `Please check that you have Wifi or 4g signal, and email ${this.user['supportEmail']
                  } for support if the issue persists`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    });
  }

  createFinding(edit = false, finding = {}) {
    const dialogRef = this.dialog.open(CreateMRRFindingDialogComponent, {
      data: {
        // surveyId: this.surveyDetails['id']
        survey: this.surveyDetails,
        edit,
        finding,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result['type'] == 'final') {
        if (result['edit']) {
          this.updateFinding(result['finding']);
        } else {
          const createData = {};
          createData['questions'] = [];
          createData['id'] = this.surveyDetails['id'];
          createData['questions'].push(result['finding']);

          const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(createData, ['questions']);
          this.mrrService.createFinding(requestJSONPayload).subscribe(
            (reply) => {
              if (reply['status'] == 200) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'Finding Saved',
                    message: 'Your finding has been saved successfully.',
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
              } else {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'Cannot Save Finding',
                    message:
                      `Please email ${this.user['supportEmail']} for support`,
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
              }
              this.ngOnInit();
            },
            (err) => {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Cannot Save Finding',
                  message:
                    `Please email ${this.user['supportEmail']
                    } for support`,
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
              console.log(err);
            }
          );
        }
      } else if (result['type'] == 'delete') {
        for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
          if (
            this.surveyDetails['questions'][i]['question_id']
            == finding['question_id']
          ) {
            this.surveyDetails['questions'].splice(i, 1);
            this.surveyDetails['responses'].splice(i, 1);
          }
        }
      }
      this.cd.detectChanges();
    });
  }

  updateFinding(finding) {
    const updateData = {};
    const question = {};
    updateData['questions'] = [];
    updateData['id'] = this.surveyDetails['id'];
    question['question_id'] = finding['question_id'];
    this.differ = this.differs.find(finding).create();
    const changes = this.differ.diff(finding);
    if (changes) {
      changes.forEachChangedItem((r) => {
        question[r.key] = r.currentValue;
      });
      changes.forEachAddedItem((r) => {
        question[r.key] = r.currentValue;
      });
    }

    updateData['questions'].push(question);

    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(updateData, ['questions']);
    this.mrrService.updateFinding(requestJSONPayload).subscribe(
      (reply) => {
        if (reply['status'] == 200) {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Finding updated',
              message: 'Your finding has been saved successfully.',
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Cannot update Finding',
              message:
                `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        }
        this.ngOnInit();
      },
      (err) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot update Finding',
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(err);
      }
    );
  }

  updateFindings(edit, finding) {
    const dialogRef = this.dialog.open(CreateMRRFindingDialogComponent, {
      data: {
        // surveyId: this.surveyDetails['id']
        survey: this.surveyDetails,
        edit,
        finding,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result['edit']) {
        this.updateFinding(result['finding']);
      } else if (result['type'] == 'auto') {
        this.UnEditedFinding();
      } else if (result['type'] == 'delete') {
        this.deleteFinding(result['finding']);
      }
    });
  }

  deleteFinding(finding) {
    const message = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Are you sure you want to delete this finding? This cannot be un-done.',
        closeText: 'Delete',
        cancelText: 'Cancel',
        maxWidth: '90vw',
      },
      backdropClass: 'dialog-backdrop',
    });

    message.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        const deleteData = {};
        const question = {};

        deleteData['questions'] = [];
        deleteData['id'] = this.surveyDetails['id'];
        question['question_id'] = finding['question_id'];
        deleteData['status'] = this.surveyDetails['status'];
        deleteData['questions'].push(question);

        const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(deleteData, ['questions']);
        this.mrrService.deleteFinding(requestJSONPayload).subscribe(

          (reply) => {
            if (reply['status'] == 200) {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Finding deleted',
                  message: 'Your finding has been deleted successfully.',
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Cannot delete Finding',
                  message:
                    `Please email ${this.user['supportEmail']} for support`,
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
            }
            this.ngOnInit();
          },
          (err) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Cannot delete Finding',
                message:
                  `Please email ${this.user['supportEmail']} for support`,
              },
              disableClose: true,
              backdropClass: 'dialog-backdrop',
            });
            console.log(err);
          }
        );

        for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
          if (
            this.surveyDetails['questions'][i]['question_id']
            == finding['question_id']
          ) {
            this.surveyDetails['questions'].splice(i, 1);
            this.surveyDetails['responses'].splice(i, 1);
          }
        }
      }
    });
  }

  UnEditedFinding() {
    const updateData = {};
    const question = {};
    updateData['questions'] = [];
    updateData['id'] = this.surveyDetails['id'];
    updateData['questions'].push(question);
    this.ngOnInit();
  }

  submitForm(value) {
    const data = {};
    data['id'] = this.surveyDetails['id'];
    data['modifiedBy'] = this.user['email'];

    let answeredQs = 0;
    const obj = value;
    Object.keys(obj).map((key) => {
      if (key.indexOf('response') !== -1) {
        if (obj[key] != '') {
          answeredQs++;
        }
      }
    });

    if (answeredQs != 0) {
      data['completion'] = answeredQs / this.surveyDetails['questions'].length;
      this.surveyDetails['completion'] = answeredQs / this.surveyDetails['questions'].length;
    }
    data['responses'] = [];

    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      const response = {};
      this.surveyDetails['responses'][i]['notes'] = value[`${this.surveyDetails['questions'][i]['question_id']}note`];
      this.surveyDetails['responses'][i]['actions'] = this.surveyDetails['questions'][i]['actions'];
      this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];

      if (
        value[
          `${this.surveyDetails['questions'][i]['question_id']}reviewedFrom`
        ]
      ) {
        response['reviewedFrom'] = moment(
          value[
            `${this.surveyDetails['questions'][i]['question_id']}reviewedFrom`
          ],
          ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
        ).format();
        this.surveyDetails['responses'][i]['reviewedFrom'] = moment(
          value[
            `${this.surveyDetails['questions'][i]['question_id']}reviewedFrom`
          ],
          ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
        ).format();
      }

      if (
        value[`${this.surveyDetails['questions'][i]['question_id']}reviewedTo`]
      ) {
        response['reviewedTo'] = moment(
          value[
            `${this.surveyDetails['questions'][i]['question_id']}reviewedTo`
          ],
          ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
        ).format();
        this.surveyDetails['responses'][i]['reviewedTo'] = moment(
          value[
            `${this.surveyDetails['questions'][i]['question_id']}reviewedTo`
          ],
          ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
        ).format();
      }

      response['question_id'] = this.surveyDetails['questions'][i]['question_id'];
      response['notes'] = value[`${this.surveyDetails['questions'][i]['question_id']}note`];
      if (this.surveyDetails['questions'][i]['actions']) {
        for (
          let j = 0;
          j < this.surveyDetails['questions'][i]['actions'].length;
          j++
        ) {
          this.surveyDetails['questions'][i]['actions'][j]['title'] = this.unescapePipe.transform(
            this.surveyDetails['questions'][i]['actions'][j]['title']
          );
        }
      }
      response['actions'] = this.surveyDetails['questions'][i]['actions'];
      response['photos'] = this.surveyDetails['questions'][i]['photos'];
      response['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];

      data['responses'].push(response);
    }

    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['responses']);

    this.surveyService.saveSurvey(requestJSONPayload).subscribe(
      (response) => {
        if (response['status'] == 200) {
          // Area to place scoring and email trigger logic
          if (this.surveyDetails['filterName'] == 'eitb') {
            const eitbData = {};
            eitbData['id'] = data['id'];
            eitbData['domain'] = this.user['domain'];
            this.surveyService.eitbEmailTrigger(eitbData).subscribe(
              (eitbResponse) => {
                this.router.navigate(['thankyouMrr', 'MRR']);
              },
              (error) => {
                console.log(error);
                this.router.navigate(['thankyouMrr', 'MRR']);
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't Calculate GPS Scores",
                    message:
                      `Please email ${this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              }
            );
          } else {
            this.router.navigate(['thankyouMrr', 'MRR']);
          }
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Submit Assessment",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        console.log(error);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Network Error',
            message:
              `Please check that you have Wifi or 4g signal, and email ${this.user['supportEmail']
              } for support if the issue persists`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  saveNotes(question) {
    clearTimeout(this.notesTimer);
    this.notesTimer = setTimeout(() => this.saveForm(question), 3000);
  }

  userTimer;

  saveUser(question) {
    clearTimeout(this.userTimer);
    this.userTimer = setTimeout(() => this.saveForm(question), 3000);
  }

  saveForm(question) {
    this.saving = true;
    const data = {};

    data['id'] = this.surveyDetails['id'];
    // Only Store users should have this feature
    if (this.featureAccessService.hasAccess('mrrAssessments.addFindingNotes')) {
      data['status'] = 'started';
    }
    data['modifiedBy'] = this.user['email'];

    let answeredQs = 0;
    const obj = this.surveyForm.form.value;
    Object.keys(obj).map((key) => {
      if (key.indexOf('response') !== -1) {
        if (obj[key]) {
          answeredQs++;
        }
      }
    });
    if (answeredQs != 0) {
      this.surveyDetails['completion'] = answeredQs / this.surveyDetails['questions'].length;
    } else {
      this.surveyDetails['completion'] = 0;
    }

    const changes = this.differ.diff(this.surveyDetails);
    if (changes) {
      changes.forEachChangedItem((r) => {
        data[r.key] = r.currentValue;
      });
      changes.forEachAddedItem((r) => {
        data[r.key] = r.currentValue;
      });
      changes.forEachRemovedItem((r) => {
        data[r.key] = r.currentValue;
      });
    }

    if (question == 'All') {
      data['responses'] = [];

      for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
        const response = {};
        if (
          this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['question_id']}response`
          ]
        ) {
          this.surveyDetails['responses'][i]['response'] = this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['question_id']}response`
          ];
        }

        if (
          this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['question_id']}note`
          ]
        ) {
          this.surveyDetails['responses'][i]['notes'] = this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['question_id']}note`
          ];
          response['notes'] = this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['question_id']}note`
          ];
        }

        if (
          this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['question_id']}reviewedFrom`
          ]
        ) {
          this.surveyDetails['responses'][i]['reviewedFrom'] = moment(
            this.surveyForm.form.value[
              `${this.surveyDetails['questions'][i]['question_id']}reviewedFrom`
            ],
            ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
          ).format();
        }
        if (
          this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['question_id']}reviewedTo`
          ]
        ) {
          this.surveyDetails['responses'][i]['reviewedTo'] = moment(
            this.surveyForm.form.value[
              `${this.surveyDetails['questions'][i]['question_id']}reviewedTo`
            ],
            ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
          ).format();
        }

        const objDiffer = this.objDiffer[this.surveyDetails['responses'][i]['question_id']];
        const objChanges = objDiffer.diff(this.surveyDetails['responses'][i]);
        if (objChanges) {
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          response['question_id'] = this.surveyDetails['questions'][i]['question_id'];
          if (this.surveyDetails['questions'][i]['shortTitle']) {
            this.surveyDetails['responses'][i]['shortTitle'] = this.surveyDetails['questions'][i]['shortTitle'];
          }
          objChanges.forEachChangedItem((r) => {
            if (
              r.key != 'autoSavedAction'
              && r.key != 'photos'
              && r.key != 'actions'
            ) {
              response[r.key] = r.currentValue;
            }
          });
          objChanges.forEachAddedItem((r) => {
            if (
              r.key != 'autoSavedAction'
              && r.key != 'photos'
              && r.key != 'actions'
            ) {
              response[r.key] = r.currentValue;
            }
          });
          objChanges.forEachRemovedItem((r) => {
            if (
              r.key != 'autoSavedAction'
              && r.key != 'photos'
              && r.key != 'actions'
            ) {
              response[r.key] = r.currentValue;
            }
          });

          data['responses'].push(response);
        }
      }

      if (data['responses'].length > 0) {
        const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['responses']);

        this.surveyService.saveSurvey(requestJSONPayload).subscribe(
          (response) => {
            if (response['status'] == 400) {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Save Assessment",
                  message:
                    `Please email ${this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            } else {
              setTimeout(() => {
                if (data['status']) { // Refresh status with FE updated value
                  this.surveyDetails['status'] = data['status'];
                }
                this.saved = true;
              });
            }
          },
          (error) => {
            console.log(error);
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Network Error',
                message:
                  `Please check that you have Wifi or 4g signal, and email ${this.user['supportEmail']
                  } for support if the issue persists`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    } else {
      for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
        if (this.surveyDetails['questions'][i]['question_id'] == question) {
          data['response'] = {};
          data['response']['question_id'] = question;

          // if (this.surveyDetails['questions'][i]['actions']) {
          //   for (let j = 0; j < this.surveyDetails['questions'][i]['actions'].length; j++) {
          //     this.surveyDetails['questions'][i]['actions'][j]['title'] = this.unescapePipe.transform(this.surveyDetails['questions'][i]['actions'][j]['title']);
          //   }
          //   this.surveyDetails['responses'][i]['actions'] = this.surveyDetails['questions'][i]['actions'];
          // }

          if (this.surveyDetails['questions'][i]['shortTitle']) {
            data['response']['shortTitle'] = this.surveyDetails['questions'][i]['shortTitle'];
          }

          setTimeout(
            () => (this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = moment().format()),
            10
          );

          if (this.surveyForm.form.value[`${question}reviewedFrom`]) {
            this.surveyDetails['responses'][i]['reviewedFrom'] = moment(
              this.surveyForm.form.value[`${question}reviewedFrom`],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            ).format();
            this.minToDate = moment(
              this.surveyDetails['questions'][i]['reviewedFrom'],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            );
          }
          if (this.surveyForm.form.value[`${question}reviewedTo`]) {
            this.surveyDetails['responses'][i]['reviewedTo'] = moment(
              this.surveyForm.form.value[`${question}reviewedTo`],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            ).format();
          }

          data['response']['notes'] = this.surveyDetails['questions'][i]['notes'];
          this.surveyDetails['responses'][i]['notes'] = this.surveyDetails['questions'][i]['notes'];

          this.surveyDetails['responses'][i]['pointInTimeReviewDate'] = moment().format();
          this.surveyDetails['responses'][i]['response'] = this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['question_id']}response`
          ];

          const indObjDiffer = this.individualObjDiffer[
            this.surveyDetails['responses'][i]['question_id']
          ];
          const indObjChanges = indObjDiffer.diff(
            this.surveyDetails['responses'][i]
          );
          if (indObjChanges) {
            indObjChanges.forEachChangedItem((r) => {
              if (
                r.key != 'autoSavedAction'
                && r.key != 'photos'
                && r.key != 'actions'
              ) {
                data['response'][r.key] = r.currentValue;
              }
            });
            indObjChanges.forEachAddedItem((r) => {
              if (
                r.key != 'autoSavedAction'
                && r.key != 'photos'
                && r.key != 'actions'
              ) {
                data['response'][r.key] = r.currentValue;
              }
            });
            indObjChanges.forEachRemovedItem((r) => {
              if (
                r.key != 'autoSavedAction'
                && r.key != 'photos'
                && r.key != 'actions'
              ) {
                data['response'][r.key] = r.currentValue;
              }
            });
          }

          if (
            !this.surveyForm.form.value[
              `${this.surveyDetails['questions'][i]['question_id']}response`
            ]
          ) {
            data['response']['response'] = '';
          }
        }
      }

      const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['response']);

      this.surveyService.saveQuestion(requestJSONPayload).subscribe(
        (response) => {
          if (response['status'] == 400) {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: "Couldn't Save Question",
                message:
                  `Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          } else {
            setTimeout(() => {
              if (data['status']) { // Refresh status with FE updated value
                this.surveyDetails['status'] = data['status'];
              }
              this.saved = true;
            });
          }
        },
        (error) => {
          console.log(error);
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Network Error',
              message:
                `Please check that you have Wifi or 4g signal, and email ${this.user['supportEmail']
                } for support if the issue persists`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
    }
    this.checkActionsValid(this.surveyForm.form.value);
  }

  availableToString(value) {
    switch (value) {
      case 'store':
        return 'Store Users';
      case 'above-store':
        return 'Above Store Users';
      default:
        return '';
    }
  }

  findInvalidControls() {
    this.invalid = [];
    const controls = this.surveyForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        if (name.endsWith('reviewedTo')) {
          const question = name.split('_').pop();
          const questionNumber = question.split('r').shift();
          const visualQuestionNumber = +questionNumber + 1;
          setTimeout(() => this.invalid.push(visualQuestionNumber), 0);
        }
      }
    }
  }

  OpenVisitNotes() {
    const dialogRef = this.dialog.open(VisitNotesDialogComponent, {
      data: {
        surveyDetails: this.surveyDetails,
        assessmentType: 'mrr',
        heading: 'Visit Notes Comments',
        message:
          'Please provide a Visit Note Comments.',
        closeText: 'Submit',
        cancelText: 'Cancel',
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: '60vh',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result['type'] == 'logout') {
        if (this.surveyDetails['filterName'] == 'mrr') {
          const mrrData: MrrVisitNote = {};
          // checking whether the visit note is adding for first time or not ,for setting up meta data. 
          // If it's for first time adding visit note to a particular finding then setting 'visitNoteCount' to false else true

          if (!this.surveyDetails['visitNotes']) {
            this.surveyDetails['visitNoteCount'] = false;
          } else {
            this.surveyDetails['visitNoteCount'] = true;
          }
          mrrData.id = this.surveyDetails['id'];
          mrrData.notes = result['notes'];
          mrrData.visitNoteCount = this.surveyDetails['visitNoteCount'];
          this.mrrService.addVisitNote(mrrData).subscribe(
            (response) => {
              if (response['status'] == 200) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'Visit Notes Saved',
                    message:
                      'Your Visit Notes  has been saved successfully.',
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
                this.ngOnInit();
              }
              if (response['status'] != 200) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't add Visit Notes ",
                    message:
                      `Please email ${this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              }
            },
            (error) => {
              console.log(error);
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't add Visit Notes ",
                  message:
                    `Please email ${this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't add Visit Notes",
              message:
                `Please email ${this.user['supportEmail']
                } for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      }
    });
  }

  OpenSignOffComments() {
    const dialogRef = this.dialog.open(MrrStoreSignoffCommentsDialogComponent, {
      data: {
        surveyDetails: this.surveyDetails,
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: '60vh',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // UPDATE PAGE
      this.cd.detectChanges();
    });
  }

  viewFinalReport(): void {
    const dialogRef = this.dialog.open(MrrStoreFinalViewDialogComponent, {
      data: {
        surveyDetails: this.surveyDetails,
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: '60vh',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // UPDATE PAGE
      this.cd.detectChanges();
    });
  }

  editFinalReport(edit, finalreport): void {
    if (this.surveyDetails['rating']) {
      finalreport['rating'] = this.surveyDetails['rating'];
    }
    const dialogRef = this.dialog.open(FinalReportHeaderComponent, {
      data: {
        surveyDetails: this.surveyDetails,
        finalreport,
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result['type'] == 'final') {
        this.getMRRFinalReport();
        this.finalreport = result['finalReport'];
      } else if (edit) {
        this.finalreport = {};
        this.getMRRFinalReport();
      } else {
        this.getMRRFinalReport();
      }
      // UPDATE PAGE
      console.log('FinalReportHeaderComponent', result, this.finalreport);
      this.cd.detectChanges();
    });
  }

  getMRRFinalReport() {
    // call getMRRFinalReport api
    this.surveyService.getSurveyById(this.surveyID).subscribe((surveysData) => {
      console.log(surveysData);
      if (surveysData['status'] == 500) {
        this.router.navigate(['/error']);
      }
      this.surveyDetails = surveysData;
      this.getFinalReportNotes();
      // adding details to final headerpage
      if (this.surveyDetails['finalReportDate']) {
        this.finalreport['finalReportDate'] = this.surveyDetails['finalReportDate'];
        this.finalreport['summaryFindings'] = this.surveyDetails['summaryFindings'];
        this.finalreport['rating'] = this.surveyDetails['rating'];
        this.finalreport['id'] = this.surveyDetails['id'];
        if (this.surveyDetails['files']) {
          if (this.surveyDetails['files']['final']) this.finalreport['finalReportPhoto'] = this.surveyDetails['files']['final'];
        }
      }
    });
  }

  getFinalReportNotes() {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      this.surveyDetails['questions'][i]['uploading'] = false;
      for (let j = 0; j < this.surveyDetails['responses'].length; j++) {
        if (
          this.surveyDetails['responses'][j]['question_id']
          == this.surveyDetails['questions'][i]['question_id']
        ) {
          this.surveyDetails['questions'][i]['notes'] = this.surveyDetails['responses'][j]['notes'];
          this.surveyDetails['questions'][i]['response'] = this.surveyDetails['responses'][j]['response'];
          if (!this.surveyDetails['responses'][j]['actions']) {
            this.surveyDetails['questions'][i]['actions'] = [];
            this.surveyDetails['responses'][j]['actions'] = [];
          } else {
            this.surveyDetails['questions'][i]['actions'] = this.surveyDetails['responses'][j]['actions'];
          }

          this.surveyDetails['questions'][i]['photos'] = this.surveyDetails['responses'][j]['photos'];
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          this.surveyDetails['questions'][i]['reviewedFrom'] = this.surveyDetails['responses'][i]['reviewedFrom'];
          this.surveyDetails['questions'][i]['reviewedTo'] = this.surveyDetails['responses'][i]['reviewedTo'];
        }
      }
    }
  }

  editDraftReport(): void {
    if (this.finalreport['rating']) {
      this.surveyDetails['rating'] = this.finalreport['rating'];
    }
    const dialogRef = this.dialog.open(MrrHeaderDialogComponent, {
      data: {
        survey: this.surveyDetails,
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // UPDATE PAGE
      console.log(result);
      this.getMRRFinalReport();
    });
  }

  viewDraftReport(): void {
    const dialogRef = this.dialog.open(MrrStoreDraftViewDialogComponent, {
      data: {
        survey: this.surveyDetails,
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'auto',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  getDraftNotes() {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      this.surveyDetails['questions'][i]['uploading'] = false;
      for (let j = 0; j < this.surveyDetails['responses'].length; j++) {
        if (
          this.surveyDetails['responses'][j]['question_id']
          == this.surveyDetails['questions'][i]['question_id']
        ) {
          this.surveyDetails['questions'][i]['notes'] = this.surveyDetails['responses'][j]['notes'];
          this.surveyDetails['questions'][i]['response'] = this.surveyDetails['responses'][j]['response'];
          // this.surveyDetails["questions"][i]["actions"] =
          //   this.surveyDetails["responses"][j]["actions"];

          if (!this.surveyDetails['responses'][j]['actions']) {
            this.surveyDetails['questions'][i]['actions'] = [];
            this.surveyDetails['responses'][j]['actions'] = [];
          } else {
            this.surveyDetails['questions'][i]['actions'] = this.surveyDetails['responses'][j]['actions'];
          }

          this.surveyDetails['questions'][i]['photos'] = this.surveyDetails['responses'][j]['photos'];
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          this.surveyDetails['questions'][i]['reviewedFrom'] = this.surveyDetails['responses'][i]['reviewedFrom'];
          this.surveyDetails['questions'][i]['reviewedTo'] = this.surveyDetails['responses'][i]['reviewedTo'];
        }
      }
    }
  }

  checkVisitNotesVisibility() {
    if (this.sharedFunction.isVisitNoteAdded(this.surveyDetails)) {
      this.viewNotesAvailable = true;
    }
  }

  checkSignOffDetailVisibility() {
    const features = ['mrrAssessments.signOffLowLevel', 'mrrAssessments.signOffHighLevel'];
    const userSignOffLevelFeatures = features.filter((feature: string) => this.featureAccessService.hasAccess(feature));
    this.signedOffByAnyRole = this.sharedFunction.isAssessmentSignedoff(this.surveyDetails);
  }

  signOffAbilityCheck() {
    if (this.surveyDetails['signOff']) {
      if (this.surveyDetails['signOff']['required']) {
        for (
          let i = 0;
          i < this.surveyDetails['signOff']['details'].length;
          i++
        ) {
          if (
            this.surveyDetails['signOff']['details'][i]['role']
            == this.user['role']
          ) {
            if (!this.surveyDetails['signOff']['details'][i]['signedOff']) {
              this.signoffbyRole = true;
              console.log(
                'signOffAbilityCheck passed by ',
                this.surveyDetails['signOff']['details'][i]
              );
            }
          }
        }
      }
    }
  }

  publishMrr() {
    // If the MRR is a draft, publishing will update the status and make it available to the store. If the MRR was already published, this will update the existing response with new/modified findings. If a finding is removed that previously contained evidence/actions, these will also need to be individually deleted.
    const dialogRef = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Submitting this Material Risk Review  will make it available to Store Manager. Do you wish to proceed?',
        closeText: 'Submit',
        cancelText: 'Cancel',
      },
      backdropClass: 'dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        if (this.surveyDetails['filterName'] == 'mrr') {
          const mrrData = {};
          mrrData['id'] = this.surveyDetails['id'];
          mrrData['domain'] = this.user['domain'];
          this.mrrService.mrrActivateEmailTrigger(mrrData).subscribe(
            (response) => {
              if (response['status'] == 200) {
                this.router.navigate(['thankyou']);
              }
              if (response['status'] != 200) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't Activate Assessment",
                    message:
                      `Please email ${this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              }
            },
            (error) => {
              console.log(error);
              this.router.navigate(['thankyou']);
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't send email",
                  message:
                    `Please email ${this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Activate Assessment",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      }
    });
  }

  signoffMrr(event) {
    event.preventDefault(); // Stops form submission
    const dialogRef = this.dialog.open(SignOffDialogComponent, {
      data: {
        surveyDetails: this.surveyDetails,
        heading: 'Are You Sure?',
        message:
          'Are you sure you want to Sign off this Assessment? This cannot be un-done. Please provide a Sign off Comment.',
        closeText: 'Submit',
        cancelText: 'Cancel',
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result['type'] && result['type'] == 'logout') {
        if (this.surveyDetails['filterName'] == 'mrr') {
          const mrrData = {};
          mrrData['id'] = this.surveyDetails['id'];
          mrrData['comments'] = result['comments'];

          const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(mrrData, ['comments']);
          this.mrrService.SignOff(requestJSONPayload).subscribe(
            (response) => {
              if (response['status'] == 200) {
                this.router.navigate(['thankyouMrr', 'signoff']);
              }
              if (response['status'] != 200) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't Sign off Assessment",
                    message:
                      `Please email ${this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              }
            },
            (error) => {
              console.log(error);
              this.router.navigate(['thankyouMrr', 'signoff']);
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't send email",
                  message:
                    `Please email ${this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Sign off Assessment",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      }
    });
  }
}
