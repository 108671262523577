import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeWhiteSpace'
})
export class RemoveWhiteSpacePipe implements PipeTransform {
  transform(value: string): string {
    const cleanString = value.replace(/\s/g, '');
    return cleanString;
  }
}
