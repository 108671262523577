import {
  Component,
  Inject,
  Injectable,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { TitleCasePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel, FormControl } from '@angular/forms';
import {
  faSave, faPaperPlane, faTimes, faSortDown 
} from '@fortawesome/free-solid-svg-icons';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { DrillDownFilterPipe } from '../../custom-pipes/drill-down-filter.pipe';
import { MessagesComponent } from '../messages/messages.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-create-survey-dialog',
  templateUrl: './create-survey-dialog.component.html',
  styleUrls: ['./create-survey-dialog.component.scss'],
  providers: [
    DrillDownFilterPipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CreateSurveyDialogComponent implements OnInit {
  @ViewChild('createSurveyForm') createSurveyForm: NgForm;

  compState = (val1, val2) => val1 == val2;

  faTimes = faTimes;

  faSave = faSave;

  faSortDown = faSortDown;

  faPaperPlane = faPaperPlane;

  adhocValidity = 1;

  allDays: boolean = false;

  anyComplete: boolean = false;

  availableDays = [];

  availableStates;

  divisions = [];

  drillDownHeaders = [];

  excludedStores = [];

  foundTemplates = [];

  frequencies = ['Day', 'Week', 'Month'];

  IsChecked = true;

  minDate = moment().add(1, 'days');

  nextLevel = '';

  noEntries = '';

  numSelected: object = {};

  roleList = [];

  storeLossRoleList = [];

  searching = false;

  searchTimer;

  selectedDivision = '';

  selectedGroupings = [];

  selectedStore = { Store_No: '', Location_Name: 'All Stores' };

  selectedStores = [];

  selectedTemplate = '';

  showGroup = false;

  showStore = false;

  storeAdded = false;

  storeDetails = [];

  storeDetailsFetched = false;

  storeExcluded = false;

  storeName = '';

  storeNameNumber = '';

  storeNumber = '';

  storesReturned = [];

  survey = {};

  templateList = [];

  templates = [];

  titleCasePipe = new TitleCasePipe();

  today = moment();

  user: User;

  initialLocations;

  locationsOrdered: string[] = [];

  locationsDrilldown: object = {};

  daySelection = {
    name: 'Every Day',
    completed: false,
    color: 'primary',
    days: [
      { name: 'Monday', completed: false },
      { name: 'Tuesday', completed: false },
      { name: 'Wednesday', completed: false },
      { name: 'Thursday', completed: false },
      { name: 'Friday', completed: false },
      { name: 'Saturday', completed: false },
      { name: 'Sunday', completed: false },
    ],
  };

  constructor(
    private ref: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private surveyService:SurveysService,
    private dialogRef: MatDialogRef<CreateSurveyDialogComponent>,
   
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public closeMe() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.user = this.csaAuth.user;

    this.surveyService
      .getNextLevel(`?domain=${this.user['domain']}`)
      .subscribe((divisionList) => {
        this.divisions = divisionList['division'];
      });

    this.getRoleList();

    this.surveyService
      .getTemplates(`/list/${this.user['division']}/0/100`)
      .subscribe((templateData) => {
        this.templateList = templateData['templates'];

        if (this.data.templateid) {
          this.templateList.forEach((obj) => {
            if (obj.id == this.data.templateid) {
              this.selectedTemplate = obj;

              // re-retrieve the role list when a template is pre-selected because it impacts which roles can be used
              this.getRoleList();
            }
          });
        }
      });

    this.getNextLevel('division', {}, true);
  }

  getCellWidth() {
    return `${(100 / this.getKeysLength(this.user.orgStruct)).toFixed(0)}vw !important`;
  }

  getValueName(selection): string {
    let valueName = '';
    for (const [key, value] of Object.entries(selection)) {
      valueName += value;
    }
    return valueName;
  }

  getRoleList() {
    this.roleList = [];
    this.storeLossRoleList = [];

    if (
      this.selectedTemplate['filterName']
      && this.selectedTemplate['filterName'] == 'eitb'
    ) {
      Object.keys(this.user['divisionRoles']).forEach((key) => {
        if (
          key == 'Store'
          || key == 'GroupManager' 
          || key == 'Admin'
        ) {
          this.roleList.push(key);
        } else if (key.indexOf('StoreLoss') > -1) {
          this.storeLossRoleList.push(key);
        }
      });

      this.storeLossRoleList.sort((a, b) => this.user['divisionRoles'][b] - this.user['divisionRoles'][a]);

      if (this.storeLossRoleList.length > 1) {
        this.roleList.push('StoreLossUser');
        console.log(this.roleList);
      } else {
        this.roleList = [...this.roleList, ...this.storeLossRoleList];
        console.log(this.roleList);
      }
    } else {
      Object.keys(this.user['divisionRoles']).forEach((key) => {
        if (
          this.user['divisionRoles'][key] > 0 
          && key != 'Support'
          && key != 'Super'
          && key.indexOf('StoreLoss') == -1
        ) {
          this.roleList.push(key);
        }
      });
    }

    this.roleList.sort((a, b) => this.user['divisionRoles'][b] - this.user['divisionRoles'][a]);

    console.log(this);
  }

  splitByCaps(value): string {
    if (
      this.user['division'] == 'DC'
      && (value == 'Store' || value == 'MultiStore')
    ) {
      if (value == 'Store') {
        return 'DCs';
      }
      if (value == 'MultiStore') {
        return 'Multi DC Users';
      }
    }

    if (
      value == 'Admin'
      || value == 'NationalView'
      || value == 'MultiStore'
    ) {
      value += 'Users';
    } else {
      if (value == 'OperationManager') {
        value = 'ZoneOperationManager';
      }
      if (value.substr(-1) != 's') {
        value += 's';
      }
    }

    return value.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
  }

  setStore(storeDetail, include) {
    if (include) {
      if (
        this.selectedStores.filter(
          (selectedStore) => selectedStore['Store_No'] == storeDetail['Store_No']
        ).length > 0
      ) {
        this.storeAdded = true;
      } else {
        this.storeAdded = false;
        this.selectedStores.push(storeDetail);
      }
    } else if (
      this.excludedStores.filter(
        (excludedStore) => excludedStore['Store_No'] == storeDetail['Store_No']
      ).length > 0
    ) {
      this.storeExcluded = true;
    } else {
      this.storeExcluded = false;
      this.excludedStores.push(storeDetail);
    }
  }

  removeStore(storeDetail, selected) {
    if (selected) {
      this.selectedStores = this.selectedStores.filter(
        (selectedStore) => selectedStore['Store_No'] != storeDetail['Store_No']
      );
    } else {
      this.excludedStores = this.excludedStores.filter(
        (selectedStore) => selectedStore['Store_No'] != storeDetail['Store_No']
      );
    }
  }

  searchForStores(storeId) {
    this.noEntries = 'Searching...';
    this.searching = true;

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      if (this.user['division'] == 'DC') {
        this.noEntries = 'No matching DCs found..';
      } else {
        this.noEntries = 'No matching Stores found..';
      }
      if (storeId.trim()) {
        this.surveyService.getStoreList(storeId).subscribe(
          (storeData) => {
            this.storesReturned = storeData;
            setTimeout(() => {
              this.searching = false;
            }, 1000);
          },
          (error) => {
            this.searching = false;
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'An Error Has Occured',
                message:
                  `An Error was returned when trying to find stores. Please email ${ 
                    this.user['supportEmail']
                  } for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      } else {
        this.storesReturned = [];
        this.searching = false;
      }
    }, 1000);
  }

  getPreviousLevel(level): string {
    return Object.keys(this.user['orgStruct']).find((key) => this.user['orgStruct'][key] == level);
  }

  addGroup(
    level, 
    selection,
    checked
  ) {
    if (level != 'storeID') {
      this.numSelected[this.getValueName(selection)] = 0;
    }
    if (checked) {
      if (level == this.user.orgStruct['division']) {
        // Top level selections are always valid and can be added with no checks
        this.selectedGroupings.push(selection);
      } else {
        /* 
          If level is below the top level, we need to check whether to extend an existing grouping or make a new grouping.
          E.g. Groupings = [{state: 'NSW/ACT'}]
            - Selected 'Zone 1' under 'NSW/ACT'
            - Find the matching grouping for above level
            - Check if current level exists
            - Since it doesn't, we extend by adding zone: '1'

          E.g. Groupings = [{state: 'NSW/ACT', zone: '1'}]
            - Selected 'Zone 2' under 'NSW/ACT'
            - Find the matching grouping for above level
            - Check if current level exists
            - Since it does, we push the new selection
        */
        this.selectedGroupings.every(
          (grouping, index, array) => {
            let match = true;
            let key = this.user.orgStruct['division'];
            while (key != level) {
              if (!selection[key] || grouping[key] != selection[key]) {
                match = false; 
                break;
              }
              key = this.user.orgStruct[key];
            }
  
            if (match) {
              if (grouping[level]) {
                array.push(selection);
              } else {
                grouping[level] = selection[level];
              }
              return false;
            } 
            return true;
          }
        );
      }
    } else {
      /* 
        If we unselect a given level, we need to unselect all grouping that share the current selections properties.
        E.g. Unselecting NSW/ACT needs to find all groupings with this value and remove them as well
      */
      this.selectedGroupings = this.selectedGroupings.filter(
        (grouping) => { 
          let key = this.user.orgStruct['division'];
          while (selection[key]) {
            if (!grouping[key] || grouping[key] != selection[key]) {
              return true;
            }
            key = this.user.orgStruct[key];
          }
          return false;
        }
      );

      /* 
        We then need to check whether or not to reinsert the selection without the current unselected level.
        Remove the current level, and check for any matches. If none are found and the new selection is not empty, push the new selection. 
      */
      if (level != this.user.orgStruct['division']) {
        delete selection[level];
        let match;
        for (const grouping of this.selectedGroupings) {
          match = true;
          let key = this.user.orgStruct['division'];
          while (selection[key]) {
            if (grouping[key] != selection[key]) {
              match = false;
              break;
            }
            key = this.user.orgStruct[key];
          }
          
          if (match) { 
            break;
          }
        }

        if (!match) {
          this.selectedGroupings.push(selection);
        }
      }
    }
    this.ref.detectChanges();
  }

  createLevelQuery(selection): string {
    let key = 'division';
    let query = '';
    while (key != this.nextLevel) {
      if (key == 'division') query = `?division=${this.user.division}`;
      else query += `&${key}=${selection[key]}`;

      key = this.user.orgStruct[key];
    }
    return query;
  }

  copyDataToDrilldown(data, selection) {
    let key = 'division';
    let obj = this.locationsDrilldown;
    while (key != this.nextLevel) {
      if (selection[key]) {
        obj = obj[selection[key]];
      }

      if (this.user.orgStruct[key] == this.nextLevel) {
        if (this.nextLevel == 'storeID') {
          // eslint-disable-next-line no-loop-func
          data.forEach((element) => {
            obj[element['Store_No']] = element['Location_Name'];
          });        
        } else {
          // eslint-disable-next-line no-loop-func
          data[this.nextLevel].forEach((element) => {
            obj[element] = {};
          });
        }
        break;
      }
      key = this.user.orgStruct[key];
    }
  }

  getNextLevel(
    level,
    selection,
    checked
  ) {
    this.nextLevel = this.user.orgStruct[level];
    if (level != 'division') {
      this.addGroup(level, selection, checked);
    } else {
      this.numSelected['division'] = 0;
    }

    if (checked) {
      // let title =
      //   "Select " +
      //   this.titleCasePipe.transform(this.nextLevel).replace("id", "") +
      //   "s";

      // if (level == "division") {
      //   this.drillDownHeaders = [title];
      // } else {        
      //   if (this.locationsOrdered.indexOf(this.nextLevel) == -1) {
      //     this.locationsOrdered.push(this.nextLevel);
      //   }

      //   if (this.drillDownHeaders.indexOf(title) == -1) {
      //     this.drillDownHeaders.push(title)
      //   }
      // }

      if (this.nextLevel == 'storeID') {
        this.surveyService
          .getStoreLevel(this.createLevelQuery(selection))
          .subscribe((data) => {
            console.log(data);
            this.copyDataToDrilldown(data, selection);
            console.log(this.locationsDrilldown);
            console.log(this.drillDownHeaders);          
          });
      } else {     
        this.surveyService
          .getNextLevel(this.createLevelQuery(selection))
          .subscribe((data) => {
            console.log(data);
            this.copyDataToDrilldown(data, selection);
            console.log(this.locationsDrilldown);
            console.log(this.drillDownHeaders);
          });
      }
    }
    this.ref.detectChanges();
  }

  clearLevelInDrilldown(level, selection) {
    let key = this.user.orgStruct['division'];
    let obj = this.locationsDrilldown;
    while (key != this.nextLevel) {
      if (key == level) {
        obj[selection[key]] = {};
        break;
      } else {
        obj = obj[selection[key]];
      }
      key = this.user.orgStruct[key];
    }
  }

  createSelection(value: string, level: string = this.user.orgStruct['division'], selection: object = {}) {
    const obj = {};
    Object.assign(obj, selection);
    if (level == 'storeID') {
      obj[level] = value['Store_No'];
    } else {
      obj[level] = value;
    }
    return obj;
  }

  updateNumSelected(valueName, checked) {
    if (checked) {
      this.numSelected[valueName] += 1;
    } else {
      this.numSelected[valueName] -= 1;
    }
  }

  getKeysLength(obj) {
    return Object.keys(obj)?.length;
  }

  dayFilter = (d: Date): boolean => {
    const day = moment(d, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).day();
    return true;
  };

  updateDayfilter(daySelected) {
    if (daySelected == 'Month') {
      this.dayFilter = (d: Date): boolean => {
        const day = moment(d, [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).date();
        return day === 1;
      };
    } else {
      this.dayFilter = (d: Date): boolean => {
        const day = moment(d, [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).day();
        if (daySelected == 'Sunday') {
          return day === 0;
        } if (daySelected == 'Monday') {
          return day === 1;
        } if (daySelected == 'Tuesday') {
          return day === 2;
        } if (daySelected == 'Wednesday') {
          return day === 3;
        } if (daySelected == 'Thursday') {
          return day === 4;
        } if (daySelected == 'Friday') {
          return day === 5;
        } if (daySelected == 'Saturday') {
          return day === 6;
        }
      };
    }
  }

  updateAllComplete(day) {
    this.daySelection.days.forEach((t) => {
      if (t['name'] == day) {
        if (t['completed'] == true) {
          t['completed'] = false;
          let index = -1;
          if (day == 'Sunday') {
            index = this.availableDays.indexOf(0);
          } else if (day == 'Monday') {
            index = this.availableDays.indexOf(1);
          } else if (day == 'Tuesday') {
            index = this.availableDays.indexOf(2);
          } else if (day == 'Wednesday') {
            index = this.availableDays.indexOf(3);
          } else if (day == 'Thursday') {
            index = this.availableDays.indexOf(4);
          } else if (day == 'Friday') {
            index = this.availableDays.indexOf(5);
          } else if (day == 'Saturday') {
            index = this.availableDays.indexOf(6);
          }
          if (index > -1) {
            this.availableDays.splice(index, 1);
          }
        } else {
          t['completed'] = true;
          if (day == 'Sunday') {
            this.availableDays.push(0);
          } else if (day == 'Monday') {
            this.availableDays.push(1);
          } else if (day == 'Tuesday') {
            this.availableDays.push(2);
          } else if (day == 'Wednesday') {
            this.availableDays.push(3);
          } else if (day == 'Thursday') {
            this.availableDays.push(4);
          } else if (day == 'Friday') {
            this.availableDays.push(5);
          } else if (day == 'Saturday') {
            this.availableDays.push(6);
          }
        }
      }
    });

    this.dayFilter = (d: Date): boolean => {
      const filterDay = moment(d, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).day();
      return this.availableDays.indexOf(filterDay) > -1;
    };

    if (this.daySelection.days.every((t) => t.completed)) {
      this.allDays = true;
      this.anyComplete = false;
    } else {
      this.allDays = false;
      this.anyComplete = this.daySelection.days.filter((t) => t.completed).length > 0;
    }
  }

  setAll() {
    if (this.allDays == false) {
      this.allDays = true;
      this.daySelection.completed = true;
      this.daySelection.days.forEach((t) => (t.completed = true));
      this.anyComplete = false;
      this.availableDays = [0, 1, 2, 3, 4, 5, 6];
      this.dayFilter = (d: Date): boolean => {
        const filterDay = moment(d, [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).day();
        return true;
      };
    } else {
      this.allDays = false;
      this.daySelection.completed = false;
      this.daySelection.days.forEach((t) => (t.completed = false));
      this.anyComplete = false;
      this.dayFilter = (d: Date): boolean => {
        const filterDay = moment(d, [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).day();
        return false;
      };
      this.availableDays = [];
    }
  }

  sendSurvey(survey, value) {
    this.survey['title'] = value.title;
    this.survey['template_id'] = value.template.id;
    this.survey['templateTitle'] = value.template.title;
    if (value.template['filterName']) {
      this.survey['filterName'] = value.template['filterName'];
    }
    if (value['surveyType'] == 'onceOff') {
      this.survey['publishDate'] = moment(value.publishDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
      this.survey['dueDate'] = moment(value.closeDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();

      if (this.selectedGroupings.length > 0) {
        const finalArray = [];
        const storeID = [];
        this.selectedGroupings.forEach((group) => {
          if (group['storeID']) {
            storeID.push(group['storeID']);
          } else {
            finalArray.push(group);
          }
        });

        if (storeID.length > 0) {
          finalArray.push({ storeID });
        }

        this.survey['targetStores'] = finalArray;
      }

      if (this.selectedStores.length > 0) {
        const storesSelected = [];
        this.selectedStores.forEach((store) => {
          storesSelected.push(store['Store_No']);
        });
        this.survey['targetStores'] = [{ storeID: storesSelected }];
      }

      if (this.excludedStores.length > 0) {
        const storesExcluded = [];
        this.excludedStores.forEach((store) => {
          storesExcluded.push(store['Store_No']);
        });
        this.survey['excludedStores'] = storesExcluded;
      }
  
      const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(this.survey, ['title']);

      this.surveyService.createSurvey(requestJSONPayload).subscribe((reply) => {
        if (reply['status'] == 200) {
          this.dialogRef.close();
          const dialogSuccess = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Assessment Scheduled',
              message:
                `${this.survey['title'] 
                } has been scheduled. The page will now reload to update your Assessment Lists`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
          
          dialogSuccess.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialogRef.close();
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Schedule Assessment",
              message:
                `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        }
      });
    } else if (value['surveyType'] == 'adHoc') {
      this.survey['type'] = 'adhoc';
      this.survey['startDate'] = moment(value.adhocFrom, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
      this.survey['endDate'] = moment(value.adhocTo, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();

      if (value.targetRole == 'Store') {
        console.log('targetRole=Store');
        this.survey['targetRoles'] = ['Store', 'MultiStore'];
      } else if (value.targetRole == 'StoreLossUser') {
        console.log('targetrole=storeLossUser');
        console.log(value.storeLossTargetRole);

        this.survey['targetRoles'] = value.storeLossTargetRole;
      } else {
        this.survey['targetRoles'] = [value.targetRole];
      }

      this.survey['surveyValidity'] = value.adhocValidity;
      this.survey['multiSurvey'] = 'Y';

      const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(this.survey, ['title']);

      this.surveyService.createSurvey(requestJSONPayload).subscribe((reply) => {
        if (reply['status'] == 200) {
          this.dialogRef.close();
          const dialogSuccess = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Adhoc Assessment Scheduled',
              message:
                `${this.survey['title'] 
                } has been made available to the requested users. The page will now reload to update your Assessment Lists`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
          dialogSuccess.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialogRef.close();
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Schedule Adhoc Assessment",
              message:
                `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        }
      });
    } else if (value['surveyType'] == 'recurring') {
      this.survey['type'] = 'recurring';
      this.survey['startDate'] = moment(value.beginRecurring, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
      this.survey['endDate'] = moment(value.endRecurring, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();

      if (value['frequency'] == 'Day') {
        console.log('day assessment');
        this.survey['frequency'] = 'daily';
        if (this.allDays == false) {
          this.survey['daysOfWeek'] = [];
          this.daySelection.days.forEach((day, index) => {
            if (day['completed']) {
              this.survey['daysOfWeek'].push(index);
            }
          });
        } else {
          this.survey['daysOfWeek'] = this.availableDays;
        }
      } else if (value['frequency'] == 'Week') {
        this.survey['frequency'] = 'weekly';

        if (value['validity'] == true) {
          this.survey['surveyValidity'] = 7;
        } else {
          this.survey['surveyValidity'] = 5;
        }

        this.survey['daysOfWeek'] = [];
        this.survey['daysOfWeek'].push(
          this.daySelection.days.findIndex(
            (dayList) => dayList.name == value['weeklyPublishDay']
          )
        );

        if (value['alertsOn'] == true) {
          this.survey['sendEmail'] = 'Y';
        } else {
          this.survey['sendEmail'] = 'N';
        }
      } else if (value['frequency'] == 'Month') {
        this.survey['frequency'] = 'monthly';

        if (value['alertsOn'] == true) {
          this.survey['sendEmail'] = 'Y';
        } else {
          this.survey['sendEmail'] = 'N';
        }
      }

      const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(this.survey, ['title']);

      this.surveyService.createSurvey(requestJSONPayload).subscribe((reply) => {
        if (reply['status'] == 200) {
          this.dialogRef.close();
          const dialogSuccess = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Recurring Assessment Scheduled',
              message:
                `${this.survey['title'] 
                } has been scheduled to publish each ${ 
                  value['frequency'] 
                }. The page will now reload to update your Assessment Lists`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
          dialogSuccess.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialogRef.close();
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Schedule Recurring Assessment",
              message:
                `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        }
      });
    }
  }
}
