import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'drillDownFilter',
  pure: false
})
export class DrillDownFilterPipe implements PipeTransform {
  transform(array: any, firstLevel: any, firstValue: any, secondLevel: any, secondValue: any, thirdLevel: any, thirdValue: any): any {
    const tempArray = [];
    let emptykey = null;
    if (firstLevel) {
      tempArray[firstLevel] = firstValue;
    }
    if (secondLevel) {
      if (secondValue != null) tempArray[secondLevel] = secondValue;
      else emptykey = secondLevel;
    }
    if (thirdLevel) {
      if (thirdValue != null) tempArray[thirdLevel] = thirdValue;
      else emptykey = thirdLevel;
    }

    const altArray = array.filter(
      (grouping) => Object.keys(tempArray).every((group) => grouping[group] === tempArray[group] && grouping[emptykey] != null)
    );

    const uniqueArray = [...new Set(altArray.map((item) => item[emptykey]))];

    return uniqueArray;
  }
}
