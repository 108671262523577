import {
  Injectable, Renderer2, Inject, RendererFactory2 
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { VERSION } from '../../environments/version';

interface IThemes {
 name: string;
 loaded: boolean;
}
export const STORE: IThemes[] = [{
  name: 'bigw',
  loaded: false,
}, {
  name: 'woolworths',
  loaded: false,
}
];
@Injectable({
  providedIn: 'root',
})
export class ThemeLoaderService {
  private _themes: IThemes[] = [];

  private _renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private _document, private _http: HttpClient) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._initialize();
  }

  private _initialize() {
    STORE.forEach((theme: any) => {
      this._themes[theme.name] = {
        loaded: false,
        name: theme.name
      };
    });
  }

  /**
   * Loads themes.
   *
   * @param themes - All in memory themes.
   */
  load(...themes: string[]) {
    console.log(themes);
    const promises: any[] = [];
    themes.forEach((theme) => promises.push(this._loadTheme(theme)));
    return Promise.all(promises);
  }

  /**
   * Checks if given theme is already loaded.
   *
   * @param name - Name of theme.
   */
  private _isThemeLoaded(name: string) {
    if (this._themes[name].loaded) {
      return true;
    }
    return false;
  }

  /**
   * Attach theme tag through Renderer2.
   *
   * @param name - Name of theme.
   * @author Sahil Purav
   */
  private _renderTheme(name: string) {
    const style = this._renderer.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = `${this._themes[name].name}.css?cachebust=${VERSION.hash}`;
    return style;
  }

  /**
   * Get resolve params based on themes status.
   *
   * @param name - Name of script.
   * @param status - Status of script.
   */
  private _setThemeStatus(name: string, status = true) {
    this._themes[name].loaded = status;
    return {
      name,
      loaded: status
    };
  }

  /**
   * Loads themes.
   *
   * @param name - Name of script.
   */
  private _loadTheme(name: string) {
    return new Promise((resolve) => {
      if (this._isThemeLoaded(name)) {
        resolve(this._setThemeStatus(name));
        return;
      }
      const theme = this._renderTheme(name);
      theme.onload = () => {
        resolve(this._setThemeStatus(name));
      };
      theme.onerror = () => resolve(this._setThemeStatus(name, false));
      this._renderer.appendChild(this._document.getElementsByTagName('head')[0], theme);
    });
  }
}
