import { Component, OnInit } from '@angular/core';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs';
import { User } from '../models/user';
import { CsaAuthService } from '../auth/csa-auth.service';
import { CsaBackEndService } from '../common-services/csa-back-end.service';
import { MessagesComponent } from '../surveys/messages/messages.component';
import { SurveysService } from '../surveys/surveys.service';
import { CsaSantiserService } from '../sanitizer/csa-santiser.service';

@Component({
  selector: 'app-multi-store-selection',
  templateUrl: './multi-store-selection.component.html',
  styleUrls: ['./multi-store-selection.component.scss']
})
export class MultiStoreSelectionComponent implements OnInit {
  compState = (val1, val2) => val1 == val2;

  faSave = faSave;

  faTimes = faTimes;

  noEntries: string = '';

  searching: boolean = false;

  selectedStore: object = {};

  storeName: string = '';

  storeNumber: string = '';

  storesReturned: object[] = [];

  user: User;

  searchTimer;

  constructor(
    private actRoute: ActivatedRoute,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private router: Router,
    private surveyService: SurveysService,
    private auth: CsaAuthService,
    private cookieService: CookieService,
    private sanitizer: CsaSantiserService
  ) { }

  ngOnInit(): void {
    this.auth.userChanges$().pipe(take(1)).subscribe(() => {
      this.user = this.csaAuth.user;
    });
  }

  searchForStores(storeId) {
    this.noEntries = 'Searching...';
    this.searching = true;

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(
      () => {
        if (this.user['division'] == 'DC') {
          this.noEntries = 'No matching DCs found..';
        } else {
          this.noEntries = 'No matching Stores found..';
        }
        if (storeId.trim()) {
          this.surveyService.getStoreList(storeId).subscribe((storeData) => {
            this.storesReturned = storeData;
            setTimeout(() => { this.searching = false; }, 1000);
          }, (error) => {
            this.searching = false;
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'An Error Has Occured',
                message: `An Error was returned when trying to find stores. Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok'
              },
              backdropClass: 'dialog-backdrop'
            });
          });
        } else {
          this.storesReturned = [];
          this.searching = false;
        }
      },
      1000
    );
  }

  setStore(store) {
    this.storeNumber = store['Store_No'];
    this.storeName = store['Location_Name'];
    this.selectedStore = store;
  }

  saveStore(form, value) {
    this.auth.setUserOverride('storeID', this.storeNumber);
    this.auth.setUserOverride('storeName', this.storeName);

    this.cookieService.set(
      'userStoreID',
      this.sanitizer.escapedAndSanitizedHtmlProperty(this.storeNumber),
      0.5
    );

    this.surveyService
      .getStoreList(
        this.sanitizer.escapedAndSanitizedHtmlProperty(this.cookieService.get('userStoreID'))
      )
      .subscribe(
        (storeData) => {
          if (storeData.length > 0) {
            this.auth.setUserOverride('storeID', storeData[0]['Store_No']);
            this.auth.setUserOverride('storeName', storeData[0]['Location_Name']);
            this.auth.setUserOverride('groupNo', storeData[0]['Group_No']);
            this.auth.setUserOverride('zone', storeData[0]['Zone']);
            this.auth.setUserOverride('state', storeData[0]['State']);
            this.router.navigateByUrl('');
          } else {
            const errorDialog3 = this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Error Saving Store Selection',
                message:
                  'Please try again',
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
            errorDialog3.afterClosed().subscribe((result3) => {
              window.location.reload();
            });
          }
        },
        (error) => {
          const errorDialog2 = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Error Saving Store Selection',
              message:
                'Please try again',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
          errorDialog2.afterClosed().subscribe((result2) => {
            window.location.reload();
          });
        }
      );
  }
}
