import { User } from 'src/app/models/user';
// TODO (8/5/24 AS): This needs to be reviewed once multiple user locations are implemented.

/**
 * Retrieves the organizational level and corresponding value of a user based on their organizational structure.
 * 
 * @param {User} user - The user object containing organization structure and other properties.
 *                    The user object must have an 'orgStruct' property which defines the organization hierarchy.
 *                    Additionally, the user object should contain properties that correspond to the keys
 *                    in the organization structure (e.g., division, groupID) which are used to generate the query string.
 * @returns {object|undefined} An object containing the organizational level and corresponding value of the user.
 * If no organizational structure is found, returns undefined.
 * @example
 * // Assuming a user object with the following structure:
 * // {
 * //   orgStruct: {division: 'state', groupID: 'storeID', state: 'zone', zone: 'groupID'},
 * //   division: 'Supermarkets',
 * //   state: 'NSW/ACT',
 * //   zone: '2',
 * //   groupNo: '422'
 * //   storeID: ''
 * // };
 * getUserOrgLevel(user);
 * // Returns {'level': 'groupID', value: '422'}
 */
export function getUserOrgLevel(user: User): object | undefined {
  const userOrgStructure = user['orgStruct'];

  // Return an empty string if userOrgStructure is not defined
  if (!userOrgStructure) {
    console.log('Expected user organisation structure, but non was found.');
    return undefined;
  }
  let orgLevel = 'division'; // default org level
  let orgLevelValue = '';
  let key = 'division'; // start with the top level organization node

  // Loop through the user's organizational structure until there are no more levels
  while (userOrgStructure[key]) {
    const nextKey = user['orgStruct'][key];
    const userPropertyName = nextKey === 'groupID' ? 'groupNo' : nextKey;

    // If the user has a property at the current organizational level, update orgLevel and orgLevelValue
    if (user[`${userPropertyName}`]) {
      orgLevel = nextKey;
      orgLevelValue = user[`${userPropertyName}`];
    }
    key = nextKey; // Move to the next organizational level
  }

  // If orgLevel is 'division' or 'store', set orgLevelValue to an empty string
  if (orgLevel == 'division' || orgLevel == 'storeID') {
    orgLevelValue = '';
  }

  // Return an object containing the organizational level and corresponding value of the user
  return { level: orgLevel, value: orgLevelValue };
}
