/**
 * Converts a value of type "string | number | undefined" to a number.
 *
 * @param {string | number | undefined} value - The value to be converted.
 * @returns {number | undefined} The converted number. Returns undefined if the input is undefined.
 * @throws {TypeError} Will throw an error if the input cannot be converted to a number.
 */
export function convertToNumber(value: string | number | undefined): number | undefined {
  // Use the "+" operator to convert the value to a number
  // If the value is undefined, return undefined
  return typeof value === 'undefined' ? undefined : +value;
}
