import {
  Component, HostBinding, Input, OnInit 
} from '@angular/core';

@Component({
  selector: 'app-panel-header',
  templateUrl: './panel-header.component.html',
  styleUrls: ['./panel-header.component.scss']
})
export class PanelHeaderComponent {
  @Input() heading: string = '';

  @Input() isSubItem = false;

  @Input() showBackButton: boolean = false;

  // if alwaysShowActions is true, the actions are not hidden below 600px
  @HostBinding('class.alwaysShowActions') @Input() alwaysShowActions: boolean = false;
}
