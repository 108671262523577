import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams 
} from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class RecurringSurveyService {
  private surveysUrl = `${environment.backEndUrl}/survey/list`;

  constructor(private httpClient: HttpClient) { }

  getRecurringSurveys(
    group: string,
    status: string,
    index: string,
    count: string,
    type: string
  ): Observable<object[]> {
    let fullUrl = '';
    if (status == '') {
      fullUrl = `${this.surveysUrl
        + index
        + count
      }?division=${
        group
      }&type=${
        type}`;
    } else {
      fullUrl = `${this.surveysUrl
        + index
        + count
      }?status=${
        status
      }&division=${
        group
      }&type=${
        type}`;
    }
    return this.httpClient.get<object[]>(fullUrl);
  }
}
