import {
  Component,
  Inject,
  OnInit,
  KeyValueChanges,
  KeyValueDiffer,
  KeyValueDiffers,
  Input,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel, FormControl } from '@angular/forms';
import {
  faCheck,
  faPaperPlane,
  faInfoCircle,
  faTimes,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { last, Subject, takeUntil } from 'rxjs';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { MessagesComponent } from '../messages/messages.component';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-store-dashboard-dialog',
  templateUrl: './store-dashboard-dialog.component.html',
  styleUrls: ['./store-dashboard-dialog.component.scss'],
})
export class StoreDashboardDialogComponent implements OnInit {
  faCheck = faCheck;

  faInfoCircle = faInfoCircle;

  faPaperPlane = faPaperPlane;

  faPlus = faPlus;

  faTimes = faTimes;

  faTrash = faTrash;

  // availableDivisions: string[] = [];
  availableLocations: object = {};

  locationsOrdered: string[] = [];

  objDiffer: object = {};

  user: User = {};

  storeData: object = {};

  isLoading: object = {};

  currentLevel: string = '';

  nextLevel: string = '';

  formIsValid = false;

  private differ: KeyValueDiffer<string, any>;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private differs: KeyValueDiffers,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private surveyService :SurveysService,
    private dialogRef: MatDialogRef<StoreDashboardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.user = this.csaAuth.user;

    this.csaAuth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.user = user;
    });

    // this.csaBackEnd
    // .getNextLevel("?domain="+this.user['domain'])
    // .subscribe((divisionList)=>{
    //     this.availableDivisions = divisionList['division'];
    // });

    let key = 'division';
    while (this.user['orgStruct'][key]) {
      const value = this.user['orgStruct'][key];
      if (this.locationsOrdered.indexOf(value) === -1) {
        this.locationsOrdered.push(value);
      }
      key = value;
    }

    if (this.data['type'] == 'update') {
      this.formIsValid = true;
      Object.assign(this.storeData, this.data['storeData']);
    } else {
      this.storeData['PILOT'] = 'Y';
    }

    // this.storeData['DIVISION'] = this.data.division;
    this.storeData['Division'] = this.user['division'];
    this.getFiltersForLevel('division', false);
    this.isValid();

    console.log(this);
  }

  closeMe(result?) {
    this.dialogRef.close(result);
  }

  createLevelQuery(queryNextLevel): string {
    let key = 'division';
    let query = '';
    while (key != queryNextLevel) {
      if (key == 'division') {
        query = `?division=${this.storeData['Division']}`;
      } else {
        query += `&${key}=${this.storeData[this.upperCaseKey(key)]}`;
      }
      key = this.user['orgStruct'][key];
    }
    return query;
  }

  getFiltersForLevel(level: string, reset: boolean = true): void {
    this.currentLevel = level;
    this.nextLevel = this.user['orgStruct'][level];

    if (reset) {
      this.resetFiltersBelowLevel();
    }

    if (
      this.storeData[this.upperCaseKey(this.currentLevel)]
    ) {
      if (this.user['orgStruct'][this.nextLevel]) {
        this.isLoading[this.nextLevel] = true;
        this.surveyService
          .getNextLevelAll(this.createLevelQuery(this.nextLevel))
          .subscribe(
            (data) => {
              this.isLoading[this.nextLevel] = false;
              this.availableLocations[this.nextLevel] = data[this.nextLevel];
              this.getFiltersForLevel(this.nextLevel, reset);
            },
            (error) => {
              this.isLoading[this.nextLevel] = false;
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: `Error Finding ${level}s`,
                  message: `Please email ${this.user['supportEmail']} for support.`
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
              console.log(error);
            }
          );
      }
    }
  }

  upperCaseKey(key: string) {
    if (key == 'groupID') {
      return 'Group_No';
    } if (key == 'storeID') {
      return 'Store_No';
    }
    return key.replace(/\b[a-z]/g, (x) => x.toUpperCase());
  }

  removeID(key: string) {
    return key.replace('ID', '');
  }

  numShown(): number {
    return this.locationsOrdered.length;
  }

  getPreviousLevel(level): string {
    return Object.keys(this.user['orgStruct']).find((key) => this.user['orgStruct'][key] == level);
  }

  isValid(): void {
    let valid = true;
    for (let key of this.locationsOrdered) {
      key = this.upperCaseKey(key);
      if (key == 'Store_No') {
        if (!this.storeData[key] || isNaN(this.storeData[key])) {
          valid = false;
          break;
        }
      } else if (!this.storeData[key]) {
        valid = false;
        break;
      }
    }
    this.formIsValid = valid;
  }

  getCurrentLevel(): string {
    let key = 'division';
    while (this.user['orgStruct'][key]) {
      const value = this.user['orgStruct'][key];
      if (!this.availableLocations[value] || this.availableLocations[value]?.length == 0) {
        return key;
      }
      key = value;
    }
    return 'division';
  }

  resetFiltersBelowLevel(): void {
    let key = this.nextLevel;
    while (this.user['orgStruct'][key]) {
      this.availableLocations[key] = [];
      this.storeData[this.upperCaseKey(key)] = '';
      key = this.user['orgStruct'][key];
    }
  }

  saveForm() {
    let successHeading; let errorHeading; let 
      successMessage;
    if (this.data['type'] == 'update') {
      successHeading = 'Store Changes Saved';
      errorHeading = 'Cannot Save Store Changes';
      successMessage = 'Your changes have been saved. These changes will be reflected on the Store Management page.';
    } else if (this.data['type'] == 'create') {
      successHeading = 'New Store Created';
      errorHeading = 'Cannot Create Store';
      successMessage = 'New store has been successfully created. These changes will be reflected on the Store Management page.';
    }

    this.surveyService.updateStore(this.data['type'], this.storeData).subscribe( // change backend function
      (response) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: response['status'] == 200 ? successHeading : errorHeading,
            message: response['status'] == 200 ? successMessage : response['message'],
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        this.closeMe('success');
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: errorHeading,
            message: `Please email ${this.user['supportEmail']} for support.`
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(error);
      }
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
