import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams
} from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { CookieService } from 'ngx-cookie-service';
import { AbstractControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { MrrSurveyDetails } from '../../models/mrr-surveyDetails';

@Injectable({
  providedIn: 'root'
})

/**
 * Contains shared functions. NOTE: These are not "util" functions, though they can be.
 * These functions probably belong in a service closer to the components that share them, but at the time of writing, our application
 * isn't structured in that way (with components sharing a module or service).
 * 
 * Once we refactor to that structure, we will move these functions to the service where they make the most sense.
 */
export class SharedFunctionService {
  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  /**
   *  Checks if an assessment has been signed off by at least one user.
   *
   *  @param assessment - Assessment object to check signoffs
   * here checking signedoff tag is true or not.
   * @returns True if the assessment has been signed off by any user type.
   */
  isAssessmentSignedoff(assessment): boolean {
    let signOffByAnyRole: boolean = false;
    if (assessment['signOff']) {
      if (assessment['signOff']['details']) {
        for (let i = 0; i < assessment['signOff']['details'].length; i++) {
          if (assessment['signOff']['details'][i]['signedOff']) {
            signOffByAnyRole = true;
            break;
          }
        }
      }
    }
    return signOffByAnyRole;
  }

  /**
   *  Checks atleast one visit Notes has been added to an assessment.
   *
   *  @param assessment - Assessment object to check visit Notes
   * here checking Visit notes array object is present or not.
   * @returns True if the Visit notes has been added to a assessment.
   */
  isVisitNoteAdded(assessment: MrrSurveyDetails): boolean {
    let visitNotesAddedOrNot: boolean = false;
    if (assessment.visitNotes) {
      visitNotesAddedOrNot = true;
    }
    return visitNotesAddedOrNot;
  }

  /**
   * Checking string empty or not.
   *
   * @param control - Having input data.
   * @returns Empty or not.
   */
  emptyStringValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.trim().length === 0) {
      return { whitespace: true };
    }
    return null;
  }
}
