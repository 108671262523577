import { Component, OnDestroy } from '@angular/core';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { CsaAuthService } from 'src/app/auth/csa-auth.service';
import { AutoLogoutService } from 'src/app/common-services/auto-logout.service';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnDestroy {
  isDisableAutoLogoutAvailable: boolean = false;

  public isAutoLogoutEnabled = false;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(public auth: CsaAuthService, private autoLogout: AutoLogoutService, private featureAccessService: FeatureAccessService) {
    this.auth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.isDisableAutoLogoutAvailable = this.featureAccessService.hasAccess('navigation.canDisableAutoLogout');
    });

    // load the previously set value of auto logout
    firstValueFrom(this.autoLogout.isAutoLogoutEnabled)
      .then((previouslySetAutoLogoutEnabled) => {
        this.isAutoLogoutEnabled = previouslySetAutoLogoutEnabled;
      });
  }

  /**
   * Called by the slide toggle on change. Sets auto log out on or off.
   * TODO: The auto logout status is managed by the loader service for some unknown reason, and then consumed by appComponet
   * should movre this to auth service at some point.
   *
   * @param $event - The event from the slide toggle.
   */
  public toggleAutoLogout($event: any) {
    if ($event.checked == false) {
      this.autoLogout.turnAutoLogoutOn();
    } else if ($event.checked == true) {
      this.autoLogout.turnAutoLogoutOff();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
