import {
  Component, ElementRef, EventEmitter, OnInit, Inject, ViewChild, Output, ChangeDetectorRef, NgZone 
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
  ParamMap,
  NavigationEnd,
} from '@angular/router';
import {
  faTimes,
  faPaperPlane,
  faCircleNotch,
  faPaperclip
} from '@fortawesome/free-solid-svg-icons';
import {
  NgForm, NgModel, FormControl, FormGroup, Validators, AbstractControl 
} from '@angular/forms';
import * as moment from 'moment';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import Compressor from 'compressorjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { DrillDownFilterPipe } from '../../custom-pipes/drill-down-filter.pipe';
import { LoaderService } from '../../common-services/loader.service';
import { PhotoPreviewComponent } from '../../surveys/photo-preview/photo-preview.component';
import { User } from '../../models/user';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';

@Component({
  selector: 'app-mrr-store-final-view-dialog',
  templateUrl: './mrr-store-final-view-dialog.component.html',
  styleUrls: ['./mrr-store-final-view-dialog.component.scss'],
  providers: [
    DrillDownFilterPipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class MrrStoreFinalViewDialogComponent implements OnInit {
  survey: any;

  photos: any;

  finalreport: any;

  user: User;

  uploading: boolean = false;

  faPaperPlane = faPaperPlane;

  faCircleNotch = faCircleNotch;

  faPaperclip = faPaperclip;

  faTimes = faTimes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MrrStoreFinalViewDialogComponent>,
    private csaBackEnd: CsaBackEndService,
    private csaAuth: CsaAuthService,
    private cd: ChangeDetectorRef,
    private loader: LoaderService,
    private zone: NgZone,
    private surveyService : SurveysService
  ) { }

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.survey = this.data.surveyDetails;
    if (this.survey['files']) {
      if (this.survey['files']['final']) this.finalreport = this.data.surveyDetails['files']['final'];
    } else {
      this.photos = [];
    }
  }
 
  closeMe() {
    this.dialogRef.close();
  }

  viewfinalDoc(photoName, question, fileName, showRemove) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.survey['id']
      }-${
        question
      }-${
        this.survey['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        console.log(photoData);
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewfinalDoc(photoName, question, fileName, showRemove);
            }
          });
        } else if (Object.keys(photoData).length > 0) {
          this.zone.run(() => {
            const photoDialog = this.dialog.open(PhotoPreviewComponent, {
              data: {
                photoURL: photoData[photo],
                photoName,
                showRemove,
                documentType: 'Final Report'
              },
              backdropClass: 'dialog-backdrop',
              minWidth: '70%',
              maxWidth: '90vw',
              height: 'auto',
            });

            photoDialog.afterClosed().subscribe((result) => {
              // if (result == "delete")
              //   this.removePhoto(photoData, question, photo);
            });
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
            `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewfinalDoc(photoName, question, fileName, showRemove);
          }
        });
      }
    );
  }
}
