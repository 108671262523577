import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})

export class UniquePipe implements PipeTransform {
  transform(array: any, key: any): any {
    const newArray = [];
    array.forEach((item, index, originalArray) => {
      if (index == 0) {
        newArray.push(item);
      } else {
        const testCase = newArray.every((newItem) => (newItem[key] != item[key]));
        if (testCase) {
          newArray.push(item);
        }
      }
    });
    return newArray;
  }
}
