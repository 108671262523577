/**
 * Returns the organization property name.
 * If the lowercase value of @param {string} roleLevel is 'group', it returns 'groupID', 
 * otherwise it returns the role level in lowercase.
 *
 * @param {string} roleLevel - The role level to determine the property name for.
 * @returns {string} The property name corresponding to the role level.
 */
export function getOrgPropertyName(roleLevel: string): string {
  if (roleLevel?.toLowerCase() === 'group' || roleLevel?.toLowerCase() === 'groupid') {
    return 'groupID';
  } 
  if (roleLevel?.toLowerCase() === 'store' || roleLevel?.toLowerCase() === 'storeid') {
    return 'storeID';
  } 
  return roleLevel?.toLowerCase();
}
