import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum DialogMode {
  Confirmation = 'confirmation', // warning
  Information = 'information',
  Success = 'success',
  Error = 'error',
}

export enum DialogResponse {
  Ok = 'ok',
  Continue = 'continue',
  Cancel = 'cancel',
}

export interface DialogData {
  title: string;
  message: string;
  mode: DialogMode;
  continueText: string;
  cancelText: string;
  okText: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  DialogMode = DialogMode;

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  continue() {
    this.dialogRef.close(DialogResponse.Continue);
  }
  
  cancel() {
    this.dialogRef.close(DialogResponse.Cancel);
  }
  
  ok() {
    this.dialogRef.close(DialogResponse.Ok);
  }
}
