import {
  Component, ElementRef, EventEmitter, OnInit, Inject, ViewChild, Output, ChangeDetectorRef, NgZone 
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
  ParamMap,
  NavigationEnd,
} from '@angular/router';
import {
  faSave,
  faPencilAlt,
  faTimes,
  faPaperPlane,
  faCircleNotch,
  faPaperclip
} from '@fortawesome/free-solid-svg-icons';
import {
  NgForm, NgModel, FormControl, FormGroup, Validators, AbstractControl 
} from '@angular/forms';
import * as moment from 'moment';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import Compressor from 'compressorjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { DrillDownFilterPipe } from '../../custom-pipes/drill-down-filter.pipe';
import { LoaderService } from '../../common-services/loader.service';
import { PhotoPreviewComponent } from '../../surveys/photo-preview/photo-preview.component';
import { User } from '../../models/user';
import { MrrSurveysService } from '../mrr-surveys.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';

@Component({
  selector: 'app-final-report-header',
  templateUrl: './final-report-header.component.html',
  styleUrls: ['./final-report-header.component.scss'],
  providers: [
    DrillDownFilterPipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class FinalReportHeaderComponent implements OnInit {
  @ViewChild('createSurveyForm') createSurveyForm: NgForm;

  mrrForm: FormGroup;

  today = moment();

  survey = {};

  faPaperPlane = faPaperPlane;

  faCircleNotch = faCircleNotch;

  faPaperclip = faPaperclip;

  faTimes = faTimes;

  uploading: boolean = false;

  FinalReports = [];

  response = {};

  user: User;

  ratings = ['Effective', 'Substantially Effective', 'Partially Effective', 'Ineffective', 'Non Rated'];

  finalReportData = {};

  uploadDocument: boolean = false;

  IsFinalReportsUploaded: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<FinalReportHeaderComponent>,
    private csaBackEnd: CsaBackEndService,
    private csaAuth: CsaAuthService,
    private cd: ChangeDetectorRef,
    private loader: LoaderService,
    private zone: NgZone,
    private mrrService : MrrSurveysService,
    private surveyService : SurveysService
  ) { }

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.survey = this.data.surveyDetails;
    this.isFinalReportsAvailable();
    this.response = this.data.finalreport;
    if (this.data.surveyDetails['finalReportDate']) {
      this.survey['finalReportDate'] = moment(this.data.surveyDetails['finalReportDate'], [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
    }

    this.mrrForm = new FormGroup({
      summaryFindings: new FormControl('', [
        Validators.required,
        this.emptyStringValidator.bind(this)
      ]),
      finalReportDate: new FormControl('', [Validators.required]),
      rating: new FormControl('', [Validators.required]),
    });
  }

  /**
   * Cheking that final Report files are available or not
   * this sets the 'FinalReports' array .
   */
  isFinalReportsAvailable():boolean {
    let isFinalReportAvailable :boolean = false;
    if (this.survey['files']) {
      if (this.survey['files']['final']) {
        this.FinalReports = this.data.surveyDetails['files']['final'];
        this.IsFinalReportsUploaded = true;
        // cheking wheteher there is already a final report is added or not 
        if (this.survey['files']['final'].length != 0) {
          isFinalReportAvailable = true;
        }
        if (this.survey['files']['final'].length == 0) {
          isFinalReportAvailable = false;
        }
      }
    } else if (this.FinalReports.length == 0) {
      this.FinalReports = [];
      this.IsFinalReportsUploaded = false;
    }
    return isFinalReportAvailable;
  }

  sendSurvey(form, value) {
    const finalReportDate = moment(value.finalReportDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format();

    this.finalReportData['id'] = this.survey['id'];
    this.finalReportData['rating'] = value.rating;
    this.finalReportData['finalReportDate'] = finalReportDate;
    this.finalReportData['summaryFindings'] = value.summaryFindings;
  
    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(this.finalReportData, ['summaryFindings']);
    this.mrrService.createMRRFinalReport(requestJSONPayload).subscribe((reply) => {
      if (reply['status'] == 200) {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Final Report Saved',
            message:
              'Your Final Report has been saved.',
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        this.dialogRef.close({ type: 'final', finalReport: this.finalReportData });
      } else {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot Save Final Report',
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });

        this.dialogRef.close({ type: 'error', finalReport: [] });
      }
    });
  }

  saveNotes(value) {
    console.log('survey', value, this.survey);
    if (value.summaryFindings) {
      this.survey['summaryFindings'] = value.summaryFindings;
    }
    if (value.rating) {
      this.survey['rating'] = value.rating;
    }

    if (value.finalReportDate) {
      this.survey['finalReportDate'] = moment(value.finalReportDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
    }
  }

  emptyStringValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.trim().length === 0) {
      return { whitespace: true };
    }
    return null;
  }

  specialCharactersValidator(control: AbstractControl): { [key: string]: any } | null {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (control.value && !re.test(control.value)) {
      return { special: true };
    }
    return null;
  }

  closeMe() {
    // this.dialogRef.close();
    this.dialogRef.close({ type: 'auto', finalReport: this.finalReportData });
  }

  /**
   * Showing popup when first finalreport uploading for adding risk level
   * 'isFinalReportAvailable' checking whether the assessment is having any other final report, 
   * to confirm that currently uploaded 'Final report' is the first one or not.
   */
  checkRiskLevelDialogAvailability() {
    this.isFinalReportsAvailable();
    console.log(this.isFinalReportsAvailable(), 'setting up', this.FinalReports.length);
    if (!this.isFinalReportsAvailable() && this.FinalReports.length == 0) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'Risk level per Finding',
          message: 'Please add Risk level per Finding',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
    }
  }

  uploadDoc($event, question, survey) {
    if (this.survey['finalReportDate']
      && this.survey['rating']
      && this.survey['summaryFindings']) {
      this.uploading = true;
      const photoName = $event.target.files[0].name;
      const goodPhotoType = $event.target.files[0].name
        .toLowerCase()
        .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

      const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
      if (goodPhotoType == null) {
        this.displayUploadErrorDoc(
          'This file is not an image or PDF',
          'Please select an image or PDF file to attach.',

        );
      } else if (PDFTest == null) {
        this.compressPhoto($event.target.files[0], question).subscribe(
          (compressedPhoto) => {
            if (typeof compressedPhoto === 'undefined') {
              this.displayUploadErrorDoc(
                'Error Compressing File',
                `Please try a different file or email ${ 
                  this.user['supportEmail']
                } for support`,

              );
            } else if (compressedPhoto['size'] > 10000000) {
              this.displayUploadErrorDoc(
                'This File Is Too Big',
                'Please upload a smaller file',

              );
            } else {
              const action = this.mrrService
                .uploadDocs(
                  compressedPhoto,
                  survey,
                  question,
                  this.survey['storeID'],
                  `${compressedPhoto['size']}`
                )
                .subscribe(
                  (response) => {
                    console.log(response, response[0]['status']);
                    // if (response[0]['status'] === '200') {
                    //   this.checkRiskLevelDialogAvailability();
                    // }
                    if (!response[0] || response[0]['status'] != 200) {
                      let message;
                      if (response[0]['status'] != 200) {
                        message = `There was an error when sending your file. Please email ${ 
                          this.user['supportEmail']
                        } for support`;
                      } else {
                        message = `No response from server. Please email ${ 
                          this.user['supportEmail']
                        } for support`;
                      }
                      this.displayUploadErrorDoc(
                        "Couldn't Add File",
                        message,

                      );
                    } else if (response[0]['message'] == 'OK') {
                      this.uploadDocument = true;
                      this.IsFinalReportsUploaded = true;
                      this.getDocThisQuestion({
                        photoName: response[0]['name'],
                        fileName: response[0]['fileID'],
                        fileSize: compressedPhoto['size'],
                      });
                    }
                    this.checkRiskLevelDialogAvailability();
                  },
                  (error) => {
                    this.displayUploadErrorDoc(
                      "Couldn't Add File",
                      `Please email ${ 
                        this.user['supportEmail']
                      } for support`

                    );
                  }
                );
            }
          }
        );
      } else if ($event.target.files[0]['size'] > 10000000) {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'This File Is Too Big',
            message: 'Please upload a smaller file',
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
        setTimeout(() => {
          this.uploading = false;
        }, 10);
      } else {
        const action = this.mrrService
          .uploadDocs(
            $event.target.files[0],
            survey,
            question,
            this.survey['storeID'],
            $event.target.files[0]['size']
          )
          .subscribe(
            (response) => {
              // if (response[0]['status'] == 200) {
              //   this.checkRiskLevelDialogAvailability();
              // }
              if (!response[0] || response[0]['status'] != 200) {
                let message;
                if (response[0]['status'] != 200) {
                  message = `There was an error when sending your file. Please email ${ 
                    this.user['supportEmail']
                  } for support`;
                } else {
                  message = `No response from server. Please email ${ 
                    this.user['supportEmail']
                  } for support`;
                }
                this.displayUploadErrorDoc("Couldn't Add File", message);
              } else {
                this.uploadDocument = true;
                this.IsFinalReportsUploaded = true;
                this.getDocThisQuestion({
                  photoName: response[0]['name'],
                  fileName: response[0]['fileID'],
                  fileSize: $event.target.files[0]['size'],
                });
                this.cd.detectChanges();
                this.checkRiskLevelDialogAvailability();
              }
            },
            (error) => {
              this.displayUploadErrorDoc(
                "Couldn't Add File",
                `Please email ${
                  this.user['supportEmail']
                } for support`,

              );
            }
          );
      }
    } else {
      this.displayUploadErrorDoc(
        "Couldn't Add File",
        'Please complete all the mandatory fields to upload the Final Report',
      );
    }
  }

  displayUploadErrorDoc(heading, message) {
    this.dialog.open(MessagesComponent, {
      data: {
        heading,
        message,
        closeText: 'Ok',
      },
      backdropClass: 'dialog-backdrop',
    });
    setTimeout(() => {
      this.uploading = false;
    }, 10);
  }

  compressPhoto(file, question) {
    const emitter = new EventEmitter<Blob>();

    const compression = new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: 1000000,
      success(result) {
        emitter.emit(result);
      },
      error(err) {
        emitter.emit();
      },
    });
    return emitter.asObservable();
  }

  getDocThisQuestion(FinalReport) {
    setTimeout(() => {
      // if(!this.surey['files']){
      //   if(!this.survey['files']['final'] )
      //   this.FinalReports = [];
      // }
      this.FinalReports.push(FinalReport);
      console.log('dinalreporyt added', this.FinalReports);
      this.uploading = false;
    }, 10);

    this.cd.detectChanges();
  }

  viewfinalDoc(photoName, question, fileName, showRemove) {
    let FinalReport = '';

    if (fileName) {
      FinalReport = fileName;
    } else {
      FinalReport = `${this.survey['id']
      }-${
        question
      }-${
        this.survey['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${FinalReport}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        console.log(photoData);
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewfinalDoc(photoName, question, fileName, showRemove);
            }
          });
        } else if (Object.keys(photoData).length > 0) {
          this.zone.run(() => {
            const photoDialog = this.dialog.open(PhotoPreviewComponent, {
              data: {
                photoURL: photoData[FinalReport],
                photoName,
                showRemove,
                documentType: 'Final Report'
              },
              backdropClass: 'dialog-backdrop',
              minWidth: '70%',
              maxWidth: '90vw',
              height: 'auto',
            });

            photoDialog.afterClosed().subscribe((result) => {
              if (result == 'delete') this.removePhoto(photoData, question, FinalReport);
              this.isFinalReportsAvailable();
            });
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
            `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewfinalDoc(photoName, question, fileName, showRemove);
          }
        });
      }
    );
  }

  removePhoto(src, question, FinalReport) {
    this.mrrService
      .removeDoc(FinalReport, src, this.survey['id'], question)
      .subscribe(
        (response) => {
          this.uploadDocument = true;
          this.removePhotoFromQ(FinalReport);
          this.cd.detectChanges();
        },
        (error) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Remove File",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
  }

  removePhotoFromQ(fileName) {
    for (let j = 0; j < this.FinalReports.length; j++) {
      if (
        this.FinalReports[j]['photoName'] == fileName
        || this.FinalReports[j]['fileName'] == fileName
      ) {
        this.FinalReports.splice(j, 1);
        setTimeout(() => {
          this.uploading = false;
        }, 10);
      }
    }
  }
}
