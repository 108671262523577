/**
 * For each object item of @param data its property @param propertyName value is assigned the new value @param newPropertyValue 
 * when it's lowercase value matches @param matchingPropertyValue.
 * The object ia changed by reference. In addition the object is returned.
 *
 * @param data - A untyped object array.
 * @param propertyName - The property name of object @param data to be assigned the value @param newPropertyValue when a match is found.
 * @param matchingPropertyValue - The value to match against nominated property @param propertyName.
 * @param newPropertyValue - The value to be assigned to the matching property.
 */
export function reassignArrayPropertyValues(
  data: object[], 
  propertyName: string, 
  matchingPropertyValue: string, 
  newPropertyValue: string
): object[] {
  data?.forEach((item) => {
    reassignPropertyValue(
      item, 
      propertyName, 
      matchingPropertyValue, 
      newPropertyValue
    );
  });
  return data;    
}

/**
 * For the object @param data its property @param propertyName value is assigned the new value @param newPropertyValue 
 * when it's lowercase value matches @param matchingPropertyValue.
 * The object ia changed by reference. In addition the object is returned.
 *
 * @param data - A untyped object.
 * @param propertyName - The property name of object @param data to be assigned the value @param newPropertyValue when a match is found.
 * @param matchingPropertyValue - The value to match against nominated property @param propertyName.
 * @param newPropertyValue - The value to be assigned to the matching property.
 */
export function reassignPropertyValue(
  data: object, 
  propertyName: string, 
  matchingPropertyValue: string, 
  newPropertyValue: string
): object {
  if (data[propertyName]?.toLowerCase() === matchingPropertyValue) {
    data[propertyName] = newPropertyValue;
  }
  return data;    
}
