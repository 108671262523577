import { DialogComponent, DialogData, DialogMode } from 'src/app/shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getUserMessageFromHttpErrorResponse } from '../handle-error/handle-error';

/**
 * Displays an error dialog using the Angular Material MatDialog.
 *
 * @param {MatDialog} dialog - The MatDialog service used to open the error dialog.
 * @param {any} error - The error object or message to be displayed in the dialog.
 * @param {string} title - The title of the error dialog.
 * @returns {void}
 * @example
 * // Example usage:
 * showErrorDialog(matDialog, 'An error occurred!', 'Error');
 */
export function showErrorDialog(dialog: MatDialog, error: any, title: string) {
  dialog.open(DialogComponent, {
    data: <DialogData>{
      title,
      message: getUserMessageFromHttpErrorResponse(error),
      mode: DialogMode.Error,
    },
    disableClose: true,
    backdropClass: 'dialog-backdrop',
  });    
}
