import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { convertToNumber } from 'src/app/utils/convert-to-number/convert-to-number';
import { isObjectEmpty } from 'src/app/utils/is-object-empty/is-object-empty';
import { IValidatedBulkValues, validateBulkValues } from 'src/app/utils/validate-bulk-values/validate-bulk-values';

/**
 * Component for bulk input of numbers with validation.
 * 
 * This component allows users to input multiple numbers, separated by spaces or commas,
 * validates them against specified constraints, and emits the validated numbers.
 */
@Component({
  selector: 'app-bulk-number-input',
  templateUrl: './bulk-number-input.component.html',
  styleUrls: ['./bulk-number-input.component.scss']
})
export class BulkNumberInputComponent implements OnInit {
  /**
   * List of allowed numbers for validation.
   */
  @Input() allowedNumbers: number[] = [];

  /**
   * Maximum number of digits allowed per number during validation. Any number is accepted when it's undefined.
   */
  @Input() digitLimit: number;

  /**
   * Sets an error message when validation fails.
   *
   * Error messages are wrapped in new objects by the host to ensure Angular detects changes,
   * even when the message content remains unchanged.
   *
   * Note: Internal error messages are cleared when input data changes, causing them to become
   * unsynced from the host's bound error message. This means any update by the host must trigger
   * a new object assignment to be detected properly.
   *
   * @param {{ message: string }} value - Error message object containing the validation message.
   */
  @Input() 
  set errorMessage(value: { message: string }) {
    this._errorMessage = isObjectEmpty(value) ? '' : value.message;
  }

  /**
   * Internal storage for the error message.
   */
  _errorMessage = '';

  /**
   * Label for the input field.
   */
  @Input() label = '';

  /**
   * Placeholder text for the input field.
   */
  @Input() placeholder = '';

  /**
   * Accessibility label for screen readers.
   */
  @Input() accessibilityLabel = '';

  /**
   * Event emitter for validated bulk input values.
   */
  @Output() validatedBulkInput = new EventEmitter<IValidatedBulkValues<number>>();

  // Regex for allowing numbers, spaces, and commas.
  numberInputRegex = /^[0-9\s,]*$/;

  // Regex for validating individual numbers based on digit limit.
  validNumberRegexInput: RegExp;

  /**
   * Raw input string bound to the displayed text input.
   */
  rawBulkNumbers = '';

  constructor() { }

  /**
   * Initializes the component and sets up regex validation based on digit limit.
   */
  ngOnInit(): void {
    // Regex for numbers with a length og digitLimit when defined
    this.validNumberRegexInput = this.digitLimit ? new RegExp(`^[0-9]{${this.digitLimit}}$`) : /^[0-9]+$/;
  }

  /**
   * Clears the bulk number input field.
   */
  resetBulkNumberInput() {
    this.rawBulkNumbers = '';
  }

  /**
   * Handles paste events by clearing the error message.
   */  
  onPaste(): void {
    // TODO: Invalid characters can be pasted. Come up with a solution or leave for onEnter to process.
    this.clearErrorMessage();
  }

  /**
   * Clears the stored error message.
   */
  clearErrorMessage() {
    this._errorMessage = '';
  }

  /**
   * Processes bulk numbers when Enter is pressed.
   */
  onEnter() {
    this.processRawBulkNumbers(this.rawBulkNumbers);
  }

  /**
   * Parses and validates the raw bulk numbers input.
   *
   * @param rawBulkNumbers - The input string containing numbers separated by spaces or commas.
   */
  processRawBulkNumbers(rawBulkNumbers: string) {
    if (rawBulkNumbers.length > 0) {
      const numbers = rawBulkNumbers.split(/[\s,]+/).map((number) => convertToNumber(number));
      const validatedBulkValues = validateBulkValues<number>(numbers, this.validNumberRegexInput, this.allowedNumbers);
      this.validatedBulkInput.emit(validatedBulkValues);
      this.resetBulkNumberInput();
    }
  }

  /**
   * Restricts input to numbers, spaces, and commas, and clears error messages on valid input.
   *
   * @param event - The keyboard event.
   */
  restrictBulkNumberInput(event: KeyboardEvent): void {
    if (!this.numberInputRegex.test(event.key)) {
      // Prevent the input if it doesn't match a number, comma or space
      event.preventDefault(); 
    }
    if (event.key.toLowerCase() !== 'enter') {   
      this.clearErrorMessage();
    }
  }
}
