/**
 * The list of panels available in the admin landing component.
 */
export enum adminLandingPanels {
    onceOffAssessments,
    adhocAssessments,
    recurringAssessments,
    openActions,
    templates,
    MRRAssessments,
    MRRActions
}
