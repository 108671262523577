<div class="page-container">

  <h1>{{action['title'] | unescape}}</h1>

  <div class="back-link" (click)="backClicked()">
    <fa-icon [icon]="faArrowLeft"></fa-icon> Go Back
  </div>

  <h2 class="left-align">Action Details:</h2>

  <div class="audit-tool-cards-container">
    <div class="p2 audit-tool-card list-screen-card ">
      <div class="question-options-row no-bottom-margin">
        <div class="survey-detail-block">
          <div *ngIf="user['role'] !== 'Store' && user['role'] !== 'MultiStore'">
            <h4>Status:</h4>
            <h4 class="red-text medium-text" *ngIf="action['status'] === 'pending' && dateToday > dueDate"
              aria-hidden="false">Overdue</h4>
            <h4 class="orange-text medium-text" *ngIf="action['status'] === 'pending'  && dateToday <= dueDate"
              aria-hidden="false">Pending</h4>
            <h4 class="light-green medium-text" *ngIf="action['status'] === 'complete'" aria-hidden="false">Complete</h4>
          </div>
          <div class="actions-check survey-button" *ngIf="user['role'] === 'Store' || user['role'] === 'MultiStore'">
            <h4>Mark as Complete:</h4>
            <a (click)="checkToggle()" *ngIf="!(action['checkEnabled'] == false)">
              <mat-icon class="action-check-box" *ngIf="action['status'] === 'pending'" aria-hidden="false">
                check_box_outline_blank</mat-icon>
              <mat-icon class="action-check-box" *ngIf="action['status'] === 'complete'" aria-hidden="false">check_box
              </mat-icon>
            </a>
            <span *ngIf="(action['checkEnabled'] == false)">
              <mat-icon class="light-grey action-check-box" aria-hidden="false">check_box</mat-icon>
            </span>
          </div>
          <h4 class="light-green"
            *ngIf="(action['checkEnabled'] == true) && (user['role'] === 'Store' || user['role'] === 'MultiStore')">
            You can open this action within 24hrs of closing it
          </h4>
          <h4 class="light-grey"
            *ngIf="(action['checkEnabled'] == false) && (user['role'] === 'Store' || user['role'] === 'MultiStore')">
            You can no longer open this action
          </h4>
        </div>
        <div class="survey-detail-block">
          <h4>Assessment:</h4>
          <p>{{action['surveyTitle'] | unescape}}</p>
        </div>
      </div>
      <div class="question-options-row no-top-margin">
        <div class="survey-detail-block">
          <h4>Created By:</h4>
          <p>{{action['createdBy'] | unescape}}</p>
        </div>
        <div class="survey-detail-block">
          <h4>Due Date:</h4>
          <p *ngIf="!editDueDate">
            {{ action['dueDate'] | date :'dd MMM y'  }} 
            <a *ngIf="(dateChanges.length < 2) && (dueDate > dateToday) && (action['status'] === 'pending') && (user['role'] === 'Store' || user['role'] === 'MultiStore')"
              (click)="editDueDate = true;">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </a>
          </p>
          <form *ngIf="editDueDate" class="wide-form" #dueDateForm="ngForm"
            (submit)="submitForm2(dueDateForm, dueDateForm.value)">
            <mat-form-field class="right-margin date-field">
              <mat-label>Due Date</mat-label>
              <input name="dueDate" [min]="minDate" [max]="maxDate" ngModel matInput [matDatepicker]="picker" required
                (click)="picker.open()" (keydown)="false">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi="true" #picker></mat-datepicker>
              <mat-error>Please select a date from today onwards</mat-error>
            </mat-form-field>
            <h2 class="smaller-text">Note - Due Date can only be changed twice</h2>
            <button [disabled]="!dueDateForm.valid" class="csa-button-solid survey-submit" type="submit">Save</button>
            <button class="csa-button-outline" (click)="editDueDate = false;">Cancel</button>
          </form>
        </div>
      </div>
      <div class="question-options-row no-top-margin">
        <div class="survey-detail-block" *ngIf="action['questionShortTitle']">
          <h4>Control Name:</h4>
          <p>{{action['questionShortTitle']| unescape}}</p>
        </div>
      </div>
      <div class="question-options-row no-top-margin">
        <div class="p2">
          <h4>Question:</h4>
          <div [innerHTML]="action['questionTitle'] | unescape"></div>
          <!-- <p>{{action['questionTitle'].slice(0, 600) | unescape}}<span *ngIf="action['questionTitle'].length > 600">...</span></p> -->
        </div>
      </div>
      <div class="question-options-row no-top-margin">
        <div class="p2" *ngIf="action['description']">
          <h4>Root Cause:</h4>
          <p>{{action['description'] | unescape}}</p>
        </div>
        <div class="p2" *ngIf="action['stepsToResolve']">
          <h4>Steps to Resolve:</h4>
          <p>{{action['stepsToResolve'] | unescape}}</p>
        </div>
      </div>
      <div class="question-options-row no-top-margin">
        <div class="p2" *ngIf="action['issueTracking']">
          <h4>Measure of Progress:</h4>
          <p>{{action['issueTracking'] | unescape}}</p>
        </div>
        <div class="p2" *ngIf="action['personResponsible']">
          <h4>Who is Responsible:</h4>
          <p>{{action['personResponsible'] | unescape}}</p>
        </div>
      </div>
    </div>
  </div>


  <h2 *ngIf="worknotes.length > 0 || dateChanges.length > 0" class="left-align">Updates:</h2>

  <div class="audit-tool-cards-container">
    <div *ngFor="let update of dateChanges" class="list-screen-card audit-tool-card p2">
      <h5>{{ update['addedOn'] | date :'dd MMM y'  }}  </h5>
      <p><b>By: </b>{{ update['addedBy'] }}</p>
      <div [innerHTML]="update['note']"></div>
    </div>
  </div>

  <div class="audit-tool-cards-container">
    <div *ngFor="let update of worknotes; let i = index;" class="list-screen-card audit-tool-card p2">
      <h5>{{ update['addedOn'] | date :'dd MMM y'  }}  </h5>
      <p><b>By: </b>{{ update['addedBy'] }}</p>
      <div class="audit-tool-cards-container">
        <span class="todo-text">{{update['notes'] | unescape}}</span>
        <div class="action-line" *ngFor="let photo of update['photos'], let j = index;">
          <div class="file-name">{{photo['photoName']?.slice(0, 25)}}<span
              *ngIf="photo['photoName']?.length > 25">...</span></div>
          <div class="right-align hide-on-mobile">{{photo['fileSize'] | filesize}}</div>
          <div class="right-align">
            <a (click)="
              this.user['divisionRoles'][this.user['role']] 
                > this.user['divisionRoles']['MultiStore'] 
                ? getAllPhotos(i, j) 
                : viewPhoto(
                    photo['photoName'], 
                    action['questionID'], 
                    photo['fileName'], 
                    false
                  )
              ">
              View
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h2
    *ngIf="(worknotes.length < 5) && (action['status'] === 'pending') && (user['role'] === 'Store' || user['role'] === 'MultiStore')"
    class="left-align">Add Update: <span class="smaller-text">Maximum of 5</span></h2>
  <div
    *ngIf="worknotes.length < 5 && (action['status'] === 'pending') && (user['role'] === 'Store' || user['role'] === 'MultiStore')"
    class="audit-tool-cards-container">
    <div class="p2 list-screen-card audit-tool-card question-options-row">
      <form class="wide-form" #actionNotesForm="ngForm">
        <div class="audit-tool-cards-container survey-container">
          <mat-form-field appearance="outline" color="primary">
            <mat-label for="noteSection">Update Notes</mat-label>
            <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput #noteSection
              maxlength="300" ngModel class="custom-control-input" name="noteSection"></textarea>
            <mat-hint align="end">{{noteSection.value?.length || 0}}/300</mat-hint>
          </mat-form-field>

          <div class="action-line" *ngFor="let photo of photos; let i = index">
            <div class="file-name">{{photo['photoName']?.slice(0, 25)}}<span
                *ngIf="photo['photoName']?.length > 25">...</span></div>
            <div class="right-align hide-on-mobile">{{photo['fileSize'] | filesize}}</div>
            <div class="right-align">
              <a (click)="
                this.user['divisionRoles'][this.user['role']] 
                  > this.user['divisionRoles']['MultiStore'] 
                  ? getAllPhotos(0, i, actionNotesForm.value)
                  : viewPhoto(
                      photo['photoName'], 
                      action['questionID'], 
                      photo['fileName'], 
                      true
                    )
              ">
                View
              </a>
            </div>
          </div>

          <div class="action-line" *ngIf="uploading == true">
            <div class="light-blue">Evidence Uploading...</div>
            <div class="light-blue right-align"><span class="padded-icon">
                <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
              </span></div>
          </div>
          <br>
        </div>

        <div class="question-options-row">
          <div class="filter-chips">
            <div class="chipDrop">
              <mat-chip-list aria-label="Filters">
                <mat-chip color="primary" selected [disabled]="photos?.length >= 1" (click)="uploader.click()">
                  <fa-icon  class="chip-left-icon" *ngIf="uploading == true" [icon]="faCircleNotch" [spin]="true"></fa-icon>
                  <fa-icon  class="chip-left-icon" *ngIf="uploading == false" [icon]="faCamera"></fa-icon>
                  <span>
                    Attach Evidence
                  </span>
                  <input hidden type="file" #uploader (click)="uploader.value = null"
                    (change)="uploadPhoto($event, action['questionID'], action['surveyID'])" />
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="chipDrop">
              <mat-chip-list aria-label="Filters">
                <mat-chip color="primary" selected [disabled]="!noteSection.value || uploading"
                  (click)="submitForm1(actionNotesForm, actionNotesForm.value)" type="submit">
                  <fa-icon class="chip-left-icon" [icon]="faPaperPlane"></fa-icon>
                  <span>
                    Add Update
                  </span>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>