import { Dictionary } from 'src/app/types/dictionary';

/**
 * Sets up organizational locations and related properties based on the provided user organization structure.
 *
 * @param {object} orgStruct - An object containing the user organization structure.
 * @param {Dictionary<string, string>} selectedLocations - Selected locations, initialized as empty for each organization node e.g. State, zone etc.
 * @param {Dictionary<string, boolean>} showLocations - An object to store visibility status of locations, initialized as false for each organization node e.g. State, zone etc.
 * @param {string[]} locationsOrdered - An array to store the ordered list of locations i.e. Organization nodes sorted by organization structure.
 * @returns {void} - Modifies the provided output parameters to reflect the initialized organizational locations.
 * @example
 * // setup org locations for:
 * const orgStructure = {division: 'state', state: 'zone', zone: 'groupID', groupID: 'storeID'}
 * let selectedLocations = {};
 * let showLocations = {};
 * const locationsOrdered = [];
 * setupOrgLocations(orgStructure, selectedLocations, showLocations, locationsOrdered);
 * console.log(selectedLocations); // Output: { state: '', zone: '', groupID: '' }
 * console.log(showLocations); // Output: { state: false, zone: false, groupID: false }
 * console.log(locationsOrdered); // Output: ['state', 'zone', 'groupID']
 */
export function setupOrgLocations(orgStruct: object, selectedLocations: Dictionary<string, string>, showLocations: Dictionary<string, boolean>, locationsOrdered: string[]) {
  let key = orgStruct['division'];

  // Iterate through the organization structure
  while (orgStruct[key]) {
    // Initialize selected location and visibility status for each node
    selectedLocations[key] = '';
    showLocations[key] = false;

    // If the location is not already in the ordered list, add it
    if (locationsOrdered.indexOf(key) === -1) {
      locationsOrdered.push(key);
    }
    // Move to the next organization node
    key = orgStruct[key]; // get next org node
  }        
}
