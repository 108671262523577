import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numKeys'
})
export class NumKeysPipe implements PipeTransform {
  transform(value: object, orgStructure?: object): any {
    const orderedList = [];
    const keys = Object.keys(value);
    if (orgStructure) {
      const orgArray = [];
      Object.keys(orgStructure).forEach((orgGroup) => {
        orgArray.push(orgStructure[orgGroup]);
      });
      orgArray.forEach((orgLevel) => {
        keys.forEach((key) => {
          if (key == orgLevel) {
            orderedList.push(key);
          }
        });
      });
      return orderedList;
    }
    return keys;
  }
}
