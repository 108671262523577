import {
  Component, ElementRef, EventEmitter, OnInit, Inject, ViewChild, Output, ChangeDetectorRef, NgZone 
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Router,
  ParamMap,
  NavigationEnd,
} from '@angular/router';
import {
  faTimes,
  faPaperPlane,
  faCircleNotch,
  faPaperclip,
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons';
import {
  NgForm, NgModel, FormControl, FormGroup, Validators, AbstractControl 
} from '@angular/forms';
import * as moment from 'moment';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import Compressor from 'compressorjs';
import { DrillDownFilterPipe } from '../../custom-pipes/drill-down-filter.pipe';
import { LoaderService } from '../../common-services/loader.service';
import { PhotoPreviewComponent } from '../../surveys/photo-preview/photo-preview.component';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { RoleFieldToDisplayStringPipe } from '../../custom-pipes/roleFieldToDisplayString.pipe';

@Component({
  selector: 'app-mrr-store-signoff-comments-dialog',
  templateUrl: './mrr-store-signoff-comments-dialog.component.html',
  styleUrls: ['./mrr-store-signoff-comments-dialog.component.scss'],
  providers: [DrillDownFilterPipe,
  // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
  // `MatMomentDateModule` in your applications root module. We provide it at the component level
  // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class MrrStoreSignoffCommentsDialogComponent implements OnInit {
  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  faCircleNotch = faCircleNotch;

  faPaperPlane = faPaperPlane;

  faTimes = faTimes;

  surveyDetails: any;

  photos: any;

  finalreport: any;

  user: object;

  uploading: boolean = false;

  allExpandState = true;

  openItems = [];

  status: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MrrStoreSignoffCommentsDialogComponent>,
    private csaBackEnd: CsaBackEndService,
    private csaAuth: CsaAuthService,
    private cd: ChangeDetectorRef,
    private loader: LoaderService,
    private zone: NgZone,
    private RoleFieldToDisplayStringPipe : RoleFieldToDisplayStringPipe
  ) { }

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.status = this.data.surveyDetails['status'];
    this.surveyDetails = this.data.surveyDetails['signOff']['details'];
  }
 
  closeMe() {
    this.dialogRef.close();
  }

  setOpened(itemIndex) {
    this.openItems.push(itemIndex);
  }

  setClosed(itemIndex) {
    for (let i = 0; i < this.openItems.length; i++) {
      if (this.openItems[i] === itemIndex) {
        this.openItems.splice(i, 1);
      }
    }
  }
}
