import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree 
} from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { CsaAuthService } from '../auth/csa-auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleBasedRoutingGuard implements CanActivate {
  /**
   * To redirect a route in the application to another based on the user's role, use this guard.
   * This guard checks for data on the route with the key 'aboveStoreRoute', 'adminroute' or 'storeRoute'. 
   */

  constructor(private authService: CsaAuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.userChanges$().pipe(
      take(1),
      map((userData) => {
        if (!userData) {
          // be default, just let the user go to their original route
          // this is not an auth guard.
          return true;
        }

        const params = [];

        for (const key in route.params) {
          params.push(route.params[key]);
        }

        if (route.data['adminRoute'] && this.authService.isUserAdmin()) {
          this.router.navigate([route.data['adminRoute'], ...params]);
          return false;
        }

        if (route.data['aboveStoreRoute'] && this.authService.isUserAboveStoreRole() && !this.authService.isUserAdmin()) {
          this.router.navigate([route.data['aboveStoreRoute'], ...params]);
          return false;
        }

        if (route.data['storeRoute'] && this.authService.isUserStoreRole()) {
          this.router.navigate([route.data['storeRoute'], ...params]);
          return false;
        }

        return true;
      })
    );
  }
}
