<div class="page-container">
  <div class="audit-tool-cards-container">


    <div *ngIf="errorType !== 'unauthorised'" class="audit-tool-card">
      <h2>Sorry, we can't find this page. Please go back to your landing page to view your Surveys and Actions.</h2>
      <h1><a [routerLink]="['/']">Go Back</a></h1>
    </div>
    <div *ngIf="errorType === 'unauthorised'" class="audit-tool-card">
      <h2>You don't have permission to see this page. Please contact the iVerify Admin team to gain access</h2>
      <h1><a [routerLink]="['/']">Go Back</a></h1>
    </div>

  </div>


</div>
