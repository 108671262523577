import { UserV2 } from 'src/app/access-management/models/user';
import { mockRoleListV2 } from './role-data.mock';

export const mockUserList: object = {
  userList: [
    {
      email: 'divya.parthiban@gmail.com',
      firstName: 'Divya',
      lastName: 'Parthiban',
      name: 'Divya Parthiban',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: 1,
      roleName: 'Admin',
      locationName: '',
      positionTitle: 'National Operations',
      state: '',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'gsarangan@tcs.woolworths.com.au',
      firstName: 'Gautaman',
      lastName: 'Sarangan',
      name: 'Gautaman Sarangan',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: 2,
      roleName: 'StateManager',
      locationName: '',
      positionTitle: 'For SLM testing',
      state: 'NSW/ACT',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'iverify-bigw-area1@test.bigw.com.au',
      firstName: 'BigW',
      lastName: 'Multistore',
      name: 'BigW Multistore',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: '',
      roleName: 'MultiStore',
      locationName: '',
      positionTitle: '',
      state: '',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'iverify-nzadmin3@test.countdown.co.nz',
      firstName: '\t\r\niVerify',
      lastName: '\t\r\nNZAdmin',
      name: '\t\r\niVerify \t\r\nNZAdmin',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: 1,
      roleName: 'Admin',
      locationName: '',
      positionTitle: 'State Admin Manager',
      state: '',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'iverify-nzadmin@test.countdown.co.nz',
      firstName: 'Store Loss Managers',
      lastName: 'QLD state',
      name: 'Store Loss Managers QLD state',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: 8,
      roleName: 'StoreLossManager',
      locationName: '',
      positionTitle: 'Store Loss Manager',
      state: 'QLD',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'iverify-nzstockloss1@test.countdown.co.nz',
      firstName: 'Countdown',
      lastName: 'StockLossManager',
      name: 'Countdown StockLossManager',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: 8,
      roleName: 'StoreLossManager',
      locationName: '',
      positionTitle: 'Stock Loss Manager',
      state: 'NSW/ACT',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'iverify1183@test-woolworthsgroup.com.au',
      firstName: 'Multi ',
      lastName: 'Store User',
      name: 'Multi  Store User',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: '',
      roleName: 'MultiStore',
      locationName: '',
      positionTitle: '',
      state: '',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'iverify1205@test-woolworthsgroup.com.au',
      firstName: 'Super',
      lastName: 'Test',
      name: 'Super Test',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: 3,
      roleName: 'Super',
      locationName: '',
      positionTitle: '',
      state: '',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'iverify1864@test-woolworthsgroup.com.au',
      firstName: 'Nation',
      lastName: 'view',
      name: 'Nation view',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: 5,
      roleName: 'NationalView',
      locationName: '',
      positionTitle: 'National View',
      state: '',
      altState: '',
      zone: '',
      groupNumber: ''
    },
    {
      email: 'iverifyadmin-firebase@test-woolworthsgroup.com.au',
      firstName: 'iverifyadmin firebase',
      lastName: 'test',
      name: 'iverifyadmin firebase test',
      division: 'Supermarkets',
      storeNumber: '',
      roleId: 1,
      roleName: 'Admin',
      locationName: '',
      positionTitle: 'SAM-SA/NT/WA',
      state: '',
      altState: '',
      zone: '',
      groupNumber: ''
    }
  ],
  hasNext: 'Y'
};

export const mockUser: object = {
  appUserID: 'FVxHHEG7SOeke3RNtxbtG58NjhB3',
  area: '',
  division: 'Supermarkets',
  divisionRoles: {
    Admin: 9,
    GroupManager: 5,
    MultiStore: 2,
    NationalView: 8,
    NoRole: 0,
    OperationManager: 6,
    StateManager: 7,
    Store: 1,
    StoreLossManager: 4,
    StoreLossPartner: 3,
    Super: 10,
    Support: 11
  },
  divisionRolesV2: mockRoleListV2,
  domain: 'Woolworths',
  email: 'iverify1143@test-woolworthsgroup.com.au',
  firstName: 'Support',
  geographics: '',
  groupNo: '',
  lastName: 'User',
  orgStruct: {
    division: 'state',
    groupID: 'storeID',
    state: 'zone',
    zone: 'groupID'
  },
  positionTitle: '',
  region: '',
  role: 'Admin',
  roleId: '1',
  state: '',
  storeID: '',
  storeName: '',
  userOrgStruct: {
    division: 'Supermarkets'
  },
  zone: '',
  supportEmail: 'https://woolworths.my/medallia',
  support: true
};

export const mockUserResponse: object = {
  ALT_STATE: '',
  DIVISION: 'Supermarkets',
  EMAIL_ADDRESS: 'test@test-woolworthsgroup.com.au',
  FIRST_NAME: 'Joe',
  GROUP_NO: '441',
  LAST_NAME: 'Bloggs',
  LOCATION_NAME: '',
  POSITION_TITLE: 'Group Manager',
  ROLE: 'GroupManager',
  ROLE_ID: '7',
  STATE: 'NSW/ACT',
  STORE_NO: '',
  ZONE: '4'
};

export const mockUserV2: UserV2 = {
  email: 'divya.parthiban@gmail.com',
  firstName: 'Divya',
  lastName: 'Parthiban',
  name: 'Divya Parthiban',
  division: 'Supermarkets',
  storeNumber: '',
  roleId: '1',
  roleName: 'Admin',
  locationName: '',
  positionTitle: 'National Operations',
  description: '',
  state: '',
  altState: '',
  zone: '',
  groupNumber: ''
};

export const mockUserTestResponse: object = {
  area: '',
  division: 'Supermarkets',
  divisionRoles: {
    Admin: 9,
    GroupManager: 5,
    MultiStore: 2,
    NationalView: 8,
    NoRole: 0,
    OperationManager: 6,
    StateManager: 7,
    Store: 1,
    StoreLossManager: 4,
    StoreLossPartner: 3,
    Super: 10,
    Support: 11
  },
  divisionRolesV2: [
    {
      id: 1,
      isProtected: 'Y',
      levelId: 1,
      levelName: 'Global',
      name: 'Admin'
    },
    {
      id: 3,
      isProtected: 'Y',
      levelId: 1,
      levelName: 'Global',
      name: 'Super'
    },
    {
      id: 4,
      isProtected: 'Y',
      levelId: 1,
      levelName: 'Global',
      name: 'Support'
    },
    {
      id: 88,
      isProtected: 'Y',
      levelId: 5,
      levelName: 'Store',
      name: 'Store'
    },
    {
      id: 32,
      isProtected: 'N',
      levelId: 1,
      levelName: 'Global',
      name: 'MultiStore'
    },
    {
      id: 5,
      isProtected: 'N',
      levelId: 1,
      levelName: 'Global',
      name: 'NationalView'
    },
    {
      id: 9,
      isProtected: 'N',
      levelId: 1,
      levelName: 'Global',
      name: 'StoreLossPartner'
    },
    {
      id: 2,
      isProtected: 'N',
      levelId: 2,
      levelName: 'State',
      name: 'StateManager'
    },
    {
      id: 8,
      isProtected: 'N',
      levelId: 2,
      levelName: 'State',
      name: 'StoreLossManager'
    },
    {
      id: 6,
      isProtected: 'N',
      levelId: 3,
      levelName: 'Zone',
      name: 'OperationManager'
    },
    {
      id: 7,
      isProtected: 'N',
      levelId: 4,
      levelName: 'Group',
      name: 'GroupManager'
    },
    {
      id: 87,
      isProtected: 'N',
      levelId: 5,
      levelName: 'Store',
      name: 'Test Store Role'
    }
  ],
  domain: 'Woolworths',
  email: 'iverify1143@test-woolworthsgroup.com.au',
  firstName: 'Support',
  geographics: '',
  groupNo: '',
  lastName: 'User',
  orgStruct: {
    division: 'state',
    groupID: 'storeID',
    state: 'zone',
    zone: 'groupID'
  },
  positionTitle: '',
  region: '',
  role: 'Support',
  state: '',
  storeID: '',
  storeName: '',
  userOrgStruct: {
    division: 'Supermarkets'
  },
  zone: ''
};

export const mockUserFeatures: string[] = [
  // 'developerHandbook.view',
  // Navigation
  'navigation.canDisableAutoLogout',
  'navigation.canAccessAsOtherRoles',
  'navigation.canAccessAsOtherRoles',
  'navigation.canAccessAsOtherDivisions',

  // To Do
  'storeToDoList.view',

  // Dashboard
  'dashboard.view',

  // Assessments
  'recurringAssessments.view',
  
  // Adhoc Assessments
  'adhocAssessments.view',
  'adhocAssessments.viewAllAvailable',
  'adhocAssessments.viewAllUpcoming',
  'adhocAssessments.viewAllClosed',
  'adhocAssessments.viewAssigned',
  'adhocAssessments.viewAssignedGPSOnly',
  'adhocAssessments.viewOthersAssessments',
  'adhocAssessments.viewOthersAssessmentsGPSOnly',
  'adhocAssessments.viewAssignedClosedAssessmentsGPSOnly',
  'adhocAssessments.viewCreateNewAssessment',
  'adhocAssessments.viewAllAdhocAssessments',
  // Adhoc Assessments Detail
  'adhocAssessments.viewDetails',
  'adhocAssessments.cancelOrCloseAssessment',
  'templates.viewDetails',
  'adhocAssessments.editSurveyDetailsBeforePublishDate',  
  // Actions
  'mrrActions.view',
  'mrrActions.delete',
  'mrrActions.save',
  // Templates
  'templates.view',
  // Reports
  'reports.view',
  // Evidence
  'standardEvidence.view',
  'mrrEvidence.view',

  // Manage Stores
  'manageStores.view',

  // Access & Roles
  'accessManagement.viewUsers',
  'accessManagement.viewRoles',
  'accessManagement.viewFeatures',
  'accessManagement.viewPermissions',
  'accessManagement.viewFeatureMapping',

  // Quick Links
  'charts.view', 
  'adhocAssessments.create', 
  'onceOffAssessments.create', 
  'recurringAssessments.create', 
  'mrrAssessments.create',

  // Dashboards
  'dashboard.onceOffAssessmentsPanel_admin',
  'dashboard.adhocAssessmentsPanel_admin',
  'dashboard.recurringAssessmentsPanel_admin',
  'dashboard.actionsPanel_admin', 
  'dashboard.templatesPanel_admin',
  'dashboard.MRRAssessmentsPanel_admin',
  'dashboard.MRRActionsPanel_admin',

  // MRR Survey Details
  'mrrSurveyDetails.view',
  'mrrSurveyDetails.cancelOrCloseAssessment',
  'mrrSurveyDetails.editAvailableStartDate',
  'mrrSurveyDetails.editAvailableToDate',
  'mrrSurveyDetails.createMRR',

  // MRR Store Surveys
  'mrrStoreDetails.view',

  // More MRR Store Surveys / Assessments
  'mrrAssessments.view',
  'mrrAssessments.viewAllAvailable',
  'mrrAssessments.viewAllUpcoming',
  'mrrAssessments.viewAllClosed',
  'mrrAssessments.viewAvailableResponsesForAssignedStore',
  'mrrAssessments.viewClosedResponsesForAssignedStore',
  'mrrAssessments.createDraftResponse',

  // MRR Above Store Survey
  'mrrAssessments.unsavedActions',
  'mrrAssessments.viewEvidence',

  // MRR Draft Details
  'mrrDraftDetails.view',
  'mrrDraftDetails.edit',
  'mrrDraftDetails.activateOrUpdate',
  'mrrDraftDetails.viewEvidence',

  // MRR Action Details
  'mrrActionDetails.view',
  'mrrActionDetails.viewStatus',
  'mrrActionDetails.viewMarkAsComplete',
  'mrrActionDetails.viewMessages',
  'mrrActionDetails.editDueDate',
  'mrrActionDetails.addUpdate',
  'mrrActionDetails.viewEvidence',

  // Once Off Assessments
  'onceOffAssessments.view',
  'onceOffAssessments.viewAllActive',
  'onceOffAssessments.viewAllUpcoming',
  'onceOffAssessments.viewAllClosed',
  'onceOffAssessments.viewAll',
  'onceOffAssessments.viewAllAssignedAndActive',
  'onceOffAssessments.viewAllAssignedAndClosed',
  'onceOffAssessments.viewAssignedResponses',

  // Once Off Assessment Overview
  'onceOffAssessment.viewOverview',
  'onceOffAssessment.editSurveyDetailsBeforePublishDate',
  'onceOffAssessment.cancelAssessment',
  'onceOffAssessment.viewAllResponsesForLocation',

  // Action Details
  'actionDetails.view',
  'actionDetails.viewStatus',
  'actionDetails.markAsComplete',
  'actionDetails.viewMessages',
  'actionDetails.editDueDate',
  'actionDetails.addUpdate',
  'actionDetails.viewEvidence',
  
  // Recurring Assessments Detail
  // 'recurringAssessments.view', // defined elsewhere
  'recurringAssessments.cancelOrClose',
  // 'templates.view',  // defined elsewhere
  'recurringAssessments.edit',
  'recurringAssessments.viewFrequency',

  // Recurring Assessments Detail
  // 'recurringAssessments.view', // defined elsewhere
  'recurringAssessments.cancelOrClose',
  // 'templates.view',  // defined elsewhere
  'recurringAssessments.edit',
  'recurringAssessments.viewFrequency',

  // Sign Off
  'mrrAssessments.signOffLowLevel',
  'mrrAssessments.signOffHighLevel',

  // 'dashboard.onceOffAssessmentsPanelAboveStore',
  // 'dashboard.adhocAssessmentsPanelAboveStore',
  // 'dashboard.recurringAssessmentsPanelAboveStore',
  // 'dashboard.actionsPanelAboveStore',
  // 'dashboard.MRRAssessmentsPanelAboveStore',
  // 'dashboard.MRRActionsPanelAboveStore',

  // 'dashboard.onceOffAssessmentsPanelStore',
  // 'dashboard.adhocAssessmentsPanelStore',
  // 'dashboard.recurringAssessmentsPanelStore',
  // 'dashboard.actionsPanelStore',
  // 'dashboard.MRRAssessmentsPanelStore',
  // 'dashboard.MRRActionsPanelStore',

  // 'accessManagement.manage',
  // 'accessManagement.view',
  // 'adhoc.view',
  // 'adhoc.edit',
  // 'adhocAssessment.view',
  // 'adhocAssessment.manage',
  // 'charts.view',
  // 'evidenceList.view',
  // 'gpsAssessment.manage',
  // 'gpsAssessment.edit',
  // 'gpsAssessment.view',
  // 'manageStores.manage',
  // 'manageStores.view',
  // 'materialRiskReviewActions.edit',
  // 'materialRiskReviewActions.view',
  // 'materialRiskReviewAssessment.manage',
  // 'materialRiskReviewAssessment.manageBySam',
  // 'materialRiskReviewAssessment.signoffMrrAssessment',
  // 'materialRiskReviewAssessment.visitNoteUpdate',
  // 'materialRiskReviewAssessment.view',
  // 'onceoff.view',
  // 'onceoff.edit',
  // 'onceOffAssessment.manage',
  // 'onceOffAssessment.view',
  // 'reportsAndExtracts.view',

  'mrrAssessments.viewResponse',
  'mrrAssessments.viewDraftResponse',
  'mrrAssessments.createAndEditDraftReport',
  'mrrAssessments.createAndEditFinalReport',
  'mrrAssessments.viewAndCreateVisitNotes',
  'mrrAssessments.addFindingEvidence',
  'mrrAssessments.addFindingNotes',
  'mrrActions.create',
  'mrrActions.activateReponse',
  'mrrAssessments.addFinding',
  'mrrAssessments.editFinding'
];
