<div [formGroup]="form" class="locations-container">
<ng-container *ngFor="let key of locationsOrdered">                   
  <div class="select-container location-option" *ngIf="showLocations[key]" [ngClass]="{'no-label': !showLabels}">
      <label *ngIf="showLabels" for="select" class="core-form-label">{{locationKey(key) | titlecase}}</label>
      <div class="select-wrapper">
          <div *ngIf="isLocationLoading[key]" class="select-loading-container">
              Finding {{(key === 'groupID' ? 'group' : key) | titlecase}}s
          </div>
          <select id="select{{key}}"
              class="core-select" 
              (change)="onSelectLocation($event, key)" 
              formControlName="{{locationKey(key)}}">
              <option *ngIf="!showAllOption" value="" disabled selected hidden>Select {{locationKey(key) | titlecase}}</option>
              <option *ngFor="let location of availableLocations[key]" value="{{location.value}}">{{location.name | unescape}}</option>
          </select>                                      
          <div class="selectArrow" aria-hidden="true">
          </div>
      </div>
  </div>
</ng-container>
</div>