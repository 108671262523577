<label class="core-text-field input-wrapper" [ngClass]="{'error': _errorMessage}">
    <span class="core-text-field-label-text" *ngIf="label">
        {{label}}
    </span>
    <div class="core-text-field-wrapper trailing">
        <input
            id="textBulkNumberInput"
            #textBulkNumberInput
            type="text" 
            class="core-text-field"
            [(ngModel)]="rawBulkNumbers"
            [placeholder]="placeholder"
            [attr.aria-label]="accessibilityLabel"
            (keypress)="restrictBulkNumberInput($event)"
            (paste)="onPaste()"
            (keyup.enter)="onEnter()"
        />
        <app-icon id="clearNumbers" iconName="close" *ngIf="rawBulkNumbers" class="clear" (click)="resetBulkNumberInput()"></app-icon>
    </div>
    <div class="core-help-text" *ngIf="!!_errorMessage">
        <app-icon iconName="errorFill"></app-icon>
        {{_errorMessage}}
    </div>      
</label>