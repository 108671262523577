/**
 * Extracts the domain extension from a given web address.
 *
 * @param {string} url - The web address from which to extract the domain extension.
 * @returns {string | undefined} The domain extension, or undefined if the URL is invalid.
 */
export function getDomainExtension(url: string): string | undefined {
  try {
    // Create a new URL object
    const urlObject = new URL(url);
    // Get the hostname from the URL object
    const hostname = urlObject.hostname;
    // Split the hostname by dots
    const parts = hostname.split('.');
    // Ensure there is a valid domain extension and it's not just a single part like 'localhost'
    if (parts.length > 1) {
      // The domain extension is the last part of the split hostname
      return parts[parts.length - 1];
    }
    // If no domain extension is found, return undefined
    return undefined;
  } catch (error) {
    // Handle the case where the URL is invalid
    console.error('Invalid URL:', error);
    return undefined;
  }
}
