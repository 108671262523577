import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams 
} from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    }),
  };

  private templatesUrl = `${environment.backEndUrl}/templates`;

  private rolesUrl = `${environment.backEndUrl}/roles`;

  constructor(private httpClient: HttpClient) { } 

  getTemplateDetails(templateID: string): Observable<object[]> {
    const fullUrl = `${this.templatesUrl}/${templateID}`;
    return this.httpClient.get<object[]>(fullUrl);
  }

  getRoles(): Observable<object> {
    return this.httpClient.get<object>(this.rolesUrl, this.httpOptions).pipe(
      catchError((err) => {
        console.log('Error in getting roles data.');
        console.log(err.message);
        return of(null);
      })
    );
  }
}
