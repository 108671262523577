import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams 
} from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AdhocSurveyService {
  private surveysUrl = `${environment.backEndUrl}/survey/list`;

  private createOnTheFlySurveyUrl = `${environment.backEndUrl}/createSurveyResponse/adhoc`;

  constructor(private httpClient: HttpClient) { }

  getOnTheFlySurveytemplates(
    userGrade: string,
    group: string,
    status: string,
    index: string,
    count: string,
    type: string
  ): Observable<object[]> {
    const fullUrl = `${this.surveysUrl
      + userGrade
      + index
      + count
    }?status=${
      status
    }&division=${
      group
    }&type=${
      type}`;
    return this.httpClient.get<object[]>(fullUrl);
  }

  createOnTheFlySurvey(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.createOnTheFlySurveyUrl;
    return this.httpClient.post<object>(fullUrl, data, options);
  }
}
