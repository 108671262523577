import { analyzeAndValidateNgModules } from '@angular/compiler';
import {
  Component, ElementRef, OnInit, ViewChild 
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faTimes,
  faPlus,
  faSortDown,
  faSearch,
  faTrash,
  faPencilAlt,
  faThList,
  faUserCircle,
  faTimesCircle,
  faUser,
  faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { StoreDashboardDialogComponent } from '../store-dashboard-dialog/store-dashboard-dialog.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { MessagesComponent } from '../messages/messages.component';
import { SortPipe } from '../../custom-pipes/sort.pipe';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-store-dashboard',
  templateUrl: './store-dashboard.component.html',
  styleUrls: ['./store-dashboard.component.scss'],
  providers: [
    SortPipe
  ]
})
export class StoreDashboardComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faSortDown = faSortDown;

  faPlus = faPlus;

  faTrash = faTrash;

  faPencilAlt = faPencilAlt;

  faSearch = faSearch;

  faUserCircle = faUserCircle;

  faTimesCircle = faTimesCircle;

  faRedoAlt = faRedoAlt;

  faTimes = faTimes;

  // availableDivisions: string[] = [];
  availableLocations: object = {};

  currentLevel: string;

  hasNext: string;

  index: number = 0;

  locationsOrdered: string[] = [];

  nextLevel: string;

  pageIndex: number = 0;

  pageSize: number = 10;

  searchTimer;

  // selectedDivision: string;
  selectedGroup: number = 0;

  selectedLocations: object = {};

  showLocations: object = {};

  tableColumns: string[] = [];

  textFilter: string = '';

  totalCount: number = 10000000000000;

  user: User;

  storesToShow: object[] = [];

  isLoading = false;

  constructor(
    private elementRef: ElementRef,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private sortPipe: SortPipe,
    private surveyService :SurveysService
  ) {}

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    // this.csaBackEnd
    // .getNextLevel("?domain="+this.user['domain'])
    // .subscribe((divisionList)=>{
    //     this.availableDivisions = divisionList['division'];
    // });
    // this.selectedDivision = this.user['division'];

    console.log(this);
    let key = this.user['orgStruct']['division'];
    while (this.user['orgStruct'][key]) {
      this.selectedLocations[key] = '';
      this.showLocations[key] = false;
      if (this.locationsOrdered.indexOf(key) === -1) this.locationsOrdered.push(key);
      key = this.user['orgStruct'][key];
    }

    this.getTableColumns();
    this.getStoresToShow('', 'division');
  }

  createStore(): void {
    const dialogRef = this.dialog.open(StoreDashboardDialogComponent, {
      data: {
        // division: this.selectedDivision,
        type: 'create',
      },
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'success') {
        this.getStores();
      }
    });
  }

  updateStore(storeData): void {
    const dialogRef = this.dialog.open(StoreDashboardDialogComponent, {
      data: {
        // division: this.selectedDivision,
        storeData,
        type: 'update',
      },
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'delete') {
        if (this.totalCount - this.index == 1 && this.totalCount != 1) this.paginator.previousPage();
        else this.getStores();
      } else if (result == 'success') {
        this.getStores();
      }
    });
  }

  createLevelQuery(): string {
    let key = 'division';
    let query = '';
    while (key != this.nextLevel) {
      if (key == 'division') {
        query = `?division=${this.user['division']}`; 
      } else {
        query += `&${key}=${this.selectedLocations[key]}`;
      }

      key = this.user['orgStruct'][key];
    }
    return query;
  }

  resetSelectedBelowLevel(): void {
    console.log(this.nextLevel);
    let key = this.nextLevel;
    while (this.user['orgStruct'][key]) {
      this.selectedLocations[key] = '';
      this.showLocations[key] = key == this.nextLevel;
      key = this.user['orgStruct'][key];
    }
    console.log(this.showLocations);
  }

  getStoresToShow(selection, level): void {
    clearTimeout(this.searchTimer);
    setTimeout(() => {
      this.isLoading = true;
      this.storesToShow = [];
    }, 0);

    this.currentLevel = level;
    this.nextLevel = this.user['orgStruct'][level];

    this.resetSelectedBelowLevel();
    // if (selection) {
    //   if (level == 'division') {
    //     this.selectedDivision = selection;
    //   } else {
    //     this.selectedLocations[this.currentLevel] = selection;
    //   }
    // }
    if (selection) {
      this.selectedLocations[this.currentLevel] = selection;
    }

    if (this.user['orgStruct'][this.nextLevel]) {
      this.surveyService
        .getNextLevelAll(this.createLevelQuery())
        .subscribe((data) => {
          this.availableLocations[this.nextLevel] = this.sortPipe.transform(data[this.nextLevel]);
          this.getStores();
        });
    } else {
      this.getStores();
    }
  }

  upperCaseKey(key: string) {
    if (key == 'groupID') {
      return 'Group_No';
    } if (key == 'storeID') {
      return 'Store_No';
    }
    return key.replace(/\b[a-z]/g, (x) => x.toUpperCase());
  }

  removeID(key: string) {
    return key.replace('ID', '');
  }

  getNext(event: PageEvent): void {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getStores();
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.storesToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.storesToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  tableColumnByLevel(lastLevel): string[] {
    const index = this.locationsOrdered.indexOf(lastLevel);
    return this.locationsOrdered.slice(0, index + 1);
  }

  getTableColumns(): void {
    this.tableColumns = ['store name', 'storeID'];
    let key = this.user['orgStruct']['division'];
    while (this.user['orgStruct'][key]) {
      this.tableColumns.push(key);
      key = this.user['orgStruct'][key];
    }
    this.tableColumns.push('email');
  }

  getPreviousLevel(level): string {
    return Object.keys(this.user['orgStruct']).find(
      (key) => this.user['orgStruct'][key] == level
    );
  }

  locationString(key): string {
    if (key == 'state' || key == 'region') return this.selectedLocations[key];
    if (key == 'groupID') return `Group ${this.selectedLocations[key]}`;
    return `${key} ${this.selectedLocations[key]}`;
  }

  locationStringAll(key): string {
    if (key == 'groupID') key = 'group';
    return `All ${key}s`;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getStores();
  }

  getStores(): void {
    setTimeout(() => {
      this.isLoading = true;
      this.storesToShow = [];
    }, 0);

    let query = `?division=${this.user['division']}`;
    for (const [key, value] of Object.entries(this.selectedLocations)) {
      if (value != '') {
        query += `&${this.upperCaseKey(key)}=${value}`;
      }
    }

    if (this.textFilter) {
      query += `&search=${this.textFilter}`;
    }

    if (this.selectedGroup == 0) {
      query += '&pilot=Y';
    } else {
      query += '&pilot=N';
    }

    this.surveyService
      .getStoreAccessList(`/${this.index}`, `/${this.pageSize}`, query)
      .subscribe(
        (message) => {
          this.storesToShow = message['stores'];
          this.hasNext = message['has_next'];
          this.getPagination();
          this.isLoading = false;
          console.log(this);
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  resetAllFilters(): void {
    this.textFilter = '';
    // this.selectedDivision = "";
    Object.keys(this.selectedLocations).forEach(
      (key) => (this.selectedLocations[key] = '')
    );
    Object.keys(this.showLocations).forEach(
      (key) => (this.showLocations[key] = false)
    );
    this.showLocations[this.user['orgStruct']['division']] = true;
    this.resetPagination();
    this.getStores();
  }

  resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
  }

  showColumn(column: string): boolean {
    return this.tableColumns.includes(column);
  }

  updateSearch(): void {
    clearTimeout(this.searchTimer);
    setTimeout(() => {
      this.isLoading = true;
      this.storesToShow = [];
    }, 0);
    this.searchTimer = setTimeout(() => {
      this.resetPagination();
      this.getStores();
    }, 1000);
  }
}
