import {
  Component, Inject, Injectable, OnInit, OnDestroy 
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MessagesComponent } from '../messages/messages.component';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-store-selection-dialog',
  templateUrl: './store-selection-dialog.component.html',
  styleUrls: ['./store-selection-dialog.component.scss'],
})
export class StoreSelectionDialogComponent implements OnInit {
  compState = (val1, val2) => val1 == val2;

  faSave = faSave;

  faTimes = faTimes;

  noEntries: string = '';

  searching: boolean = false;

  selectedStore: object = {};

  storeName: string = '';

  storeNumber: string = '';

  storesReturned: object[] = [];

  user: User;

  searchTimer;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private actRoute: ActivatedRoute,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<StoreSelectionDialogComponent>,
    private router: Router,
    private surveyService :SurveysService
  ) {}

  closeMe() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    if (this.data['allowClose']) {
      this.dialogRef.disableClose = false;
    } else {
      this.dialogRef.disableClose = true;
    }
    console.log(this.data);
    this.user = this.csaAuth.user;
  }

  searchForStores(storeId) {
    this.noEntries = 'Searching...';
    this.searching = true;
    
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(
      () => {
        if (this.user['division'] == 'DC') {
          this.noEntries = 'No matching DCs found..';
        } else {
          this.noEntries = 'No matching Stores found..';
        }
        if (storeId.trim()) {
          this.surveyService.getStoreList(storeId).subscribe((storeData) => {
            this.storesReturned = storeData;
            setTimeout(() => { this.searching = false; }, 1000);
          }, (error) => {
            this.searching = false;
            this.dialog.open(MessagesComponent, { 
              data: {
                heading: 'An Error Has Occured',
                message: `An Error was returned when trying to find stores. Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok'
              }, 
              backdropClass: 'dialog-backdrop' 
            });
          });
        } else {
          this.storesReturned = [];
          this.searching = false;
        }
      },
      1000
    );
  }

  setStore(store) {
    this.storeNumber = store['Store_No'];
    this.storeName = store['Location_Name'];
    this.selectedStore = store;
  }

  saveStore(form, value) {
    this.dialogRef.close({ storeNo: this.storeNumber, storeName: this.storeName });
  }
}
