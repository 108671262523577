import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      if (value[0] === undefined) {
        return value;
      } 
      return value.sort();
    }
  }
}
