/**
 * Finds the first item in an array that matches all the specified criteria.
 *
 * @template T - The type of items in the array.
 * @param {T[]} array - The array of items to search.
 * @param {Partial<T>} criteria - An object representing the criteria to match.
 *                                Only the specified properties in `criteria` will be used for matching.
 * @returns {T | undefined} - The first item in the array that matches all criteria, or `undefined` if no match is found.
 * @example
 * interface Item {
 *   id: number;
 *   name: string;
 *   category: string;
 * }
 *
 * const items: Item[] = [
 *   { id: 1, name: "Item 1", category: "Category A" },
 *   { id: 2, name: "Item 2", category: "Category B" },
 *   { id: 3, name: "Item 3", category: "Category A" },
 * ];
 *
 * const result = findItemByValues(items, { category: "Category A", name: "Item 3" });
 * console.log(result);
 * // Output: { id: 3, name: "Item 3", category: "Category A" }
 */
export function findItemByValues<T>(
  array: T[],
  criteria: Partial<T>
): T | undefined {
  return array?.find((item: T) => Object.entries(criteria).every(
    ([key, value]) => item[key as keyof T] === value
  ));
}
