/**
 * Returns an array of unique values from the given array.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The input array to extract unique values from.
 * @returns {T[]} An array of unique values.
 * @example
 * getUniqueValues([1, 2, 3, 1, 2, 4]); // Returns: [1, 2, 3, 4]
 * getUniqueValues(["a", "b", "a", "c", "b"]); // Returns: ["a", "b", "c"]
 * getUniqueValues([true, false, true]); // Returns: [true, false]
 */
export function getUniqueValues<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}
