<div class="page-container">
  <h1>{{surveyDetails['title'] | unescape}}</h1>

  <div class="question-options-row">
    <div class="filter-chips question-options">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)="cancelAssessment()" *ngIf="cancelOrCloseAssessment && (surveyDetails['status'] === 'active' || surveyDetails['status'] === 'published')" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faTimes"></fa-icon>
            <span *ngIf="surveyDetails['status'] === 'active'">
              Cancel Assessment
            </span>
            <span *ngIf="surveyDetails['status'] === 'published'">
              Close Assessment
            </span>
          </mat-chip>
          <mat-chip *ngIf="templatesViewDetails" [routerLink]="['/template-details', surveyDetails['template_id']]" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faFileAlt"></fa-icon>
            <span>See Template</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <h2 class="left-align">Adhoc Assessment Details:</h2>

  <div class="audit-tool-cards-container">
    <div class="p2 audit-tool-card list-screen-card">
      <div class="question-options-row no-bottom-margin">
        <div class="survey-detail-block">
          <h4>Status:</h4>
          <p class="light-green medium-text">
            <span *ngIf="surveyDetails['status'] === 'active'">
              Not Yet Published
            </span>
            <span *ngIf="surveyDetails['status'] === 'published'">
              Published
            </span>            
            <span *ngIf="surveyDetails['status'] !== 'active' && surveyDetails['status'] !== 'published'">
              {{surveyDetails['status'] | titlecase}}
            </span>
          </p>
        </div>
        <div class="survey-detail-block">
          <h4>No. of Questions:</h4>
          <p>{{surveyDetails['questions']?.length}}</p>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['activeResponseCount']">
          <h4>Active Responses:</h4>
          <p>{{ surveyDetails['activeResponseCount']}}</p>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['filterName']">
          <h4>Assessment Type:</h4>
          <p>{{ surveyDetails['filterName'] | uppercase }}</p>
        </div>    
      </div>

      <div class="question-options-row no-top-margin">
        <div class="survey-detail-block">
          <h4>Available From:</h4>
          <p *ngIf="!editStartDate">  {{ surveyDetails['startDate']| date :'dd MMM y'  }}  <a *ngIf="(dateToday < minEndDate) && editSurveyDetailsBeforePublishDate" class="med-green" (click)="editStartDate = true; editEndDate = false; editValidity = false;"><fa-icon [icon]="faPencilAlt"></fa-icon></a></p>
          <form *ngIf="editStartDate" class="wide-form" #publishDateForm="ngForm" (submit)="submitForm3(publishDateForm, publishDateForm.value)">
            <mat-form-field class="right-margin date-field">
              <mat-label>Start Date</mat-label>
              <input 
                (keydown)="false" 
                name="publishDate" 
                (click)="publishPicker.open()" 
                [max]="maxStartDate" 
                [min]="minStartDate"
                ngModel 
                matInput 
                [matDatepicker]="publishPicker" 
                required>
              <mat-datepicker-toggle matSuffix [for]="publishPicker"></mat-datepicker-toggle>
              <mat-datepicker #publishPicker></mat-datepicker>
              <mat-error>Date after today and before selected End Date</mat-error>
            </mat-form-field>
            <button [disabled]="!publishDateForm.valid" class="csa-button-solid survey-submit" type="submit">Save</button>
            <button class="csa-button-outline" (click)="editStartDate = false;">Cancel</button>
          </form>
        </div>

        <div class="survey-detail-block">
          <h4>Available To:</h4>
          <p *ngIf="!editEndDate">{{ surveyDetails['endDate']| date :'dd MMM y'  }}   <a class="med-green" *ngIf="(dateToday < minEndDate) && editSurveyDetailsBeforePublishDate" (click)="editEndDate = true; editStartDate = false; editValidity = false;"><fa-icon [icon]="faPencilAlt"></fa-icon></a></p>
          <form *ngIf="editEndDate" class="wide-form" #endDateForm="ngForm" (submit)="submitForm2(endDateForm, endDateForm.value)">
            <mat-form-field class="right-margin date-field">
              <mat-label>End Date</mat-label>
              <input 
                (keydown)="false" 
                name="dueDate" 
                (click)="picker.open()" 
                [min]="minEndDate" 
                ngModel 
                matInput 
                [matDatepicker]="picker" 
                required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>Please select a date after the Start Date</mat-error>
            </mat-form-field>
            <button [disabled]="!endDateForm.valid" class="csa-button-solid survey-submit" type="submit">Save</button>
            <button class="csa-button-outline" (click)="editEndDate = false;">Cancel</button>
          </form>
        </div>
          
        <div class="survey-detail-block">
          <h4>Days to Complete:</h4>
          <p *ngIf="!editValidity">{{ surveyDetails['surveyValidity']}} <a *ngIf="(dateToday < minEndDate) && editSurveyDetailsBeforePublishDate" class="med-green" (click)="editValidity = true; editEndDate = false; editStartDate = false;"><fa-icon [icon]="faPencilAlt"></fa-icon></a></p>
          <form *ngIf="editValidity" class="wide-form" #validityForm="ngForm" (submit)="submitForm4(validityForm, validityForm.value)">
            <mat-form-field class="right-margin date-field">
              <mat-label>Days to Complete</mat-label>
              <input matInput ngModel class="custom-control-input" max="35" min="1" name="validity"  type="number" required>
              <mat-error>Enter a number between 1 and 35</mat-error>
              <mat-hint style="color: var(--red-color);" *ngIf="(validityForm.value.validity !== '') && (validityForm.value.validity === 0 || validityForm.value.validity < 1 || validityForm.value.validity > 35)">Enter a number between 1 and 35</mat-hint>
            </mat-form-field>
            <button [disabled]="validityForm.value.validity < 1 || validityForm.value.validity > 35 || !validityForm.valid" class="csa-button-solid survey-submit" type="submit">Save</button>
            <button class="csa-button-outline" (click)="editValidity = false;">Cancel</button>
          </form>
        </div>
      </div>

      <div class="question-options-row no-top-margin">
        <div class='survey-button survey-detail-block'>
          <h4 class="orange-text" *ngIf="surveyDetails['targetRoles']?.indexOf(user['role']) > -1  && surveyDetails['status'] === 'closed'">This Adhoc Assessment is no longer available.</h4>
          <div *ngIf="surveyDetails['targetRoles']?.indexOf(user['role']) == -1 || surveyDetails['status'] === 'active'">
            <h4>Available For:</h4>
            <span *ngFor="let role of surveyDetails['targetRoles']; let j=index"> 
              {{splitByCaps(role)}}<span *ngIf="surveyDetails['targetRoles']?.length - 1 > j">,&nbsp;</span> 
            </span>
          </div>
          <a (click)="createOnTheFlySurvey()"
            *ngIf="surveyDetails['status'] === 'published' && surveyDetails['targetRoles']?.indexOf(user['role']) > -1">
            <button class="csa-button-solid large">
              <span><fa-icon [icon]="faPlus"></fa-icon>&nbsp;&nbsp;Create New</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <app-results-table [surveyType]="'adhoc'" *ngIf="surveyDetails['status'] !== 'active' && surveyDetails['status'] !== 'cancelled'"></app-results-table>
</div>


