import { getFriendlyOrgName } from './get-friendly-org-name';
import { getLocationsOrdered } from './get-locations-ordered';

/**
 * Retrieves an array of location identifiers from the given organizational structure
 * and maps them to their corresponding friendly names.
 *
 * @param {object} orgStructure - The organizational structure containing location data.
 * @param {boolean} [includeLastNode=false] - Whether to include the last node in the ordered list.
 * @returns {string[]} An array of location names with friendly representations.
 */
export function getLocationsOrderedWithFriendlyName(orgStructure: object, includeLastNode: boolean = false): string[] {
  const locationsOrdered = getLocationsOrdered(orgStructure, includeLastNode);
  return locationsOrdered.map((value) => getFriendlyOrgName(value));
}
