<div class="page-container">
	<h1>Access Management</h1>
  
  <div class="question-options-row">
		<div class="filter-chips  filter-list action-chips">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)="createUser()" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faPlus"></fa-icon>
            <span>Create New User</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      </div>
    </div>

	<div class="question-options-row">
		<div class="filter-chips  filter-list">
      <div class="chipDrop search-chip">
				<div>
					<fa-icon *ngIf="!textFilter" class="search-icon" [icon]="faSearch"></fa-icon>
				</div>
				<div class="clear-search" (click)="textFilter = ''; updateSearch();">
					<fa-icon *ngIf="textFilter" class="search-icon" [icon]="faTimes"></fa-icon>
				</div>
				<input class="search" type="text" [(ngModel)]="textFilter" placeholder="Search" (keyup)="updateSearch()">
			</div>
      
			<div class="chipDrop">
				<mat-chip-list aria-label="Filters">
					<mat-chip (click)="roleSelect.open()" color="primary" selected>
						{{splitByCaps(selectedRole) | unescape}}
						<fa-icon [icon]="faSortDown"></fa-icon>
					</mat-chip>
					<mat-select #roleSelect class="optionsDropDown" [(value)]="selectedRole"
						(ngModelChange)="selectedRole=$event; resetRoleFilters(); getUsersToShow('', 'division')"
						[ngModel]="selectedRole" name="role">
						<mat-option [value]="'All Roles'">
							All Roles
						</mat-option>
						<mat-option *ngFor="let role of availableRoles" [value]="role">
							{{splitByCaps(role) | unescape}}
						</mat-option>
					</mat-select>
				</mat-chip-list>
			</div>

			<ng-container *ngFor="let key of locationsOrdered">
        <div class="chipDrop"  *ngIf="showLocations[key]">
          <mat-chip-list aria-label="Filters">
            <mat-chip (click)="select.open()" selected>
              <span *ngIf="key === 'state' || key === 'region'; else titleLocation">
                {{(selectedLocations[key] ? selectedLocations[key] : (locationStringAll(key) | titlecase)) | unescape}}
              </span>
              <ng-template #titleLocation>
                {{(selectedLocations[key] ? locationString(key) : locationStringAll(key)) | titlecase | unescape}}
              </ng-template>
              <fa-icon [icon]="faSortDown"></fa-icon>
            </mat-chip>
            <mat-select #select class="optionsDropDown">
              <mat-option (click)="resetPagination(); getUsersToShow('', getPreviousLevel(key))">
                {{locationStringAll(key) | titlecase}}
              </mat-option>
              <mat-option *ngFor="let location of availableLocations[key]" (click)="resetPagination(); getUsersToShow(location, key)">
                {{location | unescape}}
              </mat-option>
            </mat-select>
          </mat-chip-list>
        </div>
      </ng-container>

      <div class="chipDrop" *ngIf="selectedRole !== 'All Roles' || textFilter">
				<mat-chip-list aria-label="Filters">
					<mat-chip class="clear-chip" (click)="resetAllFilters()" color="primary" selected>
						<fa-icon class="chip-left-icon" [icon]="faRedoAlt"></fa-icon>
            <span>Clear</span>
					</mat-chip>
				</mat-chip-list>
			</div>
		</div>
	</div>

	<div class="audit-tool-cards-container">
		<div class="audit-tool-card more-list-screen-card">
			<table mat-table [dataSource]="usersToShow">
				<ng-container [matColumnDef]="key" *ngFor="let key of tableColumns">
					<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align" [ngClass]="{'hide-on-mobile': key === 'email'}">
						<h4>{{(key === "groupID" ? "group" : key) | titlecase}}</h4>
					</th>
					<td mat-cell *matCellDef="let userData" class="survey-details-link center-align cell-field" [style.--numShown]="tableColumns.length" [style.--numShownMobile]="tableColumns.length-1" [ngClass]="{'hide-on-mobile overflow-ellipsis': key === 'email'}">
						<span *ngIf="key === 'email'">
							{{userData['EMAIL_ADDRESS']}}
						</span>

						<span *ngIf="key === 'name'">
							{{userData['FIRST_NAME'] | unescape}} {{userData['LAST_NAME'] | unescape}}
						</span>

						<span *ngIf="key === 'role'">
							{{splitByCaps(userData['ROLE']) | unescape}}
						</span>

						<span *ngIf="locationsOrdered.includes(key)">
							{{userData[key == 'groupID' ? 'GROUP_NO' : key.toUpperCase()] | unescape}}
						</span>
					</td>
				</ng-container>

				<mat-header-row *matHeaderRowDef="tableColumns" class="survey-heading-line"></mat-header-row>
				<mat-row *matRowDef="let userData; columns: tableColumns;" (click)="updateUser(userData)" class="survey-line survey-line-link">
				</mat-row>
			</table>
      
      <div *ngIf="isLoading" class="loader-container">
				<div class="loading-wapple"></div>
      </div>

			<div *ngIf="usersToShow.length == 0 && !isLoading">
				<h3 class="none-found-message">
					There are no users to show.
				</h3>
			</div>

			<mat-paginator 
					#paginator 
					[pageIndex]=pageIndex 
					[pageSize]=pageSize 
					[length]="totalCount"
					[pageSizeOptions]="[5, 10, 25, 100]" 
					[showFirstLastButtons]=false 
					(page)="getNext($event)">
			</mat-paginator>
		</div>
	</div>
</div>