import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { MessagesComponent } from './surveys/messages/messages.component';
import { CsaAuthService } from './auth/csa-auth.service';
import { ThemeLoaderService } from './common-services/ThemeLoaderService';
import { DomainService } from './domain/domain.service';
import { LoaderService } from './common-services/loader.service';
import { VERSION, BUILD_DATE } from '../environments/version';
import { AutoLogoutService } from './common-services/auto-logout.service';
import { PreviousRouteService } from './common-services/previous-route-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'iVerify';

  domain;

  favIcon: HTMLLinkElement = document.querySelector('#favIcon');

  constructor(
    private csaAuthService: CsaAuthService, 
    private autoLogout: AutoLogoutService, 
    public loaderService: LoaderService, 
    private router: Router, 
    public themeLoaderService: ThemeLoaderService, 
    private titleService: Title, 
    private domainService: DomainService,
    private previousRouteService: PreviousRouteService // Initiates the constructor from the top/start of the app
  ) {
    this.logVersionNumber();
    this.redirectToLoginIfUnauthenticated();
    this.autoLogout.watchAutoLogout();
  }

  /**
   * Logs a buildstamp/version number which consists of the commit hash and the date.
   */
  private logVersionNumber() {
    console.log(`%c BUILD VERSION: ${VERSION.tag} - HASH:  ${VERSION.hash} - DATE: ${BUILD_DATE}`, 'background: #178841; color: #FFFFFF');
  }

  ngOnInit() {
    this.domain = this.domainService.getDomain();
    console.log('Domain: ', this.domain);
    console.log('User: ', this.csaAuthService.user);

    switch (this.domain) {
      case 'Bigw':
        this.titleService.setTitle('BigW iVerify');
        this.themeLoaderService.load('bigw');
        this.favIcon.href = 'faviconBigw.ico';
        break;
      case 'Woolworths':
        this.themeLoaderService.load('woolworths');
        break;
      case 'WoolworthsNZ':
        this.titleService.setTitle('Woolworths NZ iVerify');
        this.themeLoaderService.load('woolworths');
        break;
      default:
        this.themeLoaderService.load('woolworths');
        break;
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    const cookieEnabled = navigator.cookieEnabled;
    console.log('Cookies??', cookieEnabled);
    if (!cookieEnabled) {
      this.router.navigate(['error/cookies']);
    }
  }

  /**
   * If the user isn't signed in, redirect them to login.
   */
  async redirectToLoginIfUnauthenticated() {
    const user = await firstValueFrom(this.csaAuthService.userChanges$());
    console.log(user);
    if (!user) {
      this.router.navigateByUrl('/login');
    }
  }
}
