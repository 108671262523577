import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from './icon/icon.component';
import { TableEmptyStateComponent } from './table-empty-state/table-empty-state.component';
import { PercentageBarComponent } from './percentage-bar/percentage-bar.component';
import { TagComponent } from './tag/tag.component';
import { TableLoadingStateComponent } from './table-loading-state/table-loading-state.component';
import { AccordionComponent } from './accordion/accordion.component';
import { TextFieldCounterComponent } from './text-field-counter/text-field-counter.component';
import { UnescapePipe } from '../custom-pipes/unescape.pipe';
import { UniquePipe } from '../custom-pipes/unique.pipe';
import { PanelHeaderComponent } from '../dashboard/components/panel-header/panel-header.component';
import { DialogComponent } from './dialog/dialog.component';
import { AutocompleteDropdownComponent } from './autocomplete-dropdown/autocomplete-dropdown.component';
import { LocationSelectionComponent } from './location-selection/location-selection.component';
import { AccessCheckPipe } from '../custom-pipes/access-check.pipe';

@NgModule({
  declarations: [
    IconComponent,
    TableEmptyStateComponent,
    PercentageBarComponent,
    TagComponent,
    TableLoadingStateComponent,
    AccordionComponent,
    TextFieldCounterComponent,
    UnescapePipe,
    UniquePipe,
    AccessCheckPipe,
    PanelHeaderComponent,
    DialogComponent,
    AutocompleteDropdownComponent,
    LocationSelectionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    MatDialogModule
  ],
  providers: [
    UnescapePipe,
    UniquePipe,
  ],
  exports: [
    IconComponent,
    TableEmptyStateComponent,
    PercentageBarComponent,
    TagComponent,
    TableLoadingStateComponent,
    AccordionComponent,
    OverlayModule,
    TextFieldCounterComponent,
    UnescapePipe,
    UniquePipe,
    AccessCheckPipe,
    PanelHeaderComponent,
    DialogComponent,
    AutocompleteDropdownComponent,
    LocationSelectionComponent,
  ]
})
export class SharedModule { }
