import {
  Component, OnDestroy, Inject, OnInit 
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CsaAuthService } from 'src/app/auth/csa-auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';

@Component({
  selector: 'app-mobile-header-menu',
  templateUrl: './mobile-header-menu.component.html',
  styleUrls: ['./mobile-header-menu.component.scss']
})
export class MobileHeaderMenuComponent implements OnInit, OnDestroy {
  public user?: User;

  public canAccessAsOtherRoles = false;

  public canAccessAsOtherStores = false;

  public canAccessAsOtherDivisions = false;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private auth: CsaAuthService,
    private featureAccessService: FeatureAccessService,
    private router: Router,
    private dialogRef: MatDialogRef<MobileHeaderMenuComponent>
  ) {}

  ngOnInit(): void {
    this.auth.userChanges$().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((user) => {
      this.user = user;
      this.canAccessAsOtherRoles = user?.support 
        || this.featureAccessService.hasAccess('navigation.canAccessAsOtherRoles');
      this.canAccessAsOtherStores = user?.role === 'MultiStore' 
        || this.featureAccessService.hasAccess('navigation.canAccessAsOtherStores');
      this.canAccessAsOtherDivisions = (user.super && user.domain !== 'Bigw')
        || this.featureAccessService.hasAccess('navigation.canAccessAsOtherDivisions');
    });
  }

  public goToFeedback() {
    if (this.auth.user.supportEmail.includes('medallia')) {
      window.open(this.auth.user.supportEmail, '_blank');
    } else {
      alert(`Please send your feedback via ${this.auth.user.supportEmail}`);
    }
    this.dialogRef.close();
  }

  public switchUserAccess() {
    this.auth.clearOverrides();
    this.router.navigateByUrl('/support-landing');
    this.dialogRef.close();
  }

  public switchDivision() {
    this.router.navigateByUrl('/switch-division');
    this.dialogRef.close();
  }

  public switchStoreAccess() {
    this.router.navigate(['/multi-store-selection']);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
