<div class="page-container">

    <h1>{{surveyDetails['title'] | unescape}}</h1>

    <h2 class="left-align">Assessment Details:</h2>
    <div class="audit-tool-cards-container">
        <div class="p2 audit-tool-card list-screen-card">


            <div class="question-options-row no-bottom-margin">

                <div class="survey-detail-block">
                    <h4>Store:</h4>
                    <p>
                        {{surveyDetails['storeID']}} - {{surveyDetails['storeName']}}
                    </p>
                </div>


                <div class="survey-detail-block">
                    <h4>Draft Created:</h4>
                    <p>  {{ surveyDetails['publishDate'] | date :'dd MMM y'  }} </p>
                </div>

                <div class="survey-detail-block" *ngIf="surveyDetails['rating']">
                    <h4>Final Rating:</h4>
                    <p> {{surveyDetails['rating']}} </p>
                </div>

                <div class="survey-detail-block">
                    <h4>Status:</h4>
                    <p class="light-green medium-text">{{surveyDetails['status']}}</p>
                </div>

            </div>

        </div>
    </div>
    <!-- draft report button -->

    <div class="question-options-row" *ngIf="surveyDetails['storeManager'] && surveyDetails['LeadAuditor']">
        <div class="filter-chips">
            <div class="chipDrop">
                <mat-chip-list aria-label="Filters">
                    <mat-chip color="primary" selected (click)="editHeader()">
                        <!-- <fa-icon class="chip-left-icon" [icon]="faPencilAlt"></fa-icon> -->
                        <span> Draft Report</span>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>

    <!-- final report part -->
    <div *ngIf="surveyDetails['finalReportDate'] && surveyDetails['summaryFindings']">
        <div class="question-options-row">
            <div class="filter-chips">
                <div class="chipDrop">
                    <mat-chip-list aria-label="Filters">
                        <mat-chip color="primary" selected (click)="finalRate(finalreport)">
                            <!-- <fa-icon class="chip-left-icon" [icon]="faPencilAlt"></fa-icon> -->
                            <span> Final Report</span>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
    </div>



    <div class="survey-container">
        <h2 class="left-align">Material Risk Review Findings:</h2>
        <div class="question-options-row no-bottom-margin">
            <h3 class="light-green medium-text expand-collapse" (click)="allExpandState = !allExpandState">
                <span *ngIf="allExpandState == false">
                    &nbsp;&nbsp;Show All
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                </span>
                <span *ngIf="allExpandState == true">
                    &nbsp;&nbsp;Collapse All
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                </span>
                &nbsp;&nbsp;
            </h3>
        </div>


        <mat-accordion class="questions-accordion" multi>
            <mat-expansion-panel [expanded]="allExpandState" #panel hideToggle
                *ngFor="let section of surveyDetails['sectionHeading']; let sectionIndex = index;"
                (opened)="setOpened(sectionIndex)" (closed)="setClosed(sectionIndex)">
                <mat-expansion-panel-header
                    *ngIf="(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length > 0">
                    <mat-panel-title>
                        <span class="neg-5-margin hide-on-mobile">
                            <svg height="3" width="200">
                                <line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
                            </svg>
                        </span>
                        &nbsp;&nbsp;
                        <span>{{section}}</span>&nbsp;&nbsp;<fa-icon *ngIf="openItems.indexOf(sectionIndex) === -1"
                            [icon]="faAngleDown"></fa-icon>
                        <fa-icon *ngIf="openItems.indexOf(sectionIndex) > -1" [icon]="faAngleUp"></fa-icon>&nbsp;&nbsp;
                        <span class="neg-5-margin hide-on-mobile">
                            <svg height="3" width="200">
                                <line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
                            </svg>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <span *ngIf="(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length == 0">
                    <!-- No findings under this section -->
                </span>
                <div
                    *ngFor="let question of (surveyDetails['questions'] | groupQuestions:{sectionHeading: section}); let sectionQuestionIndex = index;">

                    <div class="audit-tool-card question-card">
                        <div class="p2">
                            <div class="question-count">
                                <span class="large-count">
                                    Finding {{sectionQuestionIndex+1}}
                                </span> of
                                {{(surveyDetails['questions'] | groupQuestions:{sectionHeading: section}).length}}
                            </div>

                            <h3 class="actions-heading no-top-margin">
                                Title
                            </h3>
                            <div class="action-line">
                                {{question['title']}}
                            </div>

                            
              <h3 class="actions-heading no-top-margin" *ngIf="question['riskLevel']">
                Risk Level
              </h3>
              <div class="action-line" *ngIf="question['riskLevel']">
                {{question['riskLevel']}}
              </div>

                            <h3 class="actions-heading no-top-margin" *ngIf="question['reference']">
                                Reference
                            </h3>
                            <div class="action-line" *ngIf="question['reference']">
                                {{question['reference']}}
                            </div>

                            <div class="centered-fields">
                                <div class="multiple-field" [style.--numShown]="2">
                                    <h3 class="actions-heading no-top-margin">
                                        Type
                                    </h3>
                                    <div class="action-line">
                                        {{question['MRRtype'] | titlecase}}
                                    </div>
                                </div>
                                <div class="multiple-field" [style.--numShown]="2">
                                    <h3 class="actions-heading no-top-margin">
                                        Available To
                                    </h3>
                                    <div class="action-line">
                                        {{availableToString(question['availableTo'])}}
                                    </div>
                                </div>
                            </div>

                            <h3 class="actions-heading no-top-margin" *ngIf="question['rootCause']">
                                Root Cause
                            </h3>
                            <div class="action-line" *ngIf="question['rootCause']">
                                {{question['rootCause']}}
                            </div>

                            <h3 class="actions-heading no-top-margin">
                                Detailed Findings
                            </h3>
                            <div class="action-line">
                                {{question['description']}}
                            </div>

                            <h3 class="actions-heading no-top-margin"
                                *ngIf="question['reviewedFrom'] && question['reviewedTo']">
                                Period Reviewed
                            </h3>
                            <div class="action-line" *ngIf="question['reviewedFrom'] && question['reviewedTo']">
                                <span>
                                    From {{ question['reviewedFrom'] | date :'dd MMM y'  }}  To {{ question['reviewedTo'] | date :'dd MMM y'  }} 
                                </span>
                            </div>

                            <h3 class="actions-heading no-top-margin" *ngIf="question['notes']">
                                Notes
                            </h3>
                            <div class="action-line" *ngIf="question['notes']">
                                {{question['notes'] | unescape}}
                            </div>

                            <h3 class="actions-heading no-top-margin" *ngIf="question['actions']?.length > 0">
                                Actions
                            </h3>
                            <div class="action-line" *ngFor="let action of question['actions']">
                                <div class="file-name">
                                    {{action['title']?.slice(0, 25) | unescape}}{{action['title']?.length > 25 ? '...' :
                                    ''}}
                                </div>
                                <div *ngIf="!action['actionId']" class="right-align">
                                     {{ action['dueDate']| date :'dd MMM y'  }}
                                </div>
                                <div *ngIf="action['actionId']" class="right-align">
                                    <a [routerLink]="['/mrr-action-details', action['actionId']]">
                                        <span *ngIf="user['role'] === 'Store'">Edit</span>
                                        <span *ngIf="user['role'] !== 'Store'">View</span>
                                    </a>
                                </div>
                            </div>

                            <h3 class="actions-heading no-top-margin"
                                *ngIf="question['photos']?.length > 0 || question['uploading']">
                                Evidence
                            </h3>
                            <div class="action-line" *ngFor="let photo of question['photos']; let i = index">
                                <div class="file-name">
                                    {{photo['photoName']?.slice(0, 25)}}{{photo['photoName']?.length > 25 ? '...' : ''}}
                                </div>
                                <div class="right-align hide-on-mobile">
                                    {{photo['fileSize'] | filesize}}
                                </div>
                                <div class="right-align">
                                    <a
                                        (click)="viewPhoto(photo['photoName'], question['question_id'], photo['fileName'])">
                                        View
                                    </a>
                                </div>
                            </div>

                            <div class="action-line" *ngIf="question['uploading'] == true">
                                <div class="light-blue">Evidence Uploading...</div>
                                <div class="light-blue right-align">
                                    <span class="padded-icon">
                                        <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
                                    </span>
                                </div>
                            </div>

                            <h3 class="actions-heading  no-top-margin" *ngIf="question['pointInTimeReviewDate']">
                                Reviewed On
                            </h3>
                            <div class="action-line" *ngIf="question['pointInTimeReviewDate']">
                                <div class="file-name">
                                    Point In Time Review Date:   {{ question['pointInTimeReviewDate']| date :'dd MMM y'  }} 
                                </div>
                            </div>
                        </div>

                        <div class="action-buttons">

                            <div class="csa-button-solid action-button note-action">
                                <span class="action-icon">
                                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                                </span>
                                Add Notes
                            </div>

                            <div (click)="uploader.click()" [ngClass]="{
                  'semi-disabled' : question['uploading'] == true,
                  'disabled' : question['photos']?.length >= 10
                }" class="csa-button-solid action-button">
                                <span class="action-icon">
                                    <fa-icon *ngIf="question['uploading'] == true" [icon]="faCircleNotch" [spin]="true">
                                    </fa-icon>
                                    <fa-icon *ngIf="question['uploading'] == false" [icon]="faCamera"></fa-icon>
                                </span>
                                Add Evidence
                                <input hidden type="file" #uploader (click)="uploader.value = null"
                                    (change)="uploadPhoto($event, question['question_id'], surveyDetails['id'])" />
                            </div>

                            <div class="csa-button-solid action-button action-action delete-button">
                                <span class="action-icon">
                                    <fa-icon [icon]="faCalendarPlus"></fa-icon>
                                </span>
                                Add Actions
                            </div>

                        </div>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="submit-button-container center-align"
        *ngIf="user['divisionRoles'][user['role']] >= user['divisionRoles']['Admin']">
        <div *ngIf="surveyDetails['status'] === 'open'">
            <div class="medium-text" *ngIf="!surveyDetails['questions']">
                <span class="subtle-centered-text">
                    There are No findings under this survey.
                </span>
            </div>

            <div >
                <button [disabled]="surveyDetails['questions'].length == 0   && !surveyDetails['finalReportDate'] && !surveyDetails['summaryFindings']" class="csa-button-solid survey-submit"
                    (click)="publishMrr()">
                    <fa-icon class="chip-left-icon" [icon]="faPaperPlane"></fa-icon>
                    <span >
                        Sign off
                    </span>
                </button>
            </div>


        </div>

    </div>
</div>