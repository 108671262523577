import {
  Component, OnInit, ElementRef, AfterViewInit, 
  HostListener, NgZone, ViewChild 
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  faPlus, faPencilAlt, faSortDown, faTimes, faFileAlt 
} from '@fortawesome/free-solid-svg-icons';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { User } from '../../models/user';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

@Component({
  selector: 'app-recurring-survey-details',
  templateUrl: './recurring-survey-details.component.html',
  styleUrls: ['./recurring-survey-details.component.scss'],
})
export class RecurringSurveyDetailsComponent implements OnInit {
  compState = (val1, val2) => val1 == val2;

  faPlus = faPlus;

  faTimes = faTimes;

  faFileAlt = faFileAlt;

  faSortDown = faSortDown;

  faPencilAlt = faPencilAlt;

  dateToday = new Date();

  maxStartDate;

  minStartDate = new Date();

  minEndDate;

  editEndDate = false;

  editStartDate = false;

  editValidity = false;

  groupedSurveysToShow = [];

  groupedTableColumns = [];

  noEntries = '';

  orgStructure;

  searching = false;

  selectedGroup = 0;

  selectedStore = { Store_No: '', Location_Name: 'All Stores' };

  showGroupedBreakdown = true;

  storeDetails = [];

  storeDetailsFetched = false;

  storeName = '';

  storeNameNumber = '';

  storeNumber = '';

  storesReturned = [];

  surveyDetails = {};

  surveyGroups = ['New', 'Started', 'Submitted', 'Closed'];

  surveyID = '';

  surveyResponses = {};

  tableColumns: string[];

  user: User;

  dayFilter = (d: Date): boolean => {
    const day = moment(d, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).day();
    return true;
  };

  constructor(
    private elementRef: ElementRef,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private _ngZone: NgZone,
    private surveyService : SurveysService
  ) {}

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.getAssessmentDetails();
  }

  getAssessmentDetails() {
    this.surveyService
      .getAdminSurveyById(this.surveyID)
      .subscribe((surveysData) => {
        this.surveyDetails = surveysData;
        this.minStartDate.setDate(this.minStartDate.getDate() + 1);
        this.maxStartDate = new Date(this.surveyDetails['endDate']);
        this.minEndDate = new Date(this.surveyDetails['startDate']);

        if (this.surveyDetails['frequency'] == 'monthly') {
          this.dayFilter = (d: Date): boolean => {
            const day = moment(d, [
              'DD/MM/YYYY',
              'DD MMM YYYY',
              moment.ISO_8601,
            ]).date();
            return day === 1;
          };
        } else {
          this.dayFilter = (d: Date): boolean => {
            const day = moment(d, [
              'DD/MM/YYYY',
              'DD MMM YYYY',
              moment.ISO_8601,
            ]).day();
            
            if (!this.surveyDetails['daysOfWeek']) {
              return true;
            } 
            if (day == 0) {
              return this.surveyDetails['daysOfWeek'].indexOf('6') > -1;
            } if (day == 1) {
              return this.surveyDetails['daysOfWeek'].indexOf('0') > -1;
            } if (day == 2) {
              return this.surveyDetails['daysOfWeek'].indexOf('1') > -1;
            } if (day == 3) {
              return this.surveyDetails['daysOfWeek'].indexOf('2') > -1;
            } if (day == 4) {
              return this.surveyDetails['daysOfWeek'].indexOf('3') > -1;
            } if (day == 5) {
              return this.surveyDetails['daysOfWeek'].indexOf('4') > -1;
            } if (day == 6) {
              return this.surveyDetails['daysOfWeek'].indexOf('5') > -1;
            }
          };
        }
        this.getOrgStructure();
        console.log(this);
      });
  }

  getOrgStructure() {
    // this.orgArray = [];
    this.surveyService
      .getOrgStructure(this.user['division'])
      .subscribe((OrgStructure) => {
        this.orgStructure = OrgStructure;
        // this.orgArray.push(this.orgStructure["division"]);
        // for (let i = 0; i < Object.keys(this.orgStructure).length - 1; i++) {
        //   this.orgArray.push(this.orgStructure[this.orgArray[i]]);
        // }
      });
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }

  cancelAssessment() {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      type: 'recurring',
      status: 'closed',
    };

    const confirmationCancel = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          `If you continue, no future '${ 
            this.surveyDetails['title'] 
          }' Assessments will be sent to users. The Currently Published Assessment will remain active until its due date.`,
        closeText: 'Close Assessment',
        cancelText: 'Cancel',
      },
      backdropClass: 'dialog-backdrop',
    });

    confirmationCancel.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        this.surveyService.updateSurvey(updateData).subscribe(
          (message) => {
            if (message['status'] == 200) {
              const confirmationTwo = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Recurring Assessment Closed',
                  message:
                    'This Recurring Assessment has now been closed. The page will now reload to update the status',
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });

              confirmationTwo.afterClosed().subscribe((result) => {
                window.location.reload();
              });
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Close Recurring Assessment",
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          },
          (error) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: "Couldn't Close Recurring Assessment",
                message:
                  `Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    });
  }

  submitForm2(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      endDate: moment(value.dueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
      surveyValidity: Number(this.surveyDetails['surveyValidity']),
      type: 'recurring',
      frequency: this.surveyDetails['frequency'],
      status: this.surveyDetails['status'],
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmationTwo = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'End Date Updated',
              message:
                'The end date has been updated. The page will now reload to update the reminder dates',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmationTwo.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update End Date",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update End Date",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.surveyDetails['dueDate'] = this.getDate(value.dueDate);

    this.editEndDate = false;
    this.maxStartDate = moment(value.dueDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    survey.reset();
  }

  submitForm3(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      startDate: moment(value.publishDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
      surveyValidity: Number(this.surveyDetails['surveyValidity']),
      type: 'recurring',
      frequency: this.surveyDetails['frequency'],
      status: this.surveyDetails['status'],
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmation = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Start Date Updated',
              message:
                'The start date has been updated. The page will now reload to update the Assessment Schedule',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmation.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update Start Date",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Start Date",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.minEndDate = moment(value.publishDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    this.editStartDate = false;
    this.surveyDetails['publishDate'] = this.getDate(value.publishDate);

    survey.reset();
  }

  submitForm4(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      startDate: this.surveyDetails['startDate'],
      endDate: this.surveyDetails['endDate'],
      type: 'recurring',
      frequency: this.surveyDetails['frequency'],
      status: this.surveyDetails['status'],
    };

    if (value['validity'] == true) {
      updateData['surveyValidity'] = 7;
    } else {
      updateData['surveyValidity'] = 5;
    }

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmation = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Days to Complete Updated',
              message:
                'The Days to Complete have been updated. The page will now reload to update the Assessment Schedule',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmation.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update Days to Complete",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Days to Complete",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.minEndDate = moment(value.publishDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    this.editValidity = false;
    this.surveyDetails['publishDate'] = this.getDate(value.publishDate);

    survey.reset();
  }
}
