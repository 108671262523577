import { Dictionary } from 'src/app/types/dictionary';

/**
 * Clears the selected location for a given key in the context of the current instance.
 * This function sets the selected location associated with the provided key to an empty string
 * and updates the corresponding HTML input element value to an empty string, effectively clearing it.
 *
 * @param {string} key - The key associated with the selected location to be cleared.
 * @param {Dictionary<string, any>} selectedLocations - Selected organization node values.
 * @returns {void} - This function does not return a value.
 */
export function clearSelectedLocation(key: string, selectedLocations: Dictionary<string, any>) {
  selectedLocations[key] = '';
  const selectElement = (document.getElementById(`select${key}`) as HTMLInputElement);
  if (selectElement) {
    selectElement.value = '';
  }    
}
