<div class="page-container">
  <h1>Material Risk Review Assessments</h1>
  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">
          <div class="survey-line survey-heading-line no-ripple">
            <div *ngIf="tableColumnPermissions.tags" 
              class="survey-stat template-stat">
              <h4>Tags</h4>
            </div>
            <div class="survey-stat template-stat">
              <h4>Assessment</h4>
            </div>
            <div *ngIf="tableColumnPermissions.sections"
              class="survey-stat template-stat">
              <h4>Sections</h4>
            </div>
            <div *ngIf="tableColumnPermissions.rating"
               class="survey-stat template-stat">
              <h4>Rating</h4>
            </div>
            <div *ngIf="tableColumnPermissions.responses"
              class="survey-stat template-stat hide-on-mobile">
              <h4>Responses</h4>
            </div>
            <div *ngIf="tableColumnPermissions.publishDate"
              class="survey-stat template-stat">
              <h4>Publish Date</h4>
            </div>
            <div *ngIf="tableColumnPermissions.endDate"
              class="survey-stat template-stat">
              <h4>End Date</h4>
            </div>
            <div *ngIf="tableColumnPermissions.status"
              class="survey-stat template-stat">
              <h4>Status</h4>
            </div>
            <div *ngIf="tableColumnPermissions.createNew"
              class="survey-stat template-stat">
              <h4>Create New</h4>
            </div>
            <div *ngIf="tableColumnPermissions.createDraft" 
              class="survey-stat template-stat">
              <h4>Create Draft</h4>
            </div>
          </div>

          <div *ngIf="!isLoading">
            <div *ngFor="let survey of surveysToShow">
              <div class="survey-line">
                <div class="survey-details-link center-vertical" *ngIf="tableColumnPermissions.tags">
                  <app-tags-cell-display [tags]="survey['tagList']" [contextName]="survey['title']"></app-tags-cell-display>                  
                </div>
                <div class="survey-details-link" *ngIf="tableColumnPermissions.assessment">
                  <h3 class="grey-text center-vertical" *ngIf="!tableColumnPermissions.assignedStoreResponses"
                    class="survey-details-link center-vertical" [routerLink]="['/mrr-survey-details', survey['id']]">
                    {{survey['title'].slice(0, 50) | unescape}}
                    <span *ngIf="survey['title']?.length > 50">...</span>
                  </h3>
                  <h3 class="grey-text center-vertical" *ngIf="tableColumnPermissions.assignedStoreResponses"
                    class="survey-details-link center-vertical" [routerLink]="['/mrr-response', survey['id']]">
                    {{survey['title'].slice(0, 50) | unescape}}
                    <span *ngIf="survey['title']?.length > 50">...</span>
                  </h3>
                </div>
                <!-- Sections -->
                <div
                  *ngIf="tableColumnPermissions.sections"
                  class="survey-details-link center-vertical" [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h3>{{survey['sectionHeading']?.length || '0' }}</h3>
                </div>
                <!-- Rating -->
                <div *ngIf="tableColumnPermissions.rating"
                  class="survey-details-link center-vertical" [routerLink]="['/mrr-response', survey['id']]">
                  <h3>{{survey['rating']}}</h3>
                </div>
                <!-- Responses -->
                <div
                  *ngIf="tableColumnPermissions.responses"
                  class="survey-details-link hide-on-mobile center-vertical"
                  [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h3>{{survey['activeResponseCount']}}</h3>
                </div>
                <!-- Publish Date -->
                <div *ngIf="tableColumnPermissions.publishDate" class="survey-details-link center-vertical"
                  [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h3>  {{ survey.startDate | date :'dd MMM y'  }} </h3>
                </div>
                <!-- End Date -->
                <div
                  *ngIf="tableColumnPermissions.endDate"
                  class="survey-details-link center-vertical" [routerLink]="['/mrr-survey-details', survey['id']]">
                  <h3>  {{survey.endDate| date :'dd MMM y'  }} </h3>
                </div>
                <!-- Status -->
                <div *ngIf="tableColumnPermissions.status"
                  class="survey-details-link center-vertical"
                  [routerLink]="['/mrr-response', survey['id']]">
                  <h3>{{survey['mrrStatus']}}</h3>
                </div>
                <!-- Create New -->
                <div *ngIf="tableColumnPermissions.createNew"
                  class="survey-details-link center-vertical" [routerLink]="['/mrr-response', survey['id']]">
                  <div class="survey-details-link survey-button">
                    <div class="survey-button">
                      <a [ngClass]="{'disabled-link' :  survey['mrrStatusBtn'] === 'Closed'}"
                          [routerLink]="['/mrr-response', survey.id]">
                          <button class="csa-button-solid" [ngClass]="{'disabled-btn' :  survey['mrrStatusBtn'] === 'Closed'}">
                            <fa-icon class="chip-left-icon" *ngIf="!(survey['mrrStatusBtn'] === 'Closed')" [icon]="faPlayCircle"></fa-icon>
                            <span>{{survey['mrrStatusBtn']}}</span>
                          </button>
                      </a>
                  </div>
                  </div>
                </div>
                <!-- Create Draft -->
                <div class="survey-details-link survey-button" *ngIf="tableColumnPermissions.createDraft">
                  <button class="csa-button-solid" (click)="createMRR(survey)">
                    <span>
                      <fa-icon [icon]="faPlus"></fa-icon> Create
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <h3 class="none-found-message" *ngIf="surveysToShow.length == 0 && !showNotAssignedToStoreMessage">
              You currently have no {{surveyGroup}} Material Risk Review Assessments.
            </h3>
            <h3 class="none-found-message" *ngIf="surveysToShow.length == 0 && showNotAssignedToStoreMessage">
              You are not assigned to a store.
            </h3>
          </div>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <mat-paginator #paginator [pageIndex]=pageIndex [pageSize]=pageSize [length]="totalCount"
        [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]=false (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>
</div>
