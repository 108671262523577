/**
 * Returns a new array excluding all values from `array` that are present in `excluded`.
 *
 * @template T - The type of elements in the arrays.
 * @param {T[]} array - The input array to filter.
 * @param {T[]} excluded - The array of values to exclude from the output.
 * @returns {T[]} A new array containing only the values that do not exist in the `excluded` array.
 * @example
 * excludeValues([1, 2, 3, 4], [2, 4]); // Returns: [1, 3]
 * excludeValues(["apple", "banana", "cherry"], ["banana", "cherry"]); // Returns: ["apple"]
 * excludeValues([true, false, true], [true]); // Returns: [false]
 */
export function excludeValues<T>(array: T[], excluded: T[]): T[] {
  if (!array || !array.length) return [];
  return array.filter((value) => !excluded.includes(value));
}
