/**
 * Returns the lower case role level name for the organization property name.
 *
 * @param {string} orgPropertyName - The organization property name.
 * @returns {string} The role level for the corresponding organization property name.
 */
export function getRoleLevelFromOrgPropertyName(orgPropertyName: string): string {
  if (orgPropertyName?.toLowerCase() === 'groupid') {
    return 'group';
  } 
  if (orgPropertyName?.toLowerCase() === 'storeid') {
    return 'store';
  }
  return orgPropertyName?.toLowerCase();
}
