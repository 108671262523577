<div class="overlay">
    <ul class="userMenuList">
        <li>
            <button (click)="auth.logout()">
                <app-icon iconName="logOut"></app-icon>
                <span class="core-body-md">Log Out</span>
            </button>
        </li>

        <div class="disableAutoLogout" *ngIf="isDisableAutoLogoutAvailable">
            <div class="lineBreak"></div>

            <mat-slide-toggle color="primary" [ngModel]="!isAutoLogoutEnabled" (change)="toggleAutoLogout($event)">
                Disable Auto Logout
            </mat-slide-toggle>
        </div>
    </ul>
</div>