import { Injectable } from '@angular/core';
import { Entity } from '../models/entity';

@Injectable({
  providedIn: 'root',
})
export class EntitiesMapper {
  public mapEntityResponseToEntity(
    entityResponse: object
  ): Entity {
    const entity = <Entity>{
      id: entityResponse['LEVEL_ID'],
      name: entityResponse['NAME'],
      division: entityResponse['DIVISION'],
      parentId: entityResponse['PAREBT_LEVEL_ID'],
    };
    return entity;
  }

  public mapEntitiesResponseToEntities(
    entitiesResponse: object[]
  ): Entity[] {
    if (!entitiesResponse || entitiesResponse.length === 0) {
      return [];
    }
    return entitiesResponse.map((entityResponse: object) => this.mapEntityResponseToEntity(entityResponse));
  }
}
