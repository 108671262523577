import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Tag, TagType } from 'src/app/types/tag';

@Component({
  selector: 'app-tags-dialog',
  templateUrl: './tags-dialog.component.html',
  styleUrls: ['./tags-dialog.component.scss']
})
export class TagsDialogComponent implements OnInit {
  title: string = '';

  tags: Tag[] = [];

  generateTags: Tag[] = [];

  userAddedTags: Tag[] = [];
  
  constructor(
    private dialogRef: MatDialogRef<TagsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.title = this.data?.title ?? '';
    this.tags = this.data?.tags ?? [];
    this.generateTags = this.tags ? this.tags?.filter((tag: Tag) => tag.type !== TagType.Custom && tag.type !== TagType.Default) : [];
    this.userAddedTags = this.tags ? this.tags?.filter((tag: Tag) => tag.type === TagType.Custom) : [];
  }

  closeMe(result?) {
    this.dialogRef.close(result);
  }
}
