import {
  Component, ElementRef, OnInit, ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faTimes,
  faPlus,
  faSortDown,
  faSearch,
  faTrash,
  faPencilAlt,
  faThList,
  faUserCircle,
  faTimesCircle,
  faUser,
  faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { splitRoleByCaps } from 'src/app/utils/user/split-role-by-caps';
import { AccessManagementDialogComponent } from '../access-management-dialog/access-management-dialog.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

import { MessagesComponent } from '../messages/messages.component';
import { User } from '../../models/user';
import { SortPipe } from '../../custom-pipes/sort.pipe';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.scss'],
  providers: [
    SortPipe
  ]
})
export class AccessManagementComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faSortDown = faSortDown;

  faPlus = faPlus;

  faTrash = faTrash;

  faPencilAlt = faPencilAlt;

  faSearch = faSearch;

  faUserCircle = faUserCircle;

  faTimesCircle = faTimesCircle;

  faRedoAlt = faRedoAlt;

  faTimes = faTimes;

  availableLocations: object = {};

  availableRoles: string[] = [];

  currentLevel: string;

  hasNext: string;

  index: number = 0;

  locationsOrdered: string[] = [];

  nextLevel: string;

  lastLevel: string;

  pageIndex: number = 0;

  pageSize: number = 10;

  roleGroups: string[] = [];

  searchTimer;

  selectedLocations: object = {};

  selectedRole: string = 'All Roles';

  showLocations: object = {};

  tableColumns: string[] = [];

  textFilter: string = '';

  totalCount: number = 10000000000000;

  user: User;

  usersToShow: object[] = [];

  isLoading = false;

  constructor(
    private elementRef: ElementRef,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private sortPipe: SortPipe,
    private surveyService: SurveysService
  ) { }

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    let aboveStoreCount = 0;
    console.log(this.user);
    for (const [key, value] of Object.entries(this.user['divisionRoles'])) {
      if (
        key != 'StoreLossPartner'
        && key != 'NationalView'
        && value > this.user['divisionRoles']['MultiStore']
        && value < this.user['divisionRoles']['Admin']
      ) {
        aboveStoreCount += 1;
      }
      if (
        value > this.user['divisionRoles']['Store']
        && value != this.user['divisionRoles']['Support']
      ) {
        this.availableRoles.push(key);
      }
    }

    this.availableRoles.sort((a, b) => this.user['divisionRoles'][b] - this.user['divisionRoles'][a]);

    if (
      aboveStoreCount > 1
    ) {
      this.availableRoles.push('Above Store Roles');
    }

    let key = this.user['orgStruct']['division'];
    while (this.user['orgStruct'][key]) {
      this.selectedLocations[key] = '';
      this.showLocations[key] = false;
      if (this.locationsOrdered.indexOf(key) === -1) this.locationsOrdered.push(key);
      key = this.user['orgStruct'][key];
    }

    this.getTableColumns();
    this.getUsers();
  }

  createUser(): void {
    const dialogRef = this.dialog.open(AccessManagementDialogComponent, {
      data: {
        type: 'create',
      },
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'success') {
        this.getUsers();
      }
    });
  }

  updateUser(userData): void {
    if (!this.user['super'] && userData['ROLE'] == 'Super') {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'Cannot Edit User',
          message: 'You do not have access to edit this user. Only Super Users can edit other Super Users.'
        },
        disableClose: true,
        backdropClass: 'dialog-backdrop',
      });
    } else if (this.user['email'] == userData['EMAIL_ADDRESS']) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'Cannot Edit User',
          message: `You do not have access to edit this user. Please email ${this.user['supportEmail']} for support.`
        },
        disableClose: true,
        backdropClass: 'dialog-backdrop',
      });
    } else {
      const dialogRef = this.dialog.open(AccessManagementDialogComponent, {
        data: {
          userData,
          type: 'update',
        },
        backdropClass: 'dialog-backdrop',
        minWidth: '70%',
        minHeight: 'calc(100vh - 90px)',
        maxWidth: '90vw',
        maxHeight: '90vh',
        height: 'auto',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == 'delete') {
          if (this.totalCount - this.index == 1 && this.totalCount != 1) this.paginator.previousPage();
          else this.getUsers();
        } else if (result == 'success') {
          this.getUsers();
        }
      });
    }
  }

  createLevelQuery(): string {
    let key = 'division';
    let query = '';
    while (key != this.nextLevel) {
      if (key == 'division') query = `?division=${this.user['division']}`;
      else query += `&${key}=${this.selectedLocations[key]}`;

      key = this.user['orgStruct'][key];
    }
    return query;
  }

  resetSelectedBelowLevel(): void {
    switch (this.selectedRole) {
      case 'StateManager':
        this.lastLevel = this.user['orgStruct']['state'];
        break;
      case 'OperationManager':
        this.lastLevel = this.user['orgStruct']['zone'];
        break;
      case 'GroupManager':
        this.lastLevel = this.user['orgStruct']['groupID'];
        break;
      case 'RegionManager':
        this.lastLevel = this.user['orgStruct']['region'];
        break;
      case 'AreaManager':
        this.lastLevel = this.user['orgStruct']['area'];
        break;
      case 'StoreLossManager':
        this.lastLevel = this.user['orgStruct']['state'];
        break;
      default:
        this.lastLevel = this.user['orgStruct']['division'];
        break;
    }
    let key = this.nextLevel;
    while (this.user['orgStruct'][key]) {
      this.selectedLocations[key] = '';
      if (this.selectedRole == 'Above Store Roles') this.showLocations[key] = (key == this.user['orgStruct']['division']);
      else this.showLocations[key] = (key == this.nextLevel && key != this.lastLevel);
      key = this.user['orgStruct'][key];
    }
  }

  getUsersToShow(selection, level): void {
    clearTimeout(this.searchTimer);
    setTimeout(() => {
      this.isLoading = true;
      this.usersToShow = [];
    }, 0);

    if (
      this.selectedRole != 'Above Store Roles'
      && !(
        this.selectedRole != 'StoreLossPartner'
        && this.selectedRole != 'NationalView'
        && this.user['divisionRoles'][this.selectedRole] > this.user['divisionRoles']['MultiStore']
        && this.user['divisionRoles'][this.selectedRole] < this.user['divisionRoles']['Admin']
      )
    ) {
      this.getUsers();
      return;
    }

    this.currentLevel = level;
    this.nextLevel = this.user['orgStruct'][level];

    this.resetSelectedBelowLevel();
    if (selection) this.selectedLocations[this.currentLevel] = selection;

    if (this.user['orgStruct'][this.nextLevel]) {
      this.surveyService
        .getNextLevel(this.createLevelQuery())
        .subscribe((data) => {
          this.availableLocations[this.nextLevel] = this.sortPipe.transform(data[this.nextLevel]);
          this.getUsers();
        });
    } else {
      this.getUsers();
    }
  }

  getNext(event: PageEvent): void {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getUsers();
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.usersToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.usersToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  tableColumnByLevel(lastLevel): string[] {
    const index = this.locationsOrdered.indexOf(lastLevel);
    return this.locationsOrdered.slice(0, index + 1);
  }

  getTableColumns(): void {
    switch (this.selectedRole) {
      case 'StateManager':
        this.tableColumns = ['name', 'role', ...this.tableColumnByLevel('state'), 'email'];
        break;
      case 'OperationManager':
        this.tableColumns = ['name', 'role', ...this.tableColumnByLevel('zone'), 'email'];
        break;
      case 'GroupManager':
        this.tableColumns = ['name', 'role', ...this.tableColumnByLevel('groupID'), 'email'];
        break;
      case 'RegionManager':
        this.tableColumns = ['name', 'role', ...this.tableColumnByLevel('region'), 'email'];
        break;
      case 'AreaManager':
        this.tableColumns = ['name', 'role', ...this.tableColumnByLevel('area'), 'email'];
        break;
      case 'StoreLossManager':
        this.tableColumns = ['name', 'role', ...this.tableColumnByLevel('state'), 'email'];
        break;
      default:
        this.tableColumns = ['name', 'role', 'email'];
        break;
    }
  }

  getPreviousLevel(level): string {
    return Object.keys(this.user['orgStruct']).find((key) => this.user['orgStruct'][key] == level);
  }

  locationString(key): string {
    if (key == 'state' || key == 'region') return this.selectedLocations[key];
    if (key == 'groupID') return `Group ${this.selectedLocations[key]}`;
    return `${key} ${this.selectedLocations[key]}`;
  }

  locationStringAll(key): string {
    if (key == 'groupID') key = 'group';
    return `All ${key}s`;
  }

  getUsers(): void {
    setTimeout(() => {
      this.isLoading = true;
      this.usersToShow = [];
    }, 0);

    let query = `?DIVISION=${this.user['division']}`;
    if (this.selectedRole && this.selectedRole != 'All Roles') {
      if (this.selectedRole == 'Above Store Roles') {
        for (const role in this.user['divisionRoles']) {
          if (
            this.user['divisionRoles'][role] > this.user['divisionRoles']['MultiStore']
            && this.user['divisionRoles'][role] < this.user['divisionRoles']['NationalView']
          ) {
            query += `&ROLE[]=${role}`;
          }
        }
      } else {
        query += `&ROLE[]=${this.selectedRole}`;
      }
    } else {
      this.availableRoles.forEach((role) => {
        if (role != 'Above Store Roles') query += `&ROLE[]=${role}`;
      });
    }

    for (const [key, value] of Object.entries(this.selectedLocations)) {
      if (value != '') {
        query
          += `&${key == 'groupID' ? 'GROUP_NO' : key.toUpperCase()
          }=${value}`;
      }
    }

    if (this.textFilter) query += `&SEARCH=${this.textFilter}`;

    this.surveyService
      .getUserAccessList(`/${this.index}`, `/${this.pageSize}`, query)
      .subscribe((message) => {
        this.usersToShow = message['users'];
        this.hasNext = message['has_next'];
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }

  resetAllFilters(): void {
    this.textFilter = '';
    this.selectedRole = 'All Roles';
    this.resetRoleFilters();
    this.getUsers();
  }

  resetRoleFilters(): void {
    Object.keys(this.selectedLocations).forEach((key) => this.selectedLocations[key] = '');
    Object.keys(this.showLocations).forEach((key) => this.showLocations[key] = false);
    this.resetPagination();
    this.getTableColumns();
  }

  resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
  }

  showColumn(column: string): boolean {
    return this.tableColumns.includes(column);
  }

  // Exposes global function splitRoleByCaps to template
  splitRoleByCaps(value): string {
    return splitRoleByCaps(value, this.user['division']);
  }

  updateSearch(): void {
    clearTimeout(this.searchTimer);
    setTimeout(() => {
      this.isLoading = true;
      this.usersToShow = [];
    }, 0);
    this.searchTimer = setTimeout(() => {
      this.resetPagination();
      this.getUsers();
    }, 1000);
  }
}
