/* eslint-disable no-useless-escape */
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

/**
 * This is a generic error handler used by catchError(handleError).
 *
 * @param {HttpErrorResponse} error - Http error response stream container the error.
 */
export function handleError(error: HttpErrorResponse) {
  if (error.status == 0) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error('Backend returned error:', error);
  }
  // Return an observable with a user-facing error message.
  if (error.status == 500) {
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
  return throwError(() => error);
}

/**
 * Extracts a user-friendly error message from an HTTP error response in the following order of priority:
 * 1. User-specific error message from error.error.userMessage.
 * 2. Embedded error message from error.error.message.
 * 3. Default error message from error.message.
 *
 * @param {HttpErrorResponse} error - The HTTP error response containing the error details.
 * @returns {string} The extracted error message based on the priority mentioned above.
 */
export function getUserMessageFromHttpErrorResponse(error: HttpErrorResponse): string {
  if (error?.error?.userMessage) {
    // return user friendly error message
    return error.error.userMessage;
  }
  if (error?.error?.message) {
    // extract and return embedded error message
    return extractErrorMessage(error.error.message);
  }
  // return the top level error message when all else fails
  console.log('Error:', error);
  return 'Something bad happened; please try again later.';
}

/**
 * Extracts a message enclosed within double quotes from the body of a formatted string.
 * For example, given the input "(1062, \"Duplicate entry 'test12' for key 'SUBFEATURE_KEY'\")",
 * it returns the message "Duplicate entry 'test12' for key 'SUBFEATURE_KEY'."
 * If no enclosed quotes are found, it returns the original message.
 *
 * @param {string} message - The input string containing a formatted message.
 * @returns {string} The extracted message enclosed in double quotes or the original message if no quotes are found.
 */
export function extractErrorMessage(message: string): string {
  // Define the regular expression to match the substring between encoded quotes
  const regex = /\"(.*?)\"/;
  
  // Use the regular expression to match and extract the embedded message
  const match = message.match(regex);
    
  // Check if a match is found
  if (match) {
    // The entire match is the substring between quotes
    let extractedString = match[0];

    // Remove the leading and trailing escaped quotes
    extractedString = extractedString.replace(/\"/g, '');

    // Output the extracted substring
    return extractedString;
  }
  // return original message when no embedded message is found
  return message;
}
