import {
  Component, OnDestroy, OnInit, ViewChild 
} from '@angular/core';
import {
  faBars, faCheck, faUser, faUserFriends, faHome, faEnvelope 
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment/moment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject, takeUntil } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { LandingPageService } from 'src/app/landing-pages/landing-page.service';
import { AutoLogoutService } from 'src/app/common-services/auto-logout.service';
import { environment } from '../../../environments/environment';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

import { StateService } from '../../common-services/state.service';
import { User } from '../../models/user';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { LoaderService } from '../../common-services/loader.service';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  faCheck = faCheck;

  faBars = faBars;

  faUser = faUser;

  faUserFriends = faUserFriends;

  faHome = faHome;

  faEnvelope = faEnvelope;

  userName = '';

  userRole = '';

  supportEmail = '';

  availableDivisions = [];

  version;

  user: User = {};

  date = moment().format();

  @ViewChild('divisionSelect') divisionSelect: MatExpansionPanel;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private router: Router,
    public loaderService: LoaderService,
    public csaAuthService: CsaAuthService,
    private autoLogout: AutoLogoutService,
    private cookieService: CookieService,
    private csaBackEnd: CsaBackEndService,
    private state: StateService,
    private surveyService : SurveysService,
    private landingPageService : LandingPageService
  ) { }

  ngOnInit() {
    this.csaAuthService.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      if (user) {
        this.supportEmail = user.supportEmail;

        this.userRole = user['role'];
        if (user['firstName']) {
          this.userName = user['firstName'];
        } else if (user['lastName']) {
          this.userName = user['lastName'];
        } else if (user['storeID']) {
          this.userName = `${user['storeID']} Team`;
        }
        this.user = user;

        this.surveyService
          .getNextLevel(`?domain=${this.user['domain']}`)
          .subscribe((divisionList) => {
            this.availableDivisions = divisionList['division'];
          });
      }
    });
  }

  onChange($event) {
    if ($event.checked == false) {
      this.autoLogout.turnAutoLogoutOn();
    } else if ($event.checked == true) {
      this.autoLogout.turnAutoLogoutOff();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  switchUser() {
    this.csaAuthService.clearOverrides();
    this.user = this.csaAuthService.user;
    // this.router.navigateByUrl('/support-landing');
    if (window.location.hostname.indexOf('localhost') == -1) {
      window.location.href = `https://${window.location.hostname}`;
    } else {
      window.location.href = 'http://localhost:3000/';
    }
  }

  switchDivision(drawer, division) {
    if (division != this.user['division']) {
      this.divisionSelect.close();
      drawer.close();
      const role = this.user['role'];
      this.user = this.csaAuthService.user;

      this.surveyService.getOrgStructure(division).subscribe((orgStruct) => {
        this.landingPageService.getRoles().subscribe((data) => {
          this.csaAuthService.setUserOverride('divisionRoles', data[division]);
          this.csaAuthService.setUserOverride('orgStruct', orgStruct);
          this.csaAuthService.setUserOverride('role', role);
          this.csaAuthService.setUserOverride('super', true);
          this.csaAuthService.setUserOverride('division', division);

          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';

          if (window.location.pathname.indexOf('access-management') > -1) {
            this.router.navigate(['/access-management']);
          } else if (window.location.pathname.indexOf('store-dashboard') > -1) {
            this.router.navigate(['/store-dashboard']);
          } else if (window.location.pathname.indexOf('more-evidence') > -1) {
            this.router.navigate(['/more-evidence']);
          } else {
            this.router.navigate(['/']);
          }
        });
      });
    }
  }
}
