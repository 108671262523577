<div mat-dialog-title>
  <div class="close-container">
    <span class="info-icon med-grey" (click)="closeMe()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </span>
  </div>
</div>

<div mat-dialog-content>
  <form [formGroup]="mrrForm" (submit)="sendSurvey(mrrForm, mrrForm.value); submitButton.disabled = true;"
    (keydown.enter)="$event.preventDefault()">

    <div class="create-survey-form">
      <h2>New Material Risk Review </h2>
      <h1 class="create-survey-headings left-align">Assessment Title:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="title">Assessment Title</mat-label>
        <input spellcheck="true" #title formControlName="title" maxLength="50" matInput class="custom-control-input"
          name="title" required>
        <mat-hint align="end">{{title['value'].length || 0}}/50</mat-hint>
        <mat-error>
          <span *ngIf="mrrForm.controls.title.errors?.['special']">
            Please enter an assessment title without special characters
          </span>
          <span *ngIf="mrrForm.controls.title.errors?.['whitespace']">
            This field cannot contain only whitespace
          </span>
          <span *ngIf="mrrForm.controls.title.errors?.['required']">
            This field is required
          </span>
        </mat-error>
      </mat-form-field>

      <div *ngIf="user['domain'] !== 'Bigw'">
        <h1 class="create-survey-headings left-align">Division:</h1>
        <mat-form-field appearance="outline" color="primary">
          <mat-label class="" for="division">Division</mat-label>
          <mat-select #division matInput formControlName="division" class="custom-control-input" name="division"
            required>
            <mat-option *ngFor="let division of divisions" [value]="division">
              {{division}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <h1 class="create-survey-headings left-align">Sections:</h1>
        <mat-form-field appearance="outline" color="primary">
          <mat-label class="" for="title">Press "Enter" or click the icon to add a section</mat-label>
          <input spellcheck="true" formControlName="section" #section maxLength="50" matInput
            class="custom-control-input" name="section"
            (keyup.enter)="addSection(section['value']); section.blur(); section.focus()">
          <button matSuffix mat-icon-button
            [disabled]="mrrForm.controls.section.invalid || section['value'].trim().length == 0"
            (click)="addSection(section['value']); section.blur(); section.focus()">
            <mat-icon
              [color]="mrrForm.controls.section.invalid || section['value'].trim().length == 0 ? '' : 'primary'">
              playlist_add</mat-icon>
          </button>
          <mat-hint align="end">{{section['value'].length || 0}}/50</mat-hint>
          <mat-error>
            <span *ngIf="mrrForm.controls.section.errors?.['special']">
              Please enter a section heading without special characters
            </span>
            <span *ngIf="mrrForm.controls.section.errors?.['duplicate']">
              This section heading already exists
            </span>
            <span *ngIf="mrrForm.controls.section.errors?.['maximum']">
              Maximum of 30 section headings allowed
            </span>
            <span *ngIf="mrrForm.controls.section.errors?.['whitespace']">
              This field cannot contain only whitespace
            </span>
          </mat-error>
        </mat-form-field>

        <mat-chip-list #chipList aria-label="sections">
          <mat-chip color="primary" selected *ngFor="let section of selectedSections; let i = index"
            (removed)="removeSection(i);">
            {{i+1}}. {{section}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <span *ngIf="selectedSections.length == 30">Maximum number of sections</span>
        </mat-chip-list>
      </div>

      <h1 class="create-survey-headings left-align padding-top">Available Dates:</h1>
      <div>Select available dates:</div>
      <div class="centered-fields">
        <div class="multiple-field" [style.--numShown]="2">
          <mat-form-field appearance="outline" color="primary">
            <mat-label>From</mat-label>
            <input name="startDate" (keydown)="false" [min]="today" formControlName="startDate" matInput
              [matDatepicker]="datepickerStart" (click)="datepickerStart.open()" required>
            <mat-datepicker-toggle matSuffix [for]="datepickerStart"></mat-datepicker-toggle>
            <mat-datepicker #datepickerStart></mat-datepicker>
            <mat-error>Please select a date from today onwards</mat-error>
          </mat-form-field>
        </div>
        <div class="multiple-field" [style.--numShown]="2">
          <mat-form-field appearance="outline" color="primary">
            <mat-label>To</mat-label>
            <input name="endDate" (keydown)="false" [min]="mrrForm.value.startDate" formControlName="endDate" matInput
              [matDatepicker]="datePickerEnd" (click)="datePickerEnd.open()" required>
            <mat-datepicker-toggle matSuffix [for]="datePickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #datePickerEnd></mat-datepicker>
            <mat-error>Please select a date after the start date</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="submit-button-container center-align pt2">
        <div *ngIf="!mrrForm.valid || selectedSections.length == 0 || selectedSections.length > 30"
          class="subtle-centered-text">
          Please complete all fields correctly to send. {{selectedSections.length == 0 ? 'You must provide at least one section.' : ''}}
        </div>
        <button #submitButton
          [disabled]="!mrrForm.valid || selectedSections.length == 0 || selectedSections.length > 30"
          class="csa-button-solid survey-submit" type="submit">
          <span>
            <fa-icon [icon]="faPaperPlane"></fa-icon>
            <span> Save Assessment</span>
          </span>
        </button>
      </div>
    </div>
  </form>
</div>