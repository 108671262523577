<div class="dialog-contents">
      <h1 id="dialog-heading">{{data.heading}}</h1>

      <h3 id="dialog-message" [innerHTML]="data.message"></h3>
      
      <h1    *ngIf="data.AuditCase"   id="dialog-heading">For  &nbsp; - {{data.storeID}}  &nbsp; - {{data.storeName}}</h1>
      <h4 *ngIf="data.idleMessage">{{idleState}}</h4>

  <div class="admin-button-container">
    <div *ngIf="data.closeText">
      <button class="csa-button-solid" (click)="submit(data.navigateRef)">{{data.closeText}}</button>
    </div>

    <div *ngIf="!data.closeText">
      <button class="csa-button-solid" (click)="submit(data.navigateRef)">{{confirmText}}</button>
    </div>

    <div *ngIf="data.cancelText">
      <button class="csa-button-outline" (click)="cancel(data.navigateRef)">{{data.cancelText}}</button>
    </div>

    <div *ngIf="data.retryText">
      <button class="csa-button-outline" (click)="retry()">{{data.retryText}}</button>
    </div>

  </div>


</div>
