<div class="page-container">

  <h1>Select a Location</h1>
  <div class="content-container">
    <div class="audit-tool-card more-list-screen-card">
      <form class="audit-tool-cards-container">
        <p class="instructions">You are able to access iVerify for multiple locations, please select the location you would like to use.</p>
        <div class="access-management-form">
          <div class="table-container">
            <table mat-table [dataSource]="dataSource"> 
              <!-- Option Column -->
              <ng-container matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef class="header-cell header-cell-left"></th>
                <td mat-cell *matCellDef="let row">
                  <div class="custom-radio-group">
                    <label class="custom-radio">
                      <input
                        type="radio"
                        [name]="row['name']"
                        [value]="row['value']"
                        [(ngModel)]="selectedValue"
                        (change)="onChange(row['value'])"
                      />
                      <span class="radio-custom"></span>
                    </label>
                  </div>
                </td>
              </ng-container>
          
              <!-- State Column -->
              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef class="header-cell"> State </th>
                <td mat-cell *matCellDef="let row"> {{row['state']}} </td>
              </ng-container>
          
              <!-- Zone Column -->
              <ng-container matColumnDef="zone">
                <th mat-header-cell *matHeaderCellDef class="header-cell"> Zone </th>
                <td mat-cell *matCellDef="let row"> {{row['zone']}} </td>
              </ng-container>
          
              <!-- Group Column -->
              <ng-container matColumnDef="group">
                <th mat-header-cell *matHeaderCellDef class="header-cell header-cell-right"> Group </th>
                <td mat-cell *matCellDef="let row"> {{row['group']}} </td>
              </ng-container>
          
              <!-- Region Column -->
              <ng-container matColumnDef="region">
                <th mat-header-cell *matHeaderCellDef class="header-cell"> Region </th>
                <td mat-cell *matCellDef="let row"> {{row['region']}} </td>
              </ng-container>
          
              <!-- Area Column -->
              <ng-container matColumnDef="area">
                <th mat-header-cell *matHeaderCellDef class="header-cell"> Area </th>
                <td mat-cell *matCellDef="let row"> {{row['area']}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr 
                mat-row 
                *matRowDef="let row; columns: displayedColumns; let i = index;"
                class="row-item"
                [ngClass]="{'row-item-even': i % 2 === 1}"
              ></tr>
            </table>
            <app-table-loading-state *ngIf="isLoading"></app-table-loading-state>
            <app-table-empty-state
              *ngIf="!isLoading && (!locationList || locationList?.length === 0)"
              emptyStateText="No user locations."
            >
            </app-table-empty-state>
          </div>

        </div>

        <div class="buttons">
          <button [disabled]="!locationList || locationList.length === 0" class="csa-button-solid large" (click)="save()">
           Save
          </button>
          <button class="csa-button-solid large outline" (click)="cancel()">
            Cancel
          </button>                
        </div>
        
      </form>
    </div>
  </div>
</div>