/**
 * Retrieves an ordered list of locations from the given organizational structure.
 *
 * This function takes an organizational structure object and returns an array of location names 
 * in the order they appear within the structure. The traversal starts from the 'division' key 
 * and proceeds to the subsequent nodes specified by each key's value.
 *
 * @param {object} orgStructure - The organizational structure represented as an object where each 
 * key points to the next node in the structure.
 * @param {boolean} [includeLastNode=false] - Whether to include the last node in the ordered list.
 * @returns {string[]} An array of location names in the order they are found in the structure.
 */
export function getLocationsOrdered(orgStructure: object, includeLastNode: boolean = false): string[] {
  const locationsOrdered: string[] = [];
  let key = orgStructure['division'];

  // Iterate through the organization structure
  while (orgStructure[key]) {
    // If the location is not already in the ordered list, add it
    if (locationsOrdered.indexOf(key) === -1) {
      locationsOrdered.push(key);
    }
    // Move to the next organization node
    key = orgStructure[key]; // get next org node
  }

  // Include the last node if the option is set and it's not already in the list
  if (includeLastNode && key && !locationsOrdered.includes(key)) {
    locationsOrdered.push(key);
  }
  
  return locationsOrdered;
}
