import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any, searchText: any, filterBy: any): any {
    if (searchText == null) return items;

    return items.filter((item) => item[filterBy].indexOf(searchText) > -1);
  }
}
