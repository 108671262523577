import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren 
} from '@angular/core';

@Component({
  selector: 'app-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss']
})
export class AutocompleteDropdownComponent implements OnChanges, OnInit {
  @Input() searchTerm = '';
  
  @Input() searchResults: object[] = [];

  @Input() overlayWidthPercentage = 50;

  @Input() emptyMessage = '';

  @Input() showEmptyMessage = false;

  @Input() placeholder = '';

  @Input() accessibilityLabel = '';

  @Output() searchTermChanged = new EventEmitter<string>();

  @Output() selectedItem = new EventEmitter<object>();

  @ViewChild('textSearchTerm') textSearchTerm: ElementRef;
  
  @ViewChildren('searchResultListItem') searchResultListItem: QueryList<ElementRef>;

  openOverlay = false;

  _overlayWidthPercentage = '';

  constructor() { }

  ngOnInit(): void {
    this._overlayWidthPercentage = `${this.overlayWidthPercentage}%`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchTerm) {
      // console.log(`Auto-complete - search term changed: ${this.searchTerm}`);
    }
    if (changes.searchResults) {
      // console.log('Auto-complete - search results changed:', this.searchTerm, this.searchResults);
      this.openOverlay = this.searchResults.length > 0;
    }
  }  

  onSearchTermInput(event) {
    const value = event.target.value;
    this.updateSearchTerm(value.trim());
  }  

  onSearchTermBlur(event) {
    event.preventDefault();
    if (this.searchResultListItem?.length > 0) {
      this.searchResultListItem.toArray()[0].nativeElement.focus();
    }
  }

  onSearchTermFocus() {
    this.openOverlay = this.searchResults.length > 0;
  }

  clearSearchTerm() {
    this.updateSearchTerm('');
  }

  keydown(event: KeyboardEvent, currentIndex: number) {
    event.preventDefault();
    const key = event.key.toLowerCase();
    if (key === 'arrowup') {
      this.focusPreviousListItem(currentIndex);
    } else if (key === 'arrowdown') {
      this.focusNextListItem(currentIndex);
    } else if (key === 'tab') {
      if (event.shiftKey) {
        this.focusPreviousListItem(currentIndex);
      } else {
        this.focusNextListItem(currentIndex);
      } 
    }    
  }

  backdropClick() {
    this.openOverlay = false;
  }

  selectItem(value) {
    this.selectedItem.emit(value);
    this.clearSearchTerm();
  }

  private updateSearchTerm(searchTerm: string) {
    const newSearchTerm = searchTerm.trim();
    this.searchTerm = newSearchTerm;
    this.searchTermChanged.emit(newSearchTerm);
  }

  private focusPreviousListItem(currentIndex: number) {
    const listItems = this.searchResultListItem.toArray();
    const previousListItem = currentIndex - 1;
    if (previousListItem < 0) {
      this.textSearchTerm.nativeElement.focus();
      return;
    }
    listItems[previousListItem].nativeElement.focus();
  }

  private focusNextListItem(currentIndex: number) {
    const listItems = this.searchResultListItem.toArray();
    const nextListItem = currentIndex + 1;
    if (listItems.length - 1 < nextListItem) {
      return;
    }
    listItems[nextListItem].nativeElement.focus();
  }
}
