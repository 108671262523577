import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  tap, finalize, first, map, take, mergeMap, switchMap 
} from 'rxjs/operators';
import { CsaAuthService } from '../auth/csa-auth.service';
import { LoaderService } from '../common-services/loader.service';
import { DomainService } from '../domain/domain.service';

@Injectable()
export class CsaLoaderInterceptor implements HttpInterceptor {
  /**
   * This interceptor is used by our global loader (the woolies icon that shows up when something is loading) to keep track of incomplete http requests
   * it shows/hides the loader accordingly.
   */

  incompleteHttpRequestsCount = 0;

  constructor(private auth: CsaAuthService, public loaderService: LoaderService, private domainService: DomainService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const appDomain = this.domainService.getDomain();
    
    if (!request.headers.get('skip')) {
      this.incompleteHttpRequestsCount++; // increment count for each intercepted http request.
      this.loaderService.show(request.url);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!request.headers.get('skip')) {
          this.incompleteHttpRequestsCount--;

          if (this.incompleteHttpRequestsCount === 0) {
            this.loaderService.hide(request.url);
          }
        }
      })
    );
  }
}
