import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private storeListAllUrl = `${environment.backEndUrl}/store/list/all`;

  constructor(private http: HttpClient) {}

  getStoreList(): Observable<object[]> {
    const storeListCache = this.getStoreListCache();
    if (storeListCache) {
      return of(storeListCache);
    }

    return this.http.get<object[]>(this.storeListAllUrl).pipe(
      tap((stores) => (this.setStoreListCache(stores)))
    );
  }

  /**
   * Retrieves the cached store list from session storage.
   * 
   * @returns {object[] | undefined} The cached store list, or an empty array if no data is found.
   */
  getStoreListCache(): object[] | undefined {
    let existingStoreList = JSON.parse(sessionStorage.getItem('storeList'));
    // if there are no existing store, return undefined
    if (!existingStoreList) {
      existingStoreList = undefined;
    }
    return existingStoreList;
  }

  /**
   * Caches the store list to session storage.
   * 
   * @param {object} storeList - The list of stores to cache.
   */
  setStoreListCache(storeList: object) {
    sessionStorage.setItem('storeList', JSON.stringify(storeList));
  }
}
