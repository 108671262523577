/**
 * Removes the last token from a given string value using a specified delimiter.
 *
   @param {string} value - The string value from which the last token will be removed.
 * @param {string} delimiter - The character used to split the string value into tokens.
 * @returns {string} The string value with the last token removed.
 */
export function removeLastToken(value: string, delimiter: string): string {
  const urlParts = value.split(delimiter);
  urlParts.pop();
  return urlParts.join(delimiter);
}
