import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpInterceptor, HttpRequest, HttpResponse 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { mockFeatureList, mockSubFeature } from './mock-data/feature-data.mock';
import { mockPermissionList, mockSubPermission } from './mock-data/permission-data.mock';
import { mockFeatureMappingList, mockSubFeatureSearchResults } from './mock-data/feature-mapping-data.mock';
import { mockEntityList } from './mock-data/entity-data.mock';
import { mockRoleListResponse, mockRoleResponse } from './mock-data/role-data.mock';
import { mockUserResponse, mockUserTestResponse } from './mock-data/user-data.mock';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment['useMockData']) {
      // Feature Mapping
      // if (request.url === `${environment.backEndUrl}/featuremapping/list`) {
      // // return throwError(() => new HttpErrorResponse({ status: 400, statusText: 'Something bad happened; please try again later' }));
      // // return throwError(() => new HttpErrorResponse({ status: 400, statusText: 'Something bad happened; please try again later', error: { message: 'Support message', userMessage: 'User friendly message' } }));
      //   return of(new HttpResponse({ status: 200, body: mockFeatureMappingList }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/featuremapping/subpermissions/search`)) {
      //   return of(new HttpResponse({ status: 200, body: mockSubFeatureSearchResults }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/featuremapping/create`)) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/featuremapping/delete`)) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // Features
      // if (request.url === `${environment.backEndUrl}/features/list`) {
      //   // return throwError(() => new HttpErrorResponse({ status: 400, statusText: 'Something bad happened; please try again later' }));
      //   // return throwError(() => new HttpErrorResponse({ status: 400, statusText: 'Something bad happened; please try again later', error: { message: 'Support message', userMessage: 'User friendly message' } }));
      //   return of(new HttpResponse({ status: 200, body: mockFeatureList }));
      // }
      // if (request.url === `${environment.backEndUrl}/level/list`) {
      //   // return throwError(() => new HttpErrorResponse({ status: 400, statusText: 'Something bad happened; please try again later' }));
      //   // return throwError(() => new HttpErrorResponse({ status: 400, statusText: 'Something bad happened; please try again later', error: { message: 'Support message', userMessage: 'User friendly message' } }));
      //   return of(new HttpResponse({ status: 200, body: mockEntityList }));
      // }
      // if (request.url === `${environment.backEndUrl}/features/move`) {
      //   return throwError(() => new HttpErrorResponse({ status: 404, statusText: 'Feature not found 1', error: { message: '(1062, "Feature not found 2")', userMessage: 'Feature not found 3' } }));
      //   // return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/subfeatures/move`) {
      //   return throwError(() => new HttpErrorResponse({ status: 404, statusText: 'SubFeature not found 1', error: { message: '(1062, "SubFeature not found 2")', userMessage: 'SubFeature not found 3' } }));
      //   // return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/feature/`) && request.method == 'GET') {
      //   return throwError(() => new HttpErrorResponse({ status: 404, statusText: 'Feature not found 1', error: { message: '(1062, "Feature not found 2")', userMessage: 'Feature not found 3' } }));
      //   // return of(new HttpResponse({ status: 200, body: mockFeatureList['featureList'][0] }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/subfeature/`) && request.method == 'GET') {
      //   return throwError(() => new HttpErrorResponse({ status: 404, statusText: 'SubFeature not found 1', error: { message: '(1062, "SubFeature not found 2")', userMessage: 'SubFeature not found 3' } }));
      //   // return of(new HttpResponse({ status: 200, body: mockSubFeature }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/feature/`) && request.method == 'DELETE') {
      //   return throwError(() => new HttpErrorResponse({ status: 404, statusText: 'Feature not found 1', error: { message: '(1062, "Feature not found 2")', userMessage: 'Feature not found 3' } }));
      //   // return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/subfeature/`) && request.method == 'DELETE') {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/feature/create`) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/subfeature/create`) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/feature/update`) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/subfeature/update`) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // Permissions
      // if (request.url === `${environment.backEndUrl}/permissions/list`) {
      //   return of(new HttpResponse({ status: 200, body: mockPermissionList }));
      // }
      // if (request.url === `${environment.backEndUrl}/permissions/move`) {
      //   return throwError(() => new HttpErrorResponse({ status: 404, statusText: 'Permission not found 1', error: { message: '(1062, "Permission not found 2")', userMessage: 'Permission not found 3' } }));
      //   // return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/subpermissions/move`) {
      //   return throwError(() => new HttpErrorResponse({ status: 404, statusText: 'SubPermission not found 1', error: { message: '(1062, "SubPermission not found 2")', userMessage: 'SubPermission not found 3' } }));
      //   // return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/permission/fetch/`)) {
      //   return of(new HttpResponse({ status: 200, body: mockPermissionList['permissionList'][0] }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/subpermission/fetch/`)) {
      //   return of(new HttpResponse({ status: 200, body: mockSubPermission }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/permission/delete/`)) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/subpermission/delete/`)) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/permission/create`) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/subpermission/create`) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/permission/update`) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/subpermission/update`) {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // Roles
      // if (request.url === `${environment.backEndUrl}/role` && request.method == 'POST') {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/role` && request.method == 'PUT') {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url === `${environment.backEndUrl}/role` && request.method == 'DELETE') {
      //   return of(new HttpResponse({ status: 200 }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/role/`) && request.method == 'GET') {
      //   return of(new HttpResponse({ status: 200, body: mockRoleResponse }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/roles/list`) && request.method == 'GET') {
      //   return of(new HttpResponse({ status: 200, body: mockRoleListResponse }));
      // }
      // if (request.url.includes(`${environment.backEndUrl}/user/`) && request.method == 'GET') {
      //   return of(new HttpResponse({ status: 200, body: mockUserTestResponse }));
      // }
    }
    return next.handle(request);
  }
}
