import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboveStoreLandingComponent } from './landing-pages/above-store-landing/above-store-landing.component';
import { AboveStoreMoreSurveysComponent } from './surveys/above-store-more-surveys/above-store-more-surveys.component';
import { StoreDashboardComponent } from './surveys/store-dashboard/store-dashboard.component';
import { ActionDetailsComponent } from './surveys/action-details/action-details.component';
import { ActionsDrillDownComponent } from './surveys/actions-drill-down/actions-drill-down.component';
import { MrrActionsDrillDownComponent } from './mrr-surveys/mrr-actions-drill-down/mrr-actions-drill-down.component';
import { AdminLandingComponent } from './landing-pages/admin-landing/admin-landing.component';
import { AdminReportsComponent } from './surveys/admin-reports/admin-reports.component';
import { CsaAuthGuard } from './auth/csa-auth.guard';
import { ErrorPageComponent } from './surveys/error-page/error-page.component';
import { MoreActionsComponent } from './surveys/more-actions/more-actions.component';
import { MrrMoreActionsComponent } from './mrr-surveys/mrr-more-actions/mrr-more-actions.component';
import { MoreEvidenceComponent } from './surveys/more-evidence/more-evidence.component';
import { MoreOntheflySurveysComponent } from './adhoc-surveys/more-onthefly-surveys/more-onthefly-surveys.component';
import { MoreRecurringSurveysComponent } from './recurring-survey/more-recurring-surveys/more-recurring-surveys.component';
import { MoreSurveysComponent } from './surveys/more-surveys/more-surveys.component';
import { MoreSurveysAdminComponent } from './surveys/more-surveys-admin/more-surveys-admin.component';
import { MoreTemplatesComponent } from './surveys/more-templates/more-templates.component';
import { OnceOffSurveyDetailsComponent } from './surveys/once-off-survey-details/once-off-survey-details.component';
import { OnTheFlySurveyDetailsComponent } from './adhoc-surveys/on-the-fly-survey-details/on-the-fly-survey-details.component';
import { RecurringSurveyDetailsComponent } from './recurring-survey/recurring-survey-details/recurring-survey-details.component';
import { StoreLandingComponent } from './landing-pages/store-landing/store-landing.component';
import { StoreSurveyComponent } from './surveys/store-survey/store-survey.component';
import { SurveyDetailsComponent } from './surveys/survey-details/survey-details.component';
import { TemplateDetailsComponent } from './surveys/template-details/template-details.component';
import { ThankYouComponent } from './surveys/thank-you/thank-you.component';
import { SupportLandingComponent } from './landing-pages/support-landing/support-landing.component';
import { MrrSurveyDetailsComponent } from './mrr-surveys/mrr-survey-details/mrr-survey-details.component';
import { MoreMrrSurveysComponent } from './mrr-surveys/more-mrr-surveys/more-mrr-surveys.component';
import { MrrStoreDetailsComponent } from './mrr-surveys/mrr-store-details/mrr-store-details.component';
import { ThankYouMrrComponent } from './mrr-surveys/thank-you-mrr/thank-you-mrr.component';
import { MrrActionDetailsComponent } from './mrr-surveys/mrr-action-details/mrr-action-details.component';
import { LoginComponent } from './auth/login/login.component';
import { DeveloperHandbookComponent } from './developer-handbook/developer-handbook.component';
import { MultiStoreSelectionComponent } from './multi-store-selection/multi-store-selection.component';
import { SwitchDivisionComponent } from './switch-division/switch-division.component';
import { RoleBasedRoutingGuard } from './shared/role-based-routing.guard';
import { ToDoListComponent } from './to-do-list/to-do-list.component';
import { SubfeatureRouteGuard } from './shared/subfeature-route-guard/subfeature-route-guard';
import { MrrResponseComponent } from './mrr-surveys/mrr-response/mrr-response.component';

const routes: Routes = [
  {
    path: 'developer-handbook',
    component: DeveloperHandbookComponent,
    data: { breadcrumbKey: 'developer-handbook' },
  },
  {
    path: 'above-store-landing',
    component: AboveStoreLandingComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'Store', breadcrumbKey: 'above-store-landing' },
  },
  {
    path: 'above-store-landing/above-store-more-surveys',
    component: AboveStoreMoreSurveysComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'Store', breadcrumbKey: 'above-store-once-off' },
  },
  {
    path: 'above-store-landing/once-off-survey-details/:id',
    component: OnceOffSurveyDetailsComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'Store', breadcrumbKey: 'above-store-once-off-survey-details' },
  },
  // {
  //   path: 'access-management',
  //   component: AccessManagementComponent,
  //   canActivate: [CsaAuthGuard],
  //   data: { role: 'Admin', breadcrumbKey: 'access-management' },
  // },
  // {
  //   path: 'access-management/users/manage',
  //   component: AccessManagementComponent,
  //   canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
  //   data: { breadcrumbKey: 'manage-access', subfeature: 'accessManagement.viewUsers' },
  // },
  // {
  //   path: 'access-management/roles/manage',
  //   component: AccessManagementComponent,
  //   canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
  //   data: { breadcrumbKey: 'manage-roles', subfeature: 'accessManagement.viewRoles' },
  // },
  {
    path: 'store-dashboard',
    component: StoreDashboardComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: {
      role: 'Admin',
      breadcrumbKey: 'store-dashboard', 
      subfeature: 'manageStores.view', 
    }
  },
  {
    path: 'action-details/:id',
    component: ActionDetailsComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'action-details' },
  },
  {
    path: 'drill-down-action-details/:id',
    component: ActionDetailsComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'drill-down-action-details' },
  },
  {
    path: 'mrr-action-details/:id',
    component: MrrActionDetailsComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'NoRole', breadcrumbKey: 'mrr-action-details', subfeature: 'mrrActionDetails.view' },
  },
  {
    path: 'actions-drill-down',
    component: ActionsDrillDownComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'Store', breadcrumbKey: 'actions-drill-down', subfeature: 'actions.view' },
  }, 
  {
    path: 'mrr-actions-drill-down',
    component: MrrActionsDrillDownComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'Store', breadcrumbKey: 'mrr-actions-drill-down', subfeature: 'mrrActions.view' },
  },
  {
    path: 'to-do-list',
    component: ToDoListComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'Store', breadcrumbKey: 'to-do-list', subfeature: 'storeToDoList.view' }
  },
  {
    path: 'admin-landing',
    component: AdminLandingComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'Admin', breadcrumbKey: 'admin-landing' },
  },
  {
    path: 'admin-landing/more-surveys',
    component: MoreSurveysAdminComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'Admin', breadcrumbKey: 'admin-more-surveys' },
  },
  {
    path: 'admin-landing/more-templates',
    component: MoreTemplatesComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'NoRole', breadcrumbKey: 'admin-more-templates', subfeature: 'templates.view' },
  },
  {
    path: 'admin-landing/once-off-survey-details/:id',
    component: OnceOffSurveyDetailsComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'Admin', breadcrumbKey: 'admin-once-off-survey-details' },
  },
  {
    path: 'mrr-response/:id',
    component: MrrResponseComponent,
    canActivate: [SubfeatureRouteGuard],
    data: { breadcrumbKey: 'mrr-response-details', subfeatures: ['mrrAssessments.viewDraftResponse', 'mrrAssessments.viewResponse'] },
  },
  {
    path: 'admin-landing/reports',
    component: AdminReportsComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'Store', breadcrumbKey: 'admin-reports', subfeature: 'charts.view' },
  },
  {
    path: 'error/:id',
    component: ErrorPageComponent,
    data: { breadcrumbKey: 'error', hideNav: true }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { breadcrumbKey: 'login' }
  },
  {
    // TODO (3/4/24 AS): Path more-actions will be merged into actions-drill-down
    path: 'more-actions',
    component: MoreActionsComponent,
    canActivate: [CsaAuthGuard, RoleBasedRoutingGuard],
    data: {
      role: 'NoRole', breadcrumbKey: 'more-actions', aboveStoreRoute: '/actions-drill-down', adminRoute: '/actions-drill-down'
    },
  },
  {
    // TODO (3/4/24 AS): Path mrr-more-actions will be merged into mrr-actions-drill-down
    path: 'mrr-more-actions',
    component: MrrMoreActionsComponent,
    canActivate: [CsaAuthGuard, RoleBasedRoutingGuard],
    data: {
      role: 'NoRole', breadcrumbKey: 'mrr-more-actions', aboveStoreRoute: '/mrr-actions-drill-down', adminRoute: '/mrr-actions-drill-down'
    },
  },
  // {
  //   path: 'more-evidence/:evidenceType',
  //   component: MoreEvidenceComponent,
  //   canActivate: [CsaAuthGuard],
  //   data: { role: 'NationalView', breadcrumbKey: 'more-evidence' },
  // },
  {
    path: 'more-evidence/standard',
    component: MoreEvidenceComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'NationalView', breadcrumbKey: 'more-evidence', subfeature: 'standardEvidence.view' },
  },
  {
    path: 'more-evidence/mrr',
    component: MoreEvidenceComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'NationalView', breadcrumbKey: 'more-evidence', subfeature: 'mrrEvidence.view' },
  }, 
  {
    path: 'more-onthefly-surveys',
    component: MoreOntheflySurveysComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'NoRole', breadcrumbKey: 'more-onthefly-surveys', subfeature: 'adhocAssessments.view' },
  },
  {
    path: 'more-recurring-surveys',
    component: MoreRecurringSurveysComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'Store', breadcrumbKey: 'more-recurring-surveys', subfeature: 'recurringAssessments.view' },
  },
  {
    path: 'more-mrr-surveys',
    component: MoreMrrSurveysComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'Store', breadcrumbKey: 'more-mrr-surveys', subfeature: 'mrrAssessments.view' },
  },
  {
    path: 'mrr-survey-details/:id',
    component: MrrSurveyDetailsComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'NoRole', breadcrumbKey: 'mrr-survey-details', subfeature: 'mrrSurveyDetails.view' },
  },
  {
    path: 'mrr-store-details/:id',
    component: MrrStoreDetailsComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'store-mrr-details' },
  },
  {
    path: 'onthefly-survey-details/:id',
    component: OnTheFlySurveyDetailsComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'NoRole', breadcrumbKey: 'onthefly-survey-details', subfeature: 'adhocAssessments.viewDetails' },
  },
  {
    path: 'recurring-survey-details/:id',
    component: RecurringSurveyDetailsComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'recurring-survey-details' },
  },
  {
    path: 'store-landing',
    component: StoreLandingComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'store-landing' },
  },
  {
    path: 'store-landing/more-surveys',
    component: MoreSurveysComponent,
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: {
      role: 'NoRole', 
      breadcrumbKey: 'store-more-surveys', 
      subfeatureRedirects: [
        { order: 1, subfeature: 'onceOffAssessments.viewAdmin', path: '/admin-landing/more-surveys' }, 
        { order: 2, subfeature: 'onceOffAssessments.viewAboveStore', path: '/above-store-landing/above-store-more-surveys' }, 
      ],
      subfeature: 'onceOffAssessments.view'
    },
  },
  {
    path: 'store-landing/store-survey/:id',
    component: StoreSurveyComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'store-surveys' },
  },
  {
    path: 'store-landing/survey-details/:id',
    component: SurveyDetailsComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'store-survey-details' },
  },
  {
    path: 'template-details/:id',
    component: TemplateDetailsComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'template-details' },
  },
  {
    path: 'support-landing',
    component: SupportLandingComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'Admin', breadcrumbKey: 'support-landing', hideNav: true },
  },
  {
    path: 'switch-division',
    component: SwitchDivisionComponent,
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole', breadcrumbKey: 'switch-division', hideNav: true },
  },
  {
    path: 'multi-store-selection',
    component: MultiStoreSelectionComponent,
    data: { role: 'NoRole', breadcrumbKey: 'multi-store-selection', hideNav: true },
  },
  {
    path: 'thankyou',
    component: ThankYouComponent,
    data: { breadcrumbKey: 'thankyou' },
  },
  {
    path: 'thankyouMrr/:id',
    component: ThankYouMrrComponent,
    data: { breadcrumbKey: 'thankyou-mrr' },
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [CsaAuthGuard, SubfeatureRouteGuard],
    data: { role: 'NoRole', breadcrumbKey: 'dashboard', subfeature: 'dashboard.view' },
  },
  {
    path: 'access-management',
    loadChildren: () => import('./access-management/access-management.module').then((m) => m.DashboardModule),
    canActivate: [CsaAuthGuard],
    data: { role: 'NoRole' },
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: { breadcrumbKey: 'error', hideNav: true }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
