import {
  Component, Inject, Injectable, OnInit, ChangeDetectorRef 
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AnyMxRecord } from 'dns';
import {

  faTimes,

} from '@fortawesome/free-solid-svg-icons';
import {
  NgForm, NgModel, FormControl, FormGroup, Validators, AbstractControl 
} from '@angular/forms';
import * as moment from 'moment';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { DrillDownFilterPipe } from '../../custom-pipes/drill-down-filter.pipe';

@Component({
  selector: 'app-sign-off-dialog',
  templateUrl: './sign-off-dialog.component.html',
  styleUrls: ['./sign-off-dialog.component.scss'],
  providers: [
    DrillDownFilterPipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SignOffDialogComponent {
  mrrForm: FormGroup;

  confirmText = 'Close';

  idleState = '';

  comments:any;

  faTimes = faTimes;

  constructor(
    private csaAuthService: CsaAuthService,
    private cd: ChangeDetectorRef,
    private idle: Idle,
    private dialogRef: MatDialogRef<SignOffDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = `You will time out in ${countdown} seconds.`;
      this.cd.detectChanges();
    });

    if (data['closeText']) {
      this.confirmText = data['closeText'];
    }

    if (data.message.indexOf('@') == -1) {
      data.message = data.message.replace('https://', '').replace('email', 'visit');
    }
  }

  ngOnInit(): void {
    this.mrrForm = new FormGroup({
      comments: new FormControl('', [
        Validators.required,
        this.emptyStringValidator.bind(this)
      ]),
      
    });
  }

  emptyStringValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value.trim().length === 0) {
      return { whitespace: true };
    }
    return null;
  }

  logOut() {
    this.csaAuthService.logout();
  }

  closeMe() {
    // this.dialogRef.close();
    this.dialogRef.close('close');
  }
   
  public submit(navigateRef) {
    if (navigateRef) {
      this.dialogRef.close('Submit');
      this.router.navigate([navigateRef]);
    } 
    // else {
    //   this.dialogRef.close("logout");
    // }
  }

  public cancel(navigateRef) {
    if (navigateRef) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close('continue');
    }
  }

  saveNotes(value) {
    if (value.comments) {
      this.comments = value.comments;
    }
  }

  sendSurvey(form, value) {
    this.comments = value.comments;
    this.dialogRef.close({ type: 'logout', comments: this.comments });
  }
}
