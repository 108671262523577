import {
  Component, Inject, Injectable, OnInit, ChangeDetectorRef 
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { CsaAuthService } from '../../auth/csa-auth.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {
  confirmText = 'Close';

  idleState = '';

  constructor(private csaAuthService: CsaAuthService, private cd: ChangeDetectorRef, private idle: Idle, private dialogRef: MatDialogRef<MessagesComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = `You will time out in ${countdown} seconds.`;
      this.cd.detectChanges();
    });

    if (data['closeText']) {
      this.confirmText = data['closeText'];
    }

    if (data.message.indexOf('@') == -1) {
      data.message = data.message.replace('https://', '').replace('email', 'visit');
    }
  }

  public submit(navigateRef) {
    if (navigateRef) {
      this.dialogRef.close('Submit');
      this.router.navigate([navigateRef]);
    } else {
      this.dialogRef.close('logout');
    }
  }

  public cancel(navigateRef) {
    if (navigateRef) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close('continue');
    }
  }

  public retry() {
    this.dialogRef.close('retry');
  }
}
