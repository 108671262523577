import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Observable, catchError, map 
} from 'rxjs';
import { environment } from '../../environments/environment';
import { handleError } from '../utils/handle-error/handle-error';
import { UsersMapper } from './users.mapper';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = `${environment.backEndUrl}/user`;

  private listUrl = `${environment.backEndUrl}/users/list`;

  private usersUrl = `${environment.backEndUrl}/users/`;

  private nextLevelUrl = `${environment.backEndUrl}/orgStructure/belowLevel`;

  constructor(
    private httpClient: HttpClient,
    private usersMapper: UsersMapper
  ) { }

  fetch(email: string): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    const encodedEmail = encodeURIComponent(email);
    return this.httpClient.get<object>(`${this.baseUrl}/${encodedEmail}`, options).pipe(
      map((data: object) => this.usersMapper.mapUserResponseToUser(data)),      
      catchError(handleError)
    );
  }
  
  search(
    searchTerm: string, 
    startIndex: number, 
    itemsPerPage: number, 
    division: string, 
    selectedRole: object, 
    selectedLocations: object,
  ): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    const query = this.getSearchQuery(searchTerm, division, selectedRole, selectedLocations);
    const fullUrl = `${this.listUrl}/${startIndex}/${itemsPerPage}${query}`;
    
    return this.httpClient.get<object>(fullUrl, options).pipe(
      map((data: object) => ({
        userList: this.usersMapper.mapUsersResponseToUsers(data['users']),
        hasNext: data['has_next']
      })),
      catchError(handleError)
    );
  }

  getSearchQuery(
    searchTerm: string, 
    division: string, 
    selectedRole: object, 
    selectedLocations: object,
  ): string {
    let query = `?DIVISION=${division}`;
    if (selectedRole && selectedRole['id']) {
      query += `&ROLE_ID=${selectedRole['id']}`;
    }
    for (const [key, value] of Object.entries(selectedLocations)) {
      if (value != '') {
        query
          += `&${key == 'groupID' ? 'GROUP_NO' : key.toUpperCase()
          }=${value}`;
      }
    }
    if (searchTerm) query += `&SEARCH=${encodeURIComponent(searchTerm)}`;
    return query;
  }

  getSearchQueryLegacy(
    searchTerm: string, 
    division: string, 
    selectedRole: string | object, 
    selectedLocations: object,
    availableRoles: string[],
    userDivisionRoles: object
  ): string {
    let query = `?DIVISION=${division}`;
    if (typeof selectedRole === 'string') {
      if (selectedRole && selectedRole != 'All Roles') {
        if (selectedRole == 'Above Store Roles') {
          for (const role in userDivisionRoles) {
            if (
              userDivisionRoles[role] > userDivisionRoles['MultiStore']
              && userDivisionRoles[role] < userDivisionRoles['NationalView']
            ) {
              query += `&ROLE[]=${role}`;
            }
          }
        } else {
          query += `&ROLE[]=${selectedRole}`;
        }
      } else {
        availableRoles.forEach((role) => {
          if (role != 'Above Store Roles') query += `&ROLE[]=${role}`;
        });
      }
    } else if (selectedRole && selectedRole['id']) {
      query += `&ROLE_ID=${selectedRole['id']}`;
    }

    for (const [key, value] of Object.entries(selectedLocations)) {
      if (value != '') {
        query
          += `&${key == 'groupID' ? 'GROUP_NO' : key.toUpperCase()
          }=${value}`;
      }
    }
    if (searchTerm) query += `&SEARCH=${searchTerm}`;
    return query;
  }

  getNextLevel(filterString: string): Observable<object[]> {
    const httpHeaders = new HttpHeaders({
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = this.nextLevelUrl + filterString;
    return this.httpClient.get<object[]>(fullUrl, options);
  }

  create(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    return this.httpClient.post<object>(`${this.usersUrl}create`, data, options).pipe(
      catchError(handleError)
    );
  }  

  update(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    return this.httpClient.post<object>(`${this.usersUrl}update`, data, options).pipe(
      catchError(handleError)
    );
  }

  delete(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    return this.httpClient.post<object>(`${this.usersUrl}delete`, data, options).pipe(
      catchError(handleError)
    );
  }      
}
