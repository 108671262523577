import {
  Component, OnInit, TemplateRef, ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/common-services/loader.service';
import { CsaAuthService } from '../csa-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginProblemsDialogRef') loginProblemsDialogRef: TemplateRef<any>;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  private helpDialogRef: MatDialogRef<any, any>;

  constructor(private dialog: MatDialog, private loaderService: LoaderService, private auth: CsaAuthService, private router: Router) { }

  ngOnInit(): void {
    this.loaderService.hide(); // the loader may be visible because of some authenticated API call stuck trying to refresh. We need to hide it.
    this.watchForSuccessfulLogin();
  }

  /**
   * Upon successful login, navigate to the previous route if we have one, or to the root page.
   * Store users are navigated to the to-do-list regardless.
   */
  private watchForSuccessfulLogin() {
    this.auth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      if (user) {
        if (this.auth.isUserStoreRole()) {
          // store users to go the todo list after login
          this.router.navigateByUrl('/to-do-list');
          return;
        }

        if (!this.auth.previousRoute || this.auth.previousRoute.startsWith('/error/')) {
          this.router.navigateByUrl('/');
        } else {
          this.router.navigateByUrl(this.auth.previousRoute);
        }
      }
    });
  }

  public login() {
    this.auth.showLogin();
  }

  public loginWithRedirect() {
    this.auth.showLogin('redirect');
  }

  /**
   * Displays the help disalog.
   */
  showHelpDialog() {
    this.loaderService.hide(); // the loader may be visible because of some authenticated API call stuck trying to refresh. We need to hide it.

    this.helpDialogRef = this.dialog.open(this.loginProblemsDialogRef, {
      height: 'auto',
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    if (this.helpDialogRef) { // close the help dialog if it's open when we leave this page
      this.helpDialogRef.close();
    }
  }
}
