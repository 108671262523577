import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { DialogComponent, DialogData, DialogMode } from '../shared/dialog/dialog.component';
import { ILocationSelection, MultiLocationSelectionComponent } from '../shared/multi-location-selection/multi-location-selection.component';
import { excludeValues } from '../utils/exclude-values/exclude-values';
import { IValidatedBulkValues } from '../utils/validate-bulk-values/validate-bulk-values';

@Component({
  selector: 'app-developer-handbook',
  templateUrl: './developer-handbook.component.html',
  styleUrls: ['./developer-handbook.component.scss']
})
export class DeveloperHandbookComponent {
  @ViewChild('multiLocationSelection') multiLocationSelection!: MultiLocationSelectionComponent;

  // text field value used for the text field example with length counter
  public textFieldCounterExampleValue = '';

  public searchResults: object[] = [];

  public locationSelectionsList: ILocationSelection[] = [
    {
      id: '1',
      roleLevel: 'Group',
      hasInitialState: true,
      locationValues: { state: 'NSW/ACT', zone: '5', groupID: '452' },
      // availableLocations: {
      //   state: [
      //     {
      //       name: 'All States',
      //       value: ''
      //     },
      //     {
      //       name: 'NSW/ACT',
      //       value: 'NSW/ACT'
      //     },
      //     {
      //       name: 'QLD',
      //       value: 'QLD'
      //     },
      //     {
      //       name: 'SA/NT/WA',
      //       value: 'SA/NT/WA'
      //     },
      //     {
      //       name: 'VIC/TAS',
      //       value: 'VIC/TAS'
      //     }
      //   ],
      //   zone: [
      //     {
      //       name: 'All Zones',
      //       value: ''
      //     },
      //     {
      //       name: '1',
      //       value: '1'
      //     },
      //     {
      //       name: '2',
      //       value: '2'
      //     },
      //     {
      //       name: '3',
      //       value: '3'
      //     },
      //     {
      //       name: '4',
      //       value: '4'
      //     },
      //     {
      //       name: '5',
      //       value: '5'
      //     },
      //     {
      //       name: '6',
      //       value: '6'
      //     }
      //   ],
      //   groupID: [
      //     {
      //       name: 'All Groups',
      //       value: ''
      //     },
      //     {
      //       name: '451',
      //       value: '451'
      //     },
      //     {
      //       name: '452',
      //       value: '452'
      //     },
      //     {
      //       name: '453',
      //       value: '453'
      //     },
      //     {
      //       name: '454',
      //       value: '454'
      //     }
      //   ]
      // } 
    }, 
    {
      id: '2',
      roleLevel: 'Group',
      hasInitialState: true,
      locationValues: { state: 'NSW/ACT', zone: '3', groupID: '433' },
      // availableLocations: {
      //   state: [
      //     {
      //       name: 'All States',
      //       value: ''
      //     },
      //     {
      //       name: 'NSW/ACT',
      //       value: 'NSW/ACT'
      //     },
      //     {
      //       name: 'QLD',
      //       value: 'QLD'
      //     },
      //     {
      //       name: 'SA/NT/WA',
      //       value: 'SA/NT/WA'
      //     },
      //     {
      //       name: 'VIC/TAS',
      //       value: 'VIC/TAS'
      //     }
      //   ],
      //   zone: [
      //     {
      //       name: 'All Zones',
      //       value: ''
      //     },
      //     {
      //       name: '1',
      //       value: '1'
      //     },
      //     {
      //       name: '2',
      //       value: '2'
      //     },
      //     {
      //       name: '3',
      //       value: '3'
      //     },
      //     {
      //       name: '4',
      //       value: '4'
      //     },
      //     {
      //       name: '5',
      //       value: '5'
      //     },
      //     {
      //       name: '6',
      //       value: '6'
      //     }
      //   ],
      //   groupID: [
      //     {
      //       name: 'All Groups',
      //       value: ''
      //     },
      //     {
      //       name: '431',
      //       value: '431'
      //     },
      //     {
      //       name: '432',
      //       value: '432'
      //     },
      //     {
      //       name: '433',
      //       value: '433'
      //     },
      //     {
      //       name: '434',
      //       value: '434'
      //     }
      //   ]
      // } 
    },   
    {
      id: '3',
      roleLevel: 'Group',
      hasInitialState: true,
      locationValues: { state: 'NSW/ACT', zone: '3', groupID: '434' },
      // availableLocations: {
      //   state: [
      //     {
      //       name: 'All States',
      //       value: ''
      //     },
      //     {
      //       name: 'NSW/ACT',
      //       value: 'NSW/ACT'
      //     },
      //     {
      //       name: 'QLD',
      //       value: 'QLD'
      //     },
      //     {
      //       name: 'SA/NT/WA',
      //       value: 'SA/NT/WA'
      //     },
      //     {
      //       name: 'VIC/TAS',
      //       value: 'VIC/TAS'
      //     }
      //   ],
      //   zone: [
      //     {
      //       name: 'All Zones',
      //       value: ''
      //     },
      //     {
      //       name: '1',
      //       value: '1'
      //     },
      //     {
      //       name: '2',
      //       value: '2'
      //     },
      //     {
      //       name: '3',
      //       value: '3'
      //     },
      //     {
      //       name: '4',
      //       value: '4'
      //     },
      //     {
      //       name: '5',
      //       value: '5'
      //     },
      //     {
      //       name: '6',
      //       value: '6'
      //     }
      //   ],
      //   groupID: [
      //     {
      //       name: 'All Groups',
      //       value: ''
      //     },
      //     {
      //       name: '431',
      //       value: '431'
      //     },
      //     {
      //       name: '432',
      //       value: '432'
      //     },
      //     {
      //       name: '433',
      //       value: '433'
      //     },
      //     {
      //       name: '434',
      //       value: '434'
      //     }
      //   ]
      // } 
    }, 
  ];

  form1: FormGroup;

  form2: FormGroup; 

  allowedNumbers: number[] = [1234, 1080, 1081, 1082, 1083, 1084, 1085, 1086, 1087, 1088, 1089, 1090];

  addedNumbers: number[] = [];

  bulkInput: IValidatedBulkValues<number>;

  validatedBulkNumberInputString = '';

  validatedStoreNumberBulkInputString = '';

  bulkInputErrorMessage = { message: '' };

  addedStoreNumbersForStoreBulkInput = [1102, 1136, 1173, 1250, 1372];

  constructor(
    private dialog: MatDialog
  ) { 
    if (!this.form1) {
      this.form1 = new FormGroup({
        stores: new FormControl('', []),
      });
    }
    if (!this.form2) {
      this.form2 = new FormGroup({});
    }
  }

  searchTermChanged(event) {
    console.log(event);
    if (event.length >= 2) {
      this.searchResults = [{ id: 1, name: 'option 1' }, { id: 2, name: 'option 2' }, { id: 3, name: 'option 3' }];
    } else {
      this.searchResults = [];
    }
  }

  selectedItem(event) {
    console.log(event);
  }

  successDialog() {
    const dialogSuccess = this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title: 'Success Title goes here...',
        message: 'Success message goes here...',
        mode: DialogMode.Success,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
    dialogSuccess.afterClosed().subscribe((result) => {
      console.log('Success dialog closed. Result:', result);
    });
  }

  errorDialog() {
    const dialogSuccess = this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title: 'Error Title goes here...',
        message: 'Error message goes here...',
        mode: DialogMode.Error,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
    dialogSuccess.afterClosed().subscribe((result) => {
      console.log('Error dialog closed. Result:', result);
    });
  }  

  informationDialog() {
    const dialogSuccess = this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title: 'Information Title goes here...',
        message: 'Information message goes here...',
        mode: DialogMode.Information,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
    dialogSuccess.afterClosed().subscribe((result) => {
      console.log('Information dialog closed. Result:', result);
    });
  } 

  confirmationDialog() {
    const dialogSuccess = this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title: 'Confirmation Title goes here...',
        message: 'Confirmation message goes here...',
        mode: DialogMode.Confirmation,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
    dialogSuccess.afterClosed().subscribe((result) => {
      console.log('Confirmation dialog closed. Result:', result);
    });
  }

  locationSelectionsListChanged(selectedLocations) {
    console.log('locationSelectionsListChanged: ', selectedLocations, ' - is form valid: ', this.form2.valid);
  }

  isMultiLocationSelectionLoadingChanged(value: boolean) {
    console.log(`isMultiLocationSelectionLoadingChanged - value: ${value}`);
  }

  validLocationsChanged(value: boolean) {
    console.log(`validLocationsChanged - value: ${value}`);
  }

  resetMultiLocationSelection() {
    this.multiLocationSelection.reset();
  }
  
  onToggleButtonChange(event) {
    console.log('onToggleButtonChange', event);
  }

  validEnteredNumbers(event) {
    console.log('validEnteredNumbers', event);
    this.allowedNumbers = excludeValues(this.allowedNumbers, event);
    console.log('validEnteredNumbers - allowedNumbers: ', this.allowedNumbers);
  }

  invalidEnteredNumbers(event) {
    console.log('invalidEnteredNumbers', event);
  }

  validatedBulkInput(validatedBulkValues: IValidatedBulkValues<number>) {
    this.validatedBulkNumberInputString = JSON.stringify(validatedBulkValues);
    console.log('validatedBulkInput', validatedBulkValues);
    this.addedNumbers = [...this.addedNumbers, ...validatedBulkValues.validValues];
    this.allowedNumbers = excludeValues(this.allowedNumbers, validatedBulkValues.validValues);
    const invalidEnteredNumbers = [...validatedBulkValues.malformedValues, ...validatedBulkValues.excludedValues];
    const errorMessage = invalidEnteredNumbers.length > 0 ? `Invalid numbers ${invalidEnteredNumbers.join(', ')} found` : '';
    // Wrapping the error message in a new object ensures changes are detected even when the message does not change.
    // This is important when the components error messages are out of sync with the hosts error message e.g. when its cleared.
    // Sending the same error message (which is valid) via [errorMessage]="bulkInputErrorMessage" would not trigger a change when it's a string.
    this.bulkInputErrorMessage = { message: errorMessage }; 
    console.log('bulkInputErrorMessage: ', this.bulkInputErrorMessage);
  }

  validatedStoreNumberBulkInput(validatedBulkValues: IValidatedBulkValues<number>) {
    this.validatedStoreNumberBulkInputString = JSON.stringify(validatedBulkValues);
    console.log('validatedStoreNumberBulkInputString', validatedBulkValues);
    if (validatedBulkValues?.validValues.length > 0) {
      this.addedStoreNumbersForStoreBulkInput = [...this.addedStoreNumbersForStoreBulkInput, ...validatedBulkValues.validValues];
    }
  }
}
