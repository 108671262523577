import { Component, OnInit } from '@angular/core';
import { combineLatest, take } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CsaAuthService } from '../auth/csa-auth.service';
import { User } from '../models/user';
import { SurveysService } from '../surveys/surveys.service';
import { SwitchDivisionService } from './switch-division.service';
import { DialogComponent, DialogData, DialogMode } from '../shared/dialog/dialog.component';
import { getUserMessageFromHttpErrorResponse } from '../utils/handle-error/handle-error';
import { RoleService } from '../access-management/services/role.service';
import { IRole } from '../access-management/models/role';
import { Feature } from '../access-management/models/feature';
import { SubFeature } from '../access-management/models/sub-feature';
import { convertToKeyName } from '../utils/convert-to-key-name/convert-to-key-name';

@Component({
  selector: 'app-switch-division',
  templateUrl: './switch-division.component.html',
  styleUrls: ['./switch-division.component.scss']
})
export class SwitchDivisionComponent implements OnInit {
  user: User;

  availableDivisions = [];

  selectedDivision;

  constructor(
    private auth: CsaAuthService, 
    private surveyService : SurveysService, 
    private router: Router, 
    private switchDivisionService: SwitchDivisionService,
    private roleService: RoleService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.auth.userChanges$().pipe(take(1)).subscribe(() => {
      this.user = this.auth.user;
      this.selectedDivision = this.user.division;
      this.getAvailableDivisions();
    });
  }

  getAvailableDivisions() {
    this.surveyService
      .getNextLevel(`?domain=${this.user['domain']}`)
      .subscribe((divisionList) => {
        this.availableDivisions = divisionList['division'];
      });
  }

  getFeatureSubfeatureKeysFromRole(role: IRole): string[] {
    return role.features?.flatMap((feature: Feature) => feature.children.filter((subfeature: SubFeature) => subfeature.isEnabled)
      .map((subfeature) => `${feature.key}.${subfeature.key}`));
  }

  public switchDivision() {
    const role = this.user['role'];
    this.user = this.auth.user;

    combineLatest([
      this.surveyService.getOrgStructure(this.selectedDivision),
      this.switchDivisionService.getRoles(),
      this.roleService.getListForDivision(this.selectedDivision)
    ])
      .subscribe({
        next: ([orgStruct, data, divisionRoles]) => {
          const matchingRole = divisionRoles['roleList'].find((listItem) => listItem.name === role);
          if (!matchingRole) {
            this.showErrorDialog(`No matching role found for: ${role}`, 'Save division error');
            return;
          }
          this.roleService.fetchForDivision(matchingRole?.id, this.selectedDivision).pipe(
            take(1),
          ).subscribe((divisionRole: IRole) => {
            const featureSubfeatureKeys = this.getFeatureSubfeatureKeysFromRole(divisionRole);
            this.auth.setUserOverride('divisionRoles', data[this.selectedDivision]);
            this.auth.setUserOverride('orgStruct', orgStruct);
            this.auth.setUserOverride('role', role);
            this.auth.setUserOverride('super', true);
            this.auth.setUserOverride('division', this.selectedDivision);
            this.auth.setUserOverride('featureSubfeatureKeys', featureSubfeatureKeys);
    
            this.router.navigate(['/']);
          });
        },
        error: (error) => {
          this.showErrorDialog(error, 'Save division error');
        }
      });
  }

  private showErrorDialog(error: any, title: string) {
    this.dialog.open(DialogComponent, {
      data: <DialogData>{
        title,
        message: typeof error === 'string' ? error : getUserMessageFromHttpErrorResponse(error),
        mode: DialogMode.Error,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
    });
  }  
}
