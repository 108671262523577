<div class="close-container">
    <span class="info-icon med-grey" (click)="closeMe()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </span>
</div>

<mat-dialog-content>

    <h1 id="dialog-heading">{{data.heading}}</h1>
    <h3 id="dialog-message" [innerHTML]="data.message"></h3>

    <h4 *ngIf="data.idleMessage">{{idleState}}</h4>

    <form #signOff="ngForm" (submit)="saveNotes(signOff.value)" (keydown.enter)="$event.preventDefault()">
        <div class="create-action-form">

            <h1 class="create-action-headings left-align">comments :</h1>
            <mat-form-field appearance="outline" color="primary">
                <mat-label class="" for="summaryFindings">comments</mat-label>
                <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" spellcheck="true"
                    ngModel #comments maxLength="3000" matInput class="custom-control-input" name="comments" required
                    (ngModelChange)="saveNotes($event)"></textarea>
                <mat-hint align="end">{{comments['value'].length || 0}}/3000</mat-hint>
                <mat-error>Please enter Sign off comments</mat-error>
            </mat-form-field>
        </div>


        <div class="submit-button-container">
            <div class="survey-link-container">
                <div *ngIf="!signOff.valid || !signOff.dirty " class="subtle-centered-text">
                    <span *ngIf="!signOff.dirty">
                        <!-- The form has not been changed. -->
                    </span>
                    <span *ngIf="!signOff.valid">
                        <!-- Please complete all fields to save. -->
                    </span>
                </div>
             

                <div class="admin-button-container">
                    <button [disabled]="!signOff.valid || !signOff.dirty "
                    class="csa-button-solid survey-submit" type="submit" (click)="sendSurvey(signOff, signOff.value)">
                    <span>
                       
                        Submit
                    </span>
                </button>
                
                    <div *ngIf="data.cancelText">
                      <button class="csa-button-outline"  (click)="closeMe()">{{data.cancelText}}</button>
                    </div>
                  </div>
            </div>
        </div>
    </form>

</mat-dialog-content>