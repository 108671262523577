import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.scss']
})
export class PercentageBarComponent {
  @Input() showPercentage: boolean = true;

  // between 0 and 100
  @Input() percentage: number = 0;

  constructor() { }
}
