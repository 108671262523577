<div [formGroup]="form" class="multi-locations-container">
  <ng-container *ngFor="let locationSelection of locationSelectionList; let i = index;">
    <app-location-selection
      [form]="form"
      class="location-selection-item"
      [id]="locationSelection.id"
      [locationSelectionListIndex]="i"
      [locationSelectionListCount]="locationSelectionList.length"
      [userOrgStructure]="userOrgStructure"
      [division]="division"
      [hasInitialState]="locationSelection['hasInitialState']"
      [roleLevel]="roleLevel"
      [initialState]="locationSelection"
      [allowAddButton]="!maxLocations || maxLocations && locationSelectionList.length < maxLocations"
      (selectedLocationsChanged)="locationSelectionsChanged($event, i)"
      (removeLocationSelections)="removeLocationSelections(i)"
      (addLocationSelections)="addLocationSelections($event)"
      (isLocationSelectionLoading)="isLocationSelectionLoadingChanged($event, i)"
      (isValidChanged)="hasValidLocationsChanged($event, i)"
    ></app-location-selection>
  </ng-container>
  </div>
