import { Dictionary } from 'src/app/types/dictionary';

/**
 * Copies the contents of a dictionary object.
 *
 * @param {Dictionary<string, any>} value - The dictionary to copy.
 * @returns {Dictionary<string, any>} A new dictionary with the same key-value pairs as the input dictionary.
 */
export function copyDictionary(value: Dictionary<string, any>): Dictionary<string, any> {
  const output: Dictionary<string, any> = {};
  Object.keys(value).forEach((key) => {
    output[key] = value[key];
  });
  return output;
}
