<div *ngIf="data['allowClose']" class="close-container">
  <span class="info-icon med-grey" (click)="closeMe()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </span>
</div>

<mat-dialog-content>
  <form autocomplete="off" #storeSelection="ngForm"
    (submit)="saveStore(storeSelection, storeSelection.value); submitButton.disabled = true;"
    (keydown.enter)="$event.preventDefault()">
    <!-- For Internal audit assessemnt only -->
    <div *ngIf="data['filterName'] && data['filterName'] === 'mrr'">
      <h1 class="dialog-heading">{{data['surveyTitle'].slice(0, 50) | unescape}}</h1>
      <div class="question-options-row no-bottom-margin">
        <div class="survey-detail-block">
          <div>No. of Sections: {{data['sections']?.length || '0'}}</div>
        </div>
        <div class="survey-detail-block">
          <div>Available From:   {{ data['startDate'] | date :'dd MMM y'  }}  </div>
        </div>
      </div>
    </div>

    <!-- For Internal audit assessemnt only -->

    <div *ngIf="user['division'] !== 'DC'" class="create-survey-form">
      <h2>Select a Store</h2>
      <p>You are able to complete Assessments across multiple stores, please enter the store number you would like to
        complete Assessments for.

      <h1 class="create-survey-headings left-align">Store:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="storeNumber">Search by Store Name or Number..</mat-label>
        <mat-select #store ngModel (ngModelChange)="setStore($event)" class="custom-control-input" name="store"
          required>
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching"
              placeholderLabel="Search by Store Name or Number.." [noEntriesFoundLabel]="noEntries" name="searchValue"
              #searchValue ngModel (ngModelChange)="searchForStores($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option [disabled]="searching" *ngFor="let returnedStore of storesReturned" [value]="returnedStore">
            {{returnedStore['Store_No']}} - {{returnedStore['Location_Name']}}
          </mat-option>
        </mat-select>
        <mat-error>Please enter the Store number</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="user['division'] === 'DC'" class="create-survey-form">
      <h2>Select a DC</h2>
      <p>{{data['message']}}</p>

      <h1 class="create-survey-headings left-align">DC:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="storeNumber">Search by Store Name or Number..</mat-label>
        <mat-select #store ngModel (ngModelChange)="setStore($event)" class="custom-control-input" name="store"
          required>
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching"
              placeholderLabel="Search by DC Name or Number.." [noEntriesFoundLabel]="noEntries" name="searchValue"
              #searchValue ngModel (ngModelChange)="searchForStores($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option [disabled]="searching" *ngFor="let returnedStore of storesReturned" [value]="returnedStore">
            {{returnedStore['Store_No']}} - {{returnedStore['Location_Name']}}
          </mat-option>
        </mat-select>
        <mat-error>Please enter the DC number</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="selectedStore['Store_No']">
      You will be completing this assessment for:
      <h3 class="med-green">
        <span class="medium-text">Store: {{selectedStore['Store_No']}} - {{selectedStore['Location_Name']}}, </span>
        <span *ngIf="selectedStore['Group_No']" class="medium-text">Group: {{selectedStore['Group_No']}}, </span>
        <span *ngIf="selectedStore['Zone'] && user['domain']==='WoolworthsNZ'" class="medium-text">Zone:
          {{selectedStore['Zone']}} </span>
        <span *ngIf="selectedStore['Zone'] && user['domain']!=='WoolworthsNZ'" class="medium-text">Zone:
          {{selectedStore['Zone']}}, </span>
        <span *ngIf="selectedStore['Area']" class="medium-text">Area: {{selectedStore['Area']}}, </span>
        <span *ngIf="selectedStore['Region']" class="medium-text">Region: {{selectedStore['Region']}} </span>
        <span *ngIf="selectedStore['State']" class="medium-text">State: {{selectedStore['State']}} </span>
      </h3>
      <!--         With Store Manager:  -->
      <!--       <h3 class="med-green"> -->
      <!--         {{selectedStore['Store_Manager']}} -->
      <!--       </h3> -->
    </div>

    <div class="submit-button-container">
      <div class="survey-link-container">
        <div *ngIf="!storeSelection.valid || !selectedStore['Store_No']" class="subtle-centered-text">Please select a
          <span *ngIf="user['division'] === 'DC'">DC</span><span *ngIf="user['division'] !== 'DC'">Store</span>.
        </div>
        <button #submitButton [disabled]="!storeSelection.valid || !selectedStore['Store_No']"
          class="csa-button-solid survey-submit" type="submit"><span>
            <fa-icon [icon]="faSave"></fa-icon>
          </span> Save <span *ngIf="user['division'] === 'DC'">DC</span><span
            *ngIf="user['division'] !== 'DC'">Store</span> Settings</button>
      </div>
    </div>
  </form>
</mat-dialog-content>