import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Observable, catchError, map, of 
} from 'rxjs';
import { environment } from '../../environments/environment';
import { RolesMapper } from '../access-management/services/roles.mapper';
import { handleError } from '../utils/handle-error/handle-error';

@Injectable({
  providedIn: 'root'
})
export class SwitchDivisionService {
  private rolesUrl = `${environment.backEndUrl}/roles`;

  private listUrl = `${environment.backEndUrl}/role/list`;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    }),
  };

  constructor(private httpClient: HttpClient, private rolesMapper: RolesMapper) { }

  getRoles(): Observable<object> {
    return this.httpClient.get<object>(this.rolesUrl, this.httpOptions).pipe(
      catchError((err) => {
        console.log('Error in getting roles data.');
        console.log(err.message);
        return of(null);
      })
    );
  }
}
