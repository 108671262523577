
<div class="close-container">
  <span class="info-icon med-grey" (click)="closeMe()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </span>
</div>

<mat-dialog-content>
  <form #createFindingForm="ngForm" (submit)="saveFinding(createFindingForm.value)">
    <div class="create-action-form">
      <h2>{{data.edit ? 'Edit' : 'New'}} Finding</h2>
      
      <div class="pre-filled-data">
        <h1 class="create-action-headings left-align">Assessment: </h1>
        <div>{{surveyData['title'] | unescape}}</div>
      </div>   

      <h1 class="create-action-headings left-align ">Finding Title:</h1>
      <h6 class="hint">This will be used throughout iVerify to identify this finding</h6>

      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="findingTitle">Finding Title</mat-label>
        <!-- <input spellcheck="true" (ngModelChange)="findingData['title']=$event" [ngModel]="findingData['title'] | unescape" (keyup)="saveNotes(createFindingForm.value)"  ngModel  maxLength="50" #findingTitle matInput class="custom-control-input" name="findingTitle" required> -->
        <input spellcheck="true" (ngModelChange)="findingData['title']=$event" [ngModel]="findingData['title'] | unescape" maxLength="1000" #findingTitle matInput class="custom-control-input" name="findingTitle" required>
        <mat-hint align="end">{{findingTitle['value'].length || 0}}/1000</mat-hint>
        <mat-error>Please enter a Finding Title</mat-error>
      </mat-form-field>



      <h1 class="create-action-headings left-align">Risk Level: </h1>
      <div class="left-align size-msg">
      </div>
<mat-form-field appearance="outline" color="primary">
  <mat-label for="riskLevel">Risk Level</mat-label>
  <mat-select #riskLevel matInput [(ngModel)]="findingData['riskLevel']" class="custom-control-input"
    name="riskLevel" required>
    <mat-option *ngFor="let riskLevel of riskLevels" [value]="riskLevel">
    {{riskLevel}}
    </mat-option>
  </mat-select>
  <mat-error>Please select a Risk level</mat-error>
  </mat-form-field> 

      <h1 class="create-action-headings left-align ">Section:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="section">Section</mat-label>
        <mat-select #section matInput [(ngModel)]="findingData['sectionHeading']" class="custom-control-input"
          name="section" required>
          <mat-option *ngFor="let section of surveyData['sectionHeading']" [value]="section">
            {{section}}
          </mat-option>
        </mat-select>
        <mat-error>Please select a Section</mat-error>
      </mat-form-field>  

       <h1 class="create-action-headings left-align ">Reference:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="reference">Reference (Optional)</mat-label>
        <!-- <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput spellcheck="true" (ngModelChange)="findingData['rootCause']=$event" [ngModel]="findingData['rootCause'] | unescape" (keyup)="saveNotes(createFindingForm.value)"  ngModel  maxLength="1000" #rootCause matInput class="custom-control-input" name="rootCause" required></textarea> -->
        <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput spellcheck="true" (ngModelChange)="findingData['reference']=$event" [ngModel]="findingData['reference'] | unescape" maxLength="1000" #reference matInput class="custom-control-input" name="reference"></textarea>
        <mat-hint align="end">{{reference['value'].length || 0}}/1000</mat-hint>
      </mat-form-field>

      <div class="centered-fields">
        <div class="multiple-field" [style.--numShown]="2">
          <h1 class="create-action-headings left-align ">Type:</h1>
          <mat-radio-group [(ngModel)]="findingData['MRRtype']" name="MRRtype" class="radio-buttons" required>            
            <div>
              <mat-radio-button color="primary" value="finding" [checked]="findingData['MRRtype'] === 'finding'">
                Finding
              </mat-radio-button>
            </div>
            <div>
              <mat-radio-button color="primary" value="improvement" [checked]="findingData['MRRtype'] === 'improvement'">
                Improvement
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
        <div class="multiple-field" [style.--numShown]="2">  
          <h1 class="create-action-headings left-align ">Available To:</h1>
          <mat-radio-group [(ngModel)]="findingData['availableTo']" name="availableTo" class="radio-buttons" required>
            <div>
              <mat-radio-button color="primary" value="above-store" [checked]="findingData['availableTo'] === 'above-store'">
                Above Store Users
              </mat-radio-button>
            </div>
            <div>
              <mat-radio-button color="primary" value="store" [checked]="findingData['availableTo'] === 'store'">
                Store Users
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>

      <h1 class="create-action-headings left-align ">Root Cause:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="rootCause">Root Cause(Optional)</mat-label>
        <!-- <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput spellcheck="true" (ngModelChange)="findingData['rootCause']=$event" [ngModel]="findingData['rootCause'] | unescape" (keyup)="saveNotes(createFindingForm.value)"  ngModel  maxLength="1000" #rootCause matInput class="custom-control-input" name="rootCause" required></textarea> -->
        <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput spellcheck="true" (ngModelChange)="findingData['rootCause']=$event" [ngModel]="findingData['rootCause'] | unescape" maxLength="1000" #rootCause matInput class="custom-control-input" name="rootCause"></textarea>
        <mat-hint align="end">{{rootCause['value'].length || 0}}/1000</mat-hint>
        <mat-error>Please enter the root cause</mat-error>
      </mat-form-field>

      <h1 class="create-action-headings left-align ">Detailed Findings:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="description">Detailed Findings</mat-label>
        <!-- <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput spellcheck="true" (ngModelChange)="findingData['description']=$event" [ngModel]="findingData['description'] | unescape" (keyup)="saveNotes(createFindingForm.value)"  ngModel  maxLength="1000" #description matInput class="custom-control-input" name="description" required></textarea> -->
        <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput spellcheck="true" (ngModelChange)="findingData['description']=$event" [ngModel]="findingData['description'] | unescape" maxLength="3000" #description matInput class="custom-control-input" name="description" required></textarea>
        <mat-hint align="end">{{description['value'].length || 0}}/3000</mat-hint>
        <mat-error>Please enter your detailed findings</mat-error>
      </mat-form-field>

      <!-- <h1 class="create-action-headings left-align no-bottom-margin">Notes:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="notes">Notes</mat-label>
        <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput spellcheck="true" (ngModelChange)="findingData['notes']=$event" [ngModel]="findingData['notes'] | unescape" (keyup)="saveNotes(createFindingForm.value)"  ngModel  maxLength="1000" #notes matInput class="custom-control-input" name="notes"></textarea>
        <mat-hint align="end">{{notes['value'].length || 0}}/500</mat-hint>
        <mat-error>Please enter your notes</mat-error>
      </mat-form-field> -->
    </div>

    <div class="submit-button-container">
      <div class="survey-link-container">
        <div *ngIf="!createFindingForm.valid || !createFindingForm.dirty" class="subtle-centered-text">
          <span *ngIf="!createFindingForm.dirty">
            The finding has not been changed.
          </span>
          <span *ngIf="!createFindingForm.valid">
            Please complete all fields to save.
          </span>
        </div>
        <button [disabled]="!createFindingForm.valid || !createFindingForm.dirty" class="csa-button-solid survey-submit" type="submit">
          <span>
            <fa-icon [icon]="faPaperPlane"></fa-icon>
            Save Finding
          </span>
        </button>
        <div  *ngIf="findingData['question_id']" class="medium-text red-text cancel-link" (click)="deleteFinding(findingData)">
          Delete Finding
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
