import {
  ChangeDetectorRef, Component, ElementRef, EventEmitter, KeyValueDiffer, KeyValueDiffers, OnInit, 
  HostListener, NgZone, ViewChild 
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import Compressor from 'compressorjs';
import {
  faAngleDown,
  faAngleUp,
  faPlayCircle,
  faPaperclip,
  faCircleNotch,
  faCamera,
  faTrash,
  faPencilAlt,
  faPlus,
  faPaperPlane,
  faCheck,
  faSave,
  faCalendarPlus
} from '@fortawesome/free-solid-svg-icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

import { CsaAuthService } from '../../auth/csa-auth.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { PhotoPreviewComponent } from '../../surveys/photo-preview/photo-preview.component';
import { FinalReportHeaderComponent } from '../final-report-header/final-report-header.component';
import { MrrStoreDraftViewDialogComponent } from '../mrr-store-draft-view-dialog/mrr-store-draft-view-dialog.component';
import { MrrStoreFinalViewDialogComponent } from '../mrr-store-final-view-dialog/mrr-store-final-view-dialog.component';

import { LoaderService } from '../../common-services/loader.service';
import { SignOffDialogComponent } from '../sign-off-dialog/sign-off-dialog.component';
import { User } from '../../models/user';
import { MrrSurveysService } from '../mrr-surveys.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-mrr-store-details',
  templateUrl: './mrr-store-details.component.html',
  styleUrls: ['./mrr-store-details.component.scss']
})
export class MrrStoreDetailsComponent implements OnInit {
  @ViewChild('printSurvey') printSurvey: ElementRef;

  private differ: KeyValueDiffer<string, any>;

  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  faPlayCircle = faPlayCircle;

  faCircleNotch = faCircleNotch;

  faCamera = faCamera;

  faTrash = faTrash;

  faPencilAlt = faPencilAlt;

  faPlus = faPlus;

  faPaperPlane = faPaperPlane;

  faCheck = faCheck;

  faSave = faSave;

  faCalendarPlus = faCalendarPlus;

  faPaperclip = faPaperclip;

  allExpandState = true;

  amberResponses = 0;

  canEdit = false;

  greenResponses = 0;

  naResponses = 0;

  noResponses = 0;

  openItems = [];

  redResponses = 0;

  objDiffer = {};

  printVersion = '';

  user: User = {};

  saved = false;

  saving = false;

  surveyDetails = {};

  surveyID = '';

  surveyResponses = {};

  surveys = [];

  surveyStates = [];

  yesResponses = 0;

  today = moment();

  finalreport = {};

  finalratingFlag: boolean = false;

  uploading: boolean = false;

  photos = [];

  Draftphotos: [];

  constructor(
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private differs: KeyValueDiffers,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private zone: NgZone,
    private loader: LoaderService,
    private mrrService : MrrSurveysService,
    private surveyService : SurveysService
  ) { }

  ngOnInit(): void {
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    console.log(this.surveyID);
    this.user = this.csaAuth.user;
    this.differ = this.differs.find(this.surveyDetails).create();
    const changes = this.differ.diff(this.surveyDetails);
    if (changes) {
      changes.forEachChangedItem((r) => { });
      //   changes.forEachAddedItem(function (r) {});
      //   changes.forEachRemovedItem(function (r) {});
    }

    this.surveyDetails['surveyState'] = 'Start';
    console.log(this);
    window.addEventListener('scroll', this.scroll, true);
    this.cd.detectChanges();

    this.surveyService.getSurveyById(this.surveyID).subscribe(
      (surveysData) => {
        console.log(surveysData);
        if (surveysData['status'] == 500) {
          this.router.navigate(['/error']);
        }
        this.surveyDetails = surveysData;
        this.getMRRFinalReport();

        this.getSurveyState();

        if (
          this.user['divisionRoles'][this.user['role']]
          >= this.user['divisionRoles']['Admin']
          && this.surveyDetails['status'] == 'open'
        ) {
          this.canEdit = true;
        }
        // this.canEdit = true;

        this.differ = this.differs.find(this.surveyDetails).create();

        const changes = this.differ.diff(this.surveyDetails);
        if (changes) {
          changes.forEachChangedItem((r) => { });
          changes.forEachAddedItem((r) => { });
          changes.forEachRemovedItem((r) => { });
        }

        this.surveyDetails['surveyState'] = 'Start';
        console.log(this);
        window.addEventListener('scroll', this.scroll, true);
        this.cd.detectChanges();
      },
      (error) => {
        this.router.navigate(['/error', 'unauthorised']);
      }
    );
  }

  getAssessmentDetails() {
    this.surveyService
      .getSurveyById(this.surveyID)
      .subscribe((surveysData) => {
        this.surveyDetails = surveysData;
        console.log('he form getSurveyById', this.surveyDetails['status']);
      });
  }

  // ngOnDestroy() {
  //   window.removeEventListener("scroll", this.scroll, true);
  // }

  @ViewChild('saveButton', { static: false }) saveButton: ElementRef;

  scroll = (event): void => {
    // if (event.srcElement.scrollTop > 75) {
    //   this.saveButton["nativeElement"].classList.add("float-right");
    // } else {
    //   this.saveButton["nativeElement"].classList.remove("float-right");
    // }
  };

  editHeader(): void {
    if (this.finalreport['rating']) {
      this.surveyDetails['Rating'] = this.finalreport['rating'];
    }
    const dialogRef = this.dialog.open(MrrStoreDraftViewDialogComponent, {
      data: {
        survey: this.surveyDetails
      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
     
    });
  }

  availableToString(value) {
    switch (value) {
      case 'store':
        return 'Store Users';
      case 'above-store':
        return 'Above Store Users';
      default:
        return '';
    }
  }

  setOpened(itemIndex) {
    this.openItems.push(itemIndex);
  }

  setClosed(itemIndex) {
    for (let i = 0; i < this.openItems.length; i++) {
      if (this.openItems[i] === itemIndex) {
        this.openItems.splice(i, 1);
      }
    }
  }

  splitByCaps(value): string {
    if (
      this.user['division'] == 'DC'
      && (value == 'Store' || value == 'MultiStore')
    ) {
      if (value == 'Store') {
        return 'DCs';
      }
      if (value == 'MultiStore') {
        return 'Multi DC Users';
      }
    }

    if (
      value == 'Admin'
      || value == 'NationalView'
      || value == 'MultiStore'
    ) {
      value += 'Users';
    } else {
      if (value == 'OperationManager') {
        value = 'ZoneOperationManager';
      } else if (value == 'StockLossPartner') {
        value = 'StoreLossPartner';
      }
      if (value.substr(-1) != 's') {
        value += 's';
      }
    }

    return value.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
  }

  viewPhoto(photoName, questionID, fileName) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.surveyDetails['id']
      }-${
        questionID
      }-${
        this.user['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewPhoto(photoName, questionID, fileName);
            }
          });
        } else {
          const photoDialog = this.dialog.open(PhotoPreviewComponent, {
            data: {
              photoURL: photoData[photo],
              photoName,
              showRemove: false,
              documentType: 'Evidence'
            },
            backdropClass: 'dialog-backdrop',
            minWidth: '70%',
            maxWidth: '90vw',
            height: 'auto',
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewPhoto(photoName, questionID, fileName);
          }
        });
      }
    );
  }

  // getAllPhotos(questionID: string, photoIndex = 0, fileName?: string) {

  //   let evidence = []
  //   let questionIndex = 0;

  //   for (var i = 0; i < this.surveyDetails['responses'].length; i++) {
  //     let response = this.surveyDetails['responses'][i];
  //     if (response['photos'] && response['photos'].length > 0) {

  //       let responsePhotos = {
  //         photos: [],
  //         questionTitle: this.surveyDetails['questions'][i]['title'],
  //         questionID: questionID,
  //         sectionHeading: this.surveyDetails['questions'][i]['sectionHeading'],
  //         totalIndex: i + 1,
  //         response: response['response']
  //       }

  //       if (questionID == response['question_id']) {
  //         questionIndex = evidence.length
  //       }

  //       for (var j = 0; j < response['photos'].length; j++) {

  //         if (!response['photos'][j]['fileName']) {
  //           response['photos'][j]['fileName'] = this.surveyDetails['id'] + '-' + response['question_id'] + '-' + this.user['storeID'] + '-' + response['photos'][j]['photoName'];
  //         }

  //         if (fileName == response['photos'][j]['fileName'].replace(/\s/g, '-').replace(/\.[^.]*$/, '')) {
  //           photoIndex = j;
  //         }

  //         responsePhotos.photos.push({
  //           photoName: response['photos'][j]['photoName'],
  //           fileName: response['photos'][j]['fileName']
  //         });
  //       }

  //       evidence.push(responsePhotos)
  //     }
  //     this.surveyDetails['responses'][i] = response
  //   }

  //   let photoDialog = this.dialog.open(PhotoCarouselComponent, {
  //     data: {
  //       evidence: evidence,
  //       evidenceIndex: questionIndex,
  //       photoIndex: photoIndex,
  //       totalNumber: this.surveyDetails['questions'].length,
  //       showRemove: false,
  //       type: 'survey'
  //     },
  //     backdropClass: 'dialog-backdrop',
  //     minWidth: '70%',
  //     maxWidth: '90vw',
  //     height: 'auto'
  //   });
  // }

  getNotes() {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      this.surveyDetails['questions'][i]['uploading'] = false;
      for (let j = 0; j < this.surveyDetails['responses'].length; j++) {
        if (
          this.surveyDetails['responses'][j]['question_id']
          == this.surveyDetails['questions'][i]['id']
        ) {
          this.surveyDetails['questions'][i]['notes'] = this.surveyDetails['responses'][j]['notes'];
          this.surveyDetails['questions'][i]['response'] = this.surveyDetails['responses'][j]['response'];
          this.surveyDetails['questions'][i]['actions'] = this.surveyDetails['responses'][j]['actions'];
          this.surveyDetails['questions'][i]['photos'] = this.surveyDetails['responses'][j]['photos'];
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          this.surveyDetails['questions'][i]['reviewedFrom'] = this.surveyDetails['responses'][i]['reviewedFrom'];
          this.surveyDetails['questions'][i]['reviewedTo'] = this.surveyDetails['responses'][i]['reviewedTo'];
        }
      }
    }
  }

  getSurveyState() {
    const completion = this.surveyDetails['completion'];
    if (this.surveyDetails['status'] == 'active') {
      this.surveyDetails['surveyState'] = 'Start';
    } else if (this.surveyDetails['status'] == 'started') {
      this.surveyDetails['surveyState'] = 'Continue';
    } else if (this.surveyDetails['status'] == 'submitted') {
      this.surveyDetails['surveyState'] = 'Submitted';
    } else if (this.surveyDetails['status'] == 'closed') {
      this.surveyDetails['surveyState'] = 'Closed';
    }
  }

  uploadPhoto($event, findingID, survey) {
    console.log('uploadPhoto', $event, findingID, survey);
    // let questionIndex =2;
    const questionIndex = this.surveyDetails['questions'].findIndex((item) => item['question_id'] === findingID);
    this.surveyDetails['questions'][questionIndex]['uploading'] = true;
    console.log('questionIndex', questionIndex, this.surveyDetails['questions'][questionIndex]['uploading']);
    const photoName = $event.target.files[0].name;
    const goodPhotoType = $event.target.files[0].name
      .toLowerCase()
      .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

    const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
    if (goodPhotoType == null) {
      this.displayUploadError(
        'This file is not an image or PDF',
        'Please select an image or PDF file to attach.',
        questionIndex
      );
    } else if (PDFTest == null) {
      this.compressPhoto($event.target.files[0], findingID).subscribe(
        (compressedPhoto) => {
          if (typeof compressedPhoto === 'undefined') {
            this.displayUploadError(
              'Error Compressing File',
              `Please try a different file or email ${ 
                this.user['supportEmail']
              } for support`,
              questionIndex
            );
          } else if (compressedPhoto['size'] > 10000000) {
            this.displayUploadError(
              'This File Is Too Big',
              'Please upload a smaller file',
              questionIndex
            );
          } else {
            const action = this.surveyService
              .uploadPhoto(
                compressedPhoto,
                survey,
                findingID,
                this.surveyDetails['storeID'],
                `${compressedPhoto['size']}`
              )
              .subscribe(
                (response) => {
                  if (!response[0] || response[0]['status'] != 200) {
                    let message;
                    if (response[0]['status'] != 200) {
                      message = `There was an error when sending your file. Please email ${ 
                        this.user['supportEmail']
                      } for support`;
                    } else {
                      message = `No response from server. Please email ${ 
                        this.user['supportEmail']
                      } for support`;
                    }
                    this.displayUploadError(
                      "Couldn't Add File",
                      message,
                      questionIndex
                    );
                  } else if (response[0]['message'] == 'OK') {
                    this.getPhotosThisQuestion(questionIndex, {
                      photoName: response[0]['name'],
                      fileName: response[0]['fileID'],
                      fileSize: compressedPhoto['size'],
                    });
                  }
                },
                (error) => {
                  this.displayUploadError(
                    "Couldn't Add File",
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                    questionIndex
                  );
                }
              );
          }
        }
      );
    } else if ($event.target.files[0]['size'] > 10000000) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'This File Is Too Big',
          message: 'Please upload a smaller file',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
      setTimeout(() => {
        this.surveyDetails['questions'][questionIndex]['uploading'] = false;
      }, 10);
    } else {
      const action = this.surveyService
        .uploadPhoto(
          $event.target.files[0],
          survey,
          findingID,
          this.surveyDetails['storeID'],
          $event.target.files[0]['size']
        )
        .subscribe(
          (response) => {
            if (!response[0] || response[0]['status'] != 200) {
              let message;
              if (response[0]['status'] != 200) {
                message = `There was an error when sending your file. Please email ${ 
                  this.user['supportEmail']
                } for support`;
              } else {
                message = `No response from server. Please email ${ 
                  this.user['supportEmail']
                } for support`;
              }
              this.displayUploadError("Couldn't Add File", message, questionIndex);
            } else {
              this.getPhotosThisQuestion(questionIndex, {
                photoName: response[0]['name'],
                fileName: response[0]['fileID'],
                fileSize: $event.target.files[0]['size'],
              });
              this.cd.detectChanges();
            }
          },
          (error) => {
            this.displayUploadError(
              "Couldn't Add File",
              `Please email ${
                this.user['supportEmail']
              } for support`,
              questionIndex
            );
          }
        );
    }
  }

  compressPhoto(file, question) {
    const emitter = new EventEmitter<Blob>();

    const compression = new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: 1000000,
      success(result) {
        emitter.emit(result);
      },
      error(err) {
        emitter.emit();
      },
    });
    return emitter.asObservable();
  }

  displayUploadError(heading, message, questionIndex) {
    this.dialog.open(MessagesComponent, {
      data: {
        heading,
        message,
        closeText: 'Ok',
      },
      backdropClass: 'dialog-backdrop',
    });
    setTimeout(() => {
      this.surveyDetails['questions'][questionIndex]['uploading'] = false;
    }, 10);
  }

  getPhotosThisQuestion(questionIndex, photo) {
    // this.saveForm(this.surveyDetails['questions'][questionIndex]['id']);

    setTimeout(() => {
      if (!this.surveyDetails['questions'][questionIndex]['photos']) {
        this.surveyDetails['questions'][questionIndex]['photos'] = [];
        // this.surveyDetails["responses"][questionIndex]["photos"] = [];
      }
      this.surveyDetails['questions'][questionIndex]['photos'].push(photo);
      // this.surveyDetails["responses"][questionIndex]["photos"].push(photo);
      this.surveyDetails['questions'][questionIndex]['uploading'] = false;
      // this.addEvidence()
    }, 10);
    this.cd.detectChanges();
  }

  finalRate(finalreport): void {
    if (this.surveyDetails['Rating']) {
      finalreport['rating'] = this.surveyDetails['Rating'];
    }
    const dialogRef = this.dialog.open(MrrStoreFinalViewDialogComponent, {
      data: {
        surveyDetails: this.surveyDetails,
        finalreport,

      },

      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'auto',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // UPDATE PAGE
      console.log('MrrStoreFinalViewDialogComponent', result, this.finalreport);
      this.cd.detectChanges();
    });
  }

  getMRRFinalReport() {
    // call getMRRFinalReport api 
    this.surveyService.getSurveyById(this.surveyID).subscribe(
      (surveysData) => {
        console.log(' getMRRFinalReport api ', surveysData);
        if (surveysData['status'] == 500) {
          this.router.navigate(['/error']);
        }
        this.surveyDetails = surveysData;
        this.getNotes();
        // adding details to final headerpage
        if (this.surveyDetails['finalReportDate']) {
          this.finalreport['finalReportDate'] = this.surveyDetails['finalReportDate'];
          this.finalreport['summaryFindings'] = this.surveyDetails['summaryFindings'];
          this.finalreport['rating'] = this.surveyDetails['rating'];
          this.finalreport['id'] = this.surveyDetails['id'];
          this.finalreport['finalReportPhoto'] = this.surveyDetails['files']['final'];
        }
        // adding details to final headerpage
        if (this.surveyDetails['files']) {
          if (this.surveyDetails['files']['draft']) this.Draftphotos = this.surveyDetails['files']['draft'];
        }
      }
    );
  }

  setOpenedfinal() {
    this.finalratingFlag = true;
  }

  setClosedfinal() {
    this.finalratingFlag = false;
  }

  uploadDoc($event, question, survey) {
    this.uploading = true;
    const photoName = $event.target.files[0].name;
    const goodPhotoType = $event.target.files[0].name
      .toLowerCase()
      .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

    const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
    if (goodPhotoType == null) {
      this.displayUploadErrorDoc(
        'This file is not an image or PDF',
        'Please select an image or PDF file to attach.',

      );
    } else if (PDFTest == null) {
      this.compressPhoto($event.target.files[0], question).subscribe(
        (compressedPhoto) => {
          if (typeof compressedPhoto === 'undefined') {
            this.displayUploadErrorDoc(
              'Error Compressing File',
              `Please try a different file or email ${ 
                this.user['supportEmail']
              } for support`,

            );
          } else if (compressedPhoto['size'] > 10000000) {
            this.displayUploadErrorDoc(
              'This File Is Too Big',
              'Please upload a smaller file',

            );
          } else {
            const action = this.surveyService
              .uploadPhoto(
                compressedPhoto,
                survey,
                question,
                this.surveyDetails['storeID'],
                `${compressedPhoto['size']}`
              )
              .subscribe(
                (response) => {
                  if (!response[0] || response[0]['status'] != 200) {
                    let message;
                    if (response[0]['status'] != 200) {
                      message = `There was an error when sending your file. Please email ${ 
                        this.user['supportEmail']
                      } for support`;
                    } else {
                      message = `No response from server. Please email ${ 
                        this.user['supportEmail']
                      } for support`;
                    }
                    this.displayUploadErrorDoc(
                      "Couldn't Add File",
                      message,

                    );
                  } else if (response[0]['message'] == 'OK') {
                    this.getDocThisQuestion({
                      photoName: response[0]['name'],
                      fileName: response[0]['fileID'],
                      fileSize: compressedPhoto['size'],
                    });
                  }
                },
                (error) => {
                  this.displayUploadErrorDoc(
                    "Couldn't Add File",
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`

                  );
                }
              );
          }
        }
      );
    } else if ($event.target.files[0]['size'] > 10000000) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'This File Is Too Big',
          message: 'Please upload a smaller file',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
      setTimeout(() => {
        this.uploading = false;
      }, 10);
    } else {
      const action = this.surveyService
        .uploadPhoto(
          $event.target.files[0],
          survey,
          question,
          this.surveyDetails['storeID'],
          $event.target.files[0]['size']
        )
        .subscribe(
          (response) => {
            if (!response[0] || response[0]['status'] != 200) {
              let message;
              if (response[0]['status'] != 200) {
                message = `There was an error when sending your file. Please email ${ 
                  this.user['supportEmail']
                } for support`;
              } else {
                message = `No response from server. Please email ${ 
                  this.user['supportEmail']
                } for support`;
              }
              this.displayUploadErrorDoc("Couldn't Add File", message);
            } else {
              this.getDocThisQuestion({
                photoName: response[0]['name'],
                fileName: response[0]['fileID'],
                fileSize: $event.target.files[0]['size'],
              });
              this.cd.detectChanges();
            }
          },
          (error) => {
            this.displayUploadErrorDoc(
              "Couldn't Add File",
              `Please email ${
                this.user['supportEmail']
              } for support`,

            );
          }
        );
    }
  }

  displayUploadErrorDoc(heading, message) {
    this.dialog.open(MessagesComponent, {
      data: {
        heading,
        message,
        closeText: 'Ok',
      },
      backdropClass: 'dialog-backdrop',
    });
    setTimeout(() => {
      this.uploading = false;
    }, 10);
  }

  getDocThisQuestion(photo) {
    setTimeout(() => {
      if (!this.finalreport['finalReportPhoto']) {
        this.finalreport['finalReportPhoto'] = [];
        // this.surveyDetails["responses"][questionIndex]["photos"] = [];
      }
      // this.photos.push(photo);
      this.finalreport['finalReportPhoto'].push(photo);
      this.uploading = false;
    }, 10);
    this.cd.detectChanges();
  }

  viewfinalDoc(photoName, question, fileName, showRemove) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.surveyDetails['id']
      }-${
        question
      }-${
        this.surveyDetails['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        console.log(photoData);
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewPhoto(photoName, question, fileName);
            }
          });
        } else if (Object.keys(photoData).length > 0) {
          this.zone.run(() => {
            const photoDialog = this.dialog.open(PhotoPreviewComponent, {
              data: {
                photoURL: photoData[photo],
                photoName,
                showRemove,
                documentType: 'Final Report'
              },
              backdropClass: 'dialog-backdrop',
              minWidth: '70%',
              maxWidth: '90vw',
              height: 'auto',
            });

            photoDialog.afterClosed().subscribe((result) => {
              if (result == 'delete') this.removePhoto(photoData, question, photo);
            });
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
            `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewPhoto(photoName, question, fileName);
          }
        });
      }
    );
  }

  removePhoto(src, question, photo) {
    this.mrrService
      .removeDoc(photo, src, this.surveyDetails['id'], question)
      .subscribe(
        (response) => {
          this.removePhotoFromQ(photo);
          this.cd.detectChanges();
        },
        (error) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Remove File",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
  }

  removePhotoFromQ(fileName) {
    for (let j = 0; j < this.finalreport['finalReportPhoto'].length; j++) {
      if (
        this.finalreport['finalReportPhoto'][j]['photoName'] == fileName
        || this.finalreport['finalReportPhoto'][j]['fileName'] == fileName
      ) {
        // this.photos.splice(j, 1);
        this.finalreport['finalReportPhoto'].splice(j, 1);
        setTimeout(() => {
          this.uploading = false;
        }, 10);
      }
    }
  }

  publishMrr() {
    const dialogRef = this.dialog.open(SignOffDialogComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Submitting this Material Risk Review  will make it available to Store Manager. Do you wish to proceed?',
        closeText: 'Submit',
        cancelText: 'Cancel',
      },
      backdropClass: 'dialog-backdrop',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result['type'] == 'logout') {
        console.log(result['comments'], 'logout');
      }
    });
  }
}
