import { Dictionary } from 'src/app/types/dictionary';

/**
 * Generates a query string filter for the specified next organizational level based on user division and selected locations.
 *
 * @param {object} orgStruct - An object containing the user organization structure.
 * @param {string} nextLevel - The next organizational node for which the query string is generated.
 * @param {string} userDivision - The division to which the user belongs.
 * @param {Dictionary<string, string>} selectedLocations - Selected organization node values.
 * @returns {string} - The generated query string filter for the specified next organizational level.
 */
export function createLevelQuery(orgStruct: object, nextLevel: string, userDivision: string, selectedLocations: Dictionary<string, string>): string {
  if (
    orgStruct === undefined
    || selectedLocations === undefined
    || Object.keys(orgStruct).length === 0
    || Object.keys(selectedLocations).length === 0
    || nextLevel === '' || nextLevel === undefined 
    || userDivision === '' || userDivision === undefined 
  ) {
    return '';
  }
  let key = 'division';
  let query = '';
  while (key != nextLevel && key !== undefined) {
    if (key == 'division') query = `?division=${userDivision}`;
    else query += `&${key}=${selectedLocations[key]}`;
  
    key = orgStruct[key];
    if (key === undefined) {
      break;
    }
  }
  return query;
}
