import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// eslint-disable-next-line import/no-duplicates
import firebase from 'firebase/compat/app';
// eslint-disable-next-line import/no-duplicates
import auth from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Router, RoutesRecognized } from '@angular/router';
import {
  concatMap, tap, retry, first, map, mergeMap, switchMap, catchError,
  filter, pairwise
} from 'rxjs/operators';
import {
  Observable, of, BehaviorSubject, throwError, firstValueFrom, from, ReplaySubject
} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CsaBackEndService } from '../common-services/csa-back-end.service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';

/**
 * Auth service provides everything related to authentication and user information.
 * 
 * Key things to note are:
 * This service will never provide direct access to the user object. Any user object returned by the service is a readonly copy.
 * To modify the user object locally, you can apply overrides. Overrides are session based and rather than modifying the user object directly, they are stored separately
 * and are applied to the copy of the user object whenever this service sends one out.
 * 
 * To get the latest user object (including realtime overrides), it's recommended that you subscribe to the userChanges() behaviour subject.
 * Otherwise, if realtime state updates aren't important - you can use the user() getter.
 */
@Injectable({
  providedIn: 'root'
})
export class CsaAuthService {
  private _user: User; // The complete user object for the currently signed in user, as recieved from our backend. Do not read this value directly, use the getter.

  private _userChanges$: ReplaySubject<User>; // we maintain a single userChanges replay subject globally. We store it here so we can return the same one.

  public authToken: firebase.auth.IdTokenResult; // the firebase auth token - does not include user details from our backend.

  public previousRoute: string; // the last route the user was trying to access - so we can take them there upon successul login

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private csaBackEnd: CsaBackEndService,
    private cookieService: CookieService
  ) {
    this.userChanges$();
    this.watchAndUpdatePreviousRoute();
  }

  /**
   * Displays the login popup or redirects to login.
   * Ideally this should only be called from the login component.
   * The redirect method is only used in the scenario where popup login isn't working (happens when some VPN or proxy services are being used).
   *
   * @param method - If popup, the login is shown in a popup. If redirect, redirect the user to login.
   */
  public showLogin(method: 'popup' | 'redirect' = 'popup') {
    this.clearOverrides(true); // clear the overrides - when we return as a new user, we don't want our old overrides applied
    const provider = new firebase.auth.SAMLAuthProvider(environment.samlAuthProvider);
    if (method == 'popup') {
      this.afAuth.signInWithPopup(provider);
    } else {
      this.afAuth.signInWithRedirect(provider);
    }
  }

  /**
   * Returns the subject for the currently signed in user. Automatically updated whenever auth changes, or when refreshUser() is called.
   *
   * @returns Subject for currently signed in user object.
   */
  public userChanges$(): ReplaySubject<User> {
    if (!this._userChanges$) {
      this._userChanges$ = new ReplaySubject(1);
      this.afAuth.user.pipe(
        map((auth) => {
          // if the user isn't signed in, we've got nothing to return
          if (!auth) {
            return null;
          }
          return auth;
        }),
        switchMap((auth) => {
          if (!auth) {
            return of(null);
          }
          return from(auth.getIdTokenResult()).pipe(map((idTokenResult) => ({ idTokenResult, auth })));
        }),
        switchMap((tokenAndAuth) => {
          if (!tokenAndAuth) {
            return of(null);
          }
          this.authToken = tokenAndAuth.idTokenResult;

          // if we haven't previously retrieved the user object for this user id, get it from the backend.
          if (!this.user || this.user?.appUserId !== tokenAndAuth.auth.uid) {
            return this.refreshUser();
          }
          return of(this.user);
        }),
        map((user) => (this.user))
      ).subscribe(this._userChanges$);
    }

    return this._userChanges$;
  }

  /**
   * Returns a COPY of the user object.
   * NOTE: if the user isn't signed in OR if the user object hasn't been fetched yet (quite possible when application start), this will be undefined.
   * It is therefore preferred that you subscribe to the userChanges$ subject instead.
   */
  public get user(): User {
    if (this._user) {
      // Creating a copy of the user object using parse and stringify: https://stackoverflow.com/a/46843393
      const userObjectCopy = JSON.parse(JSON.stringify(this._user));
      const userObjectWithOverrides = this.applyUserOverridesToUserObject(userObjectCopy);
      return userObjectWithOverrides;
    }
    return null;
  }

  /**
   * Applies user overrides to a passed in user object. Whenever a user object is passed out of this service, make sure user overrides have been
   * applied to it using this method. 
   *
   * @param user - User object to apply the overrides to. This should probably be a COPY of the actual user object.
   * @returns User object with overrides applied.
   */
  private applyUserOverridesToUserObject(user: User): User {
    const userOverrides = this.getUserOverrides();

    // apply the overrides
    Object.assign(user, userOverrides);

    return user;
  }

  /**
   * Retrieves user override values from session storage.
   * User Overrides are modifications to the user object made only locally (for example when a super admin user assumes another type of role).
   *
   * @returns Object with existing user overrides OR an empty object.
   */
  private getUserOverrides(): Object {
    let existingOverrides = JSON.parse(sessionStorage.getItem('userOverrides'));

    // if there are no existing overrides, return an empty object
    if (!existingOverrides) {
      existingOverrides = {};
    }

    return existingOverrides;
  }

  /**
   * Updates user override value in session storage.
   * User overrides are modifications to the user object made locally (for example when super admin user assumes another type of role )
   * Whenever a user object is returned from the auth service, if a key has an override, the override value will be returned instead of the actual value for that key,.
   *
   * @param key - Key of the override.
   * @param value - Value of the override.
   */
  public setUserOverride(key: 'division' | 'orgStruct' | 'role' | 'divisionRoles' | 'super' | 'state' | 'zone' | 'groupNo' | 'storeName' | 'storeID' | 'support' | 'featureSubfeatureKeys', value: any) {
    const existingOverrides = this.getUserOverrides();

    existingOverrides[key] = value;
    sessionStorage.setItem('userOverrides', JSON.stringify(existingOverrides));

    // anyone subecribed to the userChanges will need to get this user with newly updated overrides.
    this.emitUserChanges();
  }

  /**
   * Clears applied user overrides.
   *
   * @param skipEmitChanges - If true, changes are not emitted on the user observable.
   */
  public clearOverrides(skipEmitChanges = false) {
    sessionStorage.removeItem('userOverrides');

    if (!skipEmitChanges) {
      // anyone subecribed to the userChanges will need to get this user with newly updated overrides.
      this.emitUserChanges();
    }
  }

  public hasUserOverrides(): boolean {
    return !!sessionStorage.getItem('userOverrides');
  }

  /**
   *  Gets a user object from the backend and updates the user subject with it.
   */
  public refreshUser(): Observable<ReplaySubject<User>> {
    return this.csaBackEnd.getUser().pipe(map((latestUser) => {
      if (latestUser) {
        this._user = latestUser;
        this.emitUserChanges();
        return this.userChanges$();
      }
    }));
  }

  /**
   * Emits user changes on the _userChanges subject.
   */
  private emitUserChanges() {
    if (this._userChanges$) {
      // anyone subecribed to the userChanges will get this newly updated user.
      this._userChanges$.next(this.user);
    }
  }

  /**
   * Gets the latest token from firebase authentication, and updates the authToken member variable.
   *
   * @returns Observable for the updated auth token.
   */
  public getAndUpdateToken$(): Observable<any> {
    return this.afAuth.user.pipe(switchMap((user) => from(user.getIdTokenResult()).pipe(map((authToken) => {
      this.authToken = authToken;
      return this.authToken;
    }))), catchError((error) => of(null)));
  }

  /**
   * Compares the user's role to the admin role. If the role is equal to or greater than the admin role for the division, it is considered an admin user.
   * Returns false by default.
   *
   * @param user - User object to check. If not provided, attempts to use the currently signed in user.
   */
  public isUserAdmin(user?: User) {
    let userToCheck = user;

    if (!user && this.user) {
      userToCheck = this.user;
    } else if (!user && !this.user) {
      // no user passed and no user currently signed in, return false by default
      return false;
    }

    // compare the roles
    const adminRole = parseInt(userToCheck.divisionRoles.Admin);
    const userRole = parseInt(userToCheck.divisionRoles[userToCheck.role]);
    if (userRole >= adminRole) {
      return true;
    }
    return false;
  }

  /**
   * Returns whether the user is a support user.
   * Returns false by default.
   *
   * @param user - User object to check. If not provided, attempts to use the currently signed in user.
   */
  public isUserSupport(user?: User) {
    let userToCheck = user;

    if (!user && this.user) {
      userToCheck = this.user;
    } else if (!user && !this.user) {
      // no user passed and no user currently signed in, return false by default
      return false;
    }
    return userToCheck?.support;
  }

  /**
   * Compares the user's role to the store role. If the role is greater than the store role for the division, it is considered an above store user.
   * Returns false by default.
   * 
   * Note: this also returns true if the user is admin, because it is also an above store role.
   *
   * @param user - User object to check. If not provided, attempts to use the currently signed in user.
   */
  public isUserAboveStoreRole(user?: User) {
    let userToCheck = user;

    if (!user && this.user) {
      userToCheck = this.user;
    } else if (!user && !this.user) {
      // no user passed and no user currently signed in, return false by default
      return false;
    }

    // compare the roles
    const storeRole = parseInt(userToCheck.divisionRoles.Store);
    const userRole = parseInt(userToCheck.divisionRoles[userToCheck.role]);

    // if the user is a multi store user, it is considered to be a store role. Even though tecnhically it is an above store role
    if (userToCheck.role == 'MultiStore') {
      return false;
    }

    if (userRole > storeRole) {
      return true;
    }
    return false;
  }

  public isUserStoreRole(user?: User) {
    return !this.isUserAboveStoreRole(user);
  }

  /**
   * Compares the user's role against the passed in role. 
   * Returns whether the role matches the user's role.
   *
   * @param role - Role to compare against the user's role.
   * @param user - User object to check. If not provided, attempts to use the currently signed in user.
   */
  public isUserRole(role: string, user?: User): boolean {
    let userToCheck = user;

    if (!user && this.user) {
      userToCheck = this.user;
    } else if (!user && !this.user) {
      // no user passed and no user currently signed in, return false by default
      return false;
    }

    // compare the roles
    const roleToCompare = parseInt(userToCheck.divisionRoles[role]);
    const userRole = parseInt(userToCheck.divisionRoles[userToCheck.role]);

    if (userRole === roleToCompare) {
      return true;
    }
    return false;
  }

  /**
   * Clears saved user state, logs the user out and directs them to the login screen.
   */
  public async logout() {
    await this.afAuth.signOut();
    this._user = null;
    this.authToken = null;

    // clear cookie value - StoreId
    this.cookieService.delete('userStoreID', '/');
    this.cookieService.delete('userStoreName', '/');
    this.clearOverrides();

    window.location.href = 'https://login.microsoftonline.com/common'
      + '/wsfederation?wa=wsignout1.0';
  }

  /**
   * Upon successful login, we want to take the user to the route they were previously trying to access.
   * This function watches router events and updates the previousRoute.
   */
  private watchAndUpdatePreviousRoute() {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousRoute = events[0].urlAfterRedirects;
      });
  }
}
