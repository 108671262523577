<div class="page-container">
	<h1>Store Management</h1>
  
  <div class="question-options-row">
		<div class="filter-chips  filter-list action-chips">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)="createStore()" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faPlus"></fa-icon>
            <span>Create New Store</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      </div>
    </div>

	<div class="question-options-row">
		<div class="filter-chips  filter-list">
      <div class="chipDrop search-chip">
				<div>
					<fa-icon *ngIf="!textFilter" class="search-icon" [icon]="faSearch"></fa-icon>
				</div>
				<div class="clear-search" (click)="textFilter = ''; updateSearch();">
					<fa-icon *ngIf="textFilter" class="search-icon" [icon]="faTimes"></fa-icon>
				</div>
				<input class="search" type="text" [(ngModel)]="textFilter" placeholder="Search" (keyup)="updateSearch()">
			</div>

			<!-- <div class="chipDrop">
				<mat-chip-list aria-label="Filters">
					<mat-chip (click)="divisionSelect.open()" selected>
						<span>
							{{(selectedDivision ? selectedDivision : 'All Divisions') | unescape}}
						</span>
						<fa-icon [icon]="faSortDown"></fa-icon>
					</mat-chip>
					<mat-select #divisionSelect class="optionsDropDown">
						<mat-option *ngFor="let division of availableDivisions" (click)="resetPagination(); getStoresToShow(division, 'division')">
							{{division | unescape}}
						</mat-option>
					</mat-select>
				</mat-chip-list>
			</div> -->

			<ng-container *ngFor="let key of locationsOrdered">
        <div class="chipDrop"  *ngIf="showLocations[key]">
          <mat-chip-list aria-label="Filters">
            <mat-chip (click)="locationSelect.open()" selected>
              <span *ngIf="key === 'state' || key === 'region'; else titleLocation">
                {{(selectedLocations[key] ? selectedLocations[key] : (locationStringAll(key) | titlecase)) | unescape}}
              </span>
              <ng-template #titleLocation>
                {{(selectedLocations[key] ? locationString(key) : locationStringAll(key)) | titlecase | unescape}}
              </ng-template>
              <fa-icon [icon]="faSortDown"></fa-icon>
            </mat-chip>
            <mat-select #locationSelect class="optionsDropDown">
              <mat-option (click)="resetPagination(); getStoresToShow('', getPreviousLevel(key))">
                {{locationStringAll(key) | titlecase}}
              </mat-option>
              <mat-option *ngFor="let location of availableLocations[key]" (click)="resetPagination(); getStoresToShow(location, key)">
                {{location | unescape}}
              </mat-option>
            </mat-select>
          </mat-chip-list>
        </div>
      </ng-container>

      <div class="chipDrop" *ngIf="selectedLocations[user['orgStruct']['division']] || textFilter">
				<mat-chip-list aria-label="Filters">
					<mat-chip class="clear-chip" (click)="resetAllFilters()" color="primary" selected>
						<fa-icon class="chip-left-icon" [icon]="faRedoAlt"></fa-icon>
            <span>Clear</span>
					</mat-chip>
				</mat-chip-list>
			</div>
		</div>
	</div>

	<div class="audit-tool-cards-container">
		<div class="audit-tool-card more-list-screen-card">
			<mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" [selectedIndex]="selectedGroup" (selectedTabChange)="tabChanged($event)">
				<mat-tab label="{{tableGroup}}" *ngFor="let tableGroup of ['Active', 'Inactive']">
					<table mat-table [dataSource]="storesToShow">
            <ng-container [matColumnDef]="key" *ngFor="let key of tableColumns">
							<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align" [ngClass]="{'hide-on-mobile': key === 'email'}">
								<h4>{{removeID(key) | titlecase}} {{key === 'storeID' ? 'ID' : ''}}</h4>
							</th>
							<td mat-cell *matCellDef="let storeData" class="survey-details-link center-align cell-field" [style.--numShown]="tableColumns.length" [style.--numShownMobile]="tableColumns.length-1" [ngClass]="{'hide-on-mobile overflow-ellipsis': key === 'email'}">

								<span *ngIf="key === 'store name'">
									{{storeData['Location_Name'] | unescape | titlecase}}
								</span>
		
								<span *ngIf="key === 'storeID'">
									{{storeData['Store_No'] | unescape}}
								</span>
		
								<span *ngIf="locationsOrdered.includes(key)">
									{{storeData[upperCaseKey(key)] | unescape}}
								</span>
		
								<span *ngIf="key === 'email'">
									{{storeData['EMAIL']}}
								</span>
							</td>
						</ng-container>

						<mat-header-row *matHeaderRowDef="tableColumns" class="survey-heading-line"></mat-header-row>
						<mat-row *matRowDef="let storeData; columns: tableColumns;" (click)="updateStore(storeData)" class="survey-line survey-line-link"></mat-row>
					</table>

					<div *ngIf="isLoading" class="loader-container">
						<div class="loading-wapple"></div>
					</div>
		
					<div *ngIf="storesToShow.length == 0 && !isLoading">
						<h3 class="none-found-message">
							There are no stores to show.
						</h3>
					</div>
				</mat-tab>
			</mat-tab-group>
			<mat-paginator #paginator 
					[pageIndex]=pageIndex 
					[pageSize]=pageSize 
					[length]="totalCount"
					[pageSizeOptions]="[5, 10, 25, 100]" 
					[showFirstLastButtons]=false 
					(page)="getNext($event)">
			</mat-paginator>
		</div>
	</div>
</div>