<div class="page-container">
    <div class="audit-tool-cards-container">
  
      <div class="audit-tool-card">
        <div class="success-check">
          <div class="checkmark-circle">
          <div class="background"></div>
          <div class="checkmark draw"></div>
        </div>
        <h2>Thank you</h2>
        <div *ngIf="thankYouType === 'MRR'">
        <p>Thank you. All your actions has been saved.</p>
        <p>Click the below button to go back to the home screen</p>
      </div>
      <div *ngIf="thankYouType === 'signoff'">
        <p>Thank you for providing Sign off for this assessment.</p>
        <p>Click the below button to go back to the home screen</p>
      </div>
        <div class="survey-button padded-icon">
          <a [routerLink]="['/']">
            <button class="csa-button-solid">Return Home</button>
          </a>
        </div>
      </div>

    </div>
</div>
