import { Injectable } from '@angular/core';
import { FeatureAccessService } from '../common-services/feature-access.service';
import {
  IMenuItem, IMenuItemFeature, ISubMenuItem, ISubMenuItemFeature 
} from './navigation.component';

@Injectable({
  providedIn: 'root'
})
export class MenuBuilderService {
  constructor(private featureAccessService: FeatureAccessService) { }

  /**
   * Adds a menu item to a list of menu items at a specified position if the user has access to the specified composite feature.
   * If no position is specified, or if the specified position is greater than the current number of items in the menu,
   * the new menu item is added to the end of the menu. If the position is specified but is negative, the function logs
   * an error message and does not insert the menu item.
   *
   *
   * @param {IMenuItem[]} menuItems - The array of current menu items to which the new menu item will be added.
   * @param {IMenuItem} menuItem - The menu item to be added to the menu items array.
   * @param {string} [compositeFeatureKey=undefined] - An optional key representing a composite feature.
   *        If specified, the function checks if the user has access to this feature before adding the menu item.
   *        If not specified or if the user has access, the menu item will be added.
   * @param {number} [menuPosition=undefined] - The position at which to add the new menu item within the menu items array.
   *        If not specified or if the value exceeds the length of the array, the menu item is added at the end.
   *        If specified as a negative number, the function logs an error and does not insert the item.
   * @returns {void}
   */
  public addMenuItem(menuItems: IMenuItem[], menuItem: IMenuItem, compositeFeatureKey: string, menuPosition: number = undefined) {
    if (this.featureAccessService.hasAccess(compositeFeatureKey)) {
      if (menuPosition < 0) {
        console.log('Negative menu position is invalid. Menu item not inserted.');
        return;
      }
      // If the menu position is within the array bounds, use splice to insert
      if (menuPosition < menuItems.length) {
        menuItems.splice(menuPosition, 0, menuItem);
      } else {
        // If the menu position exceeds the array length, push the item to the end
        menuItems.push(menuItem);
      }
    }
  }

  /**
   * Builds and appends a menu item to a given list of menu items based on feature access permissions.
   * This function checks if the user has access to the main menu item feature and conditionally adds
   * submenu items based on their access permissions. If the user has access to any of the submenu
   * features, the main menu item is added as a parent item (without a link) with its submenus. 
   * Otherwise, it is added as a standalone link when one is supplied.
   *
   * @param {IMenuItem[]} menuItems - The array of menu items to which the new menu item will be appended.
   * @param {IMenuItemFeature} menuItemFeature - The feature associated with the main menu item being added. 
   *        This includes the composite feature key used for access checks and the menu item details.
   * @param {ISubMenuItemFeature[]} subMenuItemFeatures - An array of submenu item features. Each feature
   *        contains a composite feature key for access checks and the submenu item details.
   * 
   * The function begins by checking if the user has access to the main menu item feature using its
   * composite feature key. If so, it proceeds to check access for any of the submenu features. If the
   * user has access to any submenu features, the main menu item is prepared as a parent item that can
   * expand to show its children. Otherwise, it is prepared as a simple link. The prepared menu item
   * is then appended to the `menuItems` array.
   *
   * Note: This function relies on `this.featureAccessService` for access checks. It uses the methods:
   * - `hasAccess(compositeFeatureKey: string): boolean` to check access to individual features.
   * - `hasAccessToAnyFeature(compositeFeatureKeys: string[]): boolean` to check access to any feature
   *   in a list of composite feature keys.
   * 
   * It also utilizes `this.appendSubMenuItem` to append valid submenu items to the parent menu item.
   * This is not detailed within this documentation block.
   * @example
   * // Define menu items array, menuItemFeature, and subMenuItemFeatures
   * const menuItems = [];
   * const menuItemFeature = { compositeFeatureKey: 'dashboard', item: { itemString: 'Dashboard', icon: 'dashboard_icon', link: '/dashboard' } };
   * const subMenuItemFeatures = [
   *   { compositeFeatureKey: 'reports', item: { itemString: 'Reports', link: '/reports' } },
   *   { compositeFeatureKey: 'analytics', item: { itemString: 'Analytics', link: '/analytics' } }
   * ];
   * // Assuming access to 'dashboard' and at least one of 'reports' or 'analytics'
   * buildMenuItem(menuItems, menuItemFeature, subMenuItemFeatures);
   * // menuItems now contains the Dashboard menu item, potentially with submenus for Reports and/or Analytics.
   */

  public buildMenuItem(menuItems: IMenuItem[], menuItemFeature: IMenuItemFeature, subMenuItemFeatures: ISubMenuItemFeature[] = []) {
    if (!menuItems) {
      menuItems = [];
    }
    // Build menu item when user has access
    if (this.featureAccessService.hasAccessToAnyFeature(menuItemFeature.compositeFeatureKeys)) {
      const subMenuCompositeFeatureKeys = subMenuItemFeatures.flatMap(
        (subMenuItemFeature) => (typeof subMenuItemFeature.compositeFeatureKeys === 'string' ? [subMenuItemFeature.compositeFeatureKeys] : subMenuItemFeature.compositeFeatureKeys)
      );
      const menuItemToAdd = <IMenuItem>{};
      // Add sub menu items that contain permissions otherwise create a standalone link top level link
      const hasSubMenuAccess = this.featureAccessService.hasAccessToAnyFeature(subMenuCompositeFeatureKeys);
      const hasSubMenuAccessCount = this.featureAccessService.hasAccessToAnyFeatureCount(subMenuCompositeFeatureKeys);
      if (hasSubMenuAccess) {
        const menuItemToCopy = (<IMenuItem>menuItemFeature.item);
        if (hasSubMenuAccessCount > 1 || !menuItemToCopy.link) {
          // Format menuItemFeature item as a top level menu item with child items i.e. expands to show child links when clicked.
          menuItemToAdd.itemString = menuItemToCopy.itemString;
          menuItemToAdd.icon = menuItemToCopy.icon;
          menuItemToAdd.isExternal = false;
          menuItemToAdd.isExpanded = false;
          menuItemToAdd.children = [];
          menuItems.push(menuItemToAdd);
          // Add sub menu items that have valid permissions
          for (const subMenuItemFeature of subMenuItemFeatures) {
            this.appendSubMenuItem(menuItems, menuItemToAdd.itemString, subMenuItemFeature.item, subMenuItemFeature.compositeFeatureKeys);    
          } 
        } else {
          menuItemToAdd.itemString = menuItemToCopy.itemString;
          menuItemToAdd.icon = menuItemToCopy.icon;
          menuItemToAdd.isExternal = false;
          menuItemToAdd.isExpanded = false;
          menuItemToAdd.link = menuItemToCopy.link;
          menuItems.push(menuItemToAdd);          
        }
      } else {
        // Format menuItemFeature item as a top level menu item with no child items i.e. acts as a link.
        const menuItemToCopy = (<IMenuItem>menuItemFeature.item);
        if (menuItemToCopy.link) {
          menuItemToAdd.itemString = menuItemToCopy.itemString;
          menuItemToAdd.icon = menuItemToCopy.icon;
          menuItemToAdd.link = menuItemToCopy.link;
          menuItems.push(menuItemToAdd);
        }
      }
    }
  }

  private appendSubMenuItem(menuItems: IMenuItem[], menuItemString: string, subMenuItem: ISubMenuItem, compositeFeatureKey: string[]) {
    const matchingMenuItem = menuItems.find((menuItem: IMenuItem) => menuItem.itemString === menuItemString);
    if (matchingMenuItem && this.featureAccessService.hasAccessToAnyFeature(compositeFeatureKey)) {
      matchingMenuItem.children.push(subMenuItem);
    }
  }

  public appendSupportSubMenuItemsToAccessAndRoles(menuItems: IMenuItem[]) {
    const accessAndRolesMenuItem = menuItems.find((menuItem: IMenuItem) => menuItem.itemString === 'Access & Roles');
    if (accessAndRolesMenuItem) {
      const supportSubMenuItems: ISubMenuItem[] = [
        { itemString: 'Manage Features', link: '/access-management/features/manage' },
        { itemString: 'Manage Permissions', link: '/access-management/permissions/manage' },
        { itemString: 'Feature Mapping', link: '/access-management/feature-mapping/manage' }
      ];    
      accessAndRolesMenuItem.children = accessAndRolesMenuItem.children.concat(supportSubMenuItems);
    }
  }

  /**
   * Inserts a new menu item into an existing menu item list after the menu item location identified by insertAfterMenuItemString.
   * It appends the new menu item to the end of the existing menu item list when no insertAfterMenuItemString match is found.
   *
   * @param menuItems - Existing menu item list to insert new menu items into to.
   * @param newMenuItem - New menu item to insert/append to the existing list of menu items.
   * @param insertAfterMenuItemString - Identifies a menu item by its itemString property. The new menu item is inserted after its menu items location.
   */
  public insertMenuItem(
    menuItems: IMenuItem[],
    newMenuItem: IMenuItem,
    insertAfterMenuItemString: string
  ) {
    const matchingMenuItemIndex = menuItems.findIndex(
      (menuItem: IMenuItem) => menuItem.itemString === insertAfterMenuItemString
    );
    if (matchingMenuItemIndex !== -1) {
      menuItems.splice(matchingMenuItemIndex + 1, 0, newMenuItem);
    } else {
      menuItems.push(newMenuItem);
    }
  }  
}
