import {
  Component, ElementRef, Input, OnInit 
} from '@angular/core';

@Component({
  selector: 'app-text-field-counter',
  templateUrl: './text-field-counter.component.html',
  styleUrls: ['./text-field-counter.component.scss']
})
export class TextFieldCounterComponent implements OnInit {
  @Input() maxLength = 0;

  @Input() fieldContent: string;

  public currentLength = 0;

  constructor() { }

  ngOnInit(): void {
    if (!this.maxLength) {
      console.error('please provide a valid max length for the text field counter component');
    }

    if (typeof this.fieldContent === 'undefined') {
      console.error('please pass in the text field content for the text field counter component');
    }
  }
}
