import { ILocation, User } from 'src/app/models/user';
import { findItemByValues } from '../find-array-item-by-values/find-array-item-by-values';

/**
 * Retrieves the location of a user based on their state, zone, and group number.
 *
 * @param {User} user - The user object containing location details and properties for matching.
 * @returns {ILocation | undefined} - The matching location object if found, or `undefined` if no match exists.
 * @example
 * interface User {
 *   state?: string;
 *   zone?: string;
 *   groupNo?: number;
 *   region?: string;
 *   area?: string;
 *   locations: ILocation[];
 * }
 *
 * interface ILocation {
 *   locationId: number;
 *   state?: string;
 *   zone?: string;
 *   groupNo?: number;
 *   region?: string;
 *   area?: string;
 * }
 *
 * const user: User = {
 *   state: "NSW/ACT",
 *   zone: "2",
 *   groupNo: "411",
 *   locations: [
 *     { locationId: 1, state: "NSW/ACT", zone: "2", groupNo: "411" },
 *     { locationId: 2, state: "NSW/ACT", zone: "4", groupNo: "511" },
 *   ],
 * };
 *
 * const location = getUserLocation(user);
 * console.log(location);
 * // Output: { locationId: 1, state: "NSW/ACT", zone: "2", groupNo: "411" }
 */
export function getUserLocation(user: User): ILocation | undefined {
  console.log(`getUserLocation - state: ${user['state']}, zone: ${user['zone']}, groupNo: ${user['groupNo']}, region: ${user['region']}, area: ${user['area']}`);
  const criteria = {};
  if (user['state']) {
    criteria['state'] = user['state'];
  }
  if (user['zone']) {
    criteria['zone'] = user['zone'];
  }
  if (user['groupNo']) {
    criteria['groupNo'] = user['groupNo'];
  }
  if (user['region']) {
    criteria['region'] = user['region'];
  }
  if (user['area']) {
    criteria['area'] = user['area'];
  }
  return findItemByValues<ILocation>(user.locations, criteria);
}
