import { Injectable } from '@angular/core';
import { convertToNumber } from '../utils/convert-to-number/convert-to-number';
import { IStoreListItem } from '../models/store';

@Injectable({
  providedIn: 'root',
})
export class StoreMapper {
  /**
   * Maps a store response to a store number.
   * 
   * @param {object} storeResponse - The raw store response data.
   * @returns {number} The store number.
   */
  public mapStoreResponseToStoreNumber(
    storeResponse: object
  ): number {
    return convertToNumber(storeResponse['Store_No']);
  }

  /**
   * Maps a store response to an `IStoreListItem`.
   * 
   * @param {object} storeResponse - The raw store response data.
   * @returns {IStoreListItem} The mapped store.
   */
  mapStoreResponseToStore(
    storeResponse: object
  ): IStoreListItem {
    if (!storeResponse) {
      return <IStoreListItem>{};
    }
    const data = storeResponse;
    const store = <IStoreListItem>{
      storeNumber: data['Store_No'],
      locationName: data['Location_Name'],
      suburb: data['Suburb'],
      postcode: data['Post_Code'],
      state: data['State'],      
    };
    return store;
  }
}
