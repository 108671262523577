import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'RoleFieldToDisplayString',
  pure: true
})

/**
 * Pipe that converts role field value, such as "GroupManager" to the display equivalent, such as "Group Manager".
 */
export class RoleFieldToDisplayStringPipe implements PipeTransform {
  transform(value: string): string {
    let roleDisplayString = '';
    switch (value) {
      case 'GroupManager':
        roleDisplayString = 'Group Manager';
        break;
      case 'OperationManager':
        roleDisplayString = 'Zone Operation Manager';
        break;
      default:
        // if we don't have a matching display string, just return the role as is.
        roleDisplayString = value;
        break;
    }

    return roleDisplayString;
  }
}
