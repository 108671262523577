<div class="page-container">
  <h1>Recurring Assessments</h1>
<!-- 
  <div class="admin-button-container"  *ngIf="user['role'] == 'Admin'">
    <div class="filter-chips question-options">
      <div class="chipDrop">
        <mat-chip-list aria-label="Action">
          <mat-chip (click)="createSurvey()" color="primary" selected>
            <span>
              <fa-icon [icon]="faPlus"></fa-icon>
              &nbsp;&nbsp;Create New Assessment
            </span>
          </mat-chip>
        </mat-chip-list>
        
      </div>
    </div>
  </div> -->

  <!-- <div class="admin-button-container" *ngIf="user['role'] == 'Admin'">
    <div class="submit-button-container">
      <button (click)="createSurvey()" class="csa-button-solid"><span><fa-icon [icon]="faPlus"></fa-icon></span> Create New Assessment</button>
    </div>
  </div>
   -->
  <div class="question-options-row">
    <div class="filter-chips" *ngIf="user['role'] === 'Admin'">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=frequencySelect.open() color="primary" selected>
            {{selectedFrequency | titlecase}} <fa-icon [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #frequencySelect class="optionsDropDown">
          <mat-option (click)="resetPagination(); getSurveysToShow('')">
            All Frequencies
          </mat-option>
          <mat-option *ngFor="let frequency of frequencies"
            (click)="resetPagination(); getSurveysToShow(frequency)">
            {{frequency | titlecase}}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="true" mat-align-tabs="center" [(selectedIndex)]="selectedGroup" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">
          <ng-template mat-tab-label>
            {{surveyGroup}}
          </ng-template>

          <table mat-table [dataSource]="surveysToShow">
            <ng-container matColumnDef="survey">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Assessment</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h3>
                    {{survey.title.slice(0, 50) | unescape}}<span *ngIf="survey.title.length > 50">...</span>
                  </h3>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="publishDate">
              <th mat-header-cell *matHeaderCellDef class="center-align survey-details-link">
                <h4>First Publish Day</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h4>  {{ survey['startDate'] | date :'dd MMM y'  }} </h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="dueDate">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Due Date</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h4>  {{ survey.dueDate | date :'dd MMM y'  }}</h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="questions">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link hide-on-mobile center-align">
                <h4>Frequency</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link hide-on-mobile">
                <div class="center-align">
                  <h4>{{survey['frequency'] | titlecase}}</h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="daysPublished">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link hide-on-mobile center-align">
                <h4>Days Published</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link hide-on-mobile">
                <div class="center-align">
                  <span *ngFor="let day of survey['daysOfWeek']; let j=index">
                    <span *ngIf="survey['daysOfWeek'][j] == 0">Monday</span>
                    <span *ngIf="survey['daysOfWeek']?.length - 1 > j">,</span>
                    <span *ngIf="survey['daysOfWeek'][j] == 1">Tuesday</span>
                    <span *ngIf="survey['daysOfWeek']?.length - 1 > j">,</span>
                    <span *ngIf="survey['daysOfWeek'][j] == 2">Wednesday</span>
                    <span *ngIf="survey['daysOfWeek']?.length - 1 > j">,</span>
                    <span *ngIf="survey['daysOfWeek'][j] == 3">Thursday</span>
                    <span *ngIf="survey['daysOfWeek']?.length - 1 > j">,</span>
                    <span *ngIf="survey['daysOfWeek'][j] == 4">Friday</span>
                    <span *ngIf="survey['daysOfWeek']?.length - 1 > j">,</span>
                    <span *ngIf="survey['daysOfWeek'][j] == 5">Saturday</span>
                    <span *ngIf="survey['daysOfWeek']?.length - 1 > j">,</span>
                    <span *ngIf="survey['daysOfWeek'][j] == 6">Sunday</span>
                    <span *ngIf="survey['daysOfWeek']?.length - 1 > j">,</span>
                  </span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="%Complete">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>% Complete</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align survey-stat">
                  <h1 *ngIf="survey['avgCompletionRate'] || survey['avgCompletionRate'] == 0">
                    {{survey['avgCompletionRate'] | percent}}
                  </h1>
                  <p class="light-grey" *ngIf="!survey['avgCompletionRate'] && survey['avgCompletionRate'] != 0">
                    No Active Assessments
                  </p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="nextPublishDate">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Next Publish Date</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h4>  {{ survey['nextPublishDate'] | date :'dd MMM y'  }} </h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="commsEnabled">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Reminders Enabled?</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h4>{{survey['sendEmail']}}</h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="validity">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Assessment Validity</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h4>{{survey['surveyValidity']}}</h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align hide-on-mobile">
                <h4>Open/Created Actions</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link hide-on-mobile">
                <div class="center-align">
                  <h3 *ngIf="!survey['actionsOutstanding'] && !survey['actionsCreated']">0 / 0 </h3>
                  <h3 *ngIf="!survey['actionsOutstanding'] && survey['actionsCreated']">
                    0 / {{survey['actionsCreated']}}
                  </h3>
                  <h3 *ngIf="survey['actionsOutstanding'] && survey['actionsCreated']">
                    {{survey['actionsOutstanding']}} / {{survey['actionsCreated']}}
                  </h3>
                </div>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns" class="survey-heading-line"></mat-header-row>
            <mat-row class="survey-line survey-line-link" *matRowDef="let survey; columns: tableColumns;"
              (click)="surveyDetails(survey['id'], survey['avgCompletionRate'], $event); saveState = true"></mat-row>
          </table>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>
    
          <div *ngIf="surveysToShow.length == 0 && !isLoading">
            <h3 class="none-found-message">
              There are currently no {{surveyGroup}} Recurring Assessments.
            </h3>
          </div>
          <mat-paginator #paginator
              [pageIndex]=pageIndex
              [pageSize]=pageSize
              [length]="totalCount"
              [pageSizeOptions]="[5, 10, 25, 100]"
              [showFirstLastButtons]=false
              (page)="getNext($event)">
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>