import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToDoListService {
  constructor(private httpClient: HttpClient) { }

  getTodoList(
    timeframe: 'this_week' | 'next_week' | 'available',
    storeID: string,
  ): Observable<toDoListData> {
    let fullUrl = `${environment.backEndUrl}/todo_list/${timeframe}`;

    if (storeID) {
      fullUrl += `?storeID=${storeID}`;
    }

    const httpHeaders = new HttpHeaders({
      skip: 'true',
    });
    const options = {
      headers: httpHeaders,
    };

    return this.httpClient.get<toDoListData>(fullUrl, options);
  }
}

export interface toDoListData {actions: {mrr_actions: [], standard: []}, surveys: {adhoc: [], mrr: [], recurring: [], standard: []}}
