/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CsaAuthInterceptor } from './csa-auth.interceptor';
import { CsaLoaderInterceptor } from './csa-loader.interceptor';
import { BackendInterceptor } from './backend.interceptor';

/** Http interceptor providers in outside-in order. */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: BackendInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CsaAuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CsaLoaderInterceptor, multi: true },
];
