const hostName = `https://${window.location.hostname}/api`;

export const environment = {
  production: false,
  defaultBackEndUrl: 'https://dev-iverify.woolworths.com.au/api',
  backEndUrl: hostName,
  samlAuthProvider: 'saml.csa-test-app',
  firebaseConfig: {
    apiKey: 'AIzaSyCKu5bm6aIoic-prfV2b9qIqfHxY-xUJ4s',
    authDomain: 'gcp-wow-food-wlx-csaat-dev.firebaseapp.com'
  }
};
