import {
  Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, 
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { StoreMapper } from 'src/app/common-services/store.mapper';
import { StoreService } from 'src/app/common-services/store.service';
import { IStoreListItem } from 'src/app/models/store';
import { excludeValues } from 'src/app/utils/exclude-values/exclude-values';
import { getIncludedValues } from 'src/app/utils/get-included-values/get-included-values';
import { IValidatedBulkValues } from 'src/app/utils/validate-bulk-values/validate-bulk-values';

export interface IValidatedStoreNumberBulkValues extends IValidatedBulkValues<number> {
  validStores: IStoreListItem[];
}
/**
 * Component for bulk number input of store numbers.
 * 
 * Allows users to input a list of store numbers, validate them, and filter them based on a pre-fetched list of stores.
 * The component also handles validation of input, ensuring that duplicate and non-existent store numbers are identified.
 *
 * @example
 * <app-store-bulk-number-input 
 *   [excludedStoreNumbers]="[101, 102]" 
 *   [label]="'Store Numbers'" 
 *   (validatedBulkInput)="onValidatedBulkInput($event)">
 * </app-store-bulk-number-input>
 */
@Component({
  selector: 'app-store-bulk-number-input',
  templateUrl: './store-bulk-number-input.component.html',
  styleUrls: ['./store-bulk-number-input.component.scss']
})
export class StoreBulkNumberInputComponent implements OnDestroy, OnInit, OnChanges {
  /**
   * List of store numbers that have already been added.
   */
  @Input() excludedStoreNumbers: number[] = [];

  /**
   * Label for the component input field.
   */
  @Input() label = '';

  /**
   * Event emitted when bulk input validation is complete, containing valid and invalid store numbers.
   * 
   * @type {EventEmitter<IValidatedStoreNumberBulkValues>}
   */
  @Output() validatedStoreNumberBulkInput = new EventEmitter<IValidatedStoreNumberBulkValues>();
  
  /**
   * Sets an error message when validation fails.
   *
   * Error messages are wrapped in new objects by the host to ensure Angular detects changes,
   * even when the message content remains unchanged.
   *
   * Note: Internal error messages are cleared when input data changes, causing them to become
   * unsynced from the host's bound error message. This means any update by the host must trigger
   * a new object assignment to be detected properly.
   *
   * @param {{ message: string }} value - Error message object containing the validation message.
   */  
  errorMessage = { message: '' };

  /**
   * List of stores fetched from the server.
   */
  storeList: IStoreListItem[] = [];

  /**
   * List of allowed store numbers that are available for input.
   */
  allowedStoreNumbers: number[] = [];

  _originalAllowedStoreNumbers: number[] = [];

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(private storeService: StoreService, private storeMapper: StoreMapper) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['excludedStoreNumbers']) {
      // remove excluded store numbers from allowed list
      this.allowedStoreNumbers = excludeValues(this._originalAllowedStoreNumbers, this.excludedStoreNumbers);
    }
  }

  ngOnInit(): void {
    this.storeService.getStoreList()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (data) => {
          if (data) {
            this.generateStoreList(data);
          }
        },
        error: (error) => {
          console.log('Error fetching store list', error);
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Generates the list of stores from the provided data.
   * 
   * @param {object[]} data - The store data.
   */
  generateStoreList(data: object[]) {
    this.storeList = data.map((item: object) => this.storeMapper.mapStoreResponseToStore(item));
    this._originalAllowedStoreNumbers = data.map((item: object) => this.storeMapper.mapStoreResponseToStoreNumber(item));
    // remove excluded store numbers from allowed list
    this.allowedStoreNumbers = excludeValues(this._originalAllowedStoreNumbers, this.excludedStoreNumbers);
  }

  /**
   * Handles validated bulk input values.
   * 
   * @param {IValidatedBulkValues<number>} validatedBulkValues - The validated bulk input values.
   */
  onValidatedBulkInput(validatedBulkValues: IValidatedBulkValues<number>) {
    const validatedStoreBulkValues = this.processValidatedBulkValues(validatedBulkValues);
    this.validatedStoreNumberBulkInput.emit(validatedStoreBulkValues);
  }

  /**
   * Processes validated bulk input values to identify duplicate and invalid store numbers.
   * 
   * @param {IValidatedBulkValues<number>} validatedBulkValues - The validated bulk input values.
   */
  processValidatedBulkValues(validatedBulkValues: IValidatedBulkValues<number>): IValidatedStoreNumberBulkValues {
    const duplicateStoreNumbers = getIncludedValues(validatedBulkValues.excludedValues, this.excludedStoreNumbers);
    const invalidExcludedStoreNumbers = excludeValues(validatedBulkValues.excludedValues, duplicateStoreNumbers);
    const invalidStoreNumbers = [...validatedBulkValues.malformedValues, ...invalidExcludedStoreNumbers];
    let errorMessage = invalidStoreNumbers.length > 0 ? `Store number(s) ${invalidStoreNumbers.join(', ')} were not found.` : '';
    errorMessage += duplicateStoreNumbers.length > 0 ? ` Duplicate store number(s) ${duplicateStoreNumbers.join(', ')} were found.` : '';
    this.errorMessage = { message: errorMessage };
    const validatedStoreBulkValues: IValidatedStoreNumberBulkValues = {
      ...validatedBulkValues,
      validStores: this.getStores(validatedBulkValues.validValues),
    };
    return validatedStoreBulkValues;
  }

  getStores(storeNumbers: number[]): IStoreListItem[] {
    if (!storeNumbers || storeNumbers.length === 0) {
      return [];
    }
    return storeNumbers.map((storeNumber) => this.getStoreListItem(storeNumber));
  }

  getStoreListItem(storeNumber: number): IStoreListItem | undefined {
    return this.storeList.find((store) => store.storeNumber == storeNumber);
  }
}
