<div>
	<div class="question-options-row" *ngIf="user['role'] !== 'Store' && user['role'] !== 'MultiStore'">
		<div class="filter-chips">
			<ng-container *ngFor="let key of locationsOrdered">
				<div class="chipDrop" *ngIf="showLocations[key]">
					<mat-chip-list aria-label="Filters">
						<mat-chip (click)=select.open() selected>
							<span *ngIf="key === 'state' || key === 'region'; else titleLocation">
								{{(selectedLocations[key] ? selectedLocations[key] : (locationStringAll(key) |
								titlecase)) | unescape}}
							</span>
							<ng-template #titleLocation>
								{{(selectedLocations[key] ? locationString(key) : locationStringAll(key)) | titlecase |
								unescape}}
							</ng-template>
							<fa-icon [icon]="faSortDown"></fa-icon>
						</mat-chip>
						<mat-select #select class="optionsDropDown">
							<mat-option (click)="resetPagination(); getSurveysToShow('', getPreviousLevel(key))">
								{{locationStringAll(key) | titlecase}}
							</mat-option>
							<mat-option *ngFor="let location of availableLocations[key]"
								(click)="resetPagination(); getSurveysToShow(location, key)">
								{{location | unescape}}
							</mat-option>
						</mat-select>
					</mat-chip-list>
				</div>
			</ng-container>

			<div class="chipDrop">
				<mat-chip-list aria-label="Filters">
					<mat-chip (click)=storeSelect.open() color="primary" selected>
						<span
							*ngIf="selectedStore['Location_Name'] === 'All Stores' && user['division'] !== 'DC'">{{selectedStore['Location_Name']}}</span>
						<span *ngIf="selectedStore['Location_Name'] === 'All Stores' && user['division'] === 'DC'">All
							DCs</span>
						<span *ngIf="selectedStore['Location_Name'] !== 'All Stores'">{{selectedStore['Store_No']}} -
							{{selectedStore['Location_Name']}}</span>
						<fa-icon [icon]="faSortDown"></fa-icon>
					</mat-chip>
				</mat-chip-list>
				<mat-select #storeSelect class="optionsDropDown">
					<mat-option>
						<ngx-mat-select-search [clearSearchInput]="false" [searching]="searching"
							placeholderLabel="Search by Store Name or Number.." [noEntriesFoundLabel]="noEntries"
							name="searchValue" #searchValue ngModel (ngModelChange)="searchForStores($event)">
						</ngx-mat-select-search>
					</mat-option>
					<mat-option *ngIf="user['division'] === 'DC'"
						(click)="resetPagination(); setStore({Store_No: '', Location_Name: 'All Stores'})">All DCs
					</mat-option>
					<mat-option *ngIf="user['division'] !== 'DC'"
						(click)="resetPagination(); setStore({Store_No: '', Location_Name: 'All Stores'})">All Stores
					</mat-option>
					<mat-option [disabled]="searching" *ngFor="let returnedStore of storesReturned"
						(click)="resetPagination(); setStore(returnedStore)">
						{{returnedStore['Store_No']}} - {{returnedStore['Location_Name']}}
					</mat-option>
				</mat-select>
			</div>

			<div class="chipDrop" *ngIf="showReset()">
				<mat-chip-list aria-label="Filters">
					<mat-chip class="clear-chip" (click)="resetFilters()" color="primary" selected>
						<fa-icon class="chip-left-icon" [icon]="faRedoAlt"></fa-icon>
						<span>Clear</span>
					</mat-chip>
				</mat-chip-list>
			</div>
		</div>
	</div>

	<!--State / Zone / Group Breakdown-->
	<div class="audit-tool-cards-container" *ngIf="showGroupedBreakdown && surveyDetails['status'] !== 'active'">
		<h2 class=" no-bottom-margin" *ngIf="!(user['division'] === 'DC' && nextLevel === 'store')">
			{{(nextLevel === "groupID" ? "group" : nextLevel) | titlecase}} Status for {{surveyDetails['title'] |
			unescape}}
		</h2>
		<h2 class="no-bottom-margin" *ngIf="user['division'] === 'DC' && nextLevel === 'store'">
			DC Status for {{surveyDetails['title'] | unescape}}
		</h2>
		<div class="audit-tool-card more-list-screen-card">
			<table mat-table [(dataSource)]="groupedSurveysToShow">

				<ng-container [matColumnDef]="level" *ngFor="let level of locationsOrdered">
					<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
						<h4>{{(nextLevel === "groupID" ? "group" : nextLevel) | titlecase}}</h4>
					</th>
					<td mat-cell *matCellDef="let survey" class="survey-details-link">
						<div class="center-align">
							<div class="store-name">{{survey[level]}}</div>
						</div>
					</td>
				</ng-container>

				<!-- adhoc -->
				<ng-container matColumnDef="store" *ngIf="surveyType === 'adhoc' || surveyType === 'mrr'">
					<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
						<h4>{{user['division'] === 'DC' ? 'DC' : 'Store'}}</h4>
					</th>
					<td mat-cell *matCellDef="let survey" class="survey-details-link">
						<div class="center-align">
							<div class="store-name">{{survey['storeID'] | unescape}}</div>
						</div>
					</td>
				</ng-container>

				<!-- adhoc, mrr -->
				<ng-container matColumnDef="responsesCreated" *ngIf="surveyType === 'adhoc' || surveyType === 'mrr'">
					<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
						<h4>Responses Created</h4>
					</th>
					<td mat-cell *matCellDef="let survey" class="survey-details-link">
						<div class="center-align">
							<h1>{{survey['responseCount'] ? survey['responseCount'] : 0 }}</h1>
						</div>
					</td>
				</ng-container>

				<!-- recurring, onceoff -->
				<ng-container matColumnDef="%Complete" *ngIf="surveyType === 'recurring' || surveyType === 'onceoff'">
					<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
						<h4>% Complete</h4>
					</th>
					<td mat-cell *matCellDef="let survey" class="survey-details-link">
						<div class="center-align survey-stat">
							<h1 *ngIf="currentLevel === 'store'">{{survey['completion'] | percent}}</h1>
							<h1 *ngIf="currentLevel !== 'store'">{{survey['avgCompletionRate'] | percent}}</h1>
						</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align hide-on-mobile">
						<h4>Open/Created Actions</h4>
					</th>
					<td mat-cell *matCellDef="let survey" class="survey-details-link hide-on-mobile">
						<div class="center-align">
							<h4 *ngIf="!survey['actionsOutstanding'] && !survey['actionsCreated']">
								0 / 0
							</h4>
							<h4 *ngIf="!survey['actionsOutstanding'] && survey['actionsCreated']">
								0 / {{survey['actionsCreated']}}
							</h4>
							<h4 *ngIf="survey['actionsOutstanding'] && survey['actionsCreated']">
								{{survey['actionsOutstanding']}} / {{survey['actionsCreated']}}
							</h4>
						</div>
					</td>
				</ng-container>

				<mat-header-row *matHeaderRowDef="groupedTableColumns" class="survey-heading-line"></mat-header-row>

				<mat-row class="survey-line survey-line-link" *matRowDef="let survey; columns: groupedTableColumns;"
					(click)="resetPagination(); 
					drillDownTo(survey)">
				</mat-row>

			</table>

			<div *ngIf="isLoadingGrouped" class="loader-container">
				<div class="loading-wapple"></div>
			</div>

			<h3 class="none-found-message" *ngIf="!isLoadingGrouped && groupedSurveysToShow.length == 0">
				There are no results to show.
			</h3>
		</div>
	</div>

	<!--store by store break downs-->
	<h2 class=" no-bottom-margin">
		<span>Individual {{user['division'] === 'DC' ? 'DC' : 'Store'}} Results</span>
		<span *ngIf="storeName; else locationsList"> for {{storeName}}</span>
		<ng-template #locationsList>
			<span *ngFor="let key of locationsOrdered">
				<span *ngIf="selectedLocations[key]">, {{(key === "groupID" ? "group" : key) | titlecase}}:
					{{selectedLocations[key]}}</span>
			</span>
		</ng-template>
	</h2>

	<div class="audit-tool-cards-container">
		<div class="audit-tool-card more-list-screen-card">
			<mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" [selectedIndex]="selectedGroup"
				(selectedTabChange)="tabChanged($event)">
				<mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">
					<table mat-table [dataSource]="surveysToShow">

						<!-- adhoc, recurring, onceoff, mrr -->
						<ng-container matColumnDef="store">
							<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
								<h4>{{user['division'] === 'DC' ? 'DC' : 'Store'}}</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow" class="survey-details-link center-align">
								<div class="center-align">
									<h3>{{surveyToShow['storeID'] | unescape}} - {{surveyToShow['storeName'] |
										unescape}}</h3>
								</div>
							</td>
						</ng-container>

						<!-- onceoff -->
						<ng-container matColumnDef="lastModified">
							<th mat-header-cell *matHeaderCellDef
								class="survey-details-link center-align hide-on-mobile">
								<h4>Modified On</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow" class="survey-details-link hide-on-mobile">
								<div class="center-align">
									<h4 *ngIf="surveyToShow['lastModified']">{{ surveyToShow['lastModified']| date :'dd MMM y'  }} 
									</h4>
									<h4 *ngIf="!surveyToShow['lastModified']">{{ surveyDetails['lastModified']| date :'dd MMM y'  }}  
									</h4>
								</div>
							</td>
						</ng-container>

						<!-- adhoc, recurring, onceoff, mrr -->
						<ng-container matColumnDef="%Complete">
							<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
								<h4>% Complete</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow" class="survey-details-link center-align">
								<div class="float-up">
									<h1 [ngClass]="{
                    'light-green': surveyToShow['surveyState'] === 'Complete',
                    'light-blue' : surveyToShow['surveyState'] === 'Continue'
                  }">
										{{ surveyToShow['completion'] | percent}}
									</h1>
								</div>
							</td>
						</ng-container>

						<!-- adhoc, mrr -->
						<ng-container matColumnDef="completedBy">
							<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align center-align">
								<h4>Submitted By</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow" class="survey-details-link center-align">
								<div class="center-align email-and-date" *ngIf="surveyToShow['modifiedBy']">
									<span class="email">{{surveyToShow['modifiedBy'].slice(0, 25) | unescape}}<span
											*ngIf="surveyToShow['modifiedBy'].length > 25">...</span></span>
									<div class="medium-text">
										<span class="medium-text" *ngIf="surveyToShow['lastModified']">on
											{{ surveyToShow['lastModified']| date :'dd MMM y'  }}  </span>
									</div>
								</div>
								<div class="center-align email-and-date" *ngIf="!surveyToShow['modifiedBy']">
									<span class="email">{{surveyToShow['createdBy']}}</span>
									<div class="medium-text">
										<span class="medium-text">on {{ surveyToShow['publishDate']| date :'dd MMM y'  }}  </span>
									</div>
								</div>
							</td>
						</ng-container>

						<!-- adhoc, recurring, onceoff, mrr -->
						<ng-container matColumnDef="findings">
							<th mat-header-cell *matHeaderCellDef
								class="survey-details-link center-align hide-on-mobile">
								<h4>Findings Created</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow" class="survey-details-link hide-on-mobile">
								<h4>
									{{surveyToShow['findingsCreated']}}
								</h4>
							</td>
						</ng-container>



						<!-- adhoc, recurring, mrr -->
						<ng-container matColumnDef="dueDate"
							*ngIf="surveyType === 'adhoc' || surveyType === 'recurring' || surveyType === 'mrr'">
							<th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
								<h4>Due Date</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow" class="survey-details-link center-align">
								<div class="center-align" *ngIf="surveyToShow['dueDate']">
									<h3>  {{ surveyToShow['dueDate']| date :'dd MMM y'  }} </h3>
								</div>
								<div class="center-align" *ngIf="!surveyToShow['dueDate']">
									<h3>NA</h3>
								</div>
							</td>
						</ng-container>

						<!-- adhoc -->
						<ng-container matColumnDef="status" *ngIf="surveyType === 'adhoc'">
							<th mat-header-cell *matHeaderCellDef
								class="survey-details-link center-align hide-on-mobile">
								<h4>Status</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow"
								class="survey-details-link center-align hide-on-mobile">
								<div class="survey-button">
									<button
										[ngClass]="{'disabled-link' : surveyToShow['status'] === 'submitted' || surveyToShow['surveyState'] === 'Closed'}"
										class="csa-button-solid"
										[ngClass]="{'disabled-btn' : surveyToShow['status'] === 'submitted' || surveyToShow['surveyState'] === 'Closed'}">
										<fa-icon class="chip-left-icon"
											*ngIf="!(surveyToShow['surveyState'] === 'Submitted' || surveyToShow['surveyState'] === 'Closed')"
											[icon]="faPlayCircle"></fa-icon>
										<span>{{surveyToShow['surveyState']}}</span>
									</button>
								</div>
							</td>
						</ng-container>
						<!-- ..........mrr...... -->
						<ng-container matColumnDef="status" *ngIf="surveyType === 'mrr'">
							<th mat-header-cell *matHeaderCellDef
								class="survey-details-link center-align hide-on-mobile">
								<h4>Status</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow"
								class="survey-details-link center-align hide-on-mobile">
								<div class="survey-button">
									<button
										[ngClass]="{'disabled-link' : surveyToShow['status'] === 'submitted' || surveyToShow['surveyState'] === 'Closed'}"
										class="csa-button-solid"
										[ngClass]="{'disabled-btn' : surveyToShow['status'] === 'submitted' || surveyToShow['surveyState'] === 'Closed'}">
										<fa-icon class="chip-left-icon"
											*ngIf="!(surveyToShow['surveyState'] === 'Submitted' || surveyToShow['surveyState'] === 'Closed')"
											[icon]="faPlayCircle"></fa-icon>
										<span *ngIf="!surveyToShow['signoffbyRole']">Edit</span>
										<span *ngIf="surveyToShow['signoffbyRole']">Sign off</span>
									</button>
								</div>
							</td>
						</ng-container>
						<!-- ..........mrr....started.. -->

						<ng-container matColumnDef="finding">
							<th mat-header-cell *matHeaderCellDef
								class="survey-details-link center-align hide-on-mobile">
								<h4>Open/Created Findings</h4>
							</th>
						<td mat-cell *matCellDef="let surveyToShow" class="survey-details-link hide-on-mobile">
								<div class="center-align">
									<h4 *ngIf="!surveyToShow['findingsOutstanding'] && !surveyToShow['findingsCreated']">
										0 / 0
									</h4>
									<h4 *ngIf="!surveyToShow['findingsOutstanding'] && surveyToShow['findingsCreated']">
										0 / {{surveyToShow['findingsCreated']}}
									</h4>
									<h4 *ngIf="surveyToShow['findingsOutstanding'] && surveyToShow['findingsCreated']">
										{{surveyToShow['findingsOutstanding']}} / {{surveyToShow['findingsCreated']}}
									</h4>
								</div>
							</td>
						</ng-container>

						<!-- adhoc, recurring, onceoff, mrr -->
						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef
								class="survey-details-link center-align hide-on-mobile">
								<h4>Open/Created Actions</h4>
							</th>
							<td mat-cell *matCellDef="let surveyToShow" class="survey-details-link hide-on-mobile">
								<div class="center-align">
									<h4 *ngIf="!surveyToShow['actionsOutstanding'] && !surveyToShow['actionsCreated']">
										0 / 0
									</h4>
									<h4 *ngIf="!surveyToShow['actionsOutstanding'] && surveyToShow['actionsCreated']">
										0 / {{surveyToShow['actionsCreated']}}
									</h4>
									<h4 *ngIf="surveyToShow['actionsOutstanding'] && surveyToShow['actionsCreated']">
										{{surveyToShow['actionsOutstanding']}} / {{surveyToShow['actionsCreated']}}
									</h4>
								</div>
							</td>
						</ng-container>

						<mat-header-row *matHeaderRowDef="tableColumns" class="survey-heading-line"></mat-header-row>
						<mat-row *matRowDef="let surveyToShow; columns: tableColumns;"
							class="survey-line survey-line-link"
							[ngClass]="{'highlighted' : surveyToShow['status'] === 'active' && (surveyType === 'adhoc' || surveyType === 'recurring')}"
							(click)="saveState = true"
							[routerLink]="surveyDetails['filterName'] === 'mrr' && ((user['role'] === 'Support' || user['role'] === 'Admin' || user['role'] === 'Super') && surveyToShow['surveyState'] !== 'Closed') ? ['/mrr-response', surveyToShow['id']] : 
							surveyDetails['filterName'] === 'mrr' && ((user['role'] === 'Support' || user['role'] === 'Admin' || user['role'] === 'Super') && surveyToShow['surveyState'] === 'Closed') ? ['/mrr-response', surveyToShow['id']] :
							surveyDetails['filterName'] === 'mrr' && (user['role'] === 'StateManager' || user['role'] === 'OperationManager' || user['role'] === 'GroupManager'  || user['role'] === 'NationalView' ) ? ['/mrr-response', surveyToShow['id']] :['/store-landing/survey-details', surveyToShow['id']]">
						</mat-row>
					</table>

					<div *ngIf="isLoadingIndividual" class="loader-container">
						<div class="loading-wapple"></div>
					</div>

					<div *ngIf="surveysToShow.length == 0 && !isLoadingIndividual">
						<h3 class="none-found-message" *ngIf="surveyType === 'onceoff'">
							There are no stores with {{surveyGroup}} {{surveyDetails['title'] | unescape}} Assessments.
						</h3>

						<h3 class="none-found-message" *ngIf="surveyType === 'adhoc'">
							There are no {{ surveyGroup }} "{{surveyDetails['title']}}" Adhoc Assessments <span
								*ngIf="user['role']==='Store' || user['role']==='MultiStore'"> for your store</span>. <div
								*ngIf="surveyDetails['targetRoles']?.indexOf(user['role']) > -1 && surveyDetails['status'] === 'published'">
								Click the button above to create a new {{surveyDetails['title']}} Assessment</div>
						</h3>

						<h3 class="none-found-message" *ngIf="surveyType === 'mrr'">
							There are no {{ surveyGroup }} "{{surveyDetails['title']}}" Material Risk Review Assessments
							{{user['role']==='Store' || user['role']==='MultiStore' ? 'for your store' : ''}}.
							{{user['divisionRoles'][user['role']] >= user['divisionRoles']['Admin'] &&
							surveyDetails['status'] === 'published' ? 'Click the button above to create a new ' +
							surveyDetails['title'] + ' Assessment.': ''}}
						</h3>

						<h3 class="none-found-message" *ngIf="surveyType === 'recurring'">
							There are no {{ surveyGroup }} "{{surveyDetails['title']}}" Assessments. Please check in
							other tabs.
						</h3>
					</div>
				</mat-tab>
			</mat-tab-group>
			<mat-paginator #paginator [pageIndex]=pageIndex [pageSize]=pageSize [length]="totalCount"
				[pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]=false (page)="getNext($event)">
			</mat-paginator>
		</div>
	</div>
</div>