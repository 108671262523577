import { Injectable } from '@angular/core';
import {
  UrlTree, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,
  Router 
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CsaAuthService } from './csa-auth.service';

@Injectable({
  providedIn: 'root'
})
export class CsaAuthGuard implements CanActivate {
  constructor(private authService: CsaAuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean
      | UrlTree {
    return this.authService.userChanges$().pipe(
      take(1),
      map((userData) => {
        if (!userData) {
          // there isn't a signed in user
          this.router.navigateByUrl('/login');
          return true; // if the user isn't signed in, we still want them to proceed to the route. The authService.login() function will handle sending them to the login page.
          // we do this because the firebase login method redirects the user back to the page they came from after successful login. So once they login, they should go to the page
          // they were trying to access. If we were to return false here, or redirect them to an "unauthorised" page, upon successful login they would be taken to the unauthorised page.
        }

        if (userData['role']) {
          const userRole = parseInt(userData['divisionRoles'][userData['role']]);
          const pathRole = parseInt(userData['divisionRoles'][route.data.role]);

          // if the user is a multi store user and they don't have a store set, take them to the set store page
          if (userData.role === 'MultiStore' && !userData.storeID && !state.url.includes('multi-store-selection')) {
            return this.router.parseUrl('multi-store-selection');
          }
          
          if (state.url == '/') {
            // Ensure a support user is impersonated by redirecting them to the support landing page where they can select an impersonation role
            if (userData['role'] == 'Support' && !this.authService.hasUserOverrides()) {
              return this.router.parseUrl('support-landing');
            }
            // Ensure a multi location user is impersonating a location by redirecting them to the switch location page where they can select an impersonation location
            if (this.authService.isMultiLocationUser() && !this.authService.hasUserOverrides()) {
              return this.router.parseUrl('switch-location');
            }            
          }
          
          // Remove test when pathRole does not exist
          if (!pathRole || userRole >= pathRole) {
            return true;
          }
        }
        console.log(`Access denied: ${userData.role} (${parseInt(userData['divisionRoles'][userData['role']])}) trying to access role for ${parseInt(userData['divisionRoles'][route.data.role])}`);
        return this.router.parseUrl('error/unauthorised');
      })
    );
  }
}
