<div [formGroup]="form" class="locations-container">
    <ng-container *ngFor="let key of locationsOrdered">                   
    <div class="select-container location-option" *ngIf="showLocations[key]" [ngClass]="{'no-label': !showLabels}">
        <label *ngIf="showLabels" for="select" class="core-form-label">{{locationKey(key) | titlecase}}</label>
        <div class="select-wrapper">
            <div *ngIf="isLocationLoading[key]" class="select-loading-container">
                Finding {{(key === 'groupID' ? 'group' : key) | titlecase}}s
            </div>
            <select id="select{{key}}{{id}}"
                class="core-select" 
                (change)="onSelectLocation($event, key)" 
                formControlName="{{getControlName(key)}}">
                <option *ngIf="!showAllOption" value="" disabled selected hidden>Select {{locationKey(key) | titlecase}}</option>
                <option *ngFor="let location of availableLocations[key]" value="{{location.value}}">{{location.name | unescape}}</option>
            </select>                                      
            <div class="selectArrow" aria-hidden="true">
            </div>
        </div>
    </div>
    </ng-container>
    <div class="actions" *ngIf="isPartOfMultiLocationList">
        <button *ngIf="showRemoveButton" type="button" class="button secondary remove-button" (click)="remove()">
            <app-icon iconName="roundXClearMark" color="#fff"></app-icon>
        </button> 
        <button *ngIf="allowAddButton && showAddButton && isLast" type="button" class="button secondary add-button" [ngClass]="{ 'disabled' : !isValid || isComponentLoading}" (click)="add()">
            <app-icon iconName="add" color="#fff"></app-icon>
            Add Location
        </button> 
    </div>
</div>