import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject } from 'rxjs';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog } from '@angular/material/dialog';
import { CsaAuthService } from '../auth/csa-auth.service';
import { MessagesComponent } from '../surveys/messages/messages.component';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  isAutoLogoutEnabled = new BehaviorSubject(true);

  isAutoLogoutBeingWatched = false;

  constructor(private idle: Idle, public auth: CsaAuthService, private dialog: MatDialog) {
  }

  public watchAutoLogout() {
    if (this.isAutoLogoutBeingWatched) {
      console.error('watchAutoLogout should only be called once (during app init)');
      return;
    }

    this.isAutoLogoutBeingWatched = true;

    this.isAutoLogoutEnabled.subscribe((isAutoLogoutEnabled) => {
      if (isAutoLogoutEnabled == true) {
        console.log('Auto Log Out Turned On');
        // sets an idle timeout of 30 minutes
        this.idle.setIdle(1800);
        // sets a timeout period of 30 seconds. after 30 minutes and 30 seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(30);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onTimeout.subscribe(() => {
          this.auth.logout();
        });

        this.idle.onIdleStart.subscribe(() => {
          const dialogRef = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'You Have Been Inactive.',
              message: 'Would you like to continue working, or log out?',
              idleMessage: true,
              closeText: 'Log Out',
              cancelText: 'Continue Working'
            },
            backdropClass: 'dialog-backdrop'
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result == 'logout') {
              this.auth.logout();
            } else {
              this.idle.watch();
            }
          });
        });

        this.idle.watch();
      } else if (isAutoLogoutEnabled == false) {
        console.log('Auto Log Out Turned Off');
        this.idle.stop();
      }
    });
  }

  public turnAutoLogoutOn() {
    this.isAutoLogoutEnabled.next(true);
  }

  public turnAutoLogoutOff() {
    this.isAutoLogoutEnabled.next(false);
  }
}
