import {
  Component, OnDestroy, OnInit 
} from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../models/user';
import { CsaAuthService } from '../auth/csa-auth.service';
import { IBreadcrumb, NavigationService } from './navigation.service';
import { CreateMRRDialogComponent } from '../mrr-surveys/create-mrr-dialog/create-mrr-dialog.component';
import { CreateSurveyDialogComponent } from '../surveys/create-survey-dialog/create-survey-dialog.component';
import { MobileHeaderMenuComponent } from './components/mobile-header-menu/mobile-header-menu.component';
import { FeatureAccessService } from '../common-services/feature-access.service';
import { MenuBuilderService } from './menu-builder.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  menuItems: IMenuItem[] = [];

  selectedMenuItemLink = '';

  public user?: User;

  public isUserMenuOpen = false;

  public isResponsiveMenuOpen = false;

  public isMobileMenuOpen = false;

  public canAccessAsOtherRoles = false;

  public canAccessAsOtherStores = false;

  public canAccessAsOtherDivisions = false;

  public hideNav = false; // to hide the nav, set 'hideNav:true' in the route data. See developer handbook for more details

  public breadcrumbs: IBreadcrumb[] = [];

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private router: Router,
    private auth: CsaAuthService,
    private navigationService: NavigationService,
    private menuBuilderService: MenuBuilderService,
    private featureAccessService: FeatureAccessService, 
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.auth
      .userChanges$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        this.user = user;
        this.canAccessAsOtherRoles = user?.support 
          || this.featureAccessService.hasAccess('navigation.canAccessAsOtherRoles');
        this.canAccessAsOtherStores = this.featureAccessService.hasAccess('navigation.canAccessAsOtherStores');
        this.canAccessAsOtherDivisions = this.featureAccessService.hasAccess('navigation.canAccessAsOtherDivisions');
        this.getMenuItemsByRole();
      });

    this.watchRouteChanges();
  }

  /**
   * Performs necessary actions when route changes.
   */
  private watchRouteChanges() {
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof RoutesRecognized) {
        // get breadcrumbs
        if (routerEvent.state.root.firstChild.data.breadcrumbKey) {
          this.breadcrumbs = this.navigationService.getBreadCrumbsByRouteKey(
            routerEvent.state.root.firstChild.data.breadcrumbKey
          );
        } else if (routerEvent.url) {
          this.breadcrumbs = this.navigationService.getBreadCrumbsByRouteUrl(
            routerEvent.url
          );
        }
        this.selectedMenuItemLink = routerEvent.url;

        // hide the navigation if the route has hideNav:true
        this.hideNav = routerEvent.state.root.firstChild.data.hideNav;
      }
    });
  }

  public openCreateNewAssessment() {
    const dialogRef = this.dialog.open(CreateSurveyDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '90vw',
      minHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { templateid: '', templateDivision: this.auth.user.division },
    });
  }

  public openCreateMRRAssessment() {
    const dialogRef = this.dialog.open(CreateMRRDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '90vw',
      minHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { templateid: '', templateDivision: this.auth.user.division },
    });
  }

  public openMobileMenuOpen() {
    const dialogRef = this.dialog.open(MobileHeaderMenuComponent, {
      backdropClass: 'dialog-backdrop-mobile',
      minWidth: '95vw',
      height: 'auto',
      panelClass: 'menu-dialog-mobile',
    });
  }

  private getMenuItemsByRole() {
    this.menuItems = [];

    // To Do List
    this.menuBuilderService.addMenuItem(this.menuItems, {
      itemString: 'To Do List', icon: 'toDoList', link: '/to-do-list', isExternal: false 
    }, 'storeToDoList.view');

    // Dashboard
    this.menuBuilderService.addMenuItem(this.menuItems, {
      itemString: 'Dashboard', icon: 'dashboard', link: '/', isExternal: false 
    }, 'dashboard.view');

    // Assessments
    this.menuBuilderService.buildMenuItem(
      this.menuItems,
      { 
        item: { itemString: 'Assessments', icon: 'assessment' },
        compositeFeatureKeys: ['adhocAssessments.view', 'onceOffAssessments.view', 'recurringAssessments.view', 'mrrAssessments.view'] 
      },
      [
        { item: { itemString: 'Material Risk', link: '/more-mrr-surveys' }, compositeFeatureKeys: ['mrrAssessments.view'] },
        { item: { itemString: 'Adhoc', link: '/more-onthefly-surveys' }, compositeFeatureKeys: ['adhocAssessments.view'] },
        { item: { itemString: 'Once Off', link: '/once-off-assessments' }, compositeFeatureKeys: ['onceOffAssessments.view'] },
        { item: { itemString: 'Recurring', link: '/more-recurring-surveys' }, compositeFeatureKeys: ['recurringAssessments.view'] },
      ]
    );

    // Actions
    // TODO (3/4/24 AS): Path mrr-more-actions will be merged into mrr-actions-drill-down &
    // Path more-actions will be merged into actions-drill-down
    this.menuBuilderService.buildMenuItem(
      this.menuItems,
      { item: { itemString: 'Actions', icon: 'action' }, compositeFeatureKeys: ['mrrActions.view', 'actions.view'] },
      [
        // { item: { itemString: 'Material Risk', link: '/mrr-actions-drill-down' }, compositeFeatureKeys: ['mrrActions.view'] },
        // { item: { itemString: 'Standard', link: '/actions-drill-down' }, compositeFeatureKeys: ['actions.view'] },     
        { item: { itemString: 'Material Risk', link: '/mrr-more-actions' }, compositeFeatureKeys: ['mrrActions.view'] },
        { item: { itemString: 'Standard', link: '/more-actions' }, compositeFeatureKeys: ['actions.view'] }        
      ]
    );
    // Templates
    this.menuBuilderService.addMenuItem(
      this.menuItems, 
      { itemString: 'Templates', icon: 'template', link: '/admin-landing/more-templates' },
      'templates.view'
    );
    
    // Reports, Extracts
    this.menuBuilderService.addMenuItem(
      this.menuItems, 
      {
        itemString: 'Reports, Extracts', icon: 'charts', link: this.getReportsLikBasedOnDivision(), isExternal: true 
      },
      'reports.view'
    );

    // Evidence
    this.menuBuilderService.buildMenuItem(
      this.menuItems,
      { item: { itemString: 'Evidence', icon: 'evidence' }, compositeFeatureKeys: ['standardEvidence.view', 'mrrEvidence.view'] },
      [
        { item: { itemString: 'Material Risk', link: '/more-evidence/mrr' }, compositeFeatureKeys: ['mrrEvidence.view'] },
        { item: { itemString: 'Standard', link: '/more-evidence/standard' }, compositeFeatureKeys: ['standardEvidence.view'] }        
      ]
    );

    // Line break
    this.menuItems.push({ lineBreak: true });

    // Manage Stores
    this.menuBuilderService.addMenuItem(
      this.menuItems, 
      {
        itemString: 'Manage Stores', icon: 'store', link: '/store-dashboard'
      },
      'manageStores.view'
    );    

    // Access & Roles
    this.menuBuilderService.buildMenuItem(
      this.menuItems,
      { 
        item: { itemString: 'Access & Roles', icon: 'accessRole' }, 
        compositeFeatureKeys: ['accessManagement.viewUsers', 'accessManagement.viewRoles'] 
      },
      [
        { item: { itemString: 'Manage Access', link: '/access-management/users/manage' }, compositeFeatureKeys: ['accessManagement.viewUsers'] },
        { item: { itemString: 'Manage Roles', link: '/access-management/roles/manage' }, compositeFeatureKeys: ['accessManagement.viewRoles'] },
      ]
    );

    // Access & Roles - Support Options
    if (this.auth.isUserSupport() && this.featureAccessService.hasAccess('accessManagement.manageFeaturesAndPermissions') && this.auth.user?.domain === 'Woolworths') {
      this.menuBuilderService.appendSupportSubMenuItemsToAccessAndRoles(this.menuItems);
    }
    
    // Quick Links
    this.menuBuilderService.buildMenuItem(
      this.menuItems,
      { 
        item: { itemString: 'Quick Links', icon: 'quickLinks' }, 
        compositeFeatureKeys: [
          'charts.view', 
          'adhocAssessments.create', 
          'onceOffAssessments.create', 
          'recurringAssessments.create', 
          'mrrAssessments.create'
        ]  
      },
      [
        { item: { itemString: 'Charts', link: '/admin-landing/reports' }, compositeFeatureKeys: ['charts.view'] },
        {
          item: { itemString: 'Create Standard Assessment', linkCallback: this.openCreateNewAssessment }, 
          compositeFeatureKeys: ['adhocAssessments.create', 'onceOffAssessments.create', 'recurringAssessments.create'] 
        },
        {
          item: { itemString: 'Create Material Risk Assessment', linkCallback: this.openCreateMRRAssessment }, 
          compositeFeatureKeys: ['mrrAssessments.create']
        }
      ]
    ); 

    // TODO (2/4/24 AS): Leave this as a DR migration guide. Remove once the migration is completed and tested.
    // if (this.auth.isUserAdmin()) {
    //   if (this.auth.user?.division === 'Supermarkets') {
    //     this.menuItems = [
    //       {
    //         itemString: 'Dashboard',
    //         icon: 'dashboard',
    //         link: '/',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Assessments',
    //         icon: 'assessment',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Material Risk', link: '/more-mrr-surveys' },
    //           { itemString: 'Once Off', link: '/admin-landing/more-surveys' },
    //           { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           { itemString: 'Recurring', link: '/more-recurring-surveys' },
    //         ],
    //       },
    //       {
    //         itemString: 'Actions',
    //         icon: 'action',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Material Risk', link: '/mrr-actions-drill-down' },
    //           { itemString: 'Standard', link: '/actions-drill-down' },
    //         ],
    //       },
    //       {
    //         itemString: 'Templates',
    //         icon: 'template',
    //         link: '/admin-landing/more-templates',
    //       },
    //       {
    //         itemString: 'Reports, Extracts',
    //         icon: 'charts',
    //         link: this.getReportsLinkBasedOnRole(),
    //         isExternal: true,
    //       },
    //       {
    //         itemString: 'Evidence',
    //         icon: 'evidence',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Material Risk', link: '/more-evidence/mrr' },
    //           { itemString: 'Standard', link: '/more-evidence/standard' },
    //         ],
    //       },
    //       { lineBreak: true },
    //       {
    //         itemString: 'Access & Roles',
    //         icon: 'accessRole',
    //         isExpanded: true,
    //         children: [
    //           { itemString: 'Manage Access', link: '/access-management/users/manage' },
    //           {
    //             itemString: 'Manage Roles',
    //             link: '/access-management/roles/manage',
    //           },
    //         ],
    //       },
    //       {
    //         itemString: 'Quick Links',
    //         icon: 'quickLinks',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Charts', link: '/admin-landing/reports' },
    //           {
    //             itemString: 'Create Standard Assessment',
    //             linkCallback: this.openCreateNewAssessment,
    //           },
    //           {
    //             itemString: 'Create Material Risk Assessment',
    //             linkCallback: this.openCreateMRRAssessment,
    //           },
    //         ],
    //       },
    //     ];
    //   } else if (this.auth.user?.domain === 'Countdown') {
    //     this.menuItems = [
    //       {
    //         itemString: 'Dashboard',
    //         icon: 'dashboard',
    //         link: '/',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Assessments',
    //         icon: 'assessment',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Once Off', link: '/admin-landing/more-surveys' },
    //           { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           { itemString: 'Recurring', link: '/more-recurring-surveys' },
    //         ],
    //       },
    //       {
    //         itemString: 'Actions',
    //         icon: 'action',
    //         link: '/actions-drill-down',
    //       },
    //       {
    //         itemString: 'Templates',
    //         icon: 'template',
    //         link: '/admin-landing/more-templates',
    //       },
    //       {
    //         itemString: 'Reports, Extracts',
    //         icon: 'charts',
    //         link: this.getReportsLinkBasedOnRole(),
    //         isExternal: true,
    //       },
    //       {
    //         itemString: 'Evidence',
    //         icon: 'evidence',
    //         link: '/more-evidence/standard',
    //       },
    //       { lineBreak: true },
    //       {
    //         itemString: 'Manage Stores',
    //         icon: 'store',
    //         link: '/store-dashboard',
    //       },
    //       {
    //         itemString: 'Access & Roles',
    //         icon: 'accessRole',
    //         children: [
    //           { itemString: 'Manage Access', link: '/access-management/users/manage' },
    //           {
    //             itemString: 'Manage Roles',
    //             link: '/access-management/roles/manage',
    //           },
    //         ],            
    //       },
    //       {
    //         itemString: 'Quick Links',
    //         icon: 'quickLinks',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Charts', link: '/admin-landing/reports' },
    //           {
    //             itemString: 'Create Standard Assessment',
    //             linkCallback: this.openCreateNewAssessment,
    //           },
    //         ],
    //       },
    //     ];
    //   } else {
    //     this.menuItems = [
    //       {
    //         itemString: 'Dashboard',
    //         icon: 'dashboard',
    //         link: '/',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Assessments',
    //         icon: 'assessment',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Once Off', link: '/admin-landing/more-surveys' },
    //           { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           { itemString: 'Recurring', link: '/more-recurring-surveys' },
    //         ],
    //       },
    //       {
    //         itemString: 'Actions',
    //         icon: 'action',
    //         link: '/actions-drill-down',
    //       },
    //       {
    //         itemString: 'Templates',
    //         icon: 'template',
    //         link: '/admin-landing/more-templates',
    //       },
    //       {
    //         itemString: 'Reports, Extracts',
    //         icon: 'charts',
    //         link: this.getReportsLinkBasedOnRole(),
    //         isExternal: true,
    //       },
    //       {
    //         itemString: 'Evidence',
    //         icon: 'evidence',
    //         link: '/more-evidence/standard',
    //       },
    //       { lineBreak: true },
    //       {
    //         itemString: 'Access & Roles',
    //         icon: 'accessRole',
    //         children: [
    //           { itemString: 'Manage Access', link: '/access-management/users/manage' },
    //           {
    //             itemString: 'Manage Roles',
    //             link: '/access-management/roles/manage',
    //           },
    //         ],
    //       },
    //       {
    //         itemString: 'Quick Links',
    //         icon: 'quickLinks',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Charts', link: '/admin-landing/reports' },
    //           {
    //             itemString: 'Create Standard Assessment',
    //             linkCallback: this.openCreateNewAssessment,
    //           },
    //         ],
    //       },
    //     ];
    //   }
    //   if (this.auth.isUserSupport() && this.auth.user?.domain === 'Woolworths') {
    //     this.appendSupportSubMenuItemsToAccessAndRoles(this.menuItems);
    //   }
    // } else if (this.auth.isUserAboveStoreRole()) {
    //   if (this.auth.user?.division === 'Supermarkets') {
    //     this.menuItems = [
    //       {
    //         itemString: 'Dashboard',
    //         icon: 'dashboard',
    //         link: '/',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Assessments',
    //         icon: 'assessment',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Material Risk', link: '/more-mrr-surveys' },
    //           {
    //             itemString: 'Once Off',
    //             link: '/above-store-landing/above-store-more-surveys',
    //           },
    //           { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           { itemString: 'Recurring', link: '/more-recurring-surveys' },
    //         ],
    //       },
    //       {
    //         itemString: 'Actions',
    //         icon: 'action',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Material Risk', link: '/mrr-actions-drill-down' },
    //           { itemString: 'Standard', link: '/actions-drill-down' },
    //         ],
    //       },
    //       {
    //         itemString: 'Reports, Extracts',
    //         icon: 'charts',
    //         link: this.getReportsLinkBasedOnRole(),
    //         isExternal: true,
    //       },
    //       { lineBreak: true },
    //       {
    //         itemString: 'Quick Links',
    //         icon: 'quickLinks',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Charts', link: '/admin-landing/reports' },
    //         ],
    //       },
    //     ];

    //     if (this.auth.user.role === 'StoreLossPartner') {
    //       this.menuItems = [
    //         {
    //           itemString: 'Dashboard',
    //           icon: 'dashboard',
    //           link: '/',
    //           isExternal: false,
    //         },
    //         {
    //           itemString: 'Assessments',
    //           icon: 'assessment',
    //           isExpanded: false,
    //           children: [
    //             { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           ],
    //         },
    //         {
    //           itemString: 'Actions',
    //           icon: 'action',
    //           isExpanded: false,
    //           children: [
    //             { itemString: 'Standard', link: '/actions-drill-down' },
    //           ],
    //         },
    //         {
    //           itemString: 'Reports, Extracts',
    //           icon: 'charts',
    //           link: this.getReportsLinkBasedOnRole(),
    //           isExternal: true,
    //         },
    //       ];
    //     } else if (this.auth.user.role === 'StoreLossManager') {
    //       this.menuItems = [
    //         {
    //           itemString: 'Dashboard',
    //           icon: 'dashboard',
    //           link: '/',
    //           isExternal: false,
    //         },
    //         {
    //           itemString: 'Assessments',
    //           icon: 'assessment',
    //           isExpanded: false,
    //           children: [
    //             { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           ],
    //         },
    //         {
    //           itemString: 'Actions',
    //           icon: 'action',
    //           isExpanded: false,
    //           children: [
    //             { itemString: 'Standard', link: '/actions-drill-down' },
    //           ],
    //         },
    //         {
    //           itemString: 'Reports, Extracts',
    //           icon: 'charts',
    //           link: this.getReportsLinkBasedOnRole(),
    //           isExternal: true,
    //         },
    //         { lineBreak: true },
    //         {
    //           itemString: 'Quick Links',
    //           icon: 'quickLinks',
    //           isExpanded: false,
    //           children: [
    //             { itemString: 'Charts', link: '/admin-landing/reports' },
    //           ],
    //         },
    //       ];
    //     }
    //   } else {
    //     this.menuItems = [
    //       {
    //         itemString: 'Dashboard',
    //         icon: 'dashboard',
    //         link: '/',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Assessments',
    //         icon: 'assessment',
    //         isExpanded: false,
    //         children: [
    //           {
    //             itemString: 'Once Off',
    //             link: '/above-store-landing/above-store-more-surveys',
    //           },
    //           { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           { itemString: 'Recurring', link: '/more-recurring-surveys' },
    //         ],
    //       },
    //       {
    //         itemString: 'Actions',
    //         icon: 'action',
    //         isExpanded: false,
    //         children: [{ itemString: 'Standard', link: '/actions-drill-down' }],
    //       },
    //       {
    //         itemString: 'Reports, Extracts',
    //         icon: 'charts',
    //         link: this.getReportsLinkBasedOnRole(),
    //         isExternal: true,
    //       },
    //       { lineBreak: true },
    //       {
    //         itemString: 'Quick Links',
    //         icon: 'quickLinks',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Charts', link: '/admin-landing/reports' },
    //         ],
    //       },
    //     ];
    //   }
    //   if (this.auth.isUserRole('NationalView')) {
    //     if (this.auth.user?.division === 'Supermarkets') {
    //       const newMenuItem = {
    //         itemString: 'Evidence',
    //         icon: 'evidence',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Material Risk', link: '/more-evidence/mrr' },
    //           { itemString: 'Standard', link: '/more-evidence/standard' },
    //         ],
    //       };
    //       this.insertMenuItem(this.menuItems, newMenuItem, 'Reports, Extracts');
    //     } else {
    //       const newMenuItem = {
    //         itemString: 'Evidence',
    //         icon: 'evidence',
    //         link: '/more-evidence/standard',
    //       };
    //       this.insertMenuItem(this.menuItems, newMenuItem, 'Reports, Extracts');
    //     }
    //   }
    // } else if (this.auth.isUserStoreRole()) {
    //   if (this.auth.user?.division === 'Supermarkets') {
    //     this.menuItems = [
    //       {
    //         itemString: 'To Do List',
    //         icon: 'toDoList',
    //         link: '/to-do-list',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Dashboard',
    //         icon: 'dashboard',
    //         link: '/',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Assessments',
    //         icon: 'assessment',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Once Off', link: '/store-landing/more-surveys' },
    //           { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           { itemString: 'Recurring', link: '/more-recurring-surveys' },
    //           { itemString: 'Material Risk', link: '/more-mrr-surveys' },
    //         ],
    //       },
    //       {
    //         itemString: 'Actions',
    //         icon: 'action',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Standard', link: '/more-actions' },
    //           { itemString: 'Material Risk', link: '/mrr-more-actions' },
    //         ],
    //       },
    //     ];
    //   } else {
    //     this.menuItems = [
    //       {
    //         itemString: 'To Do List',
    //         icon: 'toDoList',
    //         link: '/to-do-list',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Dashboard',
    //         icon: 'dashboard',
    //         link: '/',
    //         isExternal: false,
    //       },
    //       {
    //         itemString: 'Assessments',
    //         icon: 'assessment',
    //         isExpanded: false,
    //         children: [
    //           { itemString: 'Once Off', link: '/store-landing/more-surveys' },
    //           { itemString: 'Adhoc', link: '/more-onthefly-surveys' },
    //           { itemString: 'Recurring', link: '/more-recurring-surveys' },
    //         ],
    //       },
    //       {
    //         itemString: 'Actions',
    //         icon: 'action',
    //         isExpanded: false,
    //         children: [{ itemString: 'Standard', link: '/more-actions' }],
    //       },
    //     ];
    //   }
    // }
  }

  public toggleMenuSection(menuItem: IMenuItem) {
    if (menuItem.children) {
      menuItem.isExpanded = !menuItem.isExpanded;
    }
  }

  /**
   * Navigates to store selection page.
   */
  public switchStoreAccess() {
    this.router.navigate(['/multi-store-selection']);
  }

  public navigateToLink(
    menuItem:
      | IMenuItem
      | { link: string; linkCallback: any; isExternal?: boolean }
  ) {
    // this.selectedMenuItemLink = menuItem?.link;
    if (menuItem.linkCallback) {
      /**
       * Using .call(this), allows the callback to have access to the scope of this component.
       */
      menuItem.linkCallback.call(this);
    } else if (menuItem.isExternal) {
      window.open(menuItem.link, '_blank');
    } else {
      this.router.navigateByUrl(menuItem.link);
    }

    // hide the menu after a link is clicked
    this.isResponsiveMenuOpen = false;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Navigates to store feedback. This link is different depending on the user's division.
   * Some domains/divisions have a survey link, others have an email.
   *
   * If there is a survey link, the supportEmail field (poorly named) on the user is a link to 'medallia',
   * otherwise it's an email..
   * If the supportEmail contains 'medallia', open the link.
   * Otherwise, opn a mailto link.
   *
   */
  public goToFeedback() {
    if (this.auth.user.supportEmail.includes('medallia')) {
      window.open(this.auth.user.supportEmail, '_blank');
    } else {
      alert(`Please send your feedback via ${this.auth.user.supportEmail}`);
    }
  }

  public switchUserAccess() {
    this.auth.clearOverrides();
    this.router.navigateByUrl('/support-landing');
  }

  public switchDivision() {
    this.router.navigateByUrl('/switch-division');
  }

  private getReportsLikBasedOnDivision(): string {
    switch (this.user?.division) {
      case 'Supermarkets':
        return 'https://datastudio.google.com/reporting/57f5fafb-e776-4ae1-8880-77f1a9a6b91d';
      case 'Metro':
        return 'https://datastudio.google.com/reporting/408f1c5c-da33-4a91-b75d-2a9c55d4ce78';
      case 'DC':
        return 'https://datastudio.google.com/reporting/3ce18dd9-a183-4e86-ac4f-f40dee5d8dd1';
      case 'CFC':
        return 'https://datastudio.google.com/reporting/74bbdae1-4592-466b-9a53-c8afb58cacc6';
      case 'Bigw':
        return 'https://datastudio.google.com/reporting/25e6c115-e482-479f-b9cd-5fb632d69436';
      case 'Woolworths NZ Stores':
        return 'https://datastudio.google.com/reporting/d99e6dd2-1316-4ace-a497-f691673205ce';
      case 'Woolworths NZ eStores':
        return 'https://datastudio.google.com/reporting/3308b33e-fb4f-497f-9325-a192606175d5';
      case 'Woolworths NZ Primary Connect':
        return 'https://datastudio.google.com/reporting/4cbb12f6-a497-49d5-a8b6-2f40f2c8ac88';
      default:
        return '';
    }
  }
}

// TODO (4/4/24 AS): Look into moving these interfaces into a more centralised shared location.
export interface IMenuItem {
  lineBreak?: boolean; // if true, the menu item will only render as a line break in the navigation
  itemString?: string;
  icon?: string;
  link?: string;
  linkCallback?: any;
  isExternal?: boolean;
  isExpanded?: boolean;
  children?: ISubMenuItem[];
}

export interface ISubMenuItem {
  itemString: string;
  link?: string;
  linkCallback?: any;
  isExternal?: boolean;
}

export interface IMenuItemFeature {
  item: IMenuItem;
  compositeFeatureKeys: string[];
}

export interface ISubMenuItemFeature {
  item: ISubMenuItem;
  compositeFeatureKeys:string[];
}
