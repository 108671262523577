<div class="close-container">
  <span class="info-icon med-grey" (click)="closeMe()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </span>
</div>
<mat-dialog-content>
  <div class="dialog-contents">
    <h1>Final Report</h1>

    <div class="question-options-row no-bottom-margin">
        <div class="survey-detail-block">
            <h4> Final Report received Date :</h4>
            <p>   {{ survey['finalReportDate'] | date :'dd MMM y'  }} </p>
        </div>

        <!-- <div class="survey-detail-block">
            <h4> Rating :</h4>
            <p> {{survey['rating']}}</p>
        </div> -->

    </div>

    <div class="question-options-row no-bottom-margin">
      <h4 class="survey-detail-block">Summary :</h4>
      <p class="left">{{ survey["summaryFindings"] }}</p>
    </div>



    <div class="question-options-row no-bottom-margin report-padding">
        <!-- extra filed for file upload	 -->

      <h4
        class="survey-detail-block"
        *ngIf="finalreport?.length > 0 || uploading"
      >
        Final Report :
      </h4>
      <div class="action-line" *ngFor="let photo of finalreport; let i = index">
        <div class="file-name">
          {{ photo["photoName"]?.slice(0, 25)
          }}<span *ngIf="photo['photoName']?.length > 25">...</span>
        </div>
        <div class="right-align hide-on-mobile">
          {{ photo["fileSize"] | filesize }}
        </div>
        <div class="right-align">
          <a
            (click)="
              viewfinalDoc(
                photo['photoName'],
                'final',
                photo['fileName'],
                false
              )
            "
          >
            View
          </a>
        </div>
      </div>

      <!-- 
  <div class="action-line" *ngIf="uploading == true">
    <div class="light-blue">Uploading...</div>
    <div class="light-blue right-align"><span class="padded-icon">
        <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
      </span></div>
  </div> -->

      <!-- <div class="action-buttons" *ngIf="canEdit">
  <div (click)="uploader.click()" [ngClass]="{
    'semi-disabled' : uploading == true,
    'disabled' : finalreport['finalReportPhoto']?.length  >= 10
  }" class="csa-button-solid action-button note-action-doc">
    <span class="action-icon">
      <fa-icon *ngIf="uploading == true" [icon]="faCircleNotch" [spin]="true"></fa-icon>
      <fa-icon *ngIf="uploading == false" [icon]="faPaperclip"></fa-icon>
    </span>
    Add Final Report
    <input hidden type="file" #uploader (click)="uploader.value = null"
      (change)="uploadDoc($event,1, surveyDetails['id'])" />
  </div>
</div> -->

      <!-- extra filed for file upload	 -->
    </div>
  </div>
</mat-dialog-content>
