import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faRedoAlt, faSortDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { getUserOrgLevel } from 'src/app/utils/user-roles/get-user-org-level';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

import { MessagesComponent } from '../../surveys/messages/messages.component';
import { StateService } from '../../common-services/state.service';
import { SortPipe } from '../../custom-pipes/sort.pipe';
import { User } from '../../models/user';

@Component({
  selector: 'app-mrr-actions-drill-down',
  templateUrl: './mrr-actions-drill-down.component.html',
  styleUrls: ['./mrr-actions-drill-down.component.scss']
})
export class MrrActionsDrillDownComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  compState = (val1, val2) => val1 == val2;

  faSortDown = faSortDown;

  faTimes = faTimes;

  faRedoAlt = faRedoAlt;

  actionGroups: string[] = ['Open', 'Complete'];

  actionsToShow: any[] = [];

  availableLocations: object = {};

  currentLevel: string = 'division';

  fullFilter: string = '';

  hasNext: string;

  index: number = 0;

  locationsOrdered: string[] = [];

  nextLevel: string;

  noEntries: string = '';

  noSurveys: string = 'No Surveys Found';

  orgStructure: object;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  searching: boolean = false;

  surveysSearching: boolean = false;

  searchTimer: NodeJS.Timeout;

  selectedLocations: object = {};

  selectedAssessment: string = 'All Assessments';

  selectedGroup: number = 0;

  selectedStore: object = { Store_No: '', Location_Name: 'All Stores' };

  showLocations: object = {};

  storeName: string = '';

  storeNameNumber: string = '';

  storeNumber: string = '';

  storesReturned: any[] = [];

  surveyFilter: string = '';

  surveysReturned: any[] = [];

  tableColumns: string[] = ['action', 'assessment', 'description', 'updates'];

  totalCount: number = 0;

  typeFilter: string = '';

  user: User;

  isLoading = false;

  constructor(
    private actRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private router: Router,
    private state: StateService,
    private sortPipe: SortPipe,
    private surveyService : SurveysService
  ) {}

  ngOnInit() {
    this.user = this.csaAuth.user;

    // show store column when data fetched spans multiple stores or the user is not a store level user.
    // This can be determined by the org structure i.e. when store level is found the data returned should only contain specific store information for one store.
    // This could change when multi store support via multi locations is rolled out.

    if (!this.user['storeID']) {
      this.tableColumns = [
        'action',
        'store',
        'assessment',
        'description',
        'updates',
      ];
    }

    this.typeFilter = '&filterName=mrr';

    if (this.state.state && this.state.origin == 'mrr-actions-drill-down') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }

      if (this.selectedStore['Location_Name'] != 'All Stores') {
        this.setStore(this.selectedStore);
      } else {
        this.getActions();
      }
    } else {
      this.getSurveysForLevel();
    }
  }

  ngOnDestroy() {
    this.setState();
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'mrr-actions-drill-down';
      this.state.state = {
        availableLocations: this.availableLocations,
        currentLevel: this.currentLevel,
        fullFilter: this.fullFilter,
        index: this.index,
        locationsOrdered: this.locationsOrdered,
        nextLevel: this.nextLevel,
        orgStructure: this.orgStructure,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedAssessment: this.selectedAssessment,
        selectedGroup: this.selectedGroup,
        selectedLocations: this.selectedLocations,
        selectedStore: this.selectedStore,
        showLocations: this.showLocations,
        store: this.user,
        storeName: this.storeName,
        storeNumber: this.storeNumber,
        surveyFilter: this.surveyFilter,
        typeFilter: this.typeFilter,
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  showReset(): boolean {
    if (!this.orgStructure) return false;
    if (this.selectedStore['Location_Name'] != 'All Stores') return true;
    if (this.selectedAssessment != 'All Assessments') return true;

    for (const key of Object.keys(this.selectedLocations)) {
      if (this.selectedLocations[key] && this.showLocations[key]) return true;
    }
    return false;
  }

  getPreviousLevel(level): string {
    return Object.keys(this.orgStructure).find((key) => this.orgStructure[key] == level);
  }

  resetFilters(): void {
    const selection = this.initialFilters();
    this.assessmentFilter('All Assessments');
    this.getActionsToShow(selection, this.currentLevel);
  }

  initialFilters(): string {
    let selection = '';
    let key = 'division';
    while (this.orgStructure[key]) {
      const value = this.orgStructure[key];
      if (this.user[value]) {
        if (value == 'groupID') {
          this.selectedLocations[value] = this.user['groupNo'];
        } else {
          this.selectedLocations[value] = this.user[value];
        }
      } else {
        this.selectedLocations[value] = '';
        this.showLocations[value] = false;

        if (this.locationsOrdered.indexOf(value) === -1) {
          this.locationsOrdered.push(value);
        }
      }
      key = value;
    }

    this.selectedStore = { Store_No: '', Location_Name: 'All Stores' };
    this.storeNumber = '';
    this.storeName = '';
    
    const userOrgLevel = getUserOrgLevel(this.user);
    if (userOrgLevel) {
      this.currentLevel = userOrgLevel['level'];
      selection = userOrgLevel['value'];
    }

    return selection;
  }

  getSurveysForLevel() {
    this.surveyService
      .getOrgStructure(this.user['division'])
      .subscribe((OrgStructure) => {
        this.orgStructure = OrgStructure;
        const selection = this.initialFilters();
        this.getActionsToShow(selection, this.currentLevel);
      });
  }

  getActionUpdates(actionID) {
    const actionUpdates = [];
    for (let i = 0; i < this.actionsToShow.length; i++) {
      if (
        this.actionsToShow[i]['workNotes']
        && actionID == this.actionsToShow[i]['id']
      ) {
        for (let j = 0; j < this.actionsToShow[i]['workNotes'].length; j++) {
          if (
            !(
              this.actionsToShow[i]['workNotes'][j]['notes']
                == 'Due date updated.'
              || this.actionsToShow[i]['workNotes'][j]['notes']
                == 'Status updated.'
            )
          ) {
            actionUpdates.push(this.actionsToShow[i]['workNotes'][j]['notes']);
          }
        }
      }
    }
    return actionUpdates.length;
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }

  calculateCheckEnabled(action) {
    if (
      moment().diff(moment(action['modifiedOn']), 'hours') > 24
      && action['status'] == 'complete'
    ) {
      action['checkEnabled'] = false;
    } else if (
      moment().diff(moment(action['modifiedOn']), 'hours') < 24
      && action['status'] == 'complete'
    ) {
      action['checkEnabled'] = true;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;

    if (this.selectedStore['Location_Name'] != 'All Stores') {
      this.setStore(this.selectedStore);
    } else {
      this.getActions();
    }
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    if (this.selectedStore['Location_Name'] != 'All Stores') {
      this.setStore(this.selectedStore);
    } else {
      this.getActions();
    }
  }

  resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
  }

  createLevelQuery(): string {
    let key = 'division';
    let query = '';
    while (key != this.nextLevel) {
      if (key == 'division') query = `?division=${this.user['division']}`;
      else query += `&${key}=${this.selectedLocations[key]}`;

      key = this.orgStructure[key];
    }

    return query;
  }

  locationString(key): string {
    if (key == 'state' || key == 'region') return this.selectedLocations[key];
    if (key == 'groupID') return `Group ${this.selectedLocations[key]}`;
    return `${key} ${this.selectedLocations[key]}`;
  }

  locationStringAll(key): string {
    if (key == 'groupID') key = 'group';
    return `All ${key}s`;
  }

  resetSelectedBelowLevel(): void {
    let key = this.nextLevel;
    while (this.orgStructure[key]) {
      this.selectedLocations[key] = '';
      this.showLocations[key] = (key == this.nextLevel);
      key = this.orgStructure[key];
    }
  }

  getActionsToShow(selection, level) {
    setTimeout(() => {
      this.isLoading = true;
      this.actionsToShow = [];
    }, 0);

    this.selectedStore = { Store_No: '', Location_Name: 'All Stores' };
    this.currentLevel = level;
    this.nextLevel = this.orgStructure[level];

    this.resetSelectedBelowLevel();
    if (selection) {
      this.selectedLocations[this.currentLevel] = selection;
    }

    if (this.orgStructure[this.nextLevel]) {
      this.surveyService
        .getNextLevel(this.createLevelQuery())
        .subscribe((data) => {
          this.availableLocations[this.nextLevel] = this.sortPipe.transform(data[this.nextLevel]);
          this.getActions();
        });
    } else {
      this.getActions();
    }

    this.cd.detectChanges();
  }

  getActions() {
    this.surveyService
      .getActionList(
        `/${this.index}`,
        `/${this.pageSize}`,
        this.createActionQuery()
      )
      .subscribe((message) => {
        this.actionsToShow = message['actions'];

        this.hasNext = message['has_next'];
        this.getActionDueDates();
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }

  createActionQuery(): string {
    const actionStatus = ['pending', 'complete'];
    let query = `?status=${
      actionStatus[this.selectedGroup]
    }${this.surveyFilter
    }${this.typeFilter}`;

    if (this.storeNumber) {
      query += `&storeID=${this.storeNumber}`;
      return query;
    }

    let key = 'division';
    while (key != this.nextLevel) {
      if (key == 'division') {
        query += `&${key}=${this.user['division']}`;
      } else {
        query += this.selectedLocations[key] ? `&${key}=${this.selectedLocations[key]}` : '';
      }
      key = this.orgStructure[key];
    }
    return query;
  }

  assessmentFilter(surveyTitle) {
    this.selectedAssessment = surveyTitle;
    if (surveyTitle == 'All Assessments') {
      this.surveyFilter = '';
    } else {
      this.surveyFilter = `&surveyTitle=${surveyTitle}`;
    }
    this.getActions();
  }

  setStore(store) {
    if (store['Location_Name'] == 'All Stores') {
      this.resetFilters();
    } else {
      this.selectedStore = store;
      this.storeNumber = store['Store_No'];
      this.storeName = store['Location_Name'];
      for (const key of Object.keys(this.showLocations)) {
        this.showLocations[key] = false;
      }
      this.getActions();
    }
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.actionsToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.actionsToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  getActionDueDates() {
    for (let i = 0; i < this.actionsToShow.length; i++) {
      this.calculateCheckEnabled(this.actionsToShow[i]);

      const actionDueDate = this.actionsToShow[i].dueDate;
      const m = moment(actionDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]);
      const currentDate = moment();
      const isDueInWeek = currentDate.diff(m, 'days') > -7 && currentDate.diff(m, 'days') <= 0;

      this.actionsToShow[i].overDue = m < currentDate;
      this.actionsToShow[i].isDueInWeek = isDueInWeek;
    }
  }

  searchForStores(storeId) {
    this.noEntries = 'Searching...';
    this.searching = true;

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(
      () => {
        if (this.user['division'] == 'DC') {
          this.noEntries = 'No matching DCs found..';
        } else {
          this.noEntries = 'No matching Stores found..';
        }
        if (storeId.trim()) {
          this.surveyService.getStoreList(storeId).subscribe((storeData) => {
            this.storesReturned = storeData;
            setTimeout(() => { this.searching = false; }, 1000);
          }, (error) => {
            this.searching = false;
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'An Error Has Occured',
                message: `An Error was returned when trying to find stores. Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok'
              },
              backdropClass: 'dialog-backdrop' 
            });
          });
        } else {
          this.storesReturned = [];
          this.searching = false;
        }
      },
      1000
    );
  }

  findSurveys(surveyTitle) {
    this.noSurveys = 'Searching...';
    this.surveysSearching = true;

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(
      () => {
        let filter = '';

        filter = '?filterName=mrr';

        if (surveyTitle.trim()) {
          this.surveyService.searchSurveys(surveyTitle + filter).subscribe(
            (storeData) => {
              this.surveysReturned = storeData['surveys'];
              this.noEntries = 'No matching Surveys found..';
              setTimeout(() => {
                this.surveysSearching = false;
              }, 1000);
            },
            (error) => {
              this.surveysSearching = false;
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'An Error Has Occured',
                  message:
                    `An Error was returned when trying to find surveys. Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
        } else {
          this.surveysReturned = [];
          this.surveysSearching = false;
        }
      },
      1000
    );
  }
}
