import {
  Component, Input, OnChanges 
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-to-do-list-item-card',
  templateUrl: './to-do-list-item-card.component.html',
  styleUrls: ['./to-do-list-item-card.component.scss']
})
export class ToDoListItemCardComponent implements OnChanges {
  @Input() buttonRouterLink: string | any[];

  @Input() buttonText: string = 'Continue';

  @Input() title: string;

  @Input() number: number;

  @Input() totalNumberOfItemsInList: number;

  @Input() completionPercentage: number;

  @Input() dueDate: string;

  public isDueToday = false;

  public isOverdue = false;

  constructor() { }

  ngOnChanges() {
    this.checkIfDueTodayOrOverdue();
  }

  /**
   * Checks if the due date of the assessment is today or past the due date.
   * Sets the value of isDuetoday and isOverdue.
   */
  checkIfDueTodayOrOverdue() {
    this.isDueToday = false;
    this.isOverdue = false;

    if (this.dueDate) {
      const today = moment();
      const dueDateMoment = moment(this.dueDate);

      if (dueDateMoment.isSame(today, 'day')) {
        this.isDueToday = true;
      }

      if (dueDateMoment.isBefore(today, 'day')) {
        this.isOverdue = true;
      }
    }
  }
}
