import {
  Component,
  Inject,
  OnInit,
  KeyValueChanges,
  KeyValueDiffer,
  KeyValueDiffers,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel, FormControl } from '@angular/forms';
import { faPaperPlane, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-create-mrr-finding-dialog',
  templateUrl: './create-mrr-finding-dialog.component.html',
  styleUrls: ['./create-mrr-finding-dialog.component.scss'],
})
export class CreateMRRFindingDialogComponent implements OnInit {
  edit: boolean = false;

  findingData = {};

  faInfoCircle = faInfoCircle;

  faPaperPlane = faPaperPlane;

  faTimes = faTimes;

  maxDate = new Date();

  minDate = new Date();

  noEntries = '';

  objDiffer = {};

  searching = false;

  showHelp = true;

  surveyData = {};

  surveyNameValidation = "[A-Za-z0-9\\&\\.\\,\\'\\?\\-\\n ]*";

  uploading = false;

  user: User;

  findingStatus;

  surveyID;

  riskLevels: string[] = ['Low', 'Medium', 'High', 'Very High', 'Non Rated'];

  private differ: KeyValueDiffer<string, any>;

  constructor(
    private differs: KeyValueDiffers,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private dialogRef: MatDialogRef<CreateMRRFindingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.user = this.csaAuth.user;
    this.surveyData = this.data.survey;
    this.getNotes(this.surveyData);
    this.surveyID = this.surveyData['id'];
    this.findingData = this.data.finding;
    if (this.findingData['title']) {
      this.findingStatus = 'edit';
    } else {
      this.findingStatus = 'create';
    }
    // setting up risk level default case as 'Non Rated'
    if (!this.findingData['riskLevel']) {
      this.findingData['riskLevel'] = 'Non Rated';
    }
  }

  getNotes(surveysData) {
    for (let i = 0; i < surveysData['questions'].length; i++) {
      for (let j = 0; j < surveysData['responses'].length; j++) {
        if (
          surveysData['responses'][j]['question_id']
          == surveysData['questions'][i]['question_id']
        ) {
          if (!surveysData['responses'][j]['actions']) {
            surveysData['questions'][i]['actions'] = [];
            surveysData['responses'][j]['actions'] = [];
          } else {
            surveysData['questions'][i]['actions'] = surveysData['responses'][j]['actions'];
          }
        }
      }
    }
  }

  closeMe() {
    this.dialogRef.close({ type: 'auto', finding: this.findingData });
  }

  toggleHelp() {
    this.showHelp = !this.showHelp;
  }

  initChanges() {
    this.findingData['createdBy'] = this.user['email'];

    this.differ = this.differs.find(this.findingData).create();
    const initial = this.differ.diff(this.findingData);
  }

  checkChanges() {
    if (
      !this.data.edit
      && Object.keys(this.findingData).length !== 0
    ) {
      return true;
    }

    for (const [key, value] of Object.entries(this.findingData)) {
      if (this.data.finding[key] != value) {
        return true;
      }
    }

    return false;
  }

  saveFinding(values) {
    if (values.actionDueDate) {
      this.findingData['dueDate'] = moment(values.actionDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
    }
    if (this.surveyData) this.dialogRef.close({ type: 'final', finding: this.findingData, edit: this.data.edit });
  }

  deleteFinding(finding) {
    this.dialogRef.close({ type: 'delete', finding: this.findingData });
  }

  addfindingData() {
    this.findingData['id'] = Math.floor((Math.random() * 100) + 1);
  }
}
