/**
 * A pipe that checks user access for a given feature using the FeatureAccessService.
 * This pipe accepts a composite 'feature.subfeature' key representing the feature to be checked.
 *
 * @example
 * ```
 * <div *ngIf="'actionDetails.viewEvidence' | accessCheck">
 *   <!-- Display content if user has access -->
 * </div>
 * ```
 */
import { Pipe, PipeTransform } from '@angular/core';
import { FeatureAccessService } from '../common-services/feature-access.service';

@Pipe({
  name: 'accessCheck'
})
export class AccessCheckPipe implements PipeTransform {
  constructor(private featureAccessService: FeatureAccessService) {}

  /**
   * Transforms a composite key representing a 'feature.subfeature' key into a boolean indicating whether the user has access to it.
   *
   * @param compositeKey - The composite key representing the 'feature.subfeature' key.
   * @returns A boolean indicating whether the user has access to the 'feature.subfeature' key.
   */
  transform(compositeKey: string): boolean {
    // Call the feature access service to check user access
    return this.featureAccessService.hasAccess(compositeKey);
  }
}
