import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupQuestions',
  pure: false
})
export class GroupQuestionsPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter((item) => (
      (filter['sectionHeading'] == 'All Questions' && !item['sectionHeading'])
          || item['sectionHeading'] === filter['sectionHeading']
    ));
  }
}
