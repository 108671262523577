
<button class="button tertiary" (click)="goToFeedback()">
    <app-icon iconName="feedback"></app-icon>
    Share Feedback
</button>
<button *ngIf="canAccessAsOtherRoles" class="button tertiary" (click)="switchUserAccess()">
    <app-icon iconName="switchUser"></app-icon>
    Switch User Access
</button>
<button *ngIf="canAccessAsOtherDivisions" class="button tertiary" (click)="switchDivision()">
    <app-icon iconName="store"></app-icon>
    Switch Division
</button>
<button *ngIf="canAccessAsOtherStores" class="button tertiary" (click)="switchStoreAccess()">
    <app-icon iconName="store"></app-icon>
    Switch Store
</button>                         
