<div class="page-container">
  <h1><span *ngIf="evidenceTypeRouter == 'mrr'">Material Risk </span>Evidence</h1>
  <div class="question-options-row">
    <div class="filter-chips">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=selectAssessment.open() color="primary" selected>
            {{(selectedAssessment ? selectedAssessment : 'All Assessments') | unescape}} <fa-icon [icon]="faSortDown">
            </fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #selectAssessment class="optionsDropDown" [(value)]="selectedAssessment">
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="true" [searching]="surveysSearching"
              placeholderLabel="Search by Assessment Title.." [noEntriesFoundLabel]="noEntries" name="searchValue"
              #searchValue ngModel (ngModelChange)="findSurveys($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option (click)="resetPagination(); assessmentFilter('')" [value]="">
            All Assessments
          </mat-option>
          <mat-option [disabled]="searching" *ngFor="let returnedSurvey of surveysReturned" [value]="returnedSurvey"
            (click)="resetPagination(); assessmentFilter(returnedSurvey)">
            {{returnedSurvey | unescape}}
          </mat-option>
        </mat-select>
      </div>

      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=selectSectionHeading.open() color="primary" selected>
            {{(selectedSectionHeading ? selectedSectionHeading : 'All Section Headings') | unescape}} <fa-icon
              [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #selectSectionHeading class="optionsDropDown" [(value)]="selectedSectionHeading">
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="true" [searching]="sectionHeadingsSearching"
              placeholderLabel="Search by Section Heading.." [noEntriesFoundLabel]="noEntries" name="searchValue"
              #searchValue ngModel (ngModelChange)="findSectionHeadings($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option (click)="resetPagination(); sectionHeadingFilter('')" [value]="">
            All Section Headings
          </mat-option>
          <mat-option [disabled]="searching" *ngFor="let returnedSectionHeading of sectionHeadingsReturned"
            [value]="returnedSectionHeading" (click)="resetPagination();sectionHeadingFilter(returnedSectionHeading)">
            {{returnedSectionHeading | unescape}}
          </mat-option>
        </mat-select>
      </div>

      <div class="chipDrop" *ngIf="evidenceTypeRouter === 'standard'">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=selectControlName.open() color="primary" selected>
            {{(selectedControlName ? selectedControlName : 'All Control Names') | unescape}} <fa-icon
              [icon]="faSortDown"></fa-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-select #selectControlName class="optionsDropDown" [(value)]="selectedControlName">
          <mat-option>
            <ngx-mat-select-search [clearSearchInput]="true" [searching]="controlNamesSearching"
              placeholderLabel="Search by Control Name.." [noEntriesFoundLabel]="noEntries" name="searchValue"
              #searchValue ngModel (ngModelChange)="findControlNames($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option (click)="resetPagination(); controlNameFilter('')" [value]="">
            All Control Names
          </mat-option>
          <mat-option [disabled]="searching" *ngFor="let returnedControlName of controlNamesReturned"
            [value]="returnedControlName" (click)="resetPagination();controlNameFilter(returnedControlName)">
            {{returnedControlName | unescape}}
          </mat-option>
        </mat-select>
      </div>

      <!-- Control name, seciton heading etc -->

      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)=storeSelect.open() color="primary" selected>
            <span *ngIf="selectedStore['Location_Name'] === 'All Stores' && user['division'] !== 'DC'">
              All Stores
            </span>
            <span *ngIf="selectedStore['Location_Name'] === 'All Stores' && user['division'] === 'DC'">
              All DCs
            </span>
            <span *ngIf="selectedStore['Location_Name'] !== 'All Stores'">
              {{selectedStore['Store_No']}} - {{selectedStore['Location_Name'] | unescape}}
            </span>
            <fa-icon [icon]="faSortDown"></fa-icon>
          </mat-chip>
          <mat-select #storeSelect class="optionsDropDown" [(value)]="selectedStore">
            <mat-option>
              <ngx-mat-select-search [clearSearchInput]="true" [searching]="searching"
                placeholderLabel="Search by Store Name or Number" [noEntriesFoundLabel]="noEntries" name="searchValue"
                #searchValue ngModel (ngModelChange)="searchForStores($event)"></ngx-mat-select-search>
            </mat-option>
            <mat-option (click)="resetPagination(); setStore()" [value]="">
              All {{user['division'] === 'DC' ? 'DCs' : 'Stores'}}
            </mat-option>
            <mat-option [disabled]="searching" *ngFor="let returnedStore of storesReturned"
              (click)="resetPagination(); setStore(returnedStore)" [value]="returnedStore">
              {{returnedStore['Store_No']}} - {{returnedStore['Location_Name'] | unescape}}
            </mat-option>
          </mat-select>
        </mat-chip-list>
      </div>

      <div class="chipDrop" *ngIf="showReset()">
        <mat-chip-list aria-label="Filters">
          <mat-chip class="clear-chip" (click)="resetFilters()" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faRedoAlt"></fa-icon>
            <span>Clear</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" [(selectedIndex)]="selectedTab"
        (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{(evidenceGroup | titlecase)}}" *ngFor="let evidenceGroup of evidenceGroups">
          <table mat-table [dataSource]="dataSource">

            <ng-container [matColumnDef]="key" *ngFor="let key of tableColumnObject">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"
                [ngClass]="{'hide-on-mobile': ['photoName', 'sectionHeading', 'controlName'].indexOf(key) > -1}">
                <h4>{{tableKeys[key]}}</h4>
              </th>
              <td mat-cell *matCellDef="let evidence" class="survey-details-link center-align cell-field"
                [style.--numShown]="tableColumnObject.length" [style.--numShownMobile]="tableColumnObject.length-3"
                [ngClass]="{'hide-on-mobile overflow-ellipsis': ['photoName', 'sectionHeading', 'controlName'].indexOf(key) > -1}">

                <ng-container *ngIf="evidence[key]; else notAvailable">
                  <span *ngIf="key === 'date'; else nonDateField">
                    {{ evidence[key] | date :'dd MMM y'  }}  
                  </span>

                  <ng-template #nonDateField>
                    <span>{{evidence[key]}}</span>
                  </ng-template>
                </ng-container>

                <ng-template #notAvailable>
                  <span class="light-grey medium-text">
                    None
                  </span>
                </ng-template>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumnObject" class="survey-heading-line"></mat-header-row>
            <mat-row *matRowDef="let evidence; columns: tableColumnObject; let i = index"
              (click)="getAllPhotos(i)" class="survey-line survey-line-link"></mat-row>
          </table>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>

          <div *ngIf="evidenceToShow[evidenceType]?.length == 0 && !isLoading">
            <h3 class="none-found-message">
              {{noEvidence[evidenceType]}}
            </h3>
          </div>
        </mat-tab>
      </mat-tab-group>
      <mat-paginator #paginator [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="totalCount"
        [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]=false (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>
</div>