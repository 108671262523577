<div class="page-container">
  <h1>{{surveyDetails['title'] | unescape}}</h1>

  <div class="question-options-row">
    <div class="filter-chips question-options">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)="cancelCloseAssessment()"
            *ngIf="cancelOrCloseAssessment && (surveyDetails['status'] === 'active' || surveyDetails['status'] === 'published')"
            color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faTimes"></fa-icon>
            <span *ngIf="surveyDetails['status'] === 'active'">
              Cancel Assessment
            </span>
            <span *ngIf="surveyDetails['status'] === 'published'">
              Close Assessment
            </span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <h2 class="left-align">Material Risk Review Assessment Details:</h2>

  <div class="audit-tool-cards-container">
    <div class="p2 audit-tool-card list-screen-card">
      <div class="question-options-row no-bottom-margin">
        <div class="survey-detail-block">
          <h4>Status:</h4>
          <p class="light-green medium-text">
            <span *ngIf="surveyDetails['status'] === 'active'">
              Not Yet Published
            </span>
            <span *ngIf="surveyDetails['status'] === 'published'">
              Published
            </span>
            <span *ngIf="surveyDetails['status'] !== 'active' && surveyDetails['status'] !== 'published'">
              {{surveyDetails['status'] | titlecase}}
            </span>
          </p>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['activeResponseCount']">
          <h4>Active Responses:</h4>
          <p>{{ surveyDetails['activeResponseCount']}}</p>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['filterName']">
          <h4>Assessment Type:</h4>
          <p>{{ surveyDetails['filterName'] | uppercase }}</p>
        </div>
      </div>

      <div class="question-options-row no-top-margin no-bottom-margin">
        <div class="survey-detail-block">
          <h4>Available From:</h4>
          <p *ngIf="!editStartDate">{{ surveyDetails['startDate']| date :'dd MMM y'  }}  <a
              *ngIf="(dateToday < minEndDate) && canEditAvailableStartDate" class="med-green"
              (click)="editStartDate = true; editEndDate = false; editValidity = false;">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </a></p>
          <form *ngIf="editStartDate" class="wide-form" #publishDateForm="ngForm"
            (submit)="submitForm3(publishDateForm, publishDateForm.value)">
            <mat-form-field class="right-margin date-field">
              <mat-label>Start Date</mat-label>
              <input (keydown)="false" name="publishDate" (click)="publishPicker.open()" [max]="maxStartDate"
                [min]="minStartDate" ngModel matInput [matDatepicker]="publishPicker" required>
              <mat-datepicker-toggle matSuffix [for]="publishPicker"></mat-datepicker-toggle>
              <mat-datepicker #publishPicker></mat-datepicker>
              <mat-error>Date after today and before selected End Date</mat-error>
            </mat-form-field>
            <button [disabled]="!publishDateForm.valid" class="csa-button-solid survey-submit"
              type="submit">Save</button>
            <button class="csa-button-outline" (click)="editStartDate = false;">Cancel</button>
          </form>
        </div>

        <div class="survey-detail-block">
          <h4>Available To:</h4>
          <p *ngIf="!editEndDate"> {{ surveyDetails['endDate']| date :'dd MMM y'  }} <a class="med-green"
              *ngIf="(dateToday < minEndDate) && canEditAvailableToDate"
              (click)="editEndDate = true; editStartDate = false; editValidity = false;">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </a></p>
          <form *ngIf="editEndDate" class="wide-form" #endDateForm="ngForm"
            (submit)="submitForm2(endDateForm, endDateForm.value)">
            <mat-form-field class="right-margin date-field">
              <mat-label>End Date</mat-label>
              <input (keydown)="false" name="dueDate" (click)="picker.open()" [min]="minEndDate" ngModel matInput
                [matDatepicker]="picker" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>Please select a date after the Start Date</mat-error>
            </mat-form-field>
            <button [disabled]="!endDateForm.valid" class="csa-button-solid survey-submit" type="submit">Save</button>
            <button class="csa-button-outline" (click)="editEndDate = false;">Cancel</button>
          </form>
        </div>
      </div>

      <div class="question-options-row no-top-margin" *ngIf="'tags.view' | accessCheck">
        <div class="survey-detail-block survey-detail-block-large">
          <h4 class="tags-heading">Tags:</h4>
          <div class="tags-container">
            <div *ngIf="!editTags">
              <div class="search-tags">
                <button 
                  *ngFor="let tag of tagList"
                  tabindex="-1"
                  class="chip-input"
                >
                  {{ tag }}
                </button>
                <p *ngIf="'tags.edit' | accessCheck" class="tag-edit-pencil-container">
                  <a (click)="editTags = true" class="med-green">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                </p>               
              </div>
            </div>
          </div>
          <div
            *ngIf="editTags"
            class="wide-form"
          >
            <app-tags-autocomplete-dropdown
              [tagList]="tagList"
              (tagListChanged)="tagListChanged($event)"
            >
            </app-tags-autocomplete-dropdown>
            <button
              class="csa-button-solid survey-submit"
              [disabled]="!haveTagsChanged()"
              (click)="editTags = false; saveTagChanges()"
            >
              Save
            </button>
            <button
              class="csa-button-outline"
              (click)="editTags = false; cancelTagChanges()"
            >
              Cancel
            </button>
          </div>
        </div>        
      </div>      

      <div class="question-options-row no-top-margin"
        *ngIf="user['divisionRoles'][user['role']] >= user['divisionRoles']['Admin']">
        <div class='survey-button survey-detail-block'>
          <h4 class="orange-text" *ngIf="surveyDetails['status'] === 'closed'">This Material Risk Review Assessment is no longer
            available.</h4>
          <a (click)="createMRR(surveyDetails)" *ngIf="surveyDetails['status'] === 'published'">
            <button class="csa-button-solid large">
              <span>
                <fa-icon [icon]="faPlus"></fa-icon>&nbsp;&nbsp;Create New
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>

  <app-results-table [surveyType]="'mrr'"
    *ngIf="surveyDetails['status'] !== 'active' && surveyDetails['status'] !== 'cancelled'"></app-results-table>
</div>
