<h5 class="core-body-lg">{{title | unescape}}</h5>
<div class="dueDate" *ngIf="dueDate">
    <div class="dateCopy">Due date</div>

    <div class="date" [class.warning]="isDueToday || isOverdue">
        <span *ngIf="isDueToday">Today, </span>
        <span *ngIf="!isDueToday">{{dueDate | date: 'EEEE, '}}</span>
        {{dueDate | date: 'd MMMM yyyy'}}
    </div>
</div>

<div class="itemSummary">
    <div>
        <button class="button small" [routerLink]="buttonRouterLink">
            {{buttonText}}
        </button>
    </div>

    <div class="percentage">
        <app-percentage-bar *ngIf="completionPercentage" [percentage]="completionPercentage" showPercentage="true"></app-percentage-bar>
    </div>

    <div>
        {{number}} of {{totalNumberOfItemsInList}}
    </div>
</div>