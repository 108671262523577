import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thank-you-mrr',
  templateUrl: './thank-you-mrr.component.html',
  styleUrls: ['./thank-you-mrr.component.scss']
})
export class ThankYouMrrComponent implements OnInit {
  thankYouType: any;

  constructor(private actRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.thankYouType = this.actRoute.snapshot.paramMap.get('id');
  }
}
